import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Divider, Row, Col, Select, Spin, Modal, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
// import 'antd/dist/antd.css';
import moment from "moment";
import _ from "lodash";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import * as debugLib from "debug";
import Button from "../../../t9UIComponents/t9Button";
import * as Service from "../../../core/Service";
import UserBookingListCalendar from "./UserbookingListCalender";
import BookNowRoomForm from "../../../components/BookNowRoomForm";

const debug = debugLib("app:pages/UserbookingList");

const involvedModelName = "company";
const selectedKey = "booking_list";
const tableIDName = "item_category_id";

// let sampleText = "apple";
const { Option } = Select;

const UserbookingListCalendarView = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  const [bookingInfoList, setBookingInfoList] = useState([]);
  const [unChangeData, setUnChangeData] = useState([]);
  const [dataSet, setDataset] = useState({
    companyUserList: {},
    bookingItemList: {},
  });

  const [itemFilter, setItemFilter] = useState([]);

  const [modalVisible, setModalVisible] = useState(false);

  // For if user choose "All" catergory (First Select button)
  // if isFiltered == 0 , the item select button (second select button) disabled , else , abled

  useEffect(() => {
    getBookingInfo();
  }, []);

  const handleItemFilterChange = (value) => {
    if (value.length > 0) {
      setItemFilter(value);
      setBookingInfoList(
        _.filter(unChangeData, (eachData) => {
          return _.includes(
            _.map(value, _.toInteger),
            eachData.booking_item_id
          );
        })
      );
    } else {
      setItemFilter([]);
      setBookingInfoList(unChangeData);
    }
  };

  const selectDate = (prevOrNext, focusWeek) => {
    let nextWeek = moment(focusWeek).endOf("week").unix();
    let previousWeek = moment(focusWeek).startOf("week").unix();
    getBookingInfo();
  };

  const getBookingInfo = async () => {
    setLoading(true);
    try {
      // Get & format calander data
      let resp = await Service.call("get", "/api/booking_info/calender");
      if (!resp || resp.status !== 1) {
        return notification.error({
          message: t("error"),
          description: t(resp.errorCode)
        });
      }
      let {
        bookingItemArr,
        bookingInfoArr,
        companyUserArr,
        bookingCategoryArr,
        bookingCategoryRefArr,
      } = resp.data;
      let keyBy_bookingItemArr = _.keyBy(bookingItemArr, "booking_item_id");
      let keyBy_companyUserArr = _.keyBy(companyUserArr, "company_user_id");

      let temp = _.map(bookingInfoArr, (eachBooking) => {
        let { company_user_id, booking_item_id, start_time, end_time } =
          eachBooking;
        let companyUserRc = keyBy_companyUserArr[company_user_id] || {};
        let { first_name, last_name, nickname, mobile } = companyUserRc;
        let bookingItemRc = keyBy_bookingItemArr[booking_item_id] || {};
        let { item_name } = bookingItemRc;
        return {
          ...eachBooking,
          first_name,
          last_name,
          nickname,
          mobile,
          item_name,
          start: dayjs.unix(start_time).toDate(),
          end: dayjs.unix(end_time).toDate(),
          title: item_name,
        };
      });
      setBookingInfoList(temp);
      setUnChangeData(temp);
      setDataset({
        companyUserList: companyUserArr,
        bookingItemList: bookingItemArr,
      });
    } catch (error) {
      console.error("error >>> ", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Row gutter={12}>
        <Col style={{ marginTop: 6 }}>
          <p>{t("item")}</p>
        </Col>
        <Col>
          <Select
            mode="multiple"
            style={{ width: 300 }}
            placeholder={t("search_item")}
            value={itemFilter}
            onChange={(value) => handleItemFilterChange(value)}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {_.map(dataSet.bookingItemList, (itemRc) => (
              <Option key={itemRc.booking_item_id}>{itemRc.item_name}</Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Divider />
      <Spin indicator={<LoadingOutlined />} spinning={loading}>
        <UserBookingListCalendar
          BookingInfoList={bookingInfoList}
          setLoading={setLoading}
          dataSet={dataSet}
          selectDateCallBack={(prevOrNext, focusWeek) => {
            // selectDate(prevOrNext, focusWeek);
          }}
        />
      </Spin>
      <Divider />
      <Modal
        title={t("book_now_title_new_booking")}
        footer={null}
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
        }}
      >
        <BookNowRoomForm
          openModal={(value) => {
            setModalVisible(value);
          }}
        />
      </Modal>
    </div>
  );
};

export default UserbookingListCalendarView;
