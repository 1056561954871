import React, { Component, useState, useEffect } from 'react';
import {
  Avatar, Button, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip
} from 'antd';
import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const BlogPostListTable = (props) => {
  const { t } = useTranslation()
  const involvedModelName = "company";
  const title = "Banner";
  const tableIDName = "banner_id";
  const app = useSelector((state) => state.app);
  const { STATIC_SERVER_URL } = app.config;
  const {
    dataObj, setModalVisible, setSelectedRecord, loading, deleteRecord, handleIsActiveChange, setRulesetModalVisible
  } = props;

  const [dataList, setDataList] = useState([]);
  const [pageSize, setPageSize] = useState(100);

  useEffect(() => {
    setDataList(dataObj);
  }, [dataObj]);

  const setTableHeader = () => {
    const columns = [
      {
        title: t('operation'),
        dataIndex: tableIDName,
        render: (value, record, key) => {
          return (
            <span>
              <Tooltip title={t('edit')}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4 }}
                  icon={<EditOutlined />}
                  // disabled={record.is_active ? true : false}
                  onClick={() => {
                    setModalVisible(true);
                    setSelectedRecord(record)
                  }}
                />
              </Tooltip>
              <Tooltip title={!record.is_active ? t("activate") : ""}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4 }}
                  icon={!record.is_active ? <CheckCircleOutlined /> : ""}
                  onClick={() => {
                    handleIsActiveChange(record.banner_id);
                  }}
                />
              </Tooltip>
              {/* <Popconfirm
                title={t('confirm_delete') + "?"}
                // onConfirm={() => handleDeleteRecord(record.blog_post_id)}
                okText={t('yes')}
                cancelText={t('no')}
              >
                <Tooltip title={t('delete')}>
                  <Button
                    shape="circle"
                    style={{ marginRight: 4 }}
                    // style={{ marginRight: 8}}
                    icon={<DeleteOutlined />}
                  // onClick={() => {
                  //   setSelectedRecord(record)
                  // }}
                  />
                </Tooltip>
              </Popconfirm> */}
            </span>
          )
        }
      },
      {
        title: t("banner"),
        dataIndex: "banner",
        render: (value, record, key) => {
          return (
            <div style={{
              backgroundColor: "#ccc", alignSelf: "center", justifyContent: "center", display: "flex"
            }}
            >
              <img src={`${STATIC_SERVER_URL}/${value}`} width={256} alt={value} />
            </div>
          );
        }
      },
    ];
    return columns;
  }

  const displayIsActive = (value) => {
    let displayStr = '';
    let tagColor = 'blue';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("active");
        tagColor = 'blue';
        break;
      case 0:
        displayStr = t("inactive");
        tagColor = 'red';
        break;
      default:
        displayStr = t("error")
        tagColor = 'red';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  return (
    <Layout>
      <Table
        rowKey={tableIDName}
        scroll={{ x: 'max-content' }}
        dataSource={dataList}
        columns={setTableHeader()}
        bordered
        size="small"
        pagination={{ pageSize }}
        onChange={(pagination, filters, sorter, extra) => {
          if (pageSize !== pagination.pageSize) {
            setPageSize(pagination.pageSize);
          }
        }}
        loading={loading}
      />
    </Layout>

  )
}

export default BlogPostListTable;
