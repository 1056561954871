export const SET_ADMIN = "SET_ADMIN";
export const SET_IS_ADMIN = "SET_IS_ADMIN";
export const SET_COMPANY_ADMIN = "SET_COMPANY_ADMIN";
export const SET_COMPANY_USER = "SET_COMPANY_USER";
export const SET_IS_COMPANY_USER = "SET_IS_COMPANY_USER";
export const SET_USER = "SET_USER";
export const SET_COMPANY = "SET_COMPANY";
export const SET_AUTH = "SET_AUTH";
export const SET_ORIGIN = "SET_ORIGIN";
export const SET_CONFIG = "SET_CONFIG";
export const SET_LOADING = "SET_LOADING";
export const SET_SIDER = "SET_SIDER";
export const SET_TIME_ZONE_OFFSET = "SET_TIME_ZONE_OFFSET";
export const SET_FLOOR_LIST = "SET_FLOOR_LIST";
