import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Row,
  Col,
  Popconfirm,
  Select,
  InputNumber,
  Tooltip,
  notification
} from "antd";
import {
  MinusCircleOutlined,
  PlusOutlined,
  DeleteOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import * as Service from "../core/Service";
import * as Main from "../core/Main";

const { Option } = Select;

const tableIDName = "membership_detail_id";
const MembershipDetailTable = (props) => {
  const {
    membershipPlanRc,
    getPlanDetail
  } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [onSubmitLoading, setOnSubmitLoading] = useState(false);
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    console.log("membershipPlanRc >>>", membershipPlanRc);
    const { membershipDetailList } = membershipPlanRc;
    setDataList(membershipDetailList);
  }, [props]);

  useEffect(() => {
    console.log("dataList >>>", dataList);
  }, [dataList]);

  const onChangeItemType = ({ item_type, index }) => {
    let cloneDataList = _.cloneDeep(dataList);
    cloneDataList[index].item_type = item_type;
    cloneDataList[index].item_id = null;
    setDataList(cloneDataList);
  };

  const onChangeDiscountType = ({ discount_type, index }) => {
    let cloneDataList = _.cloneDeep(dataList);
    cloneDataList[index].discount_type = discount_type;
    setDataList(cloneDataList);
  };

  const onChangeDiscountAmount = ({ discount_amount, index }) => {
    let cloneDataList = _.cloneDeep(dataList);
    cloneDataList[index].discount_amount = discount_amount;
    setDataList(cloneDataList);
  };

  const onChangeItem = ({ item_id, index }) => {
    let cloneDataList = _.cloneDeep(dataList);
    cloneDataList[index].item_id = item_id;
    setDataList(cloneDataList);
  };

  const columns = [
    {
      title: t("item_type"),
      dataIndex: "item_type",
      render: (value, record, index) => (
        <Select
          style={{ width: '100%' }}
          defaultValue={value}
          value={value}
          onChange={(type_value) => onChangeItemType({ item_type: type_value, index })}
        >
          {_.map(Main.ITEM_TYPE, (rcValue, rcKey) => (
            <Option key={rcKey} value={rcValue}>{t(rcKey)}</Option>
          ))}
        </Select>
      ),
    },
    {
      title: t("item"),
      dataIndex: "item_id",
      render: (value, record, index) => {
        let itemList = [];
        let keyByList = {};
        let itemIDName = "";
        let optionList = [];
        let itemName = "";
        switch (record.item_type) {
          case Main.ITEM_TYPE.BOOKING:
            itemList = membershipPlanRc.bookingItemList;
            itemIDName = "booking_item_id";
            keyByList = membershipPlanRc.keyByBookingItemList;
            itemName = "item_name";
            break;
          case Main.ITEM_TYPE.PRODUCT:
            itemList = membershipPlanRc.productItemList;
            itemIDName = "product_item_id";
            keyByList = membershipPlanRc.keyByProductItemList;
            itemName = "product_name";
            break;
          case Main.ITEM_TYPE.EVENT:
            break;
          default:
            break;
        }
        _.map(itemList, (rc) => {
          let itemRc = keyByList[rc[itemIDName]] || {};
          if (!_.isEmpty(itemRc)) {
            optionList.push({ label: itemRc[itemName], value: itemRc[itemIDName] });
          }
        });
        return (
          <Select
            style={{ width: '100%' }}
            defaultValue={value}
            value={value}
            onChange={(item_id) => onChangeItem({ item_id, index })}
            options={optionList}
          />
        );
      },
    },
    {
      title: t("discount_type"),
      dataIndex: "discount_type",
      render: (value, record, index) => (
        <Select
          style={{ width: '100%' }}
          defaultValue={value}
          onChange={(discount_type) => onChangeDiscountType({ discount_type, index })}
        >
          {_.map(Main.DISCOUNT_TYPE, (rcValue, rcKey) => (
            <Option key={rcKey} value={rcValue}>{t(rcKey)}</Option>
          ))}
        </Select>
      ),
    },
    {
      title: t("discount_amount"),
      dataIndex: "discount_amount",
      render: (value, record, index) => (
        <InputNumber
          min={0}
          defaultValue={value}
          onChange={(discount_amount) => onChangeDiscountAmount({ discount_amount, index })}
        />
      ),
    },
    {
      title: t("remove_discount"),
      dataIndex: "membership_detail_id",
      render: (value, record, index) => (
        <Popconfirm
          title={t("confirm_delete")}
          onConfirm={() => onDeleteMembershipDetail(value, index)}
          okText={t("yes")}
          cancelText={t("no")}
        >
          <Tooltip title={t("remove")}>
            <Button
              shape="circle"
              icon={<DeleteOutlined />}
              style={{ marginRight: 8 }}
            />
          </Tooltip>
        </Popconfirm>
      ),
    },
  ];


  const onCreateNewDiscount = async () => {
    let cloneDataList = _.cloneDeep(dataList);
    cloneDataList.push({
      discount_amount: 0,
      discount_type: Main.DISCOUNT_TYPE.PERCENTAGE,
      item_id: _.first(membershipPlanRc.bookingItemList).booking_item_id,
      item_type: Main.ITEM_TYPE.BOOKING,
      membership_detail_id: 0,
      membership_plan_id: membershipPlanRc.membership_plan_id,
    });
    setDataList(cloneDataList);
  };

  const onSubmitMembershipDetail = async () => {
    setOnSubmitLoading(true);
    try {
      let resp = await Service.call("post", "/api/membership/detail", {
        membershipDetailList: dataList
      });
      if (!resp || resp.status !== 1) {
        return notification.error({
          message: t("error"),
          description: t(resp.errorCode),
        });
      }
      getPlanDetail(membershipPlanRc.membership_plan_id);
    } catch (error) {
      console.error(error);
    } finally {
      setOnSubmitLoading(false);
    }
  };

  const onDeleteMembershipDetail = async (membership_detail_id, index) => {
    setLoading(true);
    let cloneDataList = _.cloneDeep(dataList);
    cloneDataList.splice(index, 1);
    try {
      if (membership_detail_id === 0) {
        setDataList(cloneDataList);
        return;
      }
      const resp = await Service.call("delete", "/api/membership/detail", { membership_detail_id });
      if (!resp || resp.status !== 1) {
        return notification.error({
          message: t("error"),
          description: t(resp.errorCode),
        });
      }
      setDataList(cloneDataList);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <p>
        {t("membership_detail")}
      </p>
      <Table
        rowKey={tableIDName}
        loading={loading}
        columns={columns}
        dataSource={dataList}
        pagination={false}
        scroll={{
          x: true,
        }}
      />
      <Button
        type="dashed"
        block
        icon={<PlusOutlined />}
        loading={loading}
        onClick={onCreateNewDiscount}
      >
        {t("create_new_membership_discount")}
      </Button>
      <Row justify="end" style={{ marginTop: "10px" }}>
        <Col>
          <Button
            type="primary"
            loading={onSubmitLoading}
            onClick={onSubmitMembershipDetail}
            style={{ marginLeft: 8 }}
          >
            {t("save")}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default MembershipDetailTable;
