import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Table,
  Layout,
} from "antd";
// import 'antd/dist/antd.css';
import _ from "lodash";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import NavAndSideFrame from "../../../components/NavAndSideFrame";
import BookingInfoReportModal from "./BookingInfoReportModal";
import BookingProductReportModal from "./BookingProductReportModal";

const debug = require("debug")("app:admin:client:src:AdvertisementList");

const title = "report";
const selectedKey = "export";
const openKeys = "report_key"

const PredefinedReport = (props) => {
  const { t } = useTranslation();
  const company_admin = useSelector((state) => state.app.company_admin);
  const [bookingInfoReportModal, setBookingInfoReportModal] = useState(false);
  const [bookingProductReportModal, setBookingProductReportModal] = useState(false);

  const tableList = [
    {
      label: "Booking info Report",
      description:
        "Specific period booked record (Include payment confirmed booking info only)",
      onClickFunc: () => {
        setBookingInfoReportModal(true);
      },
    },
    {
      label: "Booking Product Report",
      description:
        "Specific period booked product record (Include payment confirmed booking product only)",
      onClickFunc: () => {
        setBookingProductReportModal(true);
      },
    },
  ];

  const setTableHeader = () => {
    const columns = [
      {
        title: t("report"),
        dataIndex: "label",
        sorter: (a, b) => a.label.localeCompare(b.label),
      },
      {
        title: t("desc"),
        dataIndex: "description",
        sorter: (a, b) => a.description.localeCompare(b.description),
      },
      {
        title: t("operation"),
        render: (value, record) => {
          return (
            <span>
              <Button type="primary" onClick={record.onClickFunc}>
                {t("export")}
              </Button>
            </span>
          );
        },
      },
    ];
    return columns;
  };

  useEffect(() => {}, []);

  const renderModals = () => {
    const allModals = [
      {
        visible: bookingInfoReportModal,
        onCancel: () => {
          setBookingInfoReportModal(false);
        },
        FilterModal: (
          <BookingInfoReportModal
            // defaultList={defaultList}
            company_id={company_admin.company_id}
          />
        ),
      },
      {
        visible: bookingProductReportModal,
        onCancel: () => {
          setBookingProductReportModal(false);
        },
        FilterModal: (
          <BookingProductReportModal
            // defaultList={defaultList}
            company_id={company_admin.company_id}
          />
        ),
      },
    ];
    return _.map(allModals, (eachModal) => {
      return (
        <Modal
          destroyOnClose
          title="Filter selection"
          visible={eachModal.visible}
          footer={null}
          style={{ maxWidth: 800 }}
          width="90%"
          onCancel={eachModal.onCancel}
        >
          {eachModal.FilterModal}
        </Modal>
      );
    });
  };

  return (
    <NavAndSideFrame {...props} title={t('title')} selectedKey={selectedKey} openKeys={openKeys}>
      <Layout>
        <Table
          scroll={{ x: "100%" }}
          dataSource={tableList}
          columns={setTableHeader()}
        />
      </Layout>
      {renderModals()}
    </NavAndSideFrame>
  );
};

export default PredefinedReport;
