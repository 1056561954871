import React, { useEffect, useState, useCallback } from "react";
import { Form, Cascader } from "antd";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import * as Service from "../core/Service";

const CategoryCascader = ({ id, action, modalVisible, primary_id, parent_primary_id }) => {
  const { t } = useTranslation();
  const [categoryCascaderList, setCategoryCascaderList] = useState([]);

  useEffect(() => {
    getCategoryList();
  }, [modalVisible]);

  const getCategoryList = useCallback(async () => {
    let resp = await Service.call("get", action); // get all category
    const tempCategory = _.map(resp.data, (item) => {
      return {
        ...item,
        key: item[primary_id],
        label: item.category_name,
        value: item[primary_id],
        children: [], // default - no children
      };
    });

    const categoryOptions = [];
    _.map(tempCategory, (category) => {
      if (!category[primary_id]) {
        return;
      }

      const parent = _.find(tempCategory, {[primary_id]: category[parent_primary_id]});
      if (_.isUndefined(parent)) {
        categoryOptions.push(category);
        return;
      }

      if (_.isUndefined(parent.children)) {
        parent.children = [];
      }

      if (category[primary_id] === id) return;

      return parent.children.push(category);
    });

    setCategoryCascaderList(categoryOptions);
  }, []);

  return (
    <Form.Item label={t("parent_category")} name={`${parent_primary_id}`}>
      <Cascader placeholder={t("select")} options={categoryCascaderList} changeOnSelect />
    </Form.Item>
  );
};

export default CategoryCascader;
