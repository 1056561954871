import React from "react";
import _ from "lodash";
import {
  Form, Input, Collapse, Select, Col, Row
} from "antd";
import { useTranslation } from "react-i18next";
import LanguageList from "../data/LanguageList.json";
import TinymceWrapper from "./TinymceWrapper";


const {TextArea} = Input;

const { Panel } = Collapse;
const {Option} = Select;

const modelInputCollapseLayout = {
  labelAlign: "left",
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 }
  },
};

const TranslationFormItemInput = ({
  name,
  header,
  label,
  bordered = true,
  is_textarea = false,
  is_model_input = false,
  collapseCol = {},
  defaultCollapseOpen = true,
}) => {
  const [t] = useTranslation();

  return (
    <>
      <Col span={24}>
        <Form.Item
          label={label}
          name={name}
          rules={[
            {
              required: true,
              message: t("input_required"),
            },
          ]}
        >
          {is_textarea ? <TextArea rows={4} /> : <Input />}
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item {...collapseCol}>
          <Collapse bordered={bordered} defaultActiveKey={defaultCollapseOpen ? ["1"] : []} className="custom-collapse">
            <Panel header={`${header}`} key="1">
              {
                _.map(LanguageList, (value, key) => {
                  if (key !== process.env.DEFAULT_LOCALE) {
                    return (
                      <Form.Item
                        {...is_model_input ? modelInputCollapseLayout : {}}
                        key={`${name}_${key}`}
                        label={`${label} - ${value}`}
                        name={`${name}_${key}`}
                      >
                        {is_textarea ? <TextArea rows={4} placeholder={t("optional")} /> : <Input placeholder={t("optional")} />}
                      </Form.Item>
                    );
                  }
                })
              }
            </Panel>
          </Collapse>
        </Form.Item>
      </Col>
    </>
  );
};

const TranslationFormItemEditor = ({
  initialValue = {}, header, label, bordered = true, onChange, defaultName, name, is_model_input = false, collapseCol = {},
}) => {
  const [t] = useTranslation();
  return (
    <Row>
      <Col span={24}>
        <Form.Item
          label={label}
        >
          <TinymceWrapper
            initialValue={initialValue[defaultName]}
            onChange={(value) => onChange(value, defaultName)}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item {...collapseCol}>
          <Collapse bordered={bordered} className="custom-collapse">
            <Panel header={`${header}`}>
              {
                _.map(LanguageList, (value, key) => {
                  if (key !== process.env.DEFAULT_LOCALE) {
                    return (
                      <Form.Item
                        {...is_model_input ? modelInputCollapseLayout : {}}
                        key={value}
                        label={`${label} - ${value}`}
                      >
                        <TinymceWrapper
                          placeholder={t("optional")}
                          initialValue={initialValue[key]}
                          onChange={(value) => onChange(value, key)}
                        />
                      </Form.Item>
                    );
                  }
                })
              }
            </Panel>
          </Collapse>
        </Form.Item>
      </Col>
    </Row>
  );
};
// const TranslationFormImageUpload = ({ postBannerImage, header, label, bordered = true, onChange, defaultName }) => {
//   const [t] = useTranslation(['common']);
//   return (
//     <>
//       <div style={{marginBottom: '20px'}}>
//         <h4><span style={{color: 'red'}}>*</span> {label}</h4>
//         <MediaLibraryComponent selectCallback={(mediaRc) => onChange(mediaRc, defaultName)} mediaObj={postBannerImage[defaultName]} />
//       </div>
//       <Collapse bordered={bordered} className="custom-collapse">
//         <Panel header={`${header}`}>
//           {
//             _.map(LanguageList, (value, key) => {
//               if (key !== process.env.DEFAULT_LOCALE)
//               return (
//                 <div style={{marginBottom: '20px'}}>
//                   <h4>{label} - {value}</h4>
//                   <MediaLibraryComponent selectCallback={(mediaRc) => onChange(mediaRc, key)} mediaObj={postBannerImage[key]} />
//                 </div>
//               )
//             })
//           }
//         </Panel>
//       </Collapse>
//     </>
//   )
// }
const TranslationFormSelect = ({
  formInstance, name, header, label, isExternal, bordered = true, options
}) => {
  const [t] = useTranslation();
  const optionsGroupByLang = _.groupBy(options, "language");

  const onOptionSelected = (option, langkey) => {
    let lookupID = option.key.split("-")[0];
    _.map(LanguageList, (val, key) => {
      if (langkey !== key && key) {
        let [optionValue] = _.filter(optionsGroupByLang[key], {lookup_id: _.toInteger(lookupID)});
        if (optionValue) {
          if (key === process.env.DEFAULT_LOCALE) {
            formInstance.setFieldsValue({
              [`${name}`]: optionValue.internal_url
            });
          } else {
            formInstance.setFieldsValue({
              [`${name}_${key}`]: optionValue.internal_url
            });
          }
        }
      }
    });
  };
  return (
    <>
      <Form.Item
        label={label}
        name={name}
        rules={[
          {
            required: true,
            message: t("input_required"),
          },
        ]}
      >
        {isExternal ? (
          <Input
            placeholder={label}
          />
        ) : (
          <Select
            defaultValue=""
            style={{ width: "50%", marginRight: "10px" }}
            onSelect={(value, option) => onOptionSelected(option, "zh-HK")}
          >
            {
              _.map(optionsGroupByLang[process.env.DEFAULT_LOCALE], (val, key) => {
                return (
                  <Option key={`${val.lookup_id}-${val.internal_url}`} value={val.internal_url}>{val.internal_url}</Option>
                );
              })
            }
          </Select>
        )}
      </Form.Item>
      <Form.Item>
        <Collapse bordered={bordered} className="custom-collapse">
          <Panel header={`${header}`} key="1">
            {
              _.map(LanguageList, (value, key) => {
                if (key !== process.env.DEFAULT_LOCALE) {
                  return (
                    <>
                      <Form.Item
                        key={`${name}_${key}`}
                        label={`${label} - ${value}`}
                        name={`${name}_${key}`}
                      >
                        {isExternal ? (
                          <Input
                            placeholder={label}
                          />
                        ) : (
                          <Select
                            defaultValue=""
                            style={{ width: "50%", marginRight: "10px" }}
                            onSelect={(value, option) => onOptionSelected(option, key)}
                          >
                            {
                            _.map(optionsGroupByLang[key], (val, key) => {
                              return (
                                <Option key={`${val.lookup_id}-${val.internal_url}`} value={val.internal_url}>{val.internal_url}</Option>
                              );
                            })
                          }
                          </Select>
                        )}
                      </Form.Item>
                    </>
                  );
                }
              })
            }
          </Panel>
        </Collapse>
      </Form.Item>
    </>
  );
};

export {
  TranslationFormItemInput,
  TranslationFormItemEditor,
  // TranslationFormImageUpload,
  TranslationFormSelect
};
