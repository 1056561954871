import React, { useState, useEffect, useMemo } from "react";
import _ from "lodash";
import {
  Button,
  Form,
  Select,
  Spin,
  DatePicker,
  message,
  TimePicker,
  InputNumber,
  Input,
  Space,
  notification,
} from "antd";
import dayjs from "dayjs";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";
import * as Service from "../../../core/Service";

const { Option } = Select;
const { RangePicker } = TimePicker;
const { TextArea } = Input;
const format = "HH:mm";

const USER_PAYMENT_TYPE = {
  PAY_IMMEDIATELY: {
    key: 0,
    name: "即時付款",
  },
  PAY_MONTHLY: {
    key: 1,
    name: "月結付款",
  },
};

const CompanyUserBookingForm = (props) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { selectedRecord, modalVisible } = props;

  const [loading, setLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [userConfigRc, setUserConfigRc] = useState({});
  const [timeSlot, setTimeSlot] = useState([]);
  const [productList, setProductList] = useState([]);

  const [bookingItemDetail, setBookingItemDetail] = useState({
    bookingItemRc: {},
    bookingRulesetRc: {},
    bookingHolidayArr: [],
  });

  const [selectedField, setSelectedFields] = useState({
    selectedUser: null,
    selectedPaymentType: null,
  });

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const promise = [];
      promise.push(
        Service.call("get", "/api/company/user/list")
          .then((res) => {
            setUserList(res);
          })
          .catch((err) => {
            console.log(err);
          })
      );
      promise.push(
        Service.call("get", "/api/company/payment/method")
          .then((res) => {
            setPaymentMethod(res.data.companyPaymentMethodList);
          })
          .catch((err) => {
            console.log(err);
          })
      );
      await Promise.all(promise);
    }
    fetchData();
  }, []);

  useEffect(() => {
    setSelectedFields({
      selectedUser: null,
      selectedPaymentType: null,
    });
    form.resetFields();
  }, [modalVisible]);

  useEffect(() => {
    // get booking detail of this booking item
    if (selectedRecord.booking_item_id > 0) {
      getBookingDetail();
    }
  }, [selectedRecord]);

  const selectedDataOnChange = async (value) => {
    setSelectedDate(value);
    const start_time = value.startOf("day").unix();
    const end_time = value.endOf("day").unix();
    setLoading(true);
    await Service.call("get", "/api/booking_info/date/info", {
      booking_item_id: selectedRecord.booking_item_id,
      start_time,
      end_time,
    })
      .then((res) => {
        if (res.status === 1) {
          setProductList(res.data.bookingProductArr);
          setValidTimeSlot(value, res.data.bookedTimeslotArr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };

  const getBookingDetail = async () => {
    setLoading(true);
    try {
      let resp = await Service.call("get", `/api/booking_item/booking_detail/${selectedRecord.booking_item_id}`);
      if (resp.status !== 1) {
        message.error(resp.errorMessage);
        return;
      }
      let {
        bookingHolidayArr,
        bookingItemRc,
        bookingRulesetRc
      } = resp.data;
      setBookingItemDetail({
        bookingItemRc,
        bookingRulesetRc,
        bookingHolidayArr
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // const getDatesInRange = (
  //   start_date,
  //   end_date,
  //   duration_time,
  //   bookedTimeslotArr
  // ) => {
  //   console.log("bookedTimeslotArr >>>", bookedTimeslotArr);
  //   const start = selectedDate
  //     .startOf("day")
  //     .add(start_date.split(":")[0], "hours")
  //     .add(start_date.split(":")[1], "minutes")
  //     .unix();
  //   const end = selectedDate
  //     .startOf("day")
  //     .add(end_date.split(":")[0], "hours")
  //     .add(end_date.split(":")[1], "minutes")
  //     .unix();
  //   const result = [];
  //   let date = start;
  //   while (date <= end) {
  //     const tempDate = date;
  //     let notValid = false;
  //     let capacity = 0;
  //     _.map(bookedTimeslotArr, (item) => {
  //       if (
  //         tempDate >= item.start_time
  //         && tempDate + duration_time - 1 <= item.end_time
  //       ) {
  //         capacity += 1;
  //       }
  //     });
  //     if (!notValid) {
  //       result.push({
  //         start_time_ts: tempDate,
  //         end_time_ts: tempDate + duration_time - 1,
  //         start_time: moment.unix(tempDate).format("HH:mm"),
  //         end_time: moment
  //           .unix(tempDate)
  //           .add(duration_time, "seconds")
  //           .format("HH:mm"),
  //         capacity
  //       });
  //     }
  //     date += duration_time;
  //   }

  //   return result;
  // };

  const getDatesInRange = (
    dayPeriodArr,
    duration_time,
    bookedTimeslotArr,
    selectDate,
  ) => {
    const result = [];
    _.map(dayPeriodArr, (eachDayPeriod) => {
      let start_time = eachDayPeriod.start_time;
      let end_time = eachDayPeriod.end_time;
      const start = selectDate
        .startOf("day")
        .add(start_time.split(":")[0], "hours")
        .add(start_time.split(":")[1], "minutes")
        .unix();
      const end = selectDate
        .startOf("day")
        .add(end_time.split(":")[0], "hours")
        .add(end_time.split(":")[1], "minutes")
        .unix();
      let date = start;
      while (date <= end) {
        const tempDate = date;
        let capacity = 0;
        _.map(bookedTimeslotArr, (item) => {
          if (
            tempDate >= item.start_time
            && tempDate + duration_time - 1 <= item.end_time
          ) {
            capacity += 1;
          }
        });
        result.push({
          start_time_ts: tempDate,
          end_time_ts: tempDate + duration_time - 1,
          start_time: moment.unix(tempDate).format("HH:mm"),
          end_time: moment.unix(tempDate).add(duration_time, "s").format("HH:mm"),
          capacity,
        });
        date += duration_time;
      }
    });
    return result;
  };

  const setValidTimeSlot = (value, bookedTimeSlot = []) => {
    let day = value.locale("en").format("ddd").toLowerCase();
    const isHoliday = checkIsHoliday(value);
    if (isHoliday) {
      day = "holiday";
    }
    const time_slot = getDatesInRange(
      // bookingItemDetail.bookingRulesetRc[`${day}_start`],
      // bookingItemDetail.bookingRulesetRc[`${day}_end`],
      bookingItemDetail.bookingRulesetRc.timeSlot[day],
      bookingItemDetail.bookingRulesetRc.section_duration,
      bookedTimeSlot,
      value,
    );
    setTimeSlot(time_slot);
  };

  const checkIsHoliday = (moment_date) => {
    let holiday = false;
    let unix_date = moment_date.unix();
    _.each(bookingItemDetail.bookingHolidayArr, (eachHoliday) => {
      if (unix_date >= eachHoliday.start_time && unix_date <= eachHoliday.end_time) {
        holiday = true;
        return false;
      }
    });
    return holiday;
  };

  const userOptionMap = () => {
    return _.map(userList, (u) => {
      const { company_user_id, nickname, email } = u;
      return (
        <Option
          key={company_user_id}
          value={company_user_id}
        >
          {`${nickname}(${email}) #${company_user_id}`}

        </Option>
      );
    });
  };

  const userPaymentTypeMap = () => {
    return _.map(Object.keys(USER_PAYMENT_TYPE), (eachKeyStr) => {
      return (
        <Option
          key={eachKeyStr}
          value={USER_PAYMENT_TYPE[eachKeyStr].key}
          disabled={
            USER_PAYMENT_TYPE[eachKeyStr].key === 1
            && userConfigRc.payment_type === 0
          }
        >
          {USER_PAYMENT_TYPE[eachKeyStr].name}
        </Option>
      );
    });
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      if (_.isUndefined(selectedField.selectedUser) || _.isNull(selectedField.selectedUser)) {
        return notification.error({
          message: t("error"),
          description: t("user_is_empty")
        });
      }
      if (_.isUndefined(selectedField.selectedPaymentType) || _.isNull(selectedField.selectedPaymentType)) {
        return notification.error({
          message: t("error"),
          description: t("payment_type_is_empty")
        });
      }
      const payload = {};
      // GENEREATE DATA OF EACH BOOKING
      payload.booking_detail_list = values.booking_detail_list.map((item) => {
        let timeSlotStartRc = timeSlot[Number(
          item.time_duration
            .reduce((previousValue, currentValue) => {
              return currentValue < previousValue ? currentValue : previousValue;
            })
        )];
        let timeSlotEndRc = timeSlot[Number(
          item.time_duration
            .reduce((previousValue, currentValue) => {
              return currentValue > previousValue ? currentValue : previousValue;
            })
        )];
        let timeSlotStartUnix = dayjs.unix(timeSlotStartRc.start_time_ts).startOf("d").unix();
        let selectedDateUnix = dayjs(selectedDate).startOf("d").unix();
        const result = {};
        result.capacity = 1;
        result.item_type = 1;
        result.start_time = timeSlotStartRc.start_time_ts - timeSlotStartUnix + selectedDateUnix;
        result.end_time = timeSlotEndRc.end_time_ts - timeSlotStartUnix + selectedDateUnix;

        result.capacity = item.capacity;
        result.bookingProduct = [];

        _.map(productList, (product) => {
          if (item[product.product_item_id] > 0) {
            const json = {
              ...productList.filter((temp) => {
                return temp.product_item_id === Number(product.product_item_id);
              })[0],
              quantity: item[product.product_item_id],
            };
            json.price *= item[product.product_item_id];
            result.bookingProduct.push(json);
          }
          return product;
        });
        return result;
      });
      // END GENERATE DATA
      payload.payment_method = values.payment_method;
      payload.user_payment_type = selectedField.selectedPaymentType;
      payload.company_user_id = selectedField.selectedUser;
      payload.item_id = selectedRecord.booking_item_id;
      console.log(payload);
      const resp = await Service.call("put", "/api/order", payload).catch(
        (err) => {
          console.log(err);
        }
      );
      setLoading(false);
      props.openModal(false);
      const totalPrice = resp.data
        .map((item) => {
          return item.orderRc.final_price;
        })
        .reduce((previousValue, currentValue) => {
          return previousValue + currentValue;
        });
      if (resp.status === 1) {
        message.success(`${t("success")}, 總數 $${totalPrice}`);
      } else {
        message.error(t("fail"));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      // form.resetFields();
    }
  };

  const handleUpdateSelectedUser = async (value) => {
    try {
      setLoading(false);
      setSelectedFields((prevState) => (
        {
          ...prevState,
          selectedUser: value,
          selectedPaymentType: null,
        }));
      let resp = await Service.call("get", `/api/company/user/config/${value}`);
      if (!resp || resp.status !== 1) {
        return notification.error({
          message: t("error"),
          description: t(resp.errorCode),
        });
      }
      let { companyUserConfigRc } = resp.data;
      setUserConfigRc(companyUserConfigRc);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const isDisable = (date) => {
    const { effective_start, effective_end } = bookingItemDetail.bookingRulesetRc;
    let notValid = date.unix() < moment().startOf("day").unix();
    if (effective_start !== 0 && effective_end !== 0) {
      notValid = notValid || date.unix() < effective_start || date.unix() > effective_end;
    }
    // holiday checking
    const { holiday_start, holiday_end } = bookingItemDetail.bookingRulesetRc;
    if (!_.isEmpty(bookingItemDetail.bookingHolidayArr) && holiday_start === "" && holiday_end === "") {
      notValid = notValid || !_.every(bookingItemDetail.bookingHolidayArr, ({ is_active, start_time, end_time }) => {
        if (is_active === 1) {
          return date.startOf("day").unix() < start_time || date.startOf("day").unix() > end_time;
        }
        return true;
      });
    }

    // weekday checking
    const currentWeek = _.toLower(date.locale("en").format("ddd"));
    notValid = notValid || bookingItemDetail.bookingRulesetRc[`${currentWeek}_start`] === "" || bookingItemDetail.bookingRulesetRc[`${currentWeek}_end`] === "";

    if (bookingItemDetail.bookingRulesetRc.prebooking_type === 1) {
      notValid = notValid || date.unix() > moment().add(1, "week").startOf("day").unix();
    }
    if (bookingItemDetail.bookingRulesetRc.prebooking_type === 2) {
      notValid = notValid || date.unix() > moment().add(2, "week").startOf("day").unix();
    }
    if (bookingItemDetail.bookingRulesetRc.prebooking_type === 3) {
      notValid = notValid || date.unix() > moment().add(3, "week").startOf("day").unix();
    }
    return notValid;
  };

  return (
    <Spin spinning={loading}>
      <Form
        form={form}
        name="CategoryInfoForm"
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          label={t("book_now_form_user_id")}
          rules={[{ required: true, message: t("input_required") }]}
        >
          <Select
            showSearch
            value={selectedField.selectedUser}
            onChange={(value) => handleUpdateSelectedUser(value)}
            filterOption={(input, option) => option.props.children
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0}
          >
            {userOptionMap()}
          </Select>
        </Form.Item>
        <Form.Item
          label={t("payment_type")}
          rules={[{ required: true, message: t("input_required") }]}
        >
          <Select
            value={selectedField.selectedPaymentType}
            onChange={(value) => setSelectedFields((prevState) => (
              {
                ...prevState,
                selectedPaymentType: value
              }
            ))}
            showSearch
            filterOption={(input, option) => option.props.children
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0}
          >
            {userPaymentTypeMap()}
          </Select>
        </Form.Item>
        {selectedField.selectedPaymentType === 0 && (
          <Form.Item
            label={t("payment_method")}
            name="payment_method"
            rules={[{ required: true, message: t("input_required") }]}
          >
            <Select showSearch>
              {paymentMethod.map((item) => {
                return (
                  <Option
                    key={item.company_payment_method_id}
                    value={item.company_payment_method_id}
                  >
                    {item.payment_name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        )}
        <DatePicker
          onChange={(value) => {
            selectedDataOnChange(value);
          }}
          style={{ width: "100%" }}
          disabledDate={(date) => isDisable(date.startOf("day"))}
          placeholder="請選擇"
        />
        <Form.List name="booking_detail_list">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Input.Group key={key} style={{ width: "100%" }} compact>
                  <Form.Item
                    label={t("time")}
                    name={[name, "time_duration"]}
                    rules={[{ required: true, message: t("input_required") }]}
                    style={{ width: "45%", marginRight: "10px" }}
                  >
                    <Select
                      mode="multiple"
                      style={{ width: "100%" }}
                      placeholder="請選擇"
                      onChange={(value) => {
                        // validation for continuous value
                        const index = _.last(value);
                        const indexOf = _.indexOf(value, index);
                        const previous_timeslot_index = value[indexOf - 1];
                        if (!_.isUndefined(previous_timeslot_index)
                          && previous_timeslot_index !== index - 1
                          && previous_timeslot_index !== index + 1) {
                          let fieldsValue = form.getFieldsValue();
                          fieldsValue.booking_detail_list[key].time_duration = [index];
                          form.setFieldsValue(fieldsValue);
                        }
                      }}
                      showSearch
                    >
                      {_.map(timeSlot, (item, index) => {
                        return (
                          <Option
                            key={item.start_time}
                            value={index}
                            disabled={item.capacity >= bookingItemDetail.bookingRulesetRc.capacity}
                            label={`${item.start_time} - ${item.end_time}`}
                          >
                            {`${item.start_time} - ${item.end_time}`}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  {
                    _.map(productList, (item) => {
                      return (
                        <Form.Item
                          label={item.product_name}
                          name={[name, `${item.product_item_id}`]}
                          rules={[
                            { required: true, message: t("input_required") },
                          ]}
                          style={{ marginRight: "10px" }}
                        >
                          <InputNumber
                            style={{ marginLeft: "8px", marginRight: "8px" }}
                            min={0}
                            defaultValue={0}
                          />
                        </Form.Item>
                      );
                    })
                  }
                  <Form.Item
                    key={key}
                    name={[name, "capacity"]}
                    label={t("capacity")}
                    style={{ display: "none" }}
                  >
                    <InputNumber />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Input.Group>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => {
                    let defaultValues = {
                      capacity: 1,
                    };
                    _.map(productList, (item) => {
                      defaultValues[item.product_item_id] = 0;
                    });
                    add(defaultValues);
                  }}
                  block
                  icon={<PlusOutlined />}
                >
                  {t("create_new_time_slot")}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item style={{ marginTop: 20 }}>
          <Button type="primary" htmlType="submit">
            {t("submit")}
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default CompanyUserBookingForm;
