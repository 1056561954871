import React from "react";
import { Form, notification, Modal } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import * as Service from "../../../core/Service";
import Button from "../../../t9UIComponents/t9Button";
import { useHistory } from "react-router-dom";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const OrderInfoModel = (props) => {
  const { t } = useTranslation();
  const {
    dataObj, modalVisible, setModalVisible, displayCompanyUser
  } = props;
  const [form] = Form.useForm();
  const history = useHistory();

  const cancelBooking = async () => {
    try {
      let resp = await Service.call("patch", "/api/booking_info/cancel", {
        booking_id: dataObj.booking_id,
        company_user_id: dataObj.company_user_id,
      });
      if (!resp || resp.status !== 1) {
        return notification.error({
          message: t("error"),
          description: t(resp.errorCode)
        });
      }
      notification.success({
        message: t("success"),
      });
      return setModalVisible(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      title={t("details")}
      visible={modalVisible}
      footer={null}
      onCancel={() => {
        setModalVisible(false);
      }}
      bodyStyle={{ paddingBottom: "1px" }}
      style={{ width: "70vw", maxWidth: "410px" }}
    >
      <Form
        {...layout}
        form={form}
        name="BookingInfoForm"
        layout="horizontal"
        initialValues={dataObj}
        onFinish={cancelBooking}
      >
        <Form.Item label={t("name")}>{dataObj.item_name}</Form.Item>
        <Form.Item label={t("user")}>{displayCompanyUser(dataObj.company_user_id)}</Form.Item>
        <Form.Item label={t("no_of_resident")}>{dataObj.quantity - dataObj.guest_capacity}</Form.Item>
        <Form.Item label={t("no_of_visitor")}>{dataObj.guest_capacity}</Form.Item>
        <Form.Item label={t("start_booking_time")}>
          {dataObj.start_time
            ? moment.unix(dataObj.start_time).format("YYYY-MM-DD HH:mm")
            : ""}
        </Form.Item>
        <Form.Item label={t("end_booking_time")}>
          {dataObj.end_time
            ? moment.unix(dataObj.end_time).format("YYYY-MM-DD HH:mm")
            : ""}
        </Form.Item>
        <Form.Item label={t("section_price")}>
          {"$" + dataObj.section_price}
        </Form.Item>
        <Form.Item label={t("status")}>
          {t(dataObj.status)}
        </Form.Item>
        {/* {
          dataObj?.order_id && (
            <Form.Item wrapperCol={16} style={{ textAlign: "center" }}>
              <Button type="primary" onClick={() => history.push("/company/order/list?order_id=" + dataObj?.order_id)}>
                {t("view_order")}
              </Button>
            </Form.Item>
          )
        } */}
      </Form>
    </Modal>
  );
};

export default OrderInfoModel;
