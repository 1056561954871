import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  notification, Table, Tag, Tooltip, Button, Select
} from "antd";
import {
  CheckCircleOutlined, CloseCircleOutlined
} from "@ant-design/icons";
import Moment from "moment";
import _ from 'lodash';
import * as Service from "../../../core/Service";
import NavAndSideFrame from "../../../components/NavAndSideFrame";

const DisplayDeviceList = () => {
  const [loading, setLoading] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [displayList, setDisplayList] = useState([]);
  const {t} = useTranslation();
  const tableIDName = "display_device_id";
  const { Option } = Select;

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async() => {
    setLoading(true);
    const response = await Service.call("get", "/api/display/device");
    let {status, errorMessage, data} = response;
    if (status !== 1) {
      setLoading(false);
      return notification.error({
        message: t("error"),
        description: t(status.errorCode)
      });
    }
    const displayRc = await getDisplayList();
    let newData = getDisplayName(data, displayRc);
    setDisplayList(displayRc);
    setDataList(newData);
    setLoading(false);
  };

  const getDisplayName = (dataRc, displayRc) => {
    let displayKeyBy = _.keyBy(displayRc, "display_id");

    return _.map(dataRc, (data) => {
      let display_name = _.get(displayKeyBy, `${data.display_id}.display_name`) || "";
      return {
        ...data,
        display_name,
      };
    });
  };

  const getDisplayList = async() => {
    const response = await Service.call("get", "/api/display");
    const {status, errorMessage, data} = response;
    if (status !== 1) {
      setLoading(false);
      return notification.error({
        message: t("error"),
        description: t(`${response.errorCode}`)
      });
    }
    return data;
  };

  const displayIsActive = (value) => {
    let displayStr = "";
    let tagColor = "blue";
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("active");
        tagColor = "blue";
        break;
      case 0:
        displayStr = t("inactive");
        tagColor = "red";
        break;
      default:
        displayStr = t("error");
        tagColor = "red";
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  };


  const handleIsActiveChange = async (display_device_id, value) => {
    setLoading(true);
    let postData = {
      display_device_id,
      is_active: value,
    };
    let submit = await Service.call(
      "patch",
      "/api/display/device/is_active",
      postData
    );
    if (submit.status < 1) {
      notification.error({
        message: t("fail"),
        description: t(`${submit.errorCode}`),
      });
      setLoading(false);
    } else {
      notification.success({
        message: t("success"),
      });
      getAllData();
      setLoading(false);
    }
  };

  const handleDisplayChange = async(display_device_id, display_name) => {
    setLoading(true);
    const display_id = _.findLast(displayList, (display) => (display.display_name === display_name)).display_id;
    const response = await Service.call("patch", "/api/display/device/display_id", {
      display_device_id,
      display_id,
    });

    if (response.status < 1) {
      notification.error({
        message: t("fail"),
        description: t(`${response.errorCode}`),
      });
      setLoading(false);
    } else {
      notification.success({
        message: t("success")
      });
      getAllData();
      setLoading(false);
    }
  };

  const setTableHeader = () => {
    let columns = [
      {
        title: t("operation"),
        dataIndex: tableIDName,
        render: (value, record, key) => {
          return (
            <span>
              <Tooltip
                title={record.is_active ? t("deactivate") : t("activate")}
              >
                <Button
                  shape="circle"
                  style={{ marginRight: 8 }}
                  icon={
                    record.is_active ? (
                      <CloseCircleOutlined />
                    ) : (
                      <CheckCircleOutlined />
                    )
                  }
                  onClick={() => {
                    let is_active = record.is_active ? 0 : 1;
                    handleIsActiveChange(
                      record.display_device_id,
                      is_active
                    );
                  }}
                />
              </Tooltip>
            </span>
          );
        },
      },
      {
        title: t("is_active"),
        dataIndex: "is_active",
        sorter: (a, b) => a.is_active - b.is_active,
        render: (value) => displayIsActive(value)
      },
      {
        title: t("display"),
        dataIndex: "display_name",
        render: (value, record, key) => {
          return (
            <Select
              defaultValue={value}
              style={{
                width: 120,
              }}
              onChange={(e) => {
                handleDisplayChange(record.display_device_id, e);
              }}
            >
              { _.map(displayList, (display) => (
                <Option
                  key={display.display_id}
                  value={display.display_name}
                >
                  {display.display_name}
                </Option>
              ))}
            </Select>
          );
        }
      },
      {
        title: t("display_hash"),
        dataIndex: "display_hash"
      },
      {
        title: t("access_token"),
        dataIndex: "access_token"
      },
      {
        title: t("sync_time"),
        dataIndex: "sync_time",
        render: (value) => {
          return (
            Moment.unix(value).format("YYYY-MM-DD HH:mm")
          );
        }
      },
      {
        title: t("will_expire"),
        dataIndex: "expires_at",
        render: (value) => {
          return (
            Moment.unix(value).format("YYYY-MM-DD HH:mm")
          );
        }
      },
    ];
    return columns;
  };

  return (
    <NavAndSideFrame title={t("display_device_list")}>
      <Table
        rowKey={tableIDName}
        columns={setTableHeader()}
        dataSource={dataList}
        bordered
        size="small"
        pagination={25}
        loading={loading}
        scroll={{ x: "max-content" }}
      />
    </NavAndSideFrame>
  );
};

export default DisplayDeviceList;
