import React, { useState, useEffect } from "react";
import { Button, Layout, Popconfirm, Table, Tag, Tooltip } from "antd";
import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const CompanyButtonTable = (props) => {
  const { t } = useTranslation();
  const tableIDName = "company_button_id";
  const app = useSelector((state) => state.app);
  const {
    dataObj,
    setModalVisible,
    setSelectedRecord,
    loading,
    handleDeleteRecord,
    isActiveChangeCallBack,
    API_ORIGIN
  } = props;

  const [dataList, setDataList] = useState([]);
  const [pageSize, setPageSize] = useState(100);

  useEffect(() => {
    setDataList(dataObj);
  }, [dataObj]);

  const setTableHeader = () => {
    const columns = [
      {
        title: t("operation"),
        dataIndex: tableIDName,
        render: (value, record, key) => {
          return (
            <span>
              <Tooltip title={t("edit")}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4 }}
                  icon={<EditOutlined />}
                  onClick={() => {
                    setModalVisible(true);
                    setSelectedRecord(record);
                  }}
                />
              </Tooltip>
              <Tooltip
                title={record.is_active ? t("deactivate") : t("activate")}
              >
                <Popconfirm
                  title={`${record.is_active
                    ? t("confirm_deactivate")
                    : t("confirm_activate")
                    } ?`}
                  onConfirm={async () => {
                    let is_active = record.is_active ? 0 : 1;
                    isActiveChangeCallBack(
                      record.company_button_id,
                      is_active
                    );
                  }}
                  okText={t("yes")}
                  cancelText={t("no")}
                >
                  <Button
                    shape="circle"
                    style={{ marginRight: 4 }}
                    icon={
                      record.is_active ? (
                        <CloseCircleOutlined />
                      ) : (
                        <CheckCircleOutlined />
                      )
                    }
                  />
                </Popconfirm>
              </Tooltip>
              <Popconfirm
                title={t("confirm_delete") + " ?"}
                onConfirm={() => {
                  handleDeleteRecord(record.company_button_id);
                }}
                okText={t("yes")}
                cancelText={t("no")}
              >
                <Tooltip title={t("delete")}>
                  <Button
                    shape="circle"
                    style={{ marginRight: 4 }}
                    icon={<DeleteOutlined />}
                  />
                </Tooltip>
              </Popconfirm>
            </span>
          );
        },
      },
      {
        title: t("display_page"),
        dataIndex: "type",
        sorter: (a, b) => a.type.localeCompare(b.type),
      },
      {
        title: t("button_img"),
        dataIndex: "button_img",
        render: (value) => {
          return (
            <div>
              <img src={`${API_ORIGIN}/${value}`} width={100} height={100} alt={value} />
            </div>
          );
        }
      },
      {
        title: t("button_title"),
        dataIndex: "button_title",
        sorter: (a, b) => a.button_title.localeCompare(b.button_title),
      },
      {
        title: t("button_link"),
        dataIndex: "link",
        sorter: (a, b) => a.button_link.localeCompare(b.button_link),
      },
      {
        title: t("sorting"),
        dataIndex: "sorting",
        sorter: (a, b) => a.sorting - b.sorting,
      },
      {
        title: t("is_active"),
        dataIndex: "is_active",
        sorter: (a, b) => a.is_active - b.is_active,
        render: (value) => displayIsActive(value),
      },
    ];
    return columns;
  };

  const displayIsActive = (value) => {
    let displayStr = "";
    let tagColor = "blue";
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("active");
        tagColor = "blue";
        break;
      case 0:
        displayStr = t("inactive");
        tagColor = "red";
        break;
      default:
        displayStr = t("error");
        tagColor = "red";
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  };

  return (
    <Layout>
      <Table
        rowKey={tableIDName}
        scroll={{ x: "max-content" }}
        dataSource={dataList}
        columns={setTableHeader()}
        bordered
        size="small"
        pagination={{ pageSize }}
        onChange={(pagination, filters, sorter, extra) => {
          if (pageSize !== pagination.pageSize) {
            setPageSize(pagination.pageSize);
          }
        }}
        loading={loading}
      />
    </Layout>
  );
};

export default CompanyButtonTable;
