import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Select, notification, Tabs, Row, Col } from "antd";
// import 'antd/dist/antd.css';
import _ from "lodash";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import * as debugLib from "debug";
import * as Service from "../../../core/Service";
import UpcomingBookingTable from "./UpcomingBookingTable";
import PastBookingTable from "./PastBookingTable";
import BookingInfoModal from "./BookingInfoModal";

// import BookingCalendar from '../../components/BookingCalendar';

const { Option } = Select;

const debug = debugLib("app:pages/UserbookingListTableView");

const { TabPane } = Tabs;

// let sampleText = "apple";

const UserbookingListTableView = (props) => {
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [bookingInfoList, setBookingInfoList] = useState([]);
  const [unChangeData, setUnChangeData] = useState([]);
  const [dataSet, setDataset] = useState({
    companyUserList: {},
    bookingItemList: {},
  });
  const [selectedRecord, setSelectedRecord] = useState({});
  const [itemFilter, setItemFilter] = useState([]);
  const { t } = useTranslation();
  const formType = 1;

  useEffect(() => {
    getBookingInfo();
  }, []);

  const getBookingInfo = async () => {
    setLoading(true);
    try {
      // Get & format calander data
      let resp = await Service.call("get", "/api/booking_info/calender");
      if (!resp || resp.status !== 1) {
        return notification.error({
          message: t("error"),
          description: t(resp.errorCode)
        });
      }
      let {
        bookingItemArr,
        bookingInfoArr,
        companyUserArr,
        bookingCategoryArr,
        bookingCategoryRefArr,
      } = resp.data;
      let keyBy_bookingItemArr = _.keyBy(bookingItemArr, "booking_item_id");
      let keyBy_companyUserArr = _.keyBy(companyUserArr, "company_user_id");

      let temp = _.map(bookingInfoArr, (eachBooking) => {
        let { company_user_id, booking_item_id, start_time, end_time } =
          eachBooking;
        let companyUserRc = keyBy_companyUserArr[company_user_id] || {};
        let { first_name, last_name, nickname, mobile } = companyUserRc;
        let bookingItemRc = keyBy_bookingItemArr[booking_item_id] || {};
        let { item_name } = bookingItemRc;
        return {
          ...eachBooking,
          first_name,
          last_name,
          nickname,
          mobile,
          item_name,
          start: dayjs.unix(start_time).toDate(),
          end: dayjs.unix(end_time).toDate(),
          title: item_name,
        };
      });
      setBookingInfoList(temp);
      setUnChangeData(temp);
      setDataset({
        companyUserList: companyUserArr,
        bookingItemList: bookingItemArr,
      });
    } catch (error) {
      console.error("error >>> ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleItemFilterChange = (value) => {
    if (value.length > 0) {
      setItemFilter(value);
      setBookingInfoList(
        _.filter(unChangeData, (eachData) => {
          return _.includes(
            _.map(value, _.toInteger),
            eachData.booking_item_id
          );
        })
      );
    } else {
      setItemFilter([]);
      setBookingInfoList(unChangeData);
    }
  };

  return (
    <div>
      <Row gutter={12}>
        <Col style={{ marginTop: 6 }}>
          <p>{t("item")}</p>
        </Col>
        <Col>
          <Select
            mode="multiple"
            style={{ width: 300 }}
            placeholder={t("search_item")}
            value={itemFilter}
            onChange={(value) => handleItemFilterChange(value)}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {_.map(dataSet.bookingItemList, (itemRc) => (
              <Option key={itemRc.booking_item_id}>{itemRc.item_name}</Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Tabs type="line" defaultActiveKey="upcoming_booking">
        <TabPane tab={t("upcoming_booking")} key="upcoming_booking">
          <UpcomingBookingTable
            dataSet={dataSet}
            bookingInfoList={bookingInfoList}
            loading={loading}
            setLoading={setLoading}
            setModalVisible={setModalVisible}
            setSelectedRecord={setSelectedRecord}
          />
        </TabPane>
        <TabPane tab={t("past_booking")} key="past_booking">
          <PastBookingTable
            dataSet={dataSet}
            bookingInfoList={bookingInfoList}
            loading={loading}
            setLoading={setLoading}
            setModalVisible={setModalVisible}
            setSelectedRecord={setSelectedRecord}
          />
        </TabPane>
      </Tabs>
      <BookingInfoModal
        formType={formType}
        dataObj={selectedRecord}
        dataSet={dataSet}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      />
    </div>
  );
};

export default UserbookingListTableView;
