import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Button,
  Form,
  Select,
  Spin,
  DatePicker,
  message,
  TimePicker,
  InputNumber,
} from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import * as Service from "../core/Service";

const { Option } = Select;
const { RangePicker } = TimePicker;

const BookNowRoomForm = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [categoryList, setCategoryList] = useState([]);
  const [itemList, setItemList] = useState([]);

  const [selectedItemCategoryID, setSelectedItemCategoryID] = useState(null);
  const [selectedItem, setSelectedItem] = useState({});

  const [userList, setUserList] = useState(null);

  const { t } = useTranslation();

  const company_admin = useSelector((state) => state.app.company_admin);

  useEffect(() => {
    getUserList();
    getCategoryList();
  }, []);

  useEffect(() => {
    getItemList();
  }, [selectedItemCategoryID]);

  useEffect(() => {
    form.setFieldsValue({
      date_period: null,
      time_period: null,
      quota: selectedItem.quota,
    });
  }, [selectedItem]);

  const getUserList = async () => {
    setLoading(true);
    let userArr = await Service.call("get", `/api/company/user/list`);
    setUserList(userArr);
    setLoading(false);
  };

  const getCategoryList = async () => {
    setLoading(true);
    let categoryArr = await Service.call(
      "get",
      "/api/item/category/list/current"
    );
    setCategoryList(categoryArr);
    setLoading(false);
  };

  const getItemList = async () => {
    setLoading(true);
    setSelectedItem({});
    form.setFieldsValue({
      item_id: null,
    });
    let { company_id } = company_admin;
    let itemArr = await Service.call("get", `/api/item/list/current`, {
      company_id,
    });
    let filterList = _.filter(itemArr, {
      item_category_id: selectedItemCategoryID,
    });
    setItemList(filterList);
    setLoading(false);
  };

  const onFinish = async (putObj) => {
    setLoading(true);
    let clonePutObj = _.clone(putObj);
    // company_user_id, item_category_id, item_id, date, time_period, (quota)
    let start_time = putObj.date.startOf('d').unix() + getDiffHHmm('00:00', _.head(putObj.time_period).format('HH:mm'));
    // Addition + 1 in file info.js, function createBooking
    let end_time = putObj.date.startOf('d').unix() + getDiffHHmm('00:00', _.last(putObj.time_period).format('HH:mm')) - 1;
    clonePutObj = {
      ...clonePutObj,
      start_time,
      end_time,
    };

    if (!putObj.quota) {
      clonePutObj = {
        ...clonePutObj,
        quota: 1
      };
    }
    let resp = await Service.call('put', '/api/order', clonePutObj);
    if (resp.status !== 1) {
      message.error(resp.errorMessage);
    }
    message.success('success');
    setLoading(false);
    return props.openModal(false);
  };

  const getDiffHHmm = (startHHmm, endHHmm) => {
    let s_time = startHHmm.split(":");
    let e_time = endHHmm.split(":");
    let diff_time =
      Date.UTC(
        1970,
        0,
        1,
        _.toInteger(e_time[0]) - _.toInteger(s_time[0]),
        _.toInteger(e_time[1]) - _.toInteger(s_time[1]),
        0
      ) / 1000;
    return diff_time;
  }

  const userOptionMap = () => {
    return _.map(userList, (u) => {
      const { company_user_id, nickname, email } = u;
      return (
        <Option value={company_user_id}>{`${nickname}(${email}) #${company_user_id}`}</Option>
      );
    });
  };

  return (
    <Spin spinning={loading}>
      <Form
        name="BookNowRoomForm"
        layout="vertical"
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          label={t("book_now_form_user_id")}
          name="company_user_id"
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
          >
            {userOptionMap()}
          </Select>
        </Form.Item>

        <Form.Item
          label={t("book_now_form_category")}
          name="item_category_id"
          rules={[{ required: true }]}
        >
          <Select
            onChange={(value) => {
              setSelectedItemCategoryID(value);
            }}
          >
            {_.map(categoryList, (room) => {
              let { item_category_id, category_name, is_active } = room;
              return (
                <Option
                  value={item_category_id}
                  disabled={!is_active}
                  key={item_category_id}
                >
                  {category_name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          label={t("book_now_form_item_room")}
          name="item_id"
          rules={[{ required: true }]}
        >
          <Select
            onChange={(value) => {
              setSelectedItem(_.find(itemList, { item_id: value }));
            }}
            disabled={!selectedItemCategoryID}
          >
            {_.map(itemList, (item) => {
              let { item_id, item_name, is_active } = item;
              return (
                <Option value={item_id} disabled={!is_active} key={item_name}>
                  {item_name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          label={t("book_now_form_date")}
          name="date"
          rules={[{ required: true, message: t("please_select_date") }]}
        >
          <DatePicker
            placeholder={t("select_time")}
            style={{ width: "100%" }}
            disabled={_.isEmpty(selectedItem)}
          />
        </Form.Item>

        <Form.Item
          label={t("book_now_form_time")}
          name="time_period"
          rules={[{ required: true }]}
        >
          <RangePicker placeholder={[t("start_time"), t("end_time")]} disabled={_.isEmpty(selectedItem)} format="HH:mm" />
        </Form.Item>
        {selectedItem.quota > 1 && (
          <Form.Item
            label={t("quota")}
            name="quota"
            rules={[{ required: true, type: "integer" }]}
          >
            <InputNumber
              placeholder={`Max: ${selectedItem.quota}`}
              min={1}
              max={selectedItem.quota}
            />
          </Form.Item>
        )}

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t("submit")}
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default BookNowRoomForm;
