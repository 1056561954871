import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Button,
  Divider,
  Form,
  Input,
  Tag,
  Row,
  Col,
  Card,
  Tooltip,
  Select,
  InputNumber,
  notification,
  Spin
} from "antd";
import * as Service from "../../core/Service";
import { useHistory, useLocation, useParams } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";


const { TextArea } = Input;
const { Option } = Select;


const SmartAccessAPISettingForm = (props) => {
  const { t } = useTranslation();
  const { initialValues } = props;
  const id = !_.isEmpty(initialValues) ? initialValues.company_id : 0;
  const history = useHistory();
  const app = useSelector((state) => state.app);
  const [UserForm] = Form.useForm();
  const [dataObj, setDataObj] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData()
  }, [initialValues])

  useEffect(() => {
    UserForm.resetFields();
    if (dataObj) {
      UserForm.setFieldsValue(dataObj);
    }
  }, [dataObj]);

  const fetchData = async () => {
    setLoading(true);
    let url = `/api/admin/sa_api/${id}`
    let method = "get"

    try {
      const resp = await Service.call(method, url)
      if (!resp || resp.status !== 1) {
        return notification.error({
          message: t(`error`),
          description: t(resp.errorCode)
        })
      }

      setDataObj(resp.data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false);
    }
  }

  const onFinish = async (formData) => {
    // set loading
    setLoading(true);
    let postData = { ...dataObj, ...formData, company_id: id};

    console.log("postData>>>", postData);

    let url = "/api/admin/sa_api";
    let method = "post"
    // dataObj have sa_api_id
    // method => patch
    try {
      if (dataObj.sa_api_id > 0) {
        method = "patch"
      }

      let resp = await Service.call(method, url, postData)
      console.log(resp)
      if (resp.status !== 1) {
        return notification.error({
          message: t(`error`),
          description: t(resp.errorCode),
        });
      }
      setDataObj(resp.data);
      notification.success({
        message: t("success"),
      });
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false);
    }
  };
  return (
    <Spin spinning={loading}>
      <Row gutter={[24, 24]}>
        <Col md={24} lg={16}>
          <Card>
            <Form
              form={UserForm}
              name="UserForm"
              layout="vertical"
              onFinish={onFinish}
            >
              <Form.Item
                label={t("api_origin")}
                name="api_origin"
                rules={[{
                  required: true,
                  message: t('input_required')
                }]}
              >
                <Input disabled={!app.isAdmin} maxLength={255} />
              </Form.Item>

              <Form.Item
                label={t("api_key")}
                name="api_key"
                rules={[{
                  required: true,
                  message: t('input_required')
                }]}
              >
                <Input disabled={!app.isAdmin} maxLength={32} />
              </Form.Item>

              <Form.Item
                label={t("api_secret")}
                name="api_secret"
                rules={[{
                  required: true,
                  message: t('input_required')
                }]}
              >
                <Input disabled={!app.isAdmin} maxLength={32} />
              </Form.Item>

              <Form.Item
                label={t("company_key")}
                name="company_key"
                rules={[{
                  required: true,
                  message: t('input_required')
                }]}
              >
                <Input disabled={!app.isAdmin} maxLength={32} />
              </Form.Item>

              <Form.Item
                label={t('active_status')}
                name="is_active"
                initialValue={1}
                rules={[{
                  required: true,
                  message: t('select_required')
                }]}
              >
                <Select disabled={!app.isAdmin}>
                  <Option value={1}>{t('active')}</Option>
                  <Option value={0}>{t('inactive')}</Option>
                </Select>
              </Form.Item>

              <Form.Item
                label={t("remarks")}
                name="remark"
              >
                <TextArea rows={4} disabled={!app.isAdmin} maxLength={255} />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={!app.isAdmin}
                  loading={loading}
                >
                  {t("submit")}
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </Spin>
  );
};

export default SmartAccessAPISettingForm;
