import React, { Component } from "react";
import {
  Layout, Button, Avatar, Row, Col
} from "antd";
import "antd/dist/antd.css";
import {connect} from "react-redux";
import { bindActionCreators } from "redux";
import { MenuOutlined } from "@ant-design/icons";
import { Redirect} from "react-router-dom";
import _ from "lodash";
import { ActionCreators } from "../redux/actions";
import logo from "../assets/Logo_White.png";
import LanguageSelector from "./LanguageSelector";

const { Header } = Layout;

class Navbar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      redirectHome: false,
    };
  }

  redirectHome() {
    this.setState({redirectHome: true});
  }

  render() {
    const {
      admin,
      company_admin,
      company_user,
      company,
    } = this.props.app;

    const {
      STATIC_SERVER_URL
    } = this.props.app.config;

    let nickname = "";
    if (company_user.company_user_id != null) {
      nickname = company_user.nickname;
    }

    if (company_admin.company_admin_id != null) {
      nickname = company_admin.nickname;
    }

    if (admin.admin_id != null) {
      nickname = admin.nickname;
    }

    let companyLogo = "";
    if (company && !_.isUndefined(company.logo) && !_.isEmpty(company.logo)) {
      companyLogo = `${STATIC_SERVER_URL}/media/${company.logo}`;
    } else {
      companyLogo = logo;
    }

    return (
      <Header
        className="header"
        style={{
          // background: "linear-gradient(to right, #000046, #1cb5e0)",
          background: "linear-gradient(to right, #fff, #1cb5e0)",
          height: "100%"
        }}
      >
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <div>
            {/* <a href="/company/home"> */}
            {/* <img style={{height: 50}} src={companyLogo} alt="" /> */}
            <img style={{height: 80}} src="/upper_south_logo.png" alt="" />
            {/* </a> */}
          </div>
          <Row align="middle">
            <Col xs={0} sm={24}>
              <LanguageSelector />
              <Avatar style={{margin: "0 10px 0 20px"}}>{nickname ? nickname.charAt(0) : ""}</Avatar>
              <span style={{color: "white"}}>{nickname}</span>
            </Col>
            <Col xs={24} sm={0}>
              <Button
                // ghost
                size="large"
                icon={<MenuOutlined />}
                style={{
                  color: "white", border: "none", background: "black", opacity: "0.5"
                }}
                onClick={() => {
                  this.props.toggleSider();
                }}
              />
            </Col>
          </Row>
        </div>
      </Header>
    );
  }
}

function mapStateToProps(state) {
  return {...state};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navbar);
