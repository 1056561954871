import React, { useState, useEffect } from "react";
import _, { initial } from "lodash";
import {
  Form,
  Input,
  message,
  notification,
  Modal,
  Button,
  InputNumber,
} from "antd";
import { useTranslation } from "react-i18next";
import * as debugLib from "debug";
import * as Service from "../../core/Service";

const debug = debugLib("app:src/admin/src/components/SystemConfigModal");

const renderInputNumberConfig = [
  'hkd_to_token_ratio',
];

const SystemConfigModal = (props) => {
  const { t } = useTranslation();
  const {
    selectedRecord,
    companyConfig,
    modalVisible,
    setModalVisible,
  } = props;
  let [CompanyConfigForm] = Form.useForm();

  useEffect(() => {
    CompanyConfigForm.setFieldsValue(selectedRecord);
  }, [selectedRecord]);

  const onFinish = async (formData) => {
    let endpoint = '/api/company/company_config';
    let putData = {
      ...companyConfig,
      [selectedRecord.label]: formData.value,
    };
    let resp = await Service.call('PUT', endpoint, putData);
    if (!resp || resp.status < 1) {
      message.error(resp.errorMessage);
      return;
    }
    notification.success({
      message: t('Success'),
    });
    setModalVisible(false);
  }

  const renderInput = (label) => {
    if (_.includes(renderInputNumberConfig, label)) {
      return <InputNumber />
    }
  }

  return (
    <Modal
      destroyOnClose
      title={t('edit')}
      visible={modalVisible}
      footer={null}
      width="60%"
      style={{maxWidth: "95%"}}
      onCancel={() => {
        setModalVisible(false);
      }}
    >
      <Form
        form={CompanyConfigForm}
        name="CompanyConfigForm"
        layout="vertical"
        initialValues={{}}
        onFinish={onFinish}
      >
        <Form.Item
          label={t(selectedRecord.label)}
          name="value"
          rules={[{ required: true, message: t('input_required') }]}
        >
          {renderInput(selectedRecord.label)}
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t("submit")}
          </Button>
        </Form.Item>

      </Form>
    </Modal>
  );
};

export default SystemConfigModal;
