import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Row,
  Col,
  Form,
  Input,
  Divider,
  InputNumber,
  Modal,
} from "antd";
import * as debugLib from "debug";
import { useTranslation } from "react-i18next";
import Button from "../../../t9UIComponents/t9Button";

const debug = debugLib("app:pages/company/blog/post/list/Modal");

const NewsCategoryModal = (props) => {
  const { t } = useTranslation();
  const {
    selectedRecord,
    modalVisible,
    setModalVisible,
    handleFormSubmit,
    loading,
  } = props;

  const [NewsCategoryForm] = Form.useForm();

  useEffect(() => {
    NewsCategoryForm.resetFields();
    NewsCategoryForm.setFieldsValue(selectedRecord);
  }, [selectedRecord]);

  return (
    <Modal
      title={
        selectedRecord.news_category_id === 0
          ? t("add_item")
          : t("edit_record")
      }
      visible={modalVisible}
      footer={null}
      width={1280}
      style={{ maxWidth: "95%" }}
      onCancel={() => {
        setModalVisible(false);
      }}
    >
      <Form
        form={NewsCategoryForm}
        name="NewsCategoryForm"
        layout="vertical"
        onFinish={(formData) => {
          handleFormSubmit({
            ...formData,
            news_category_id: selectedRecord.news_category_id,
            banner: "",
            description: "",
          });
        }}
      >
        <Form.Item name="category_name" label={t("category_name")}>
          <Input maxLength={50} />
        </Form.Item>
        {/* <Form.Item label={t("desc")}>
          <TinymceWrapper
            initialValue={blogDesc}
            onChange={(value) => {
              setBlogDesc(value);
            }}
          />
        </Form.Item> */}
        <Form.Item label={t("sorting")} name="sorting">
          <InputNumber min={0} />
        </Form.Item>
        <Divider />
        <Row justify="end">
          <Col>
            <Form.Item>
              <Button loading={loading} type="primary" htmlType="submit">
                {t("submit")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default NewsCategoryModal;
