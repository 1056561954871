import React, { Component } from 'react';
import {
  Button, Card, DatePicker, Input, Modal, notification, Popconfirm, Space, Table, Badge
} from 'antd';
import * as Icon from '@ant-design/icons';
import 'antd/dist/antd.css';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import Fuse from 'fuse.js';
import _ from 'lodash';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { ActionCreators } from '../../../redux/actions';
import * as Service from '../../../core/Service';
import NavAndSideFrame from '../../../components/NavAndSideFrame';
import AdminAccountForm from './AdminAccountForm';


const { RangePicker } = DatePicker;

// #region Change Area #1
const involvedModelName = "admin";
const pageTitle = 'admin_account_list';
const tableIdName = 'admin_id';
const selectedKey = 3;
const filterableField = [
  'username', 'email'
]
let useDatePicker = false;
const defaultStartTime = moment().subtract(1, 'months').startOf('day').unix();
const defaultEndTime = moment().endOf('day').unix();
// #endregion Change Area #1

let searchWord = '';
let selectedFormId = 0;
let selectedFormData = {};
let fuse = [];

class AdminAccountList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      originalDataList: [],
      dataList: [],
      startTime: defaultStartTime,
      endTime: defaultEndTime,
      recordId: 0,
    };
  }

  async componentDidMount() {
    await this.getAllData();
  }

  async getAllData() {
    let data = [];
    try {
      data = await Service.call('get', `/api/${involvedModelName}/list`);
    } catch (error) {
      console.error(error);
    }
    console.log('data', data);

    if (data.status !== 1) {
      return;
    }



    let originalDataList = data.admin_accounts;

    let dataList = _.clone(originalDataList);
    const fuseOptions = {
      findAllMatches: true,
      includeScore: true,
      threshold: 0.15,
      keys: filterableField
    };
    fuse = new Fuse(originalDataList, fuseOptions);
    this.setState({dataList, originalDataList});
  }

  setModalVisible(isVisible) {
    this.setState({modalVisible: isVisible});
  }

  setTableHeader() {
    const { t, i18n } = this.props
  // #region Change Area #2
    let columns = [
      {
        title: t('operation'),
        dataIndex: tableIdName,
        render: (value, record, index) => {
          let wording = t("disabled")
          let icon = <Icon.StopOutlined />
          let color = "#AA0000"
          return (
            <span>
              <Popconfirm
                title={`Confirm to proceed ${wording} this record?`}
                onConfirm={async () => {
                  let params = {
                    admin_id: value,
                    is_active: 0,
                  }

                  let result = await Service.call('patch', '/api/admin/admin_account', params);
                  if (result.status !== 1) {
                    notification.warning({message: t('fail')});
                    return;
                  }

                  notification.success({ message: t('success') });
                  this.getAllData();
                }}
                okText={t("confirm")}
                cancelText={t('cancel')}
              >
                <Button shape="circle" icon={icon} style={{color}} />
              </Popconfirm>
              <Button
                shape="circle"
                icon={<Icon.EditOutlined />}
                style={{color: "#0000AA", marginBottom: 15}}
                onClick={() => {
                  this.setState({recordId: value})
                  selectedFormId = value;
                  selectedFormData = record;
                  this.setModalVisible(true);
                }}
              />
            </span>
          )
        }
      },
      {
        title: t('active_status'),
        dataIndex: 'is_active',
        key: 'is_active',
        render: (text, record, index) => {
          let {is_active} = record;
          return (
            <span>
              { is_active === 1 ? (<Badge status="processing" />) : <Badge color="#555" /> }
            </span>
          )
        }
      },
      { title: t('username'), dataIndex: 'username' },
      { title: t('nickname'), dataIndex: 'nickname' },
      { title: t('admin_role'), dataIndex: 'admin_role' },
      { title: t('mobile'), dataIndex: 'mobile' },
      { title: t('email'), dataIndex: 'email' },
    ];
    // #endregion Change Area #2
    let columumnsWithSorter = [];
    _.forEach(columns, (column) => {
      let columnWithSorter = column;
      columnWithSorter.sorter = (_a, _b) => {
        let a = _a;
        let b = _b;
        a = `${a[`${column.dataIndex}`]}`;
        b = `${b[`${column.dataIndex}`]}`;
        return a.localeCompare(b);
      }
      columumnsWithSorter.push(columnWithSorter);
    })
    return columumnsWithSorter;
  }

  async filterData(_filterObj) {
    let filterObj = _filterObj || {};
    let {
      originalDataList,
      startTime, endTime
    } = this.state;
    let dataList = _.clone(originalDataList);
    if (filterObj.searchWord) { searchWord = filterObj.searchWord; }

    if (useDatePicker) {
      if (filterObj.startTime) { startTime = filterObj.startTime; }
      if (filterObj.endTime) { endTime = filterObj.endTime; }
      dataList = _.filter(dataList, (data) => {
        return moment(data.ctime).isBetween(startTime, endTime);
      })
    }

    const fuzzyResults = fuse.search(searchWord);
    let fuzzyArray = [];
    if (fuzzyResults.length !== 0) {
      _.forEach(fuzzyResults, (result) => {
        fuzzyArray.push(result.item);
      })
      dataList = fuzzyArray;
    } else if (searchWord !== '') {
      notification.warning({message: 'Warning', description: 'No record within date range'});
      dataList = fuzzyArray;
    }
    this.setState({dataList});
  }

  displayMoment(unixTime, outputFormat = 'YYYY/MM/DD HH:mm') {
    if (unixTime !== 0) {
      let displayTime = moment.unix(unixTime).format(outputFormat);
      return displayTime;
    }
    return '-';
  }

  displayBoolean(key_value) {
    const { t, i18n } = this.props
    let displayText = t('no')
    if (key_value === 1) {
      displayText = t('yes')
    }
    return (
      <span>
        {displayText}
      </span>
    )
  }

  render() {
    const { t, i18n } = this.props
    let {
      modalVisible, dataList,
      startTime, endTime, recordId
    } = this.state;
    return (
      <NavAndSideFrame
        title={t(pageTitle)}
        selectedKey={`${selectedKey}`}
      >
        <Card>
          <Button
            onClick={() => {
              this.setState({recordId: 0})
              selectedFormId = 0;
              selectedFormData = {};
              this.setModalVisible(true);
            }}
          >
            {t('add_admin_user')}
          </Button>
          <Modal
            title={recordId > 0 ? t("edit") : t('add')}
            visible={modalVisible}
            footer={null}
            style={{ maxWidth: 800 }}
            width="90%"
            destroyOnClose
            onCancel={() => {
              this.setModalVisible(false);
            }}
          >
            <AdminAccountForm
              recordId={recordId}
              selectedFormData={selectedFormData}
              closeMethod={async () => {
                await this.getAllData();
                this.setModalVisible(false);
              }}
            />
          </Modal>
          <div />
          {/* <Space direction="horizontal">
            <>Search by word:</>
            <Input
              placeholder="Please input"
              onChange={(event) => {
                searchWord = event.target.value;
                let filterObj = { searchWord }
                this.filterData(filterObj)
              }}
            />
            {
              // #region Range Picker Setting 2
              (useDatePicker) ? (
                <>
                  <>Search by time:</>
                  <RangePicker
                    allowClear={false}
                    defaultValue={[moment().subtract(1, 'months'), moment()]}
                    onChange={(dateArr) => {
                      startTime = dateArr[0].startOf('day').unix();
                      endTime = dateArr[1].endOf('day').unix();
                      this.setState({startTime, endTime});
                      let filterObj = {
                        startTime, endTime
                      }
                      this.filterData(filterObj);
                    }}
                  />
                </>
              ) : null
              // #endregion Range Picker Setting 2
            }
            <Button onClick={() => this.filterData()}>Search</Button>
          </Space> */}
        </Card>
        <Card>
          <Table
            pagination={{
              defaultPageSize: 25,
              showSizeChanger: true,
              pageSizeOptions: [25, 50, 100, 200, 500]
            }}
            scroll={{x: 'auto'}}
            dataSource={dataList}
            columns={this.setTableHeader()}
            rowKey={tableIdName}
          />
        </Card>
      </NavAndSideFrame>
    )
  }
}

function mapStateToProps(state) {
  return {
    app: state.app
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AdminAccountList));
