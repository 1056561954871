import React, { Component, useState, useEffect } from "react";
import {
  Avatar, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Space, Table, Tag, Tooltip,
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  CheckOutlined
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "antd/dist/antd.css";
import moment from "moment";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import * as Service from "../../../core/Service";
import NavAndSideFrame from "../../../components/NavAndSideFrame";
import Button from "../../../t9UIComponents/t9Button";
import EventTable from "../../../components/Event/EventTable";
import EventForm from "../../../components/Event/EventForm";

const debug = require("debug")("app:admin:client:src:AdvertisementList");

const title = "Event List";
const selectedKey = "event_list";
const openKeys = "event_management";

const EventList = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const [dataList, setDataList] = useState([]);
  const [periodList, setPeriodList] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getAllData();
    getEventCategoryList();
  }, []);

  const getAllData = async () => {
    try {
      setLoading(true);
      let resp = await Service.call("get", "/api/event");
      if (resp.status === 1) {
        setDataList(resp.data);
        setPeriodList(resp.period);
      }
    } catch (error) {
      console.error("error >>> ", error);
    } finally {
      setLoading(false);
    }
  };

  const getEventCategoryList = async () => {
    try {
      let resp = await Service.call("get", "/api/event/category");
      const { data } = resp;
      const list = _.map(data, (item) => {
        return { value: item.event_category_id, label: item.title };
      });
      setCategories(list);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <NavAndSideFrame
      {...props}
      title={title}
      selectedKey={selectedKey}
      openKeys={openKeys}
    >
      <Space>
        <Link
          to={{
            pathname: "/company/add_event",
          }}
        >
          <Button type="primary">{t("add_event")}</Button>
        </Link>
      </Space>
      <Divider />
      <EventTable
        loading={loading}
        setLoading={setLoading}
        dataObj={dataList}
        periodList={periodList}
        getAllData={getAllData}
      />
    </NavAndSideFrame>
  );
};

export default EventList;
