import React, { useEffect, useState } from "react";
import {
  Form, Input, Button, message, Select, Checkbox, DatePicker, InputNumber
} from "antd";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import * as Service from "../core/Service";
import { formItemLayout, tailLayout } from "./ModalLayout";
import { TranslationFormItemInput, TranslationFormItemEditor } from "./TranslationWrapper";
import LanguageList from "../data/LanguageList.json";
import MediaLibraryComponent from "./MediaLibraryComponent";

const { TextArea } = Input;
const { Option } = Select;

const debug = require("debug")("app:admin:client:src:NotificationForm");

const NotificationForm = (props) => {
  const {t} = useTranslation();

  const [form] = Form.useForm();
  const admin = useSelector((state) => state.app.admin);
  const company_admin = useSelector((state) => state.app.company_admin);
  const {floorList} = useSelector((state) => state.app);

  const { setModalVisible, companyList, companyUserList } = props;
  const [type, setType] = useState(!_.isEmpty(admin) ? "global" : "company");
  const [company_id, setCompanyID] = useState(!_.isEmpty(admin) ? null : company_admin.company_id);

  const [loading, setLoading] = useState(false);
  const [desc, setDesc] = useState({default: ""});
  const [banner, setBanner] = useState("");

  const unitList = {
    32: ["A", "B"],
    31: ["A", "B"],
    30: ["A", "B"],
    29: ["A", "B", "C", "D"],
    default: ["A", "B", "C", "D", "E", "F"],
  };
  // const floorList = (_.range(3, 31).filter((data) => !_.includes([4, 14, 24], data)));
  let addressList = _.flatMap(floorList, (floor) => {
    const units = unitList[floor] ? unitList[floor] : unitList.default;
    return _.map(units, (unit) => `${floor}${unit}`);
  });

  const onFinish = async (data) => {
    let languageParam = {};
    _.map(LanguageList, (val, key) => {
      languageParam[`description_${key}`] = desc[key];
    });

    const postObj = {
      ...languageParam,
      ...data,
      description: desc.default,
      has_post: true,
      post_date: data.post_date.startOf("d").unix(),
      expiry_date: data.expiry_date.startOf("d").unix(),
      banner
    };

    switch (postObj.type) {
      case "global": return sendToGlobal(postObj);
      case "company": return sendToCompany(postObj);
      case "user": return sendToUser(postObj);
      case "floor": return sendToFloor(postObj);
      case "unit": return sendToUnit(postObj);
      case "address": return sendToAddress(postObj);
      default: message.warning("Cannot found target receiver");
    }
  };

  const sendToGlobal = async (postObj) => {
    try {
      setLoading(true);
      debug("send to global");
      let url = `/api/notification/global`;

      let result = await Service.call("post", url, postObj);
      if (result.status !== 1) {
        return message.error(result.errorMessage);
      }
      message.success("Success");
      return props.openModal(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const sendToCompany = async (postObj) => {
    try {
      setLoading(true);
      debug("send to company");
      let url = `/api/notification/company`;

      let result = await Service.call("post", url, postObj);
      if (result.status !== 1) {
        return message.error(result.errorMessage);
      }
      message.success("Success");
      init();
      return props.openModal(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const sendToUser = async (postObj) => {
    try {
      setLoading(true);
      debug("send to user");
      let url = `/api/notification/user`;

      let result = await Service.call("post", url, postObj);
      if (result.status !== 1) {
        return message.error(result.errorMessage);
      }
      message.success("Success");
      init();
      return props.openModal(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const sendToFloor = async (postObj) => {
    try {
      setLoading(true);
      debug("send to floor");
      let url = `/api/notification/user`;

      const floorUserList = _.filter(companyUserList, (user) => {
        if (!user?.address) return false;
        const [emptyStr, floor, unit] = user?.address?.split(/(\d+)/);
        if (_.includes(postObj.floor, _.toInteger(floor))) return true;
      });

      postObj.company_user_id_arr = _.map(floorUserList, "company_user_id");

      let result = await Service.call("post", url, postObj);
      if (result.status !== 1) {
        return message.error(result.errorMessage);
      }
      message.success("Success");
      init();
      return props.openModal(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const sendToUnit = async (postObj) => {
    try {
      setLoading(true);
      debug("send to unit");
      let url = `/api/notification/user`;

      const unitUserList = _.filter(companyUserList, (user) => {
        if (!user?.address) return false;
        const [emptyStr, floor, unit] = user?.address?.split(/(\d+)/);
        if (_.includes(postObj.unit, unit)) return true;
      });

      postObj.company_user_id_arr = _.map(unitUserList, "company_user_id");

      let result = await Service.call("post", url, postObj);
      if (result.status !== 1) {
        return message.error(result.errorMessage);
      }
      message.success("Success");
      init();
      return props.openModal(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const sendToAddress = async (postObj) => {
    try {
      setLoading(true);
      debug("send to address");
      let url = `/api/notification/user`;

      const unitUserList = _.filter(companyUserList, (user) => {
        if (!user?.address) return false;
        if (_.includes(postObj.address, user.address)) return true;
      });

      postObj.company_user_id_arr = _.map(unitUserList, "company_user_id");

      let result = await Service.call("post", url, postObj);
      if (result.status !== 1) {
        return message.error(result.errorMessage);
      }
      message.success("Success");
      init();
      return props.openModal(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const init = () => {
    form.resetFields();
    setDesc({default: ""});
    setBanner("");
    form.setFieldsValue({
      type: "company",
      company_id: !_.isEmpty(admin) ? null : company_admin.company_id
    });
  };

  return (
    <Form
      {...formItemLayout}
      form={form}
      name="notification_form"
      initialValues={{
        // type: !_.isEmpty(admin) ? "global" : "company",
        type: "company",
        company_id: !_.isEmpty(admin) ? null : company_admin.company_id
      }}
      onFinish={onFinish}
    >
      <Form.Item
        label={t("notification_to")}
        name="type"
      >
        <Select onChange={setType}>
          {/* {!_.isEmpty(admin) ? <Option value="global">Global</Option> : null} */}
          <Option value="company">{t("company")}</Option>
          <Option value="user">{t("user")}</Option>
          <Option value="floor">{t("floor")}</Option>
          <Option value="unit">{t("unit")}</Option>
          <Option value="address">{t("address")}</Option>
        </Select>
      </Form.Item>

      {(type === "company" || type === "user") && (
        <Form.Item
          label="Company"
          name="company_id"
          hidden
        >
          <Select onChange={setCompanyID} disabled={_.isEmpty(admin)}>
            {_.map(companyList, (company) => (
              <Option key={`company_select_${company.company_id}`} value={company.company_id}>{company.company_name}</Option>
            ))}
          </Select>
        </Form.Item>
      )}
      {
        type === "floor" && (
          <Form.Item
            label={t("floor")}
            name="floor"
            require
          >
            <Select
              mode="multiple"
              placeholder={t("select")}
            >
              {_.map(floorList, (floor) => (
                <Option value={floor}>{floor}</Option>
              ))}
            </Select>
          </Form.Item>
        )
      }
      {
        type === "unit" && (
          <Form.Item
            label={t("unit")}
            name="unit"
            require
          >
            <Select
              mode="multiple"
              placeholder={t("select")}
            >
              {_.map(unitList.default, (unit) => (
                <Option value={unit}>{unit}</Option>
              ))}
            </Select>
          </Form.Item>
        )
      }
      {
        type === "address" && (
          <Form.Item
            label={t("address")}
            name="address"
            require
          >
            <Select
              mode="multiple"
              placeholder={t("select")}
            >
              {_.map(addressList, (address) => (
                <Option value={address}>{address}</Option>
              ))}
            </Select>
          </Form.Item>
        )
      }

      {type === "user" && company_id !== 0 && (
        <Form.Item
          label={t("user")}
          name="company_user_id_arr"
          require
        >
          <Select
            mode="multiple"
            showSearch
            placeholder={t("search")}
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
          >
            {_.map(_.filter(companyUserList, { company_id }), (user) => (
              <Option value={user.company_user_id}>{user.email}</Option>
            ))}
          </Select>
        </Form.Item>
      )}
      <TranslationFormItemInput
        name="title"
        is_model_input
        defaultCollapseOpen={false}
        collapseCol={{wrapperCol: {xs: {}, sm: {offset: 8}}}}
        label={`${t("title")}`}
        header={`${t("title")} ${t("translation")}`}
      />
      <TranslationFormItemInput
        is_textarea
        is_model_input
        name="content"
        defaultCollapseOpen={false}
        collapseCol={{wrapperCol: {xs: {}, sm: {offset: 8}}}}
        label={`${t("static_content")}`}
        header={`${t("static_content")} ${t("translation")}`}
      />
      <TranslationFormItemInput
        name="post_title"
        is_model_input
        defaultCollapseOpen={false}
        collapseCol={{wrapperCol: {xs: {}, sm: {offset: 8}}}}
        label={`${t("post_title")}`}
        header={`${t("post_title")} ${t("translation")}`}
      />
      <TranslationFormItemEditor
        defaultName="default"
        defaultCollapseOpen={false}
        collapseCol={{wrapperCol: {xs: {}, sm: {offset: 8}}}}
        label={`${t("post_description")}`}
        header={`${t("post_description")} ${t("translation")}`}
        onChange={(value, key) => {
          setDesc({
            ...desc,
            [key]: value
          });
        }}
        initialValue={desc}
      />
      <Form.Item
        label={t("post_date")}
        name="post_date"
        rules={[{ required: true, message: t("input_required") }]}
      >
        <DatePicker
          placeholder={t("select_time")}
          disabledDate={(current) => current && current < dayjs().startOf("d")}
        />
      </Form.Item>
      <Form.Item
        label={t("expiry_date")}
        name="expiry_date"
        rules={[{ required: true, message: t("input_required") }]}
      >
        <DatePicker
          placeholder={t("select_time")}
          disabledDate={(current) => current && current < dayjs().startOf("d")}
        />
      </Form.Item>
      <Form.Item
        label={t("sorting")}
        name="sorting"
        rules={[{ required: true, message: t("input_required") }]}
      >
        <InputNumber min={1} />
      </Form.Item>
      <Form.Item label={t("banner")}>
        <MediaLibraryComponent
          showImage
          selectCallback={async (mediaObj) => {
            try {
              setBanner(mediaObj.filepath);
            } catch (error) {
              console.error(error);
            }
          }}
        />
      </Form.Item>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ fontWeight: "bold" }}>{t("msg_will_send_after_1min")}</div>
      </div>
      <Form.Item {...tailLayout} style={{ marginTop: 15 }}>
        <Button
          type="primary"
          htmlType="submit"
          disabled={loading}
        >
          {t("submit")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default NotificationForm;
