import React from 'react';
import {
  Button, Form, Input, InputNumber, notification, Select
} from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import * as Service from '../../../core/Service';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 12 },
    sm: { span: 12 },
  },
};

const AdminAccountForm = (props) => {
  const { t } = useTranslation();
  let { selectedFormData, closeMethod, recordId } = props;

  let initialValues = {
    prefix: '+852',
    gender: 'm',
    admin_role: 'super'
  }

  //  Handle mobile number
  let [prefix, mobile] = _.split(selectedFormData.mobile, '-')
  selectedFormData.prefix = prefix;
  selectedFormData.mobile = mobile;

  let combinedInitialValues = {...initialValues, ...selectedFormData}

  const [form] = Form.useForm();

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 80 }}>
        <Option value="+852">+852</Option>
        <Option value="+853">+853</Option>
        <Option value="+86">+86</Option>
      </Select>
    </Form.Item>
  );

  const onFinish = async (values) => {
    let data = {};
    try {
      let reqObj = values;
      let action = 'post';

      if (recordId !== 0) { //  Edit
        action = 'patch';
        reqObj.admin_id = recordId;
      }

      data = await Service.call(action, '/api/admin/admin_account', reqObj);

      if (data.status === 1) {
        return notification.success({
          message: t('success')
        });
      }
      throw data;
    } catch (error) {
      // console.error('error >>> ', error);
      return notification.success({
        message: t('fail')
      });
    } finally {
      closeMethod();
    }
  };

  return (
    <Form
      labelCol={formItemLayout.labelCol}
      wrapperCol={formItemLayout.wrapperCol}
      onFinish={onFinish}
      form={form}
      initialValues={combinedInitialValues}
    >
      <Form.Item
        label={t("username")}
        name="username"
        rules={[{ required: true, message: t('input_required') }]}
      >
        <Input maxLength={40} />
      </Form.Item>

      <Form.Item
        label={t("first_name")}
        name="first_name"
        rules={[{ required: true, message: t('input_required') }]}
      >
        <Input maxLength={64} />
      </Form.Item>

      <Form.Item
        label={t("last_name")}
        name="last_name"
        rules={[{ required: true, message: t('input_required') }]}
      >
        <Input maxLength={64} />
      </Form.Item>

      <Form.Item
        label={t('mobile')}
        name="mobile"
        rules={[
          {
            required: true,
            message: t('input_required')
          },
        ]}
      >
        <Input addonBefore={prefixSelector} maxLength={15} style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item
        label={(t('email'))}
        name="email"
        rules={[
          {
            type: 'email',
            message: t('invalid_email'),
          },
          {
            required: true,
            message: t('input_required'),
          },
        ]}
      >
        <Input maxLength={128} />
      </Form.Item>

      <Form.Item name="gender" label={t('gender')} rules={[{ required: true }]}>
        <Select>
          <Option value="m">{t('male')}</Option>
          <Option value="f">{t('female')}</Option>
        </Select>
      </Form.Item>

      <Form.Item
        label={t('admin_role')}
        name="admin_role"
        rules={[
          {
            required: true,
            message: t('select_required'),
          },
        ]}
      >
        <Select>
          <Option value="super">Super</Option>
          <Option value="middle">Middle</Option>
        </Select>
      </Form.Item>

      <Form.Item
        label={t('nickname')}
        name="nickname"
      >
        <Input maxLength={50} />
      </Form.Item>

      {/* {
        this.state.profile ?
        <Form.Item
          label={`${t('Original')}${t("Password")}`}
          name="old_password"
          rules={[
            {
              required: true,
              message: `${t('Original')}${"密碼"}${t('common:is_required')}`,
            },
          ]}
        >
          <Input.Password iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
        </Form.Item>
        :
        <></>
      }   */}
      <Form.Item
        label={t('password')}
        name="password"
        rules={[
          {
            required: true,
            message: t('input_required'),
          },
        ]}
      >
        <Input.Password iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
      </Form.Item>

      <Form.Item
        label={t('confirm_password')}
        name="passwordConfirm"
        dependencies={['password']}
        rules={[
          {
            required: true,
            message: t('input_required'),
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(t('password_not_match'));
            },
          }),
        ]}
      >
        <Input.Password iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
      </Form.Item>

      <Form.Item wrapperCol={{ span: 12, offset: 5 }}>
        <Button type="primary" htmlType="submit">
          {t('submit')}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default AdminAccountForm;
