import React, { Component, useState, useEffect } from "react";
import {
  Button,
  Layout,
  Table,
  Tooltip,
} from "antd";
import {
  EyeOutlined,
} from "@ant-design/icons";
import "antd/dist/antd.css";
import moment from "moment";
import _ from "lodash";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import * as Service from "../../../core/Service";
import dayjs from "dayjs";

const debug = require("debug")(
  "app:admin:client:src:pages:company:UserQuotaListTable"
);

const UpcomingBookingTable = (props) => {
  const { t } = useTranslation();
  const tableIDName = "booking_id";

  const [dataList, setDataList] = useState({
    companyUserList: {},
    bookingItemList: {},
  });
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [pageSize, setPageSize] = useState(20);

  let {
    dataSet,
    bookingInfoList,
    setLoading,
    setModalVisible,
    setSelectedRecord,
    loading,
  } = props;

  useEffect(() => {
    setDataList({
      companyUserList: _.keyBy(dataSet.companyUserList, "company_user_id"),
      bookingItemList: _.keyBy(dataSet.bookingItemList, "booking_item_id"),
    });
  }, [dataSet]);

  const setTableHeader = () => {
    const columns = [
      {
        title: t("operation"),
        dataIndex: tableIDName,
        render: (value, record, key) => {
          return (
            <span>
              <Tooltip title={t("view")}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4 }}
                  icon={<EyeOutlined />}
                  onClick={() => {
                    setSelectedRecord(record);
                    setModalVisible(true);
                  }}
                />
              </Tooltip>
            </span>
          );
        },
      },
      {
        title: t("booking_id"),
        dataIndex: "booking_id",
        sorter: (a, b) => a.booking_id - b.booking_id,
      },
      {
        title: t("status"),
        dataIndex: "status",
        render: (value) => displayStatus(value),
        sorter: (a, b) => a.status - b.status,
      },
      {
        title: t("company_user_id"),
        dataIndex: "company_user_id",
        sorter: (a, b) => a.company_user_id - b.company_user_id,
      },
      {
        title: t("first_name"),
        dataIndex: "first_name",
        sorter: (a, b) => a.first_name.localeCompare(b.first_name),
      },
      {
        title: t("last_name"),
        dataIndex: "last_name",
        sorter: (a, b) => a.last_name.localeCompare(b.last_name),
      },
      {
        title: t("item_name"),
        dataIndex: "booking_item_id",
        render: (value, record) => {
          return dataList.bookingItemList[value]
            ? dataList.bookingItemList[value].item_name
            : "";
        },
      },
      {
        title: t("start_booking_time"),
        dataIndex: "start_time",
        sorter: (a, b) => a.start_time - b.start_time,
        render: (start_time) => {
          return moment.unix(start_time).format("YYYY-MM-DD HH:mm");
        },
      },
      {
        title: t("end_booking_time"),
        dataIndex: "end_time",
        sorter: (a, b) => a.end_time - b.end_time,
        render: (end_time) => {
          return moment.unix(end_time).format("YYYY-MM-DD HH:mm");
        },
      },
      {
        title: t("section_duration"),
        dataIndex: "section_duration",
        sorter: (a, b) => a.section_duration - b.section_duration,
        render: (value) => {
          return Math.round(value / 60) + t("mins");
        },
      },
      {
        title: t("section_price"),
        dataIndex: "section_price",
        sorter: (a, b) => a.section_price - b.section_price,
        render: (value) => {
          return "$" + value;
        },
      },
      {
        title: t("create_time"),
        dataIndex: "ctime",
        sorter: (a, b) => a.ctime - b.ctime,
        render: (ctime) => {
          return moment.unix(ctime).format("YYYY-MM-DD HH:mm");
        },
      },
    ];
    return columns;
  };

  const displayStatus = (value) => {
    let str = "";
    switch (value) {
      case 2:
        str = t("hold");
        break;
      case 10:
        str = t("booked");
        break;
      case -1:
        str = t("refund");
        break;
      default:
        break;
    }
    return str;
  };

  return (
    <Layout>
      <Table
        rowKey={tableIDName}
        scroll={{ x: "max-content" }}
        dataSource={_.filter(bookingInfoList, (eachBookingInfoList) => {
          return dayjs().unix() < moment(eachBookingInfoList.start_time).unix();
        })}
        columns={setTableHeader()}
        bordered
        size="small"
        pagination={{ pageSize: 20 }}
        loading={loading}
        onChange={(pagination, filters, sorter, extra) => {
          if (categoryFilter !== filters.category_name) {
            if (filters.category_name !== null) {
              setCategoryFilter(filters.category_name);
            } else {
              setCategoryFilter([]);
            }
          }
          if (pageSize !== pagination.pageSize) {
            setPageSize(pagination.pageSize);
          }
        }}
      />
    </Layout>
  );
};
export default UpcomingBookingTable;
