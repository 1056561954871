import React, { useState, useEffect } from "react";
import _, { initial } from "lodash";
import {
  Space,
  Row,
  Col,
  Form,
  Input,
  Select,
  message,
  Tabs,
  DatePicker,
  TimePicker,
  Switch,
  notification,
  Divider,
  InputNumber,
  Radio,
  AutoComplete,
  Modal,
} from "antd";
import moment from "moment";
import * as Main from "../../../core/Main";
import * as Service from "../../../core/Service";
import { useHistory, useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import * as debugLib from "debug";
import { useTranslation } from "react-i18next";
import FormUploadFile from "../../../components/form/FormUploadFile";
import { useSelector } from "react-redux";
import Button from "../../../t9UIComponents/t9Button";
import TinymceWrapper from "../../../components/TinymceWrapper";

const debug = debugLib("app:pages/company/blog/post/list/Modal");

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const BlogPostCategoryModal = (props) => {
  const { t } = useTranslation();
  const {
    selectedRecord,
    modalVisible,
    setModalVisible,
    handleFormSubmit,
    loading,
  } = props;

  const [BlogPostForm] = Form.useForm();
  const isCreate = selectedRecord.blog_post_category_id === 0 && selectedRecord.news_category_id === 0

  const types = ["blog", "news"];

  useEffect(() => {
    BlogPostForm.resetFields();
    BlogPostForm.setFieldsValue(selectedRecord);
    if (selectedRecord?.blog_post_category_id > 0) {
      BlogPostForm.setFieldsValue({ type_name: "blog" });
    } else if (selectedRecord?.news_category_id > 0) {
      BlogPostForm.setFieldsValue({ type_name: "news" });
    }
  }, [selectedRecord]);

  const handleFinish = (formData) => {
    let adjustedFormData = {
      ...formData,
      banner: "",
      description: "",
    }

    switch (formData?.type_name) {
      case "blog":
        adjustedFormData.blog_post_category_id = selectedRecord.blog_post_category_id
        break;
      case "news":
        adjustedFormData.news_category_id = selectedRecord.news_category_id
        break;
      default:
        break;
    }

    handleFormSubmit(adjustedFormData);
  }

  return (
    <Modal
      title={
        isCreate
          ? t("add_item")
          : t("edit_record")
      }
      visible={modalVisible}
      footer={null}
      width={1280}
      style={{ maxWidth: "95%" }}
      onCancel={() => {
        setModalVisible(false);
      }}
    >
      <Form
        form={BlogPostForm}
        name="BlogPostForm"
        layout="vertical"
        onFinish={handleFinish}
      >
        <Form.Item
          label={t("type_name")}
          name="type_name"
          rules={[{ required: true, message: t("select_required") }]}
        >
          <Select disabled={!isCreate}>
            {_.map(types, (value) => {
              return (
                <Option key={value} value={value}>{t(value)}</Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item name="category_name" label={t("category_name")}>
          <Input maxLength={50} />
        </Form.Item>
        {/* <Form.Item label={t("desc")}>
          <TinymceWrapper
            initialValue={blogDesc}
            onChange={(value) => {
              setBlogDesc(value);
            }}
          />
        </Form.Item> */}
        <Form.Item label={t("sorting")} name="sorting">
          <InputNumber min={0} />
        </Form.Item>
        <Divider />
        <Row justify="end">
          <Col>
            <Form.Item>
              <Button loading={loading} type="primary" htmlType="submit">
                {t("submit")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default BlogPostCategoryModal;
