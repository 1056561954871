import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Layout, Table, Tag, Tooltip } from "antd";
import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import "antd/dist/antd.css";
import moment from "moment";
import _ from "lodash";
import { useTranslation } from "react-i18next";

const CompanyListTable = (props) => {
  const { t } = useTranslation();
  const tableIDName = "company_admin_id";
  let { dataObj, loading, isActiveChangeCallBack } = props;
  const [dataList, setDataList] = useState([]);
  const [companyFilterList, setCompanyFilterList] = useState([]);
  const [pageSize, setPageSize] = useState(100);

  useEffect(() => {
    setDataList(dataObj);
    setCompanyFilterList(_.uniq(_.map(dataObj, "company_name")));
  }, [dataObj]);

  const setTableHeader = () => {
    const columns = [
      {
        title: t("operation"),
        dataIndex: tableIDName,
        render: (value, record, key) => {
          return (
            <span>
              <Tooltip title={t("edit")}>
                <Link
                  to={{
                    pathname: "/admin/company/admin/form",
                    search: `?id=${value}`,
                  }}
                >
                  <Button
                    shape="circle"
                    style={{ marginRight: 4 }}
                    icon={<EditOutlined />}
                  />
                </Link>
              </Tooltip>
              <Tooltip
                title={record.is_active ? t("deactivate") : t("activate")}
              >
                <Button
                  shape="circle"
                  style={{ marginRight: 4 }}
                  icon={
                    record.is_active ? (
                      <CloseCircleOutlined />
                    ) : (
                      <CheckCircleOutlined />
                    )
                  }
                  onClick={() => {
                    let is_active = record.is_active ? 0 : 1;
                    isActiveChangeCallBack(record.company_admin_id, is_active);
                  }}
                />
              </Tooltip>
            </span>
          );
        },
      },
      {
        title: t("username"),
        dataIndex: "username",
        sorter: (a, b) => a.username.localeCompare(b.username),
      },
      {
        title: t("active_status"),
        dataIndex: "is_active",
        render: (value) => displayIsActive(value),
        sorter: (a, b) => a.is_active - b.is_active,
      },
      {
        title: t("company_name"),
        dataIndex: "company_name",
        filters: _.map(companyFilterList, (value) => {
          return { text: value, value };
        }),
        onFilter: (value, record) => {
          return record.company_name === value;
        },
        sorter: (a, b) => a.company_name.localeCompare(b.company_name),
      },
      {
        title: t("email"),
        dataIndex: "email",
        sorter: (a, b) => a.email.localeCompare(b.email),
      },
      {
        title: t("mobile"),
        dataIndex: "mobile",
        sorter: (a, b) => a.mobile.localeCompare(b.mobile),
      },
      {
        title: t("first_name"),
        dataIndex: "first_name",
        sorter: (a, b) => a.first_name.localeCompare(b.first_name),
      },
      {
        title: t("last_name"),
        dataIndex: "last_name",
        sorter: (a, b) => a.last_name.localeCompare(b.last_name),
      },
      {
        title: t("nickname"),
        dataIndex: "nickname",
        sorter: (a, b) => a.nickname.localeCompare(b.nickname),
      },
      {
        title: t("gender"),
        dataIndex: "gender",
        sorter: (a, b) => a.gender.localeCompare(b.gender),
      },
      {
        title: t("is_verified_email"),
        dataIndex: "is_verified_email",
        sorter: (a, b) => a.is_verified_email - b.is_verified_email,
        render: (value) => displayVerify(value),
      },
      {
        title: t("is_verified_mobile"),
        dataIndex: "is_verified_mobile",
        sorter: (a, b) => a.is_verified_mobile - b.is_verified_mobile,
        render: (value) => displayVerify(value),
      },
      {
        title: t("admin_permission"),
        dataIndex: "is_admin",
        sorter: (a, b) => a.is_admin - b.is_admin,
        render: (value) => {
          return value ? <CheckOutlined /> : <CloseOutlined />;
        },
      },
      {
        title: t("company_user_role"),
        dataIndex: "company_user_role",
        sorter: (a, b) =>
          a.company_user_role.localeCompare(b.company_user_role),
        render: (value) => t(value),
      },
      {
        title: t("utime"),
        dataIndex: "utime",
        sorter: (a, b) => a.utime - b.utime,
        render: (value) => moment.unix(value).format("YYYY-MM-DD HH:mm"),
      },
      {
        title: t("create_time"),
        dataIndex: "ctime",
        sorter: (a, b) => a.ctime - b.ctime,
        render: (value) => moment.unix(value).format("YYYY-MM-DD HH:mm"),
      },
    ];
    return columns;
  };

  const displayIsActive = (value) => {
    let displayStr = "";
    let tagColor = "blue";
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("active");
        tagColor = "blue";
        break;
      case 0:
        displayStr = t("inactive");
        tagColor = "red";
        break;
      default:
        displayStr = t("error");
        tagColor = "red";
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  };

  const displayVerify = (value) => {
    let displayStr = "";
    let tagColor = "blue";
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("verified");
        tagColor = "green";
        break;
      case 0:
        displayStr = t("not_verified");
        tagColor = "red";
        break;
      default:
        displayStr = t("error");
        tagColor = "red";
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  };

  return (
    <Layout>
      <Table
        rowKey={tableIDName}
        scroll={{ x: "max-content" }}
        dataSource={dataList}
        columns={setTableHeader()}
        bordered
        size="small"
        pagination={{ pageSize }}
        loading={loading}
        onChange={(paginate) => {
          setPageSize(paginate.pageSize);
        }}
      />
    </Layout>
  );
};

export default CompanyListTable;
