import React, { Component, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import {
  Avatar, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, Row, Col, Select, notification
} from 'antd';
import {
  EditOutlined,
  FileTextOutlined,
  KeyOutlined,
  QrcodeOutlined,
  BankOutlined,
} from '@ant-design/icons';
// import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import Button from '../../../t9UIComponents/t9Button';
import * as Service from '../../../core/Service';
import * as Main from '../../../core/Main';
import NavAndSideFrame from '../../../components/NavAndSideFrame';
import ShippingListTable from './ShippingListTable';
import ShippingPostModal from './ShippingPostModal';



const debug = require('debug')('app:admin:client:src:BlogPostList');

const involvedModelName = "company";
// const selectedKey = "blog_post";
const tableIDName = "shipping_method_id";
const selectedKey = "shipping_method";
const openKeys = "sale_and_operations"
const ShippingList = (props) => {
  const [loading, setLoading] = useState(true);
  const [dataList, setDataList] = useState([]);
  const [shopDataList, setShopDataList] = useState([]);
  const { t } = useTranslation()
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(0);

  const title = t('ams_shipping_method');

  useEffect(() => {
    getAllData();
    // getShopListData();
  }, []);

  const getAllData = async () => {
    setLoading(true);
    let result = await Service.call('get', '/api/shipping_method');
    if (result.status !== 1) {
      setLoading(false);
      return notification.error({
        message: t('error'),
        description: t(`${result.errorCode}`),
      });
    }
    let { shippingResult, shopResult } = result;

    setShopDataList(shopResult);
    setDataList(shippingResult);
    setLoading(false);
  }

  const handleFormSubmit = (submitRecord) => {
    if (selectedRecord.shipping_method_id > 0) {
      handlePatchRecord(submitRecord);
    } else {
      handlePutRecord(submitRecord);
    }
  }

  const handlePutRecord = async (submitRecord) => {
    setLoading(true);

    let submit = await Service.call('put', '/api/shipping_method', submitRecord)
    if (submit.status < 1) {
      notification.error({
        message: t('fail'),
        description: t(`${submit.errorCode}`),
      });
      setLoading(false)
    } else {
      notification.success({
        message: t('success'),
      });
      getAllData();
      setLoading(false)
      setModalVisible(false)
    }
  }

  const handlePatchRecord = async (submitRecord) => {
    setLoading(true)
    let patchData = {
      shipping_method_id: selectedRecord.shipping_method_id,
      ...submitRecord
    }
    let submit = await Service.call('patch', '/api/shipping_method', patchData);
    if (submit.status < 1) {
      notification.error({
        message: t('fail'),
        description: t(`${submit.errorCode}`),
      });
      setLoading(false)
    } else {
      notification.success({
        message: t('success'),
      });
      getAllData();
      setLoading(false)
      setModalVisible(false)
    }
  }

  const handleDeleteRecord = async (deleteRecord) => {
    setLoading(true)
    let postData = {
      shipping_method_id: deleteRecord,
    };

    let submit = await Service.call('delete', `/api/shipping_method/${postData.shipping_method_id}`)
    if (submit.status < 1) {
      notification.error({
        message: t('fail'),
        description: t(`${submit.errorCode}`),
      });
      setLoading(false)
    } else {
      notification.success({
        message: t('success'),
      });
      getAllData();
      setLoading(false)
      setModalVisible(false)
    }
  }

  return (
    <NavAndSideFrame title={title} selectedKey={selectedKey} openKeys={openKeys}>
      <Button
        type="primary"
        onClick={() => {
          setModalVisible(true)
          setSelectedRecord({shipping_method_id: 0})
        }}
      >
        {t('add_item')}
      </Button>
      <Divider />
      <ShippingListTable
        dataObj={dataList}
        adminChangeData={getAllData}
        setSelectedRecord={(record) => setSelectedRecord(record)}
        handleDeleteRecord={(record) => handleDeleteRecord(record)}
        setModalVisible={(value) => setModalVisible(value)}
        loading={loading}
      />
      <ShippingPostModal
        selectedRecord={selectedRecord}
        dataObj={dataList}
        shopDataList={shopDataList}
        modalVisible={modalVisible}
        setModalVisible={
          (value) => {
            setModalVisible(value)
            getAllData()
          }
        }
        handleFormSubmit={(submitRecord) => handleFormSubmit(submitRecord)}
      />
    </NavAndSideFrame>
  )
}

export default ShippingList;
