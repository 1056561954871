import React, { useEffect } from "react";
import { Button, Form, Input, notification, Row, Col, Card } from "antd";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Service from "../../../core/Service";

const CompanyAdminAcResetPasswordForm = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { initialValues } = props;

  const [UserForm] = Form.useForm();

  useEffect(() => {}, []);

  const onFinish = async (formData) => {
    let { edit_password } = formData;
    let patchData = { ...initialValues, password: edit_password };
    let response = await Service.call(
      "patch",
      `/api/admin/company/admin/password`,
      patchData
    );
    // console.log("response>>", response)
    if (response.status === -1) {
      notification.error({
        message: t(`error`),
        description: t(response.errorCode),
      });
      return;
    }
    notification.success({
      message: t("success"),
    });
    history.push(`/admin/company/admin/list`);
  };

  return (
    <Row gutter={[24, 24]}>
      <Col md={24} lg={16}>
        <Card>
          <Form
            form={UserForm}
            name="UserForm"
            layout="vertical"
            initialValues={initialValues}
            onFinish={onFinish}
          >
            <Form.Item
              label={t("edit_password")}
              name="edit_password"
              rules={[
                {
                  required: true,
                  message: t("input_required"),
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label={t("confirm_password")}
              name="confirm_password"
              dependencies={["edit_password"]}
              rules={[
                {
                  required: true,
                  message: t("input_required"),
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("edit_password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(t("password_not_match"));
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                {t("submit")}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default CompanyAdminAcResetPasswordForm;
