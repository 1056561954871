import React, { useState, useEffect } from "react";
import _, { initial } from "lodash";
import {
  Space,
  Row,
  Col,
  Form,
  Input,
  Select,
  message,
  Tabs,
  DatePicker,
  TimePicker,
  Switch,
  notification,
  Divider,
  InputNumber,
  Radio,
  AutoComplete,
  Modal,
} from "antd";
import moment from "moment";
import * as Main from '../../../core/Main';
import * as Service from "../../../core/Service";
import { useHistory, useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import * as debugLib from "debug";
import { useTranslation } from "react-i18next";
import FormUploadFile from "../../../components/form/FormUploadFile";
import { useSelector } from "react-redux";
import Button from '../../../t9UIComponents/t9Button';

const debug = debugLib("app:pages/company/banner/list/Modal");

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const BannerPostModal = (props) => {
  const { t } = useTranslation();
  const {
    selectedRecord,
    modalVisible,
    setModalVisible,
    handleFormSubmit,
  } = props;
  const [imageURL, setImageURL] = useState("");
  const [fileinfo, setFileinfo] = useState({});
  const [initialFormValues, setInitialFormValues] = useState({});
  const STATIC_SERVER_URL = useSelector(
    (state) => state.app.config.STATIC_SERVER_URL
  );

  const [BannerPostForm] = Form.useForm();

  useEffect(() => {
    setInitialFormValues({});
    setFileinfo({});
    setImageURL("");
    BannerPostForm.resetFields();
    if (selectedRecord.banner_id > 0) {
      let path = STATIC_SERVER_URL + '/' + selectedRecord.banner;
      let data = {
        filename: selectedRecord.banner,
        filepath: selectedRecord.banner,
      };
      setImageURL(path);
      setFileinfo(data);
      setInitialFormValues(selectedRecord);
    }
  }, [selectedRecord]);

  useEffect(() => {
    BannerPostForm.resetFields();
  }, [initialFormValues]);

  const uploadOnChange = (info) => {
    const { status, response } = info.file;
    if (status === "done") {
      message.destroy();
      if (response.status > 0) {
        message.success(t("uploaded"));
        let data = {
          filename: info.file.response.filename,
          filepath: info.file.response.filepath,
        };
        let path = STATIC_SERVER_URL + '/' + info.file.response.filepath;
        setImageURL(path);
        setFileinfo(data);
      } else {
        message.error(t("fail"));
      }
    }
  };

  return (
    <Modal
      title={
        selectedRecord.banner_id === 0 ? t("add_item") : t("edit_record")
      }
      visible={modalVisible}
      footer={null}
      width={1280}
      style={{ maxWidth: "95%" }}
      onCancel={() => {
        setModalVisible(false);
      }}
    >
      <Form
        form={BannerPostForm}
        name="BannerPostForm"
        layout="vertical"
        initialValues={initialFormValues}
        onFinish={(formData) => {
          formData.item_feature_image = fileinfo.filename
            ? fileinfo.filename
            : null;
          formData.fileinfo = fileinfo.filename ? fileinfo : null;
          handleFormSubmit(formData);
        }}
      >
        <Row gutter={24}>
          <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item label={t("banner")} name="banner">
              <FormUploadFile
                style={{backgroundColor: '#ccc'}}
                data={{ scope: "public" }}
                onChange={uploadOnChange}
                imageURL={imageURL}
              />
            </Form.Item>
            <p>{t('banner_desc')}</p>
          </Col>
        </Row>
        <Divider />
        <Row justify="end">
          <Col>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {t("submit")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default BannerPostModal;
