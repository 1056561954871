const desktopFontFamily = "Inter, sans-serif";
const mobileFontFamily = "Roboto";

const FONT = {
  h1: {
    fontFamily: desktopFontFamily,
    lineHeight: "63px",
    letterSpacing: 0.015,
    fontSize: "42px",
    fontWeight: "bold",
    // fontSize: "3rem",
  },
  h2: {
    fontFamily: desktopFontFamily,
    lineHeight: "54px",
    letterSpacing: 0.005,
    fontSize: "36px",
    fontWeight: "bold",
    // fontSize: "2rem",
  },
  h3: {
    fontFamily: desktopFontFamily,
    lineHeight: "48px",
    letterSpacing: 0,
    fontSize: "32px",
    fontWeight: "bold",
    // fontSize: "2rem",
  },
  h4: {
    fontFamily: desktopFontFamily,
    lineHeight: "42px",
    letterSpacing: 0.0025,
    fontSize: "28px",
    fontWeight: "bold",
    // fontSize: "1.5rem",
  },
  h5: {
    fontFamily: desktopFontFamily,
    lineHeight: "36px",
    letterSpacing: 0,
    fontSize: "24px",
    fontWeight: "bold",
    // fontSize: "1.5rem",
  },
  h6: {
    fontFamily: desktopFontFamily,
    lineHeight: "30px",
    letterSpacing: 0.0015,
    fontSize: "20px",
    fontWeight: "bold",
    // fontSize: "1.2rem",
  },
  h7: {
    fontFamily: desktopFontFamily,
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "24px",
  },
  text: {
    fontFamily: desktopFontFamily,
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: 0.75
  },
  text2: {
    fontFamily: desktopFontFamily,
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "18px",
    letterSpacing: 0.75
  },

  textBold: {
    fontFamily: desktopFontFamily,
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: 0.75
  },
  textBold2: {
    fontFamily: desktopFontFamily,
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "24",
    letterSpacing: 0.75
  },
  textMedium: {
    fontFamily: desktopFontFamily,
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "28px",
    letterSpacing: 0.75
  },
  textMedium2: {
    fontFamily: desktopFontFamily,
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "18px",
    letterSpacing: 0.75
  },
  displaySmall: {
    fontFamily: desktopFontFamily,
    fontWeight: "400",
    fontSize: "24px",
    lineHeight: "32px",
    letterSpacing: 1
  },
  linkMedium: {
    fontFamily: desktopFontFamily,
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "28px",
    letterSpacing: 0.75
  },
  smallText: {
    fontFamily: desktopFontFamily,
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "24px",
    letterSpacing: 0.75
  },
  titleTextBold: {
    fontFamily: desktopFontFamily,
    lineHeight: "24px",
    // fontSize: "1rem",
    letterSpacing: 0.0015,
    fontSize: "16px",
    fontWeight: "bold",
  },
  titleTextRegular: {
    fontFamily: desktopFontFamily,
    lineHeight: "24px",
    // fontSize: "1rem",
    letterSpacing: 0.0015,
    fontSize: "16px",
    fontWeight: 400,
  },
  bodyText1Bold: {
    fontFamily: desktopFontFamily,
    lineHeight: "24px",
    // fontSize: "0.875rem",
    letterSpacing: 0.005,
    fontSize: "16px",
    fontWeight: "bold",
  },
  bodyText1Regular: {
    fontFamily: desktopFontFamily,
    lineHeight: "24px",
    // fontSize: "0.875rem",
    letterSpacing: 0.005,
    fontSize: "16px",
    fontWeight: 400,
  },
  bodyText2Bold: {
    fontFamily: desktopFontFamily,
    lineHeight: "21px",
    // fontSize: "0.875rem",
    letterSpacing: 0.0025,
    fontSize: "14px",
    fontWeight: 400,
  },
  bodyText2Regular: {
    fontFamily: desktopFontFamily,
    lineHeight: "21px",
    // fontSize: "0.875rem",
    letterSpacing: 0.0025,
    fontSize: "14px",
    fontWeight: 400,
  },
  buttonBold: {
    fontFamily: desktopFontFamily,
    lineHeight: "24px",
    // fontSize: "0.875rem",
    letterSpacing: 0.0125,
    fontSize: "16px",
    fontWeight: "bold",
  },
  buttonRegular: {
    fontFamily: desktopFontFamily,
    lineHeight: "24px",
    // fontSize: "0.875rem",
    letterSpacing: 0.0125,
    fontSize: "16px",
    fontWeight: 400,
  },
  captionBold: {
    fontFamily: desktopFontFamily,
    lineHeight: "18px",
    // fontSize: "0.75rem",
    letterSpacing: 0.004,
    fontSize: "12px",
    fontWeight: "bold",
  },
  captionRegular: {
    fontFamily: desktopFontFamily,
    lineHeight: "18px",
    // fontSize: "0.75rem",
    letterSpacing: 0.004,
    fontSize: "14px",
    fontWeight: 400,
  },
  captionLimitedBold: {
    fontFamily: desktopFontFamily,
    lineHeight: "15px",
    // fontSize: "0.5rem",
    letterSpacing: 0.015,
    fontSize: "10px",
    fontWeight: "bold",
  },
  captionLimitedRegular: {
    fontFamily: desktopFontFamily,
    lineHeight: "15px",
    // fontSize: "0.5rem",
    letterSpacing: 0.015,
    fontSize: "10px",
    fontWeight: 400,
  },
  tinyTextBold: {
    fontFamily: desktopFontFamily,
    lineHeight: "12px",
    // fontSize: "0.5rem",
    letterSpacing: 0,
    fontSize: "8px",
    fontWeight: "bold",
  },
  tinyTextRegular: {
    fontFamily: desktopFontFamily,
    lineHeight: "12px",
    // fontSize: "0.5rem",
    letterSpacing: 0,
    fontSize: 8,
    fontWeight: 400,
  },
};

export default FONT;
