import { Button, Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { MailOutlined } from "@ant-design/icons";

const SendEmailToolTip = () => {
  const { t } = useTranslation();
  const styles = {
    container: {
      padding: "6px 8px",
    },
    header: {
      fontSize: 16,
      paddingBottom: 8,
      borderBottom: "0.5px solid #D4D4D4",
    },
    button: {
      width: "100%",
      padding: 12,
      height: 40,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    icon: {
      display: "flex",
    },
    mt12: {
      marginTop: 12,
    },
  };

  return (
    <div style={{ ...styles.container }}>
      <div style={{ ...styles.header }}>{t("send_email")}</div>
      <Row style={{ ...styles.mt12 }}>
        <Col span={24}>
          <Button type="dashed" style={{ ...styles.button }}>
            <MailOutlined style={{ ...styles.icon }} />
            {t("invitation")}
          </Button>
        </Col>
        <Col span={24} style={{ ...styles.mt12 }}>
          <Button type="dashed" style={{ ...styles.button }}>
            <MailOutlined style={{ ...styles.icon }} />
            {t("reminder")}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default SendEmailToolTip;
