import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Card,
  InputNumber,
  notification,
  Select,
} from "antd";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import * as Service from "../../core/Service";
import { TranslationFormItemInput } from "../TranslationWrapper";

const { Option } = Select;

const CompanyNameForm = (props) => {
  const { t } = useTranslation();
  const { initialValues, refreshCompanyInfo } = props;

  const app = useSelector((state) => state.app);
  const [companyInfoForm] = Form.useForm();

  useEffect(() => {
    companyInfoForm.resetFields();
    companyInfoForm.setFieldsValue(initialValues);
  }, [initialValues]);

  const onFinish = async (formData) => {
    let postData = {
      // ...initialValues,
      company_id: initialValues.company_id,
      ...formData,
    };
    let method = "patch";
    let url = "/api/company/name";

    let response = await Service.call(method, url, postData);
    // console.log("response >>>", response);
    if (!response || response.status !== 1) {
      notification.error({
        message: `${t("error")} - ${t(response.errorMessage)} [${response.errorCode}]`,
      });
      return;
    }

    notification.success({
      message: t("success"),
    });
    refreshCompanyInfo();
  };

  return (
    <Row gutter={[24, 24]}>
      <Col md={24} lg={16}>
        <Card>
          <Form
            form={companyInfoForm}
            name="companyInfoForm"
            layout="vertical"
            onFinish={onFinish}
          >
            {/* <Form.Item
              label={t("company_name")}
              name="company_name"
              rules={[{
                required: true,
                message: t("input_required")
              }]}
            >
              <Input maxLength={55} />
            </Form.Item> */}

            <TranslationFormItemInput
              name="company_name"
              label={t("company_name")}
              header={`${t("company_name")} ${t('translation')}`}
            />

            <Form.Item>
              <Button type="primary" htmlType="submit">
                {t("submit")}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default CompanyNameForm;
