import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Row,
  Col,
  Form,
  Input,
  message,
  Divider,
  Radio,
  Modal,
  Select,
  Dropdown,
  Button as AntdButton,
} from "antd";
import _ from "lodash";
import {PhotoshopPicker} from "react-color";

import Button from "../../../t9UIComponents/t9Button";
import FormUploadFile from "../../../components/form/FormUploadFile";

const DisplayListModel = (props) => {
  const [DisplayListForm] = Form.useForm();
  const [imageURL, setImageURL] = useState("");
  const [fileinfo, setFileinfo] = useState({});
  const [bookingItemList, setBookingItemList] = useState([]);
  const [selectedBookingItemList, setSelectedBookingItemList] = useState([]);
  const [defaultBookingItemList, setDefaultBookingItemList] = useState([]);
  const [internalColor, setInternalColor] = useState("");
  const [initialFormValues, setInitialFormValues] = useState({});


  const { t } = useTranslation();
  const {
    API_ORIGIN,
    selectedRecord,
    setModalVisible,
    modalVisible,
    handleFormSubmit,
    bookingItemObj
  } = props;


  useEffect(() => {
    setInitialFormValues({});
    setFileinfo({});
    setImageURL("");
    setInternalColor("#000000");
    setBookingItemList(bookingItemObj);
    setDefaultBookingItemList([]);
    setSelectedBookingItemList([])
    DisplayListForm.resetFields();
    if (selectedRecord.display_id > 0) {
      let path = API_ORIGIN + selectedRecord.background_image;
      let data = {
        filename: selectedRecord.background_image,
        filepath: selectedRecord.background_image,
      };
      setImageURL(path);
      setFileinfo(data);
      setInitialFormValues(selectedRecord);
      setInternalColor(selectedRecord.text_color);
      setDefaultBookingItemList(_.map(selectedRecord.booking_item, (i) => i.item_name));
    }
  }, [selectedRecord]);

  useEffect(() => {
    DisplayListForm.resetFields();
  }, [initialFormValues]);

  const uploadOnChange = (info) => {
    const { status, response } = info.file;
    if (status === "done") {
      message.destroy();
      if (response.status > 0) {
        message.success(t("uploaded"));
        let data = {
          filename: info.file.response.filename,
          filepath: "/" + info.file.response.filepath,
        };
        let path = API_ORIGIN + "/" + info.file.response.filepath;
        setImageURL(path);
        setFileinfo(data);
      } else {
        message.error(t("fail"));
      }
    }
  };

  const MultipleSelect = () => {
    const option = _.map(bookingItemList, (bookingItem) => {
      return (
        <Select.Option key={bookingItem.booking_item_id} value={bookingItem.item_name}>
          {bookingItem.item_name}
        </Select.Option>
      );
    });

    return (
      <Select
        mode="multiple"
        allowClear
        style={{
          width: "100%",
        }}
        defaultValue={defaultBookingItemList}
        placeholder={"請選擇"}
        onChange={handleBookingItemChange}
      >
        {option}
      </Select>
    );
  };

  const handleBookingItemChange = (value, items) => {
    const SelectedItems = _.map(items, (item) => {
      return {booking_item_id: item.key, item_name: item.value};
    });
    setSelectedBookingItemList(SelectedItems);
    setDefaultBookingItemList(value);
  };

  const InputColor = () => {
    const handleChange = (color) => {
      setInternalColor(color.hex);
    };

    const overlay = (
      <div>
        <PhotoshopPicker
          color={internalColor}
          onChange={handleChange}
        />
      </div>
    );


    return (
      <Input
        value={internalColor || ""}
        onChange={(e) => setInternalColor(e.target.value)}
        suffix={(
          <Dropdown trigger={["click"]} overlay={overlay}>
            <AntdButton style={{ background: internalColor }}> </AntdButton>
          </Dropdown>
        )}
      />
    );
  };

  return (
    <Modal
      title={
        selectedRecord.display_id === 0 ? t("add_display") : t("edit_record")
      }
      visible={modalVisible}
      footer={null}
      width={1280}
      style={{ maxWidth: "95%" }}
      onCancel={() => {
        setModalVisible(false);
      }}
    >
      <Form
        form={DisplayListForm}
        name="DisplayListForm"
        layout="vertical"
        initialValues={initialFormValues}
        onFinish={(formData) => {
          formData.booking_item = selectedBookingItemList;
          formData.background_image = fileinfo.filepath;
          formData.text_color = internalColor;
          handleFormSubmit(formData);
        }}
      >
        <Row gutter={24}>
          <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              label={t("name")}
              name="display_name"
              rules={[{ required: true, message: "Cannot leave empty" }]}
            >
              <Input maxLength={256} />
            </Form.Item>
            <Form.Item
              label={t("booking_item")}
              name="booking_item"
            >
              <MultipleSelect />
            </Form.Item>
            <Form.Item
              label={t("background_image")}
              name="background_image"
            >
              <FormUploadFile
                data={{ scope: "public" }}
                onChange={uploadOnChange}
                imageURL={imageURL}
              />
            </Form.Item>
            <Form.Item
              label={t("text_color")}
              name="text_color"
            >
              <InputColor />
            </Form.Item>
            <Form.Item
              label={t("is_active")}
              name="is_active"
              rules={[{ required: true, message: t("select_required") }]}
            >
              <Radio.Group>
                <Radio value={1}>{t('active')}</Radio>
                <Radio value={0}>{t('not_active')}</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row justify="end">
          <Col>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {t("submit")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default DisplayListModel;
