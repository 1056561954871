import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Button,
  Divider,
  Form,
  Input,
  Tag,
  Row,
  Col,
  Card,
  message,
  notification
} from "antd";
import moment from "moment";
import {
  useHistory,
  useLocation,
  useParams
} from "react-router-dom";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import MediaLibraryComponent from "../MediaLibraryComponent";
import FormUploadFile from "../form/FormUploadFile";
import * as Service from "../../core/Service";
import * as Main from "../../core/Main";

const CompanyAcImageForm = (props) => {
  const { t } = useTranslation();
  const { initialValues, refreshCompanyInfo } = props;
  const [loading, setLoading] = useState(false);
  const [logoFileInfo, setLogoFileInfo] = useState({});
  const [bannerFileInfo, setBannerFileInfo] = useState({});
  const app = useSelector((state) => state.app);
  const [UserForm] = Form.useForm();

  useEffect(() => {
    UserForm.resetFields();
    if (initialValues.logo) {
      setLogoFileInfo({
        filename: initialValues.logo,
        filepath: `${initialValues.logo}`
      });
    }
    // if (initialValues.banner) {
    //   setBannerFileInfo({
    //     filename: initialValues.banner,
    //     filepath: `media/${initialValues.banner}`
    //   });
    // }
  }, [initialValues]);

  const onFinish = async (formData) => {
    try {
      formData.logo = logoFileInfo.filename
        ? `media/${logoFileInfo.filename}`
        : initialValues.logo;
      // formData.banner = bannerFileInfo.filename
      //   ? bannerFileInfo.filename
      //   : initialValues.banner;
      let patchData = { ...initialValues, ...formData };
      // console.log("postData>>>", postData);

      let method = "patch";
      let url = "/api/company";
      if (app.isAdmin) {
        url = "/api/admin/company";
      }

      let response = await Service.call(method, url, patchData);

      if (!response || response.status !== 1) {
        return notification.error({
          message: t(`error`),
          description: t(response.errorCode),
        });
      }
      notification.success({
        message: t("success"),
      });
      refreshCompanyInfo();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
    // history.push("/company/company_setting");
  };

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Card>
          <Form
            form={UserForm}
            name="UserForm"
            layout="vertical"
            initialValues={initialValues}
            onFinish={onFinish}
          >
            <Form.Item label={t("logo")} name="logo">
              <MediaLibraryComponent
                company_id={initialValues.company_id}
                selectCallback={(mediaObj) => {
                  setLogoFileInfo({
                    filename: mediaObj.filename,
                    filepath: mediaObj.filepath,
                  });
                }}
                mediaObj={logoFileInfo}
              />
              {/* <FormUploadFile
                data={{ scope: "private" }}
                onChange={uploadOnLogoChange}
                imageURL={logoURL}
              /> */}
            </Form.Item>

            {/* <Form.Item label={t("banner")} name="banner">
              <MediaLibraryComponent
                selectCallback={(mediaObj) => {
                  setBannerFileInfo({
                    filename: mediaObj.filename,
                    filepath: mediaObj.filepath,
                  });
                }}
                mediaObj={bannerFileInfo}
              /> */}
            {/* <FormUploadFile
                data={{ scope: "private" }}
                onChange={uploadOnBannerChange}
                imageURL={bannerURL}
              /> */}
            {/* </Form.Item> */}

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                {t("submit")}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default CompanyAcImageForm;
