import React, { useState, useEffect } from "react";
import { Divider, Modal } from "antd";
import { useTranslation } from "react-i18next";
import NavAndSideFrame from "../../../components/NavAndSideFrame";
import * as Service from "../../../core/Service";
import CompantEmailTable from "./CompanyEmailTable";
import CompanyEmailForm from "./CompanyEmailForm";

const CompanyEmailList = (props) => {
  const [dataList, setDataList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const title = t("email_template");
  const selectedKey = "email_template";
  const openKeys = "settings";
  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    setLoading(true);
    try {
      let url = `/api/email`;
      let resp = await Service.call("get", url);
      setDataList(resp.data.emailArr);
    } catch (error) {
      console.error("error >>> ", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <NavAndSideFrame
      {...props}
      title={title}
      selectedKey={selectedKey}
      openKeys={openKeys}
    >
      <Divider />
      <CompantEmailTable
        loading={loading}
        setLoading={setLoading}
        dataObj={dataList}
        adminChangeData={getAllData}
        setSelectedRecord={setSelectedRecord}
        setModalVisible={setModalVisible}
      />
      <Modal
        title="Edit"
        destroyOnClose
        visible={modalVisible}
        footer={null}
        style={{ maxWidth: 800 }}
        width="90%"
        onCancel={() => {
          setModalVisible(false);
        }}
      >
        <CompanyEmailForm
          dataObj={selectedRecord}
          adminChangeData={getAllData}
          openModal={(value) => {
            setModalVisible(value);
          }}
        />
      </Modal>
    </NavAndSideFrame>
  );
};

export default CompanyEmailList;
