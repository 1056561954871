import React, { useState, useEffect } from "react";
import _ from "lodash";
import QRCode from "qrcode";
import {
  Button,
  Form,
  Input,
  Select,
  notification,
  Tooltip,
  Row,
  Col,
  Card,
  Switch,
  Collapse,
  InputNumber,
  Modal,
} from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { DownOutlined } from "@ant-design/icons";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import * as Service from "../../core/Service";
import * as Helper from "../../core/Helper";
import dayjs from "dayjs";
import Image from "../ImageComponent";

const { Option } = Select;
const { Panel } = Collapse;

const LOGIN_METHOD = {
  USERNAME: 1,
  EMAIL: 2,
  MOBILE: 3,
  BOTH_REQUIRE_WITH_EMAIL_LOGIN: 4,
};

const VisitorForm = (props) => {
  const { t } = useTranslation();
  const {
    setVisible, visible, getAllData, userId
  } = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [formVisible, setFormVisible] = useState(true);
  const [qrcode, setQrcode] = useState();

  useEffect(() => {
    form.resetFields();
  }, [props]);

  const onFinish = async (formData) => {
    const {
      duration,
      remark
    } = formData;

    if (duration > 24) return notification.error({
      message: t(`duration maximum is 24 hours`),
    });

    if (duration <= 0) return notification.error({
      message: t(`duration minimum is 1 hour`),
    });

    if (remark.length > 30) return notification.error({
      message: `remark maximum length is 30`,
    });

    try {
      setLoading(true);
      let postData = {
        company_user_id: userId,
        duration: duration * 3600,
        remark
      };

      let method = "post";

      let resp = await Service.call(method, `/api/company/user/access/temp_code`, postData);

      if (!resp || resp.status === -1) {
        setLoading(false);
        notification.error({
          message: `${t("error")}: ${resp.errorMessage}[${resp.errorCode}]`,
        });
        return;
      }
      setFormVisible(false);
      const qrcodeBase64 = await generateQR(resp?.data?.tempCodeData?.qrCodeData);
      setQrcode(qrcodeBase64);

      notification.success({
        message: t("success"),
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const generateQR = async (text) => {
    try {
      const data = await QRCode.toDataURL(text);
      return data;
    } catch (err) {
      console.error(err);
    }
  };

  function downloadBase64File() {
    const downloadLink = document.createElement("a");
    downloadLink.href = qrcode;
    downloadLink.download = "qrCode.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    getAllData();
    setVisible(false);
  }

  return (
    <Modal visible={visible} style={{width: "max-content"}} onCancel={() => setVisible(false)} centered footer={null}>
      {formVisible && (
      <Form style={{width: '100%'}} form={form} name="VisitorForm" layout="vertical" onFinish={onFinish}>
        <Row gutter={[24, 24]}>
          <Col md={24} lg={23}>
            <Card>
              <Form.Item
                label={t("duration")}
                name="duration"
                required={[
                  {
                    require: true,
                    message: t("input_required"),
                  },
                ]}
              >
                <InputNumber />
              </Form.Item>
              <Form.Item
                label={t("remark")}
                name="remark"
                required={[
                  {
                    require: true,
                    message: t("input_required"),
                  },
                ]}
              >
                <Input maxLength={50} />
              </Form.Item>
              <Form.Item>
                <div style={{ textAlign: "right" }}>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    {t("save_change")}
                  </Button>
                </div>
              </Form.Item>
            </Card>
          </Col>
        </Row>
      </Form>
      )}
      {!formVisible && qrcode && (
        <Row justify="center" align="middle" style={{width: "100%"}}>
          <Col style={{textAlign: 'center'}} md={24}><Image src={qrcode} preview={false} /></Col>
          <Col style={{textAlign: 'center'}} md={24}>
            <Button type="primary" onClick={() => { downloadBase64File(); }}>
              {t('download')}
            </Button>
          </Col>
        </Row>
      )}
    </Modal>
  );
};

export default VisitorForm;
