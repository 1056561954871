import React, { Component, useState, useEffect } from "react";
import { Button, Layout, Popconfirm, Table, Tag, Tooltip } from "antd";
import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import "antd/dist/antd.css";
import moment from "moment";
import _ from "lodash";
import { useTranslation } from "react-i18next";

const CategoryInfoListTable = (props) => {
  const { t } = useTranslation();
  const tableIDName = "item_category_id";
  let {
    dataObj,
    setModalVisible,
    setSelectedRecord,
    loading,
    deleteRecord,
    isActiveChangeCallBack,
  } = props;

  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    setDataList(props.dataObj);
  }, [dataObj]);

  const setTableHeader = () => {
    const columns = [
      {
        title: t("operation"),
        dataIndex: tableIDName,
        render: (value, record, key) => {
          return (
            <span>
              <Tooltip title={t("edit")}>
                <Button
                  shape="circle"
                  style={{ marginRight: 8 }}
                  icon={<EditOutlined />}
                  onClick={() => {
                    setModalVisible(true);
                    setSelectedRecord(record);
                  }}
                />
              </Tooltip>
              <Tooltip
                title={record.is_active ? t("deactivate") : t("activate")}
              >
                <Button
                  shape="circle"
                  style={{ marginRight: 8 }}
                  icon={
                    record.is_active ? (
                      <CloseCircleOutlined />
                    ) : (
                      <CheckCircleOutlined />
                    )
                  }
                  onClick={() => {
                    let is_active = record.is_active ? 0 : 1;
                    isActiveChangeCallBack(
                      record.product_category_id,
                      is_active
                    );
                  }}
                />
              </Tooltip>
              <Popconfirm
                title={t("confirm_delete") + "?"}
                onConfirm={() => deleteRecord(record.product_category_id)}
                okText={t("yes")}
                cancelText={t("no")}
              >
                <Tooltip title={t("delete")}>
                  <Button shape="circle" icon={<DeleteOutlined />} />
                </Tooltip>
              </Popconfirm>
            </span>
          );
        },
      },
      {
        title: t("name"),
        dataIndex: "category_name",
        sorter: (a, b) => a.category_name.localeCompare(b.category_name),
      },
      {
        title: t("active_status"),
        dataIndex: "is_active",
        render: (value) => displayIsActive(value),
        sorter: (a, b) => a.is_active - b.is_active,
      },
      // {
      //   title: t("desc"),
      //   dataIndex: "short_desc",
      //   sorter: (a, b) => a.short_desc.localeCompare(b.short_desc),
      // },
      {
        title: t("sorting"),
        dataIndex: "sorting",
        sorter: (a, b) => a.sorting - b.sorting,
      },
      {
        title: t("utime"),
        dataIndex: "utime",
        sorter: (a, b) => a.utime - b.utime,
        render: (value) => moment.unix(value).format("YYYY-MM-DD HH:mm"),
      },
      {
        title: t("create_time"),
        dataIndex: "ctime",
        sorter: (a, b) => a.ctime - b.ctime,
        render: (value) => moment.unix(value).format("YYYY-MM-DD HH:mm"),
      },
    ];
    return columns;
  };

  const displayIsActive = (value) => {
    let displayStr = "";
    let tagColor = "green";
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("active");
        tagColor = "green";
        break;
      case 0:
        displayStr = t("inactive");
        tagColor = "red";
        break;
      default:
        displayStr = t("error");
        tagColor = "red";
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  };

  return (
    <Layout>
      <Table
        rowKey={tableIDName}
        scroll={{ x: "max-content" }}
        dataSource={dataList}
        columns={setTableHeader()}
        bordered
        size="small"
        pagination={{ pageSize: 100 }}
        loading={loading}
        locale={{ triggerDesc: t('sort_descend'), triggerAsc: t('sort_ascend'), cancelSort: t('cancel_sort') }}
      />
    </Layout>
  );
};

export default CategoryInfoListTable;
