import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import {
  Button,
  Row,
  Select,
  Checkbox,
  Popconfirm,
  Table,
  Tag,
  Tooltip,
  Modal,
  Space,
  notification,
} from "antd";
import { Link } from "react-router-dom";
import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";
import * as Service from "../../core/Service";
import SendEmailPopup from "./SendEmailPopup";

const { Option } = Select;
const { confirm } = Modal;
const MODAL_ACTION = {
  REJECT: {
    key: -1,
    name: "reject"
  },
  APPROVE: {
    key: 1,
    name: "approve"
  },
  CHECK_IN: {
    key: 2,
    name: "check-in"
  },
};

const CHECK_IN_STATUS = {
  CHECK_IN: 1
};

const EventEnrollmentList = (props) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState("all");
  const [loading, setLoading] = useState(true);
  const [sendEmailModalVisible, setSendEmailModalVisible] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const { event_key, getAllData, enrollmentList } = props;

  useEffect(() => {
    setDataList(enrollmentList);
    setFilterList(enrollmentList);
    setLoading(false);
  }, [props]);

  const handleStatusChange = (value) => {
    setStatus(value);
    if (value === "all") {
      setFilterList(dataList);
    } else {
      const filterData = _.filter(dataList, ["registration_status", value]);
      setFilterList(filterData);
    }
  };

  const handleActionsModal = (action) => {
    confirm({
      centered: true,
      title: `${t(action.name)} ${t("selected user")}`,
      icon: <QuestionCircleOutlined style={{ color: "#888888" }} />,
      okText: t("Yes"),
      cancelText: t("No"),
      okButtonProps: {
        type: "primary",
        className: "action-btn"
      },
      cancelButtonProps: {
        type: "primary",
        ghost: true,
        className: "action-btn"
      },
      async onOk() {
        // api
        switch (action.key) {
          case MODAL_ACTION.APPROVE.key:
          case MODAL_ACTION.REJECT.key:
            await patchEnrollmentStatus(action.key);
            break;
          case MODAL_ACTION.CHECK_IN.key:
            await enrollmentCheckIn();
            break;
          default:
            break;
        }
        // updateUserRegistrationStatus(event_register_user_id, status)
        // console.log({ action, selectedUsers });
      },
      onCancel() {
        console.log("cancel ", action);
      },
    });
  };


  const handleSelectedUsers = (value, record) => {
    setSelectedUsers(record);
  };

  const patchEnrollmentStatus = async (patchStatus) => {
    try {
      setLoading(true);
      const patchData = {
        event_enrollment_id_list: _.map(selectedUsers, "event_enrollment_id"),
        status: patchStatus,
        event_key
      };
      const resp = await Service.call("patch", "/api/event/enrollment/status", patchData);
      if (!resp || resp.status !== 1) {
        return notification.error({
          message: t("error"),
          description: t(resp.errorCode),
        });
      }
      setSelectedUsers([]);
      getAllData();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const enrollmentCheckIn = async () => {
    try {
      setLoading(true);
      const patchData = {
        event_enrollment_id_list: _.map(selectedUsers, "event_enrollment_id"),
        status: CHECK_IN_STATUS.CHECK_IN,
        event_key
      };
      const resp = await Service.call("patch", "/api/event/enrollment/check_in", patchData);
      if (!resp || resp.status !== 1) {
        return notification.error({
          message: t("error"),
          description: t(resp.errorCode),
        });
      }
      setSelectedUsers([]);
      getAllData();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="event-tabs-page">
      <Select
        defaultValue={status}
        style={{ width: 120 }}
        onChange={handleStatusChange}
      >
        <Option value="approved">{t("approved")}</Option>
        <Option value="pending">{t("pending")}</Option>
        <Option value="rejected">{t("rejected")}</Option>
        <Option value="all">{t("all")}</Option>
      </Select>
      <Row style={{ width: "100%" }}>
        <Button
          type="primary"
          className="status-button"
          disabled={_.isEmpty(selectedUsers)}
          onClick={() => handleActionsModal(MODAL_ACTION.APPROVE)}
        >
          {t("approve_selected_user")}
        </Button>
        <Button
          type="primary"
          className="status-button"
          disabled={_.isEmpty(selectedUsers)}
          onClick={() => handleActionsModal(MODAL_ACTION.REJECT)}
        >
          {t("reject_selected_user")}
        </Button>
        <Button
          type="primary"
          className="status-button"
          disabled={_.isEmpty(selectedUsers)}
          onClick={() => handleActionsModal(MODAL_ACTION.CHECK_IN)}
        >
          {t("check-in_selected_user")}
        </Button>
        <Button
          type="primary"
          className="status-button"
          disabled={_.isEmpty(selectedUsers)}
          onClick={() => setSendEmailModalVisible(true)}
        >
          {t("email_selected_user")}
        </Button>
      </Row>
      <EnrollmentListTable
        sendEmailModalVisible={sendEmailModalVisible}
        setSendEmailModalVisible={setSendEmailModalVisible}
        selectedUsers={selectedUsers}
        handleSelectedUsers={handleSelectedUsers}
        data={filterList}
        loading={loading}
      />
    </div>
  );
};


const EnrollmentListTable = ({
  sendEmailModalVisible,
  setSendEmailModalVisible,
  handleSelectedUsers,
  selectedUsers,
  data,
  loading
}) => {
  const { t } = useTranslation();
  const tableIDName = "event_enrollment_id";

  const deleteEnrollmentRecord = async (id) => {
    console.log({ id });
  };

  const displayStatus = (value) => {
    let displayStr = "";
    let tagColor = "green";
    let statusValue = value;
    switch (statusValue) {
      case 1:
        displayStr = "Approved";
        tagColor = "green";
        break;
      case 0:
        displayStr = "Pending";
        tagColor = "cyan";
        break;
      case -1:
        displayStr = "Rejected";
        tagColor = "red";
        break;
      default:
        displayStr = "Error";
        tagColor = "#f50";
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  };

  const displayCheckIn = (value) => {
    let displayStr = "";
    let tagColor = "green";
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = "Yes";
        tagColor = "green";
        break;
      case 0:
        displayStr = "No";
        tagColor = "red";
        break;
      default:
        displayStr = "Error";
        tagColor = "#f50";
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  };

  const displayMoment = (unixTime, outputFormat = "YYYY/MM/DD HH:mm:ss") => {
    let displayTime = "";
    if (unixTime !== 0) {
      displayTime = moment.unix(unixTime).format(outputFormat);
    } else {
      displayTime = "-";
    }
    return displayTime;
  };

  const showApproveRejectModal = (id) => {
    confirm({
      centered: true,
      title: (
        <Space size={4} style={{ color: "#888888" }}>
          {t("approve_or_reject")}
          <span style={{ color: "#000" }}>
            ID
            {" "}
            {id}
          </span>
          ?
        </Space>
      ),
      icon: <QuestionCircleOutlined style={{ color: "#888888" }} />,
      okText: t("approved"),
      okButtonProps: {
        ghost: true,
        className: ["approve-button", "action-btn"]
      },
      cancelButtonProps: {
        type: "danger",
        ghost: true,
        className: "action-btn"
      },
      cancelText: t("rejected"),
      onOk() {
        // fetch api
        console.log("approved");
      },
      onCancel() {
        // fetch api
        console.log("rejected");
      },
    });
  };

  const columns = [
    // {
    //   title: "",
    //   render: (value, record) => {
    //     return (
    //       <Checkbox
    //         onChange={(e) => handleSelectedUsers(e.target.checked, record)}
    //       />
    //     );
    //   },
    // },
    // {
    //   title: t("operation"),
    //   dataIndex: tableIDName,
    //   render: (value, record) => {
    //     return (
    //       <>
    //         <Tooltip title="Edit">
    //           <Link
    //             to={{
    //               pathname: `/company/event/${record.event_key}`,
    //             }}
    //           >
    //             <Button
    //               shape="circle"
    //               style={{ marginRight: 4 }}
    //               icon={<EditOutlined />}
    //             />
    //           </Link>
    //         </Tooltip>
    //         {/* <Popconfirm
    //           title={
    //             record.is_active
    //               ? t("confirm_deactivate")
    //               : t("confirm_activate")
    //           }
    //           onConfirm={() => {
    //             let is_active = record.is_active ? 0 : 1;
    //             handleIsActiveChange(record.event_id, is_active);
    //           }}
    //           okText={t("yes")}
    //           cancelText={t("no")}
    //         > */}
    //         <Tooltip
    //           title={record.status === "approved" ? t("reject") : t("approve")}
    //         >
    //           <Button
    //             shape="circle"
    //             style={{ marginRight: 4 }}
    //             icon={
    //               record.status === "approved" ? (
    //                 <CloseCircleOutlined />
    //               ) : (
    //                 <CheckCircleOutlined />
    //               )
    //             }
    //             onClick={() =>
    //               showApproveRejectModal(
    //                 _.toString(record.event_register_user_id).padStart(4, "0")
    //               )
    //             }
    //           />
    //         </Tooltip>
    //         {/* </Popconfirm> */}
    //         <Tooltip title="Delete">
    //           <Popconfirm
    //             placement="top"
    //             title="Are you sure to delete this enrollment record ?"
    //             disabled={record.is_deleted === 1}
    //             onConfirm={async () => {
    //               await deleteEnrollmentRecord(record?.event_register_user_id);
    //             }}
    //             okText="Yes"
    //             cancelText="No"
    //           >
    //             <Button
    //               shape="circle"
    //               disabled={record.is_deleted === 1}
    //               icon={<DeleteOutlined />}
    //             />
    //           </Popconfirm>
    //         </Tooltip>
    //       </>
    //     );
    //   },
    // },
    {
      title: "ID",
      dataIndex: "event_enrollment_id",
      render: (value) => <div>{_.toString(value).padStart(4, "0")}</div>,
      sorter: (a, b) => a.event_register_user_id - b.event_register_user_id,
    },
    {
      title: t("status"),
      dataIndex: "status",
      render: (value) => displayStatus(value),
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: t("check-in"),
      dataIndex: "check_in_status",
      render: (value) => displayCheckIn(value),
      sorter: (a, b) => a.is_check_in - b.is_check_in,
    },
    {
      title: t("registration_time"),
      dataIndex: "ctime",
      render: (value) => displayMoment(value),
      sorter: (a, b) => a.registration_time - b.registration_time,
    },
    {
      title: t("company_name"),
      dataIndex: "company_name",
      sorter: (a, b) => a.company_name.localeCompare(b.company_name),
    },
    {
      title: t("email_address"),
      dataIndex: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: t("phone_number"),
      dataIndex: "mobile",
      sorter: (a, b) => a.mobile.localeCompare(b.mobile),
    },
  ];

  const rowSelection = {
    selectedRowKeys: _.map(selectedUsers, "event_enrollment_id"),
    onChange: handleSelectedUsers,
  };

  return (
    <div className="event-enrollment-list">
      <Table
        loading={loading}
        rowKey={tableIDName}
        scroll={{ x: "max-content" }}
        dataSource={data}
        columns={columns}
        size="small"
        bordered
        style={{ marginTop: 32 }}
        rowSelection={rowSelection}
      />
      <SendEmailPopup
        modalVisible={sendEmailModalVisible}
        setModalVisible={setSendEmailModalVisible}
        selectedUsers={selectedUsers}
      />
    </div>
  );
};

export default EventEnrollmentList;
