import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Form, Spin, Row, Col, Divider, Space, Card, Table, Layout
} from "antd";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import * as Service from "../../../core/Service";

import HsbcReceipt from "../../../assets/hsbc_receipt.svg";

const OrderApprovementDocument = (props) => {
  const { modalVisible } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const config = useSelector((state) => state.app.config);
  const [imgSrc, setImgSrc] = useState("");
  const [orderInfoRc, setOrderInfoRc] = useState({
    orderRc: {},
    orderItemList: [],
  });
  const [loading, setLoading] = useState(false);

  const { selectedRecord } = props;

  useEffect(() => {
    setImgSrc("");
  }, [modalVisible]);

  useEffect(() => {
    form.resetFields();
    getAllData();
  }, [selectedRecord]);

  const getAllData = async () => {
    try {
      setLoading(true);
      let { order_id } = selectedRecord;
      if (_.isUndefined(order_id) || order_id === 0) {
        return;
      }
      let resp = await Service.call(
        "get",
        `/api/order/info/${order_id}`
      );
      const {
        orderRc,
        orderItemArr,
        bookingInfoArr,
      } = resp.data;
      let childOrderItemArr = _.remove(orderItemArr, (value) => {
        return value.parent_order_item_id !== 0;
      });
      let keyByIdOrderItemArr = _.keyBy(orderItemArr, "order_item_id");
      let groupByParentOrderItem = {};
      if (!_.isEmpty(childOrderItemArr)) {
        groupByParentOrderItem = _.groupBy(childOrderItemArr, "parent_order_item_id");
      }
      _.each(Object.keys(keyByIdOrderItemArr), (eachKey) => {
        keyByIdOrderItemArr[eachKey] = {
          ...keyByIdOrderItemArr[eachKey],
          bookingProduct: groupByParentOrderItem[eachKey] || [],
        };
      });
      setOrderInfoRc({
        orderRc,
        orderItemList: Object.values(keyByIdOrderItemArr),
      });
      let { filepath } = orderRc;
      if (!_.isEmpty(filepath)) {
        console.log(`${config.ADMIN_ORIGIN}/${filepath}`);
        setImgSrc(`${config.ADMIN_ORIGIN}/${filepath}`);
      } else {
        setImgSrc("");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const setTableHeader = () => {
    const columns = [
      {
        title: t("items_name"),
        dataIndex: "item_name",
        align: "left",
      },
      {
        title: t("booking_products"),
        dataIndex: "bookingProduct",
        align: "left",
        render: (value) => {
          return _.join(_.map(value, (eachBookingProduct) => {
            return `${eachBookingProduct.quantity} x ${eachBookingProduct.item_name}`;
          }), ", ");
        }

      },
      {
        title: t("booking_items_date"),
        dataIndex: "item_desc",
        align: "left",
      },
    ];
    return columns;
  };
  const setPaymentTableHeader = () => {
    const columns = [
      {
        title: t("payment_method"),
        dataIndex: "payment_name",
        align: "left",
      },
      {
        title: t("price"),
        dataIndex: "ptx_final_price",
        align: "left",
      },
      {
        title: t("payment_confirmed_date"),
        dataIndex: "ptx_complete_time",
        align: "left",
        render: (value) => {
          if (!orderInfoRc.orderRc.ptx_complete_time) return "-";
          return dayjs.unix(orderInfoRc.orderRc.ptx_complete_time).format("YYYY-MM-DD HH:mm:ss");
        }
      },
    ];
    return columns;
  };
  return (
    <Spin spinning={loading}>
      <Row
        style={{ margin: "0 0 21px", fontSize: "20px" }}
      >
        <Col
          style={{ fontWeight: "700", margin: "0 5px" }}
        >
          {t("order_key_title")}
        </Col>
        <Col
          style={{ color: "#1E73BE" }}
        >
          {orderInfoRc.orderRc.order_key}
        </Col>
      </Row>

      <Row
        style={{ margin: "0 0 21px" }}
      >
        <Col
          style={{ margin: "0 5px", color: "#828282" }}
        >
          {t("order_created_date")}
        </Col>
        <Col
          style={{ color: "#090909" }}
        >
          {dayjs.unix(orderInfoRc.orderRc.ctime).format("YYYY-MM-DD")}
        </Col>
      </Row>
      <Table
        rowKey="order_item_id"
        columns={setTableHeader()}
        dataSource={orderInfoRc.orderItemList}
        footer={null}
        pagination={false}
        bordered={false}
        scroll={{
          x: "max-content"
        }}
      />
      <Table
        rowKey="payment_name"
        columns={setPaymentTableHeader()}
        dataSource={orderInfoRc.orderRc.paymentRecordList || []}
        footer={null}
        pagination={false}
        bordered={false}
        scroll={{
          x: "max-content"
        }}
      />
      {/* {renderOrderItem()} */}
      {/* <Row>
        <Col span={8}>{t("subtotal")}</Col>
        <Col>{orderInfoRc.orderRc.final_price}</Col>
      </Row>
      <Row>
        <Col span={8}>{t("token_used")}</Col>
        <Col>{orderInfoRc.orderRc.token_amount}</Col>
      </Row>
      <Row>
        <Col span={8}>{t("total")}</Col>
        <Col>{orderInfoRc.orderRc.final_price - orderInfoRc.orderRc.token_amount}</Col>
      </Row> */}
      <div style={{ color: "#1E73BE", fontSize: "20px", margin: "21px 0 21px" }}>
        {t("order_summary")}
      </div>
      {/* <Row
        justify="space-between"
        style={{ fontWeight: "700", margin: "0 0 21px" }}
      >
        <Col
          span={8}

        >
          {t("sub_total")}
        </Col>
        <Col
          style={{ textAlignLast: "end" }}
          span={8}
        >
          {`$${orderInfoRc.orderRc.original_price}`}
        </Col>
      </Row> */}

      {/* <Row
        justify="space-between"
        style={{ margin: "0 0 21px" }}
      >
        <Col span={8}>
          {t("discount")}
        </Col>
        <Col
          style={{ textAlignLast: "end" }}
          span={8}
        >
          {`$${orderInfoRc.orderRc.discount + orderInfoRc.orderRc.order_discount}`}
        </Col>
      </Row> */}

      {/* <Row
        justify="space-between"
        style={{ margin: "0 0 21px" }}
      >
        <Col span={8}>
          {t("token_used")}
        </Col>
        <Col
          style={{ textAlignLast: "end" }}
          span={8}
        >
          {`$${orderInfoRc.orderRc.token_used}`}
        </Col>
      </Row> */}


      {/* <Divider /> */}
      <Row justify="space-between">
        <Col span={8}>
          {t("total_price")}
        </Col>
        <Col
          style={{ textAlignLast: "end", color: "#1E73BE", fontWeight: "700" }}
          span={8}
        >
          {`$${orderInfoRc.orderRc.final_price}`}
        </Col>
      </Row>

      {/* <Divider /> */}
      {/* <Row gutter={[0, 20]}>
        <Col>
          {`${t("upload_image")}:`}
          {" "}
          {!_.isEmpty(imgSrc) ? orderInfoRc.orderRc.filename : t("no_upload_media")}
        </Col>
      </Row>
      {!_.isEmpty(imgSrc) && (
        <>

          <div style={{ textAlign: "center" }}>
            <img src={imgSrc} alt="receipt_img" style={{ aspectRatio: 1, objectFit: "contain", width: "-webkit-fill-available" }} />
          </div>
        </>
      )} */}
    </Spin>
  );
};

export default OrderApprovementDocument;
