import React, { Component, useState, useEffect } from "react";
import {
  Divider,
  Row,
  Col,
  Input,
  DatePicker,
  Select
} from "antd";
// import 'antd/dist/antd.css';
import dayjs from "dayjs";
import moment from "moment";
import Fuse from "fuse.js";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import Button from "../../../t9UIComponents/t9Button";
import * as Service from "../../../core/Service";
import NavAndSideFrame from "../../../components/NavAndSideFrame";
import CompanyUserOrderListCalender from "./CompanyUserOrderListCalender";
import CompanyUserOrderLogListTable from "./CompanyUserOrderLogListTable";
// import CompanyHolidayInfoModal from './CompanyHolidayInfoModal';

const debug = require("debug")("app:admin:client:src:AdvertisementList");

const { RangePicker } = DatePicker;
const { Option } = Select;

const involvedModelName = "company";
const selectedKey = "booking_record";
const openKeys = "booking_management";
const tableIDName = "company_user_id";

const CompanyUserOrderLogList = (props) => {
  const VIEW_TYPE = {
    TABLE: 0,
    CALENDER: 1
  };
  const [viewType, setViewType] = useState(VIEW_TYPE.TABLE);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const [dataList, setDataList] = useState([]);
  const [unChangeData, setUnChangeData] = useState([]);
  const [dateRange, setDateRange] = useState([
    moment().subtract(1, "M").startOf("d"),
    moment().endOf("d"),
  ]);

  const title = t("booking_log");

  useEffect(() => {
    getAllData();
  }, [dateRange]);

  const getDataBySearchKeyword = (keywords) => {
    let data = [];
    try {
      const options = {
        includeScore: true,
        findAllMatches: true,
        threshold: 0.15,
        keys: [
          "company_user_id",
          "booking_item_id",
          "booking_id",
          // "status",
          "is_active",
          "item_name",
          "nickname",
          "order_status",
        ],
      };
      const fuse = new Fuse(unChangeData.bookingLogArr, options);
      const result = fuse.search(keywords);
      data = _.map(result, "item");
    } catch (error) {
      console.error("error >>> ", error);
    } finally {
      setDataList((prevState) => ({
        ...prevState,
        bookingLogArr: data,
      }));
    }
  };

  let timeout;
  const debounce = (func, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(func, delay);
  };

  const onChange = (keyword) => {
    debounce(() => {
      getDataBySearchKeyword(keyword);
      if (_.isEmpty(keyword)) {
        setDataList(unChangeData);
      }
    }, 400);
  };

  const getAllData = async () => {
    setLoading(true);
    try {
      let startTime = dayjs(_.head(dateRange)).unix();
      let endTime = dayjs(_.last(dateRange)).unix();
      let resp = await Service.call(
        "get",
        `/api/order/list?start_time=${startTime}&end_time=${endTime}`
      );

      let {
        companyUserList,
        orderList
      } = resp.data;

      let keyBy_companyUserArr = _.keyBy(companyUserList, "company_user_id");

      let temp = _.map(orderList, (eachBooking) => {
        let { company_user_id, orderItemList } = eachBooking;
        const {
          start_time,
          end_time,
          item_name,
          original_price: section_price,
          final_price,
          quantity,
          guest_capacity,
        } = _.first(orderItemList); // handle single item

        let companyUserRc = keyBy_companyUserArr[company_user_id] || {};
        let { first_name, last_name, nickname, mobile } = companyUserRc;
        return {
          ...eachBooking,
          first_name,
          last_name,
          nickname,
          mobile,
          start: dayjs.unix(start_time).toDate(),
          end: dayjs.unix(start_time).toDate(),
          title: item_name,

          // Add for table display order item
          start_time,
          end_time,
          item_name,
          section_price,
          quantity,
          guest_capacity,
          final_price
        };
      });

      setDataList({
        ...resp.data,
        bookingLogArr: temp
      });
      setUnChangeData({
        ...resp.data,
        bookingLogArr: temp,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <NavAndSideFrame
      title={title}
      selectedKey={selectedKey}
      openKeys={openKeys}
    >
      {/* <Row style={{ marginBottom: 8 }}>
        <Col>
          <Select
            style={{ width: 120 }}
            defaultValue={VIEW_TYPE.TABLE}
            onChange={(value) => {
              setViewType(value);
            }}
          >
            <Option key={VIEW_TYPE.TABLE} value={VIEW_TYPE.TABLE}>
              {t("table_view")}
            </Option>
            <Option key={VIEW_TYPE.CALENDER} value={VIEW_TYPE.CALENDER}>
              {t("calendar_view")}
            </Option>
          </Select>
        </Col>
      </Row> */}
      <Row>
        <Col>
          <RangePicker
            value={dateRange}
            onChange={(dates) => {
              setDateRange(dates);
            }}
          />
        </Col>
        <Col style={{ display: "flex", alignItems: "center", marginLeft: 10 }}>
          <div>{`${t("search_keywords")}:`}</div>
          <div style={{ marginLeft: 20 }}>
            <Input
              placeholder={t("search_keywords")}
              style={{ minWidth: 270, width: "50%" }}
              onChange={(values) => {
                onChange(values.target.value);
              }}
            />
          </div>
        </Col>
      </Row>
      <Divider />
      {
        viewType === VIEW_TYPE.TABLE ? (
          <CompanyUserOrderLogListTable
            dataList={dataList}
            loading={loading}
            setLoading={setLoading}
          />
        ) : (
          <CompanyUserOrderListCalender
            dataList={dataList}
            // BookingInfoList={dataList.bookingLogArr}
            setLoading={setLoading}
            selectDateCallBack={(prevOrNext, focusWeek) => {
              // selectDate(prevOrNext, focusWeek);
            }}
          />
        )
      }

    </NavAndSideFrame>
  );
};

export default CompanyUserOrderLogList;
