import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  Divider,
  InputNumber,
  Radio,
  Modal,
} from "antd";

import * as debugLib from "debug";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Button from "../../../t9UIComponents/t9Button";
import MediaLibraryComponent from "../../../components/MediaLibraryComponent";

const debug = debugLib("app:pages/company/CompanyButton/CompanyButtonModal");

const { Option } = Select;

const CompanyButtonModal = (props) => {
  const { t } = useTranslation();
  const {
    selectedRecord,
    modalVisible,
    setModalVisible,
    handleFormSubmit,
    loading,
    dataObj,
    buttonTitles
  } = props;

  const { company: { companyConfigRc: companyConfig } } = useSelector((state) => state.app);
  const [buttonImage, setButtonImage] = useState(null);

  const [companyButtonForm] = Form.useForm();

  let recordsTitleMap = _.keyBy(dataObj, "button_title");

  const filterTitlePredicate = (item) => {
    if (!companyConfig?.[`allow_${item.key}`]) {
      return false;
    }

    if (recordsTitleMap[item.title]) {
      if (recordsTitleMap[item.title].company_button_id === selectedRecord.company_button_id) {
        return true;
      }
      return false;
    }

    return true;
  };

  const availableTitleOptions = _.filter(buttonTitles, (item) => filterTitlePredicate(item));

  const changeButtonTitle = (selectedTitle) => {
    companyButtonForm.setFieldsValue({
      link: _.find(buttonTitles, (item) => item.title === selectedTitle).link,
      button_title: selectedTitle
    });
  };

  useEffect(() => {
    companyButtonForm.resetFields();
    companyButtonForm.setFieldsValue(selectedRecord);
    setButtonImage(null);
  }, [selectedRecord]);

  return (
    <Modal
      title={
        selectedRecord.company_button_id === 0
          ? t("add_item")
          : t("edit_record")
      }
      visible={modalVisible}
      footer={null}
      width={1280}
      style={{ maxWidth: "95%" }}
      onCancel={() => {
        setModalVisible(false);
      }}
    >
      <Form
        form={companyButtonForm}
        name="CompanyButtonForm"
        layout="vertical"
        initialValues={{ type: "landing" }}
        onFinish={(formData) => {
          handleFormSubmit({
            ...formData,
            company_button_id: selectedRecord.company_button_id,
            banner: "",
            description: "",
          });
        }}
      >
        <Form.Item
          name="type"
          label={t("display_page")}
          rules={[{ required: true, message: t("input_required") }]}
        >
          <Input maxLength={50} disabled />
        </Form.Item>
        <Form.Item
          label={t("button_img")}
          name="button_img"
        >
          <MediaLibraryComponent
            showImage
            selectCallback={async (mediaObj) => {
              setButtonImage({
                filename: mediaObj.filename,
                filepath: mediaObj.filepath,
              });
              companyButtonForm.setFieldsValue({ button_img: mediaObj.filepath });
            }}
            mediaObj={buttonImage}
          />
        </Form.Item>
        <Form.Item
          label={t("button_title")}
          rules={[{ required: true, message: t("select_required") }]}
          name="button_title"
          extra={availableTitleOptions.length === 0 ? (
            <span style={{ color: "red" }}>{t("button_titles_unavailable")}</span>
          ) : ""}
        >
          <Select style={{ width: 120 }} onChange={changeButtonTitle}>
            {
              _.map(availableTitleOptions, (option) => {
                return (
                  <Option value={option.title} key={`${option.key}`}>
                    {option.title}
                  </Option>
                );
              })
            }
          </Select>
        </Form.Item>
        <Form.Item
          name="link"
          label={t("button_link")}
        >
          <Input maxLength={50} disabled />
        </Form.Item>
        <Form.Item
          label={t("sorting")}
          name="sorting"
          rules={[{ required: true, message: t("input_required") }]}
          extra={<span style={{ color: "red" }}>{t("button_sorting_description")}</span>}
        >
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item
          label={t("is_active")}
          name="is_active"
          rules={[{ required: true, message: t("select_required") }]}
        >
          <Radio.Group>
            <Radio value={1}>Active</Radio>
            <Radio value={0}>not Active </Radio>
          </Radio.Group>
        </Form.Item>
        <Divider />
        <Row justify="end">
          <Col>
            <Form.Item>
              <Button loading={loading} type="primary" htmlType="submit">
                {t("submit")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CompanyButtonModal;
