import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import {
  Row,
  Col,
  Form,
  Input,
  Divider,
  Modal,
  notification,
  Button,
  Switch,
  Select,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const { Option } = Select;

const CompanyAdminModal = (props) => {
  const { t } = useTranslation();
  const { dataObj, selectedRecord, setModalVisible, handleFormSubmit } = props;
  const [CompanyAdminForm] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [number, setNumber] = useState("");

  const [switchItem, setSwitchItem] = useState({
    is_verified_mobile: false,
    is_verified_email: false,
  });

  useEffect(() => {
    CompanyAdminForm.resetFields();
    if (selectedRecord && selectedRecord.company_admin_id !== 0) {
      CompanyAdminForm.setFieldsValue({
        ...selectedRecord,
        mobile_prefix: _.head(_.split(selectedRecord.mobile, "-")),
        mobile: _.last(_.split(selectedRecord.mobile, "-")),
      });
      setSwitchItem({
        is_verified_mobile: selectedRecord.is_verified_mobile === 1,
        is_verified_email: selectedRecord.is_verified_email === 1,
      });
    }
  }, [selectedRecord]);

  const onNumberChange = (e) => {
    const { value } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if ((!_.isNaN(value) && reg.test(value)) || value === "" || value === "-") {
      setNumber(value);
      CompanyAdminForm.setFieldsValue({ mobile: value });
    }
  };

  const onFinish = async (formData) => {
    setLoading(true);
    try {
      if (selectedRecord.company_admin_id === 0) {
        if (formData.password !== formData.retype_password) {
          return notification.error({
            message: t("error"),
            description: t("password_not_same"),
          });
        }
      }
      await handleFormSubmit({
        ...formData,
        company_admin_id: selectedRecord.company_admin_id,
        mobile: `${formData.mobile_prefix}-${formData.mobile}`,
        is_verified_email: switchItem.is_verified_email === true ? 1 : 0,
        is_verified_mobile: switchItem.is_verified_mobile === true ? 1 : 0
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      form={CompanyAdminForm}
      name="CompanyAdminForm"
      layout="vertical"
      onFinish={onFinish}
    >
      <Row justify="space-around">
        <Col span={7}>
          <Form.Item
            label={t("first_name")}
            name="first_name"
            rules={[{ required: true, message: t("input_required") }]}
          >
            <Input maxLength={64} />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item
            label={t("last_name")}
            name="last_name"
            rules={[{ required: true, message: t("input_required") }]}
          >
            <Input maxLength={64} />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item
            label={t("nickname")}
            name="nickname"
            rules={[{ required: true, message: t("input_required") }]}
          >
            <Input maxLength={50} />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="space-around">
        <Col span={7}>
          <Form.Item
            label={t("username")}
            name="username"
            rules={[{ required: true, message: t("input_required") }]}
          >
            <Input
              disabled={selectedRecord.company_admin_id !== 0}
              maxLength={40}
            />
          </Form.Item>
        </Col>
        {selectedRecord.company_admin_id === 0 && (
          <>
            <Col span={7}>
              <Form.Item
                label={t("password")}
                name="password"
                rules={[{ required: true, message: t("input_required") }]}
              >
                <Input type="password" />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                label={t("retype_password")}
                name="retype_password"
                rules={[{ required: true, message: t("input_required") }]}
              >
                <Input type="password" />
              </Form.Item>
            </Col>
          </>
        )}
      </Row>
      <Row justify="space-around">
        <Col span={8}>
          <Form.Item
            label={t("email")}
            name="email"
            rules={[
              {
                required: true,
                message: t("input_required"),
                type: "email",
              },
            ]}
          >
            <Input maxLength={50} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={t("moblie")}
            name="mobile"
            rules={[{ required: true, message: t("input_required") }]}
          >
            <Input
              className="mobile_prefix"
              value={number}
              onChange={onNumberChange}
              addonBefore={
                <Form.Item name="mobile_prefix" noStyle>
                  <Select style={{ width: 80 }} >
                    <Option value="+852">+852</Option>
                  </Select>
                </Form.Item>
              }
              maxLength={15}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label={t("company_admin_role_id")}
        name="company_admin_role_id"
        rules={[{ required: true, message: t("input_required") }]}
      >
        <Select>
          {_.map(dataObj.companyAdminRoleArr, (eachRole) => {
            return (
              <Option value={eachRole.company_admin_role_id}>
                {eachRole.role_name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      <Row justify="end">
        <Col>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              {t("submit")}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default CompanyAdminModal;
