import { useState, useEffect, useCallback } from "react";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import * as Service from "../core/Service";

const useFetch = ({
  whereClause = {},
  route,
  method = "get",
}) => {
  const [dataList, setDataList] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [rawDataList, setRawDataList] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (refresh) {
      getData();
    }
  }, [refresh]);

  const getData = useCallback(async () => {
    try {
      setRefresh(true);
      let resp = await Service.call(method, route, whereClause);
      if (!resp || resp.status !== 1) {
        return notification.error({
          message: t("error"),
          description: t(`${resp.errorCode}`),
        });
      }

      const userList = _.keyBy(resp.data.companyUserList, "company_user_id");

      const orderList = _.map(resp.data.orderList, (order) => {
        if (!userList[order.company_user_id]) return order;
        return {...order, ...userList[order.company_user_id]};
      });
      setDataList(orderList);
      setRawDataList(orderList);
    } catch (error) {
      console.error("error >>>", error);
    } finally {
      setRefresh(false);
    }
  }, [method, route, whereClause]);
  return [dataList, setDataList, refresh, setRefresh, rawDataList];
};

export default useFetch;
