import React, { useState, useEffect } from "react";
import {
  Spin,
  Table,
  Tag,
  Row,
  Col,
  Input,
  Select,
  notification,
  Divider,
  DatePicker,
} from "antd";
// import 'antd/dist/antd.css';
import moment from "moment";
import Fuse from "fuse.js";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import * as Service from "../../core/Service";
import * as Main from "../../core/Main";
import SearchBar from "../../components/SearchBar";

const debug = require("debug")("app:admin:client:src:AdvertisementList");

const FILTERABLE_FIELDS = [
  "email",
  "order_key",
  "order_id",
  "mobile",
  "company_user_id",
];
const tableIDName = "company_user_id";
const { Option } = Select;
const { RangePicker } = DatePicker;

const CompanyUserTokenLog = (props) => {
  const { onFocus } = props;
  const { t } = useTranslation();

  const TOKEN_LOG_TYPE = {
    order_create: {
      name: t("order_create"),
      color: "green",
      value: "order_create",
    },
    order_cancel: {
      name: t("order_cancel"),
      color: "red",
      value: "order_cancel",
    },
    top_up: {
      name: t("top_up"),
      color: "blue",
      value: "top_up",
    },
    add_by_admin: {
      name: t("add_by_admin"),
      color: "yellow",
      value: "add_by_admin",
    },
    buy_token: {
      name: t("buy_token"),
      value: "buy_token",
      color: "purple",
    },
    order_item_refund: {
      name: t("order_item_refund"),
      value: "order_item_refund",
      color: "orange",
    },
    order_refund: {
      name: t("order_refund"),
      value: "order_refund",
      color: "orange",
    },
  };

  const [dataList, setDataList] = useState([]);
  const [filterDataList, setFilterDataList] = useState([]);
  const [searchType, setSearchType] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState([
    moment().subtract(1, "M").startOf("d"),
    moment(),
  ]);

  useEffect(() => {
    getAllData(selectedDate);
  }, [onFocus]);

  const getAllData = async (date) => {
    let tokenLogArr = [];
    let [start_time, end_time] = date;
    start_time = start_time.unix();
    end_time = end_time.unix();
    try {
      setLoading(true);
      let url = `/api/token/company/user?start_time=${start_time}&end_time=${end_time}`;
      let resp = await Service.call("get", url);
      if (!resp || resp.status !== 1) {
        return notification.error({
          message: t("error"),
          description: t(resp.errorCode),
        });
      }
      let { companyUserArr, userTokenLogArr } = resp.data;
      let keyById_companyUser = _.keyBy(companyUserArr, "company_user_id");
      tokenLogArr = _.orderBy(userTokenLogArr, ["ctime"], ["desc"]);
      tokenLogArr = _.map(tokenLogArr, (eachTokenLog) => {
        let { company_user_id } = eachTokenLog;
        let companyUserRc = keyById_companyUser[company_user_id] || {};
        return {
          ...eachTokenLog,
          email: companyUserRc.email,
          mobile: companyUserRc.mobile,
          nickname: companyUserRc.nickname
        };
      });
    } catch (error) {
      console.error("error >>> ", error);
    } finally {
      setDataList(tokenLogArr);
      setFilterDataList(tokenLogArr);
      setLoading(false);
    }
  };

  const setTableHeader = () => {
    const columns = [
      {
        title: t("company_user_id"),
        dataIndex: "company_user_id",
        sorter: (a, b) => a.company_user_id - b.company_user_id,
      },
      {
        title: t("nickname"),
        dataIndex: "nickname",
        render: (value) => {
          return value || "-";
        }
      },
      {
        title: t("token_change"),
        dataIndex: "token_change",
        sorter: (a, b) =>
          a.token_after - a.token_before - (b.token_after - b.token_before),
        render: (value, record) => displayTokenChange(value, record),
      },
      {
        title: t("token_before"),
        dataIndex: "token_before",
        // render: (value) => displayBoolean(value),
        sorter: (a, b) => a.token_before - b.token_before,
      },
      {
        title: t("token_after"),
        dataIndex: "token_after",
        // render: (value) => displayBoolean(value),
        sorter: (a, b) => a.token_after - b.token_after,
      },
      {
        title: t("type"),
        dataIndex: "type",
        render: (value) => displayIsType(value),
        sorter: (a, b) => a.type.localeCompare(b.type),
      },
      {
        title: t("order_id"),
        dataIndex: `order_id`,
        render: (value) => {
          return value || "-";
        },
        // sorter: (a, b) => a.ref_id.localeCompare(b.order_key)
      },
      {
        title: t("status"),
        dataIndex: "status",
        render: (value) => displayIsStatus(value),
        sorter: (a, b) => a.status.localeCompare(b.status),
      },
      {
        title: t("change_time"),
        dataIndex: "ctime",

        render: (value) => {
          return moment.unix(value).format("YYYY-MM-DD hh:mm");
        },
        sorter: (a, b) => a.ctime - b.ctime,
      },
      {
        title: t("remarks"),
        dataIndex: "remarks",
        // sorter: (a, b) => a.remarks.localeCompare(b.remarks)
      },
    ];
    return columns;
  };

  const displayIsStatus = (value) => {
    let displayStr = "";
    let tagColor = "green";
    // let isActiveValue = _.toInteger(value);
    switch (value) {
      case "success":
        displayStr = t("success");
        tagColor = "green";
        break;
      case "fail":
        displayStr = t("fail");
        tagColor = "red";
        break;
      default:
        displayStr = t("error");
        tagColor = "#f50";
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  };

  const displayTokenChange = (value, record) => {
    const diff = record.token_after - record.token_before;

    if (diff < 0) return <div style={{ color: "red" }}>{`${value}`}</div>;
    if (diff > 0) return <div style={{ color: "green" }}>{`+${value}`}</div>;
    return <div>{`${value}`}</div>;
  };

  const displayIsType = (value) => {
    let displayStr = "";
    let tagColor = "green";
    switch (value) {
      case "order":
      case "order_create":
        displayStr = t("order_create");
        tagColor = "green";
        break;
      case "order_cancel":
        displayStr = t("order_cancel");
        tagColor = "red";
        break;
      case "top_up":
        displayStr = t("top_up");
        tagColor = "blue";
        break;
      case "add_by_admin":
        displayStr = t("add_by_admin");
        tagColor = "yellow";
        break;
      case "buy_token":
        displayStr = t("buy_token");
        tagColor = "purple";
        break;
      case "order_item_refund":
        displayStr = t("order_item_refund");
        tagColor = "orange";
        break;
      case "order_refund":
        displayStr = t("order_refund");
        tagColor = "orange";
        break;
      default:
        displayStr = t("error");
        tagColor = "#f50";
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  };

  const onTypeChange = (type) => {
    if (type === "all") {
      return setSearchType(null);
    }
    setSearchType({ type });
  };

  const renderTypeSelectBox = () => {
    return (
      <Select
        defaultValue="all"
        onChange={onTypeChange}
        style={{ minWidth: 160 }}
      >
        <Option value="all">{t("all")}</Option>
        {_.map(TOKEN_LOG_TYPE, (eachType, key) => {
          return (
            <Option key={key} value={eachType.value}>
              {t(`${eachType.name}`)}
            </Option>
          );
        })}
      </Select>
    );
  };

  return (
    <>
      <Row>
        <Col>
          <span>{`${t("date")}：`}</span>
          <RangePicker
            placeholder={[t("start"), t("end")]}
            value={selectedDate}
            onChange={(dates) => {
              setSelectedDate(dates);
              getAllData(dates);
            }}
            style={{ magrinLeft: 8 }}
          />
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col style={{ alignSelf: "center" }}>
          <span>{`${t("search_keyword")}：`}</span>
        </Col>
        <Col>
          <SearchBar
            originalDataList={dataList}
            filterDataList={dataList}
            setDataList={setFilterDataList}
            filterField={FILTERABLE_FIELDS}
            extraFilter={searchType}
          />
        </Col>
        <Col style={{ marginLeft: 20 }}>
          <>{`${t("type")}: `}</>
          {renderTypeSelectBox()}
        </Col>
      </Row>
      <Divider />
      <Spin spinning={loading}>
        <Table
          rowKey={tableIDName}
          scroll={{ x: "max-content" }}
          dataSource={filterDataList}
          columns={setTableHeader()}
          pagination={{
            defaultPageSize: 50,
            showSizeChanger: true,
            pageSizeOptions: [25, 50, 100, 200, 500],
          }}
          locale={{
            triggerDesc: t("sort_descend"),
            triggerAsc: t("sort_ascend"),
            cancelSort: t("cancel_sort"),
          }}
        />
      </Spin>
    </>
  );
};

export default CompanyUserTokenLog;
