import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  message,
  DatePicker,
  Divider,
  Radio,
  Modal,
  InputNumber,
} from "antd";
import moment from "moment";
import * as debugLib from "debug";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import FormUploadFile from "../../../components/form/FormUploadFile";
import Button from "../../../t9UIComponents/t9Button";
import TinymceWrapper from "../../../components/TinymceWrapper";

const debug = debugLib("app:pages/company/blog/post/list/Modal");

const { Option } = Select;

const TYPE_CONVERT = {
  blog: {
    table: "blogPostCategory",
    id: "blog_post_category_id",
  },
  news: {
    table: "newsCategory",
    id: "news_category_id",
  }
};

const BlogPostModal = (props) => {
  const { t } = useTranslation();
  const {
    selectedRecord,
    modalVisible,
    setModalVisible,
    handleFormSubmit,
    keyByIdCategoryList
  } = props;
  const [imageURL, setImageURL] = useState("");
  const [fileinfo, setFileinfo] = useState({});
  const [type, setType] = useState("");
  const [blogDesc, setBlogDesc] = useState(null);
  const [imageData, setImageData] = useState("");

  const STATIC_SERVER_URL = useSelector(
    (state) => state.app.config.STATIC_SERVER_URL
  );

  const [BlogPostForm] = Form.useForm();


  useEffect(() => {
    BlogPostForm.resetFields();
    setImageURL("");
    setFileinfo({});
    setType("");
    setBlogDesc("");
    BlogPostForm.setFieldsValue({
      ...selectedRecord,
      post_date: moment(),
    });
    if (selectedRecord.blog_post_id > 0) {
      setType(selectedRecord.type_name);
      let path = STATIC_SERVER_URL + "/" + selectedRecord.banner;
      let covertDate = moment
        .unix(selectedRecord.post_date)
        .format("YYYY-MM-DD");
      selectedRecord.post_date = moment(covertDate);
      setImageURL(path);
      setBlogDesc(selectedRecord.description);
      if (TYPE_CONVERT[selectedRecord.type_name]) {
        let tableName = TYPE_CONVERT[selectedRecord.type_name].table;
        let tableIdFieldName = TYPE_CONVERT[selectedRecord.type_name].id;
        let id = selectedRecord.type_category_id;

        let typeCategoryRecord = keyByIdCategoryList[tableName][id];
        BlogPostForm.setFieldsValue({
          type_category_id: typeCategoryRecord
            ? typeCategoryRecord[tableIdFieldName]
            : null,
        });
      }
      setImageData(selectedRecord.banner);
    }
  }, [selectedRecord]);

  const uploadOnChange = (info) => {
    const { status, response } = info.file;
    if (status === "done") {
      message.destroy();
      if (response.status > 0) {
        message.success(t("uploaded"));
        let data = {
          filename: info.file.response.filename,
          filepath: info.file.response.filepath,
        };
        let path = STATIC_SERVER_URL + "/" + info.file.response.filepath;
        setImageURL(path);
        setFileinfo(data);
        setImageData(info.file.response.filepath);
      } else {
        message.error(t("fail"));
      }
    }
  };

  const handleChange = (value) => {
    setType(value);
    BlogPostForm.setFieldsValue({
      type_category_id: null,
    });
  };

  return (
    <Modal
      title={
        selectedRecord.blog_post_id === 0 ? t("add_item") : t("edit_record")
      }
      visible={modalVisible}
      footer={null}
      width={1280}
      style={{ maxWidth: "95%" }}
      onCancel={() => {
        setModalVisible(false);
      }}
    >
      <Form
        form={BlogPostForm}
        name="BlogPostForm"
        layout="vertical"
        onFinish={(formData) => {
          let banner = imageData || "";
          handleFormSubmit({
            ...formData,
            item_feature_image: fileinfo.filename ? fileinfo.filename : null,
            fileinfo: fileinfo.filename ? fileinfo : null,
            description: blogDesc,
            post_date: moment(formData.post_date).unix(),
            banner,
          });
        }}
      >
        <Row gutter={24}>
          <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              label={t("type_name")}
              name="type_name"
              rules={[{ required: true, message: t("select_required") }]}
            >
              <Select onChange={handleChange}>
                {/* <Option value="blog">{t("blog")}</Option> */}
                {/* <Option value="facilities">{t("facilities")}</Option> */}
                <Option value="news">{t("news")}</Option>
                <Option value="promotion">{t("promotion")}</Option>
              </Select>
            </Form.Item>

            {_.includes(["news", "promotion", "blog"], type) ? (
              <Form.Item
                label={t("title")}
                name="title"
                rules={[{ required: true, message: t("input_required") }]}
              >
                <Input maxLength={55} />
              </Form.Item>
            ) : null}

            {_.includes(["facilities", "blog", "promotion", "news"], type) ? (
              <Form.Item label={t("desc")}>
                <TinymceWrapper
                  initialValue={blogDesc}
                  onChange={(value) => {
                    setBlogDesc(value);
                  }}
                />
              </Form.Item>
            ) : null}

            {_.includes(["facilities", "blog", "promotion", "news"], type) ? (
              <Form.Item label={t("banner")} name="banner">
                <FormUploadFile
                  data={{ scope: "public" }}
                  onChange={uploadOnChange}
                  imageURL={imageURL}
                />
              </Form.Item>
            ) : null}
            {/* {_.includes(["blog", "news"], type) ? (
              <Form.Item
                label={t("category_name")}
                name="type_category_id"
                rules={[{ required: true, message: t("select_required") }]}
              >
                <Select>
                  {_.map(
                    Object.values(
                      keyByIdCategoryList[TYPE_CONVERT[type].table]
                    ),
                    (value) => {
                      return (
                        <Option value={value[TYPE_CONVERT[type].id]}>
                          {value.category_name}
                        </Option>
                      );
                    }
                  )}
                </Select>
              </Form.Item>
            ) : null} */}
            <Form.Item
              label={t("post_date")}
              name="post_date"
              rules={[{ required: true, message: t("select_required") }]}
              format="YYYY-MM-DD"
            >
              <DatePicker placeholder={t("post_date")} />
            </Form.Item>
            <Form.Item
              label={t("sorting")}
              name="sorting"
              rules={[{ required: true, message: t("input_required") }]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              label={t("is_active")}
              name="is_active"
              rules={[{ required: true, message: t("select_required") }]}
            >
              <Radio.Group>
                <Radio value={1}>{t("active")}</Radio>
                <Radio value={0}>{t("not_active")}</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row justify="end">
          <Col>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {t("submit")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default BlogPostModal;
