import React, { useState, useEffect } from "react";
import { Divider, notification } from "antd";
import { useTranslation } from "react-i18next";
import Button from "../../../t9UIComponents/t9Button";
import * as Service from "../../../core/Service";
import NavAndSideFrame from "../../../components/NavAndSideFrame";
import CompanyHolidayListTable from "./CompanyHolidayListTable";
import CompanyHolidayInfoModal from "./CompanyHolidayInfoModal";

const selectedKey = "holiday_management";
const openKeys = "facility_management";

const CompanyHolidayList = () => {
  const [dataList, setDataList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(0);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const title = t("holiday_management");

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    setLoading(true);
    let holidayList = [];
    try {
      holidayList = await Service.call("get", "/api/booking_item_holiday/list");
    } catch (error) {
      console.error("error >>> ", error);
      setLoading(false);
    } finally {
      setDataList(holidayList);
      setLoading(false);
    }
  };

  const handleFormSubmit = async (submitRecord) => {
    try {
      setLoading(true);
      let method = submitRecord.booking_item_holiday_id === 0 ? "put" : "patch";
      let resp = await Service.call(method, "/api/booking_item_holiday", submitRecord);
      if (resp.status < 1) {
        return notification.error({
          message: t("fail"),
          description: t(resp.errorCode) 
        });
      }
      notification.success({
        message: t("success"),
      });
      getAllData();
      setModalVisible(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleIsActiveChange = async (booking_item_holiday_id, value) => {
    setLoading(true);
    let postData = {
      booking_item_holiday_id,
      is_active: value,
    };
    let submit = await Service.call(
      "patch",
      "/api/booking_item_holiday/is_active",
      postData
    );
    if (submit.status < 1) {
      notification.error({
        message: t("fail"),
        description: t(submit.errorCode) 
      });
      setLoading(false);
    } else {
      notification.success({
        message: t("success"),
      });
      getAllData();
      setLoading(false);
      setModalVisible(false);
    }
  };

  const handleIDeleteChange = async (booking_item_holiday_id) => {
    setLoading(true);
    let postData = {
      booking_item_holiday_id,
    };
    let submit = await Service.call(
      "delete",
      "/api/booking_item_holiday",
      postData
    );
    if (submit.status < 1) {
      notification.error({
        message: t("fail"),
        description: t(submit.errorCode) 
      });
      setLoading(false);
    } else {
      notification.success({
        message: t("success"),
      });
      getAllData();
      setLoading(false);
      setModalVisible(false);
    }
  };

  return (
    <NavAndSideFrame title={title} selectedKey={selectedKey} openKeys={openKeys}>
      <Button
        type="primary"
        onClick={() => {
          setModalVisible(true);
          setSelectedRecord({ booking_item_holiday_id: 0 });
        }}
      >
        {t("add_item")}
      </Button>
      <Divider />
      <CompanyHolidayListTable
        dataObj={dataList}
        setModalVisible={setModalVisible}
        setSelectedRecord={setSelectedRecord}
        isActiveChangeCallBack={handleIsActiveChange}
        isDeleteCallBack={handleIDeleteChange}
        loading={loading}
      />
      <CompanyHolidayInfoModal
        selectedRecord={selectedRecord}
        categoryList={categoryList}
        modalVisible={modalVisible}
        setModalVisible={(value) => {
          setModalVisible(value);
          getAllData();
        }}
        handleFormSubmit={(submitRecord) => handleFormSubmit(submitRecord)}
      />
    </NavAndSideFrame>
  );
};

export default CompanyHolidayList;
