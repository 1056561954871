import React, { useState, useEffect } from "react";
import {
  Spin,
} from "antd";
import _ from "lodash";
import * as Service from "../../core/Service";
// import EventBanner from "./EventBanner";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import EventForm from "./EventForm";


const EventInformation = (props) => {
  const {
    dataObj,
    getEventCategoryList,
    categories,
    getEventInfo
  } = props;
  const { t } = useTranslation();
  const { companyRc } = useSelector((state) => state.app.company);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   console.log("dataObj >>>", dataObj);
  // }, [dataObj]);

  return (
    <div className="event-setting event-tabs-page">
      <Spin spinning={loading}>
        <EventForm
          {...props}
          categories={categories}
          getEventCategoryList={getEventCategoryList}
          dataObj={dataObj}
          onSuccess={getEventInfo}
        />
      </Spin>
    </div>
  );
};

export default EventInformation;
