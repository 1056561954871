import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Button,
  Form,
  Select,
  DatePicker,
  Collapse,
  notification,
} from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import { formItemLayout, tailLayout } from "../../../components/ModalLayout";
import * as Service from "../../../core/Service";
import * as Main from "../../../core/Main";

const { Option } = Select;
const { Panel } = Collapse;

const AccessReportFilterModal = (props) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const onFinish = async (postObj) => {
    let clonePostObj = _.clone(postObj);
    if (!postObj.company_door_id) {
      clonePostObj = {
        ...clonePostObj,
        company_door_id: [],
      };
    }
    if (!postObj.company_user_id_list) {
      clonePostObj = {
        ...clonePostObj,
        company_user_id_list: [],
      };
    }
    await generateAccessReport(clonePostObj);
  };

  const generateAccessReport = async (postObj) => {
    try {
      let { date_period } = postObj;
      let time_start = moment(_.head(date_period)).startOf("d").unix();
      let time_end = moment(_.last(date_period)).endOf("d").unix();
      let resp = await Service.call(
        "get",
        `/api/report/booking/info?start_time=${time_start}&end_time=${time_end}`
      );
      if (!resp || resp.status !== 1) {
        return notification.error({
          message: t("error"),
          description: t(`${resp.errorCode}`),
        });
      }
      let {
        companyUserArr,
        companyUserConfigArr,
        bookingItemArr,
        bookingInfoArr,
        invoiceItemArr,
        invoiceArr,
        paymentTransactionArr,
      } = resp.data;
      let keyById_companyUserArr = _.keyBy(companyUserArr, "company_user_id");
      let keyById_companyUserConfigArr = _.keyBy(
        companyUserConfigArr,
        "company_user_id"
      );
      let keyById_bookingItemArr = _.keyBy(bookingItemArr, "booking_item_id");
      let keyById_invoiceItemArr = _.keyBy(invoiceItemArr, "item_id");
      let keyById_invoiceArr = _.keyBy(invoiceArr, "invoice_id");
      let keyById_paymentTransactionArr = _.keyBy(
        paymentTransactionArr,
        "invoice_id"
      );
      let dataList = _.map(bookingInfoArr, (eachBooking) => {
        // start_time, end_time, duration, booking_id, create time
        let {
          start_time,
          end_time,
          section_duration,
          booking_id,
          ctime,
          section_price,
        } = eachBooking;
        // Company user id, first_name, last_name
        let userRc = keyById_companyUserArr[eachBooking.company_user_id];
        let { company_user_id, first_name, last_name } = userRc;
        let userConfigRc =
          keyById_companyUserConfigArr[eachBooking.company_user_id];
        let { payment_type } = userConfigRc;
        // Item name
        let bookingItemRc = keyById_bookingItemArr[eachBooking.booking_item_id];
        let { item_name } = bookingItemRc;
        let invoiceItemRc = keyById_invoiceItemArr[eachBooking.booking_id] || {};
        // Relational
        // booking_type (Check company_admin_id)
        let invoiceRc = keyById_invoiceArr[invoiceItemRc.invoice_id] || {};
        let { company_admin_id } = invoiceRc;
        // Payment type (Check each payment amount), approval (ptx_complete_time)
        let ptxRc =
          keyById_paymentTransactionArr[invoiceRc.invoice_group_id] || {};
        let booking_type = "-";
        if (company_admin_id === 0) {
          booking_type = "System";
        }
        if (!_.isUndefined(company_admin_id) && company_admin_id !== 0) {
          booking_type = "Admin";
        }

        let paymentTypeStr = "";
        let {
          ptx_complete_time,
          ptx_stripe_amount,
          ptx_token_amount,
          ptx_company_amount,
          ptx_offline_amount,
        } = ptxRc;
        if (!_.isEmpty(ptxRc)) {
          paymentTypeStr = checkPaymentMethod({
            ptx_stripe_amount,
            ptx_token_amount,
            ptx_company_amount,
            ptx_offline_amount,
          });
        }
        return {
          ctime: moment.unix(ctime).format("YYYY-MM-DD HH:mm:ss"),
          company_user_id,
          user_type: payment_type === 0 ? "Normal" : "Monthly",
          first_name,
          last_name,
          item_name,
          start_time: dayjs.unix(start_time).format("YYYY-MM-DD HH:mm:ss"),
          end_time: dayjs.unix(end_time).format("YYYY-MM-DD HH:mm:ss"),
          duration: `${section_duration / 60} mins`,
          amount: section_price,
          booking_type,
          payment_type: _.join(paymentTypeStr, ","),
          approval: ptx_complete_time ? moment.unix(ptx_complete_time).format("YYYY-MM-DD HH:mm:ss") : "-",
          booking_id,
        };
      });
      if (_.isEmpty(dataList)) {
        return notification.info({
          message: t("info"),
          description: t("no_record"),
        });
      }
      Main.exportCsvFile(dataList, [
        [
          "Create time",
          "Company user ID",
          "User type",
          "First name",
          "Last name",
          "Item name",
          "Start_time",
          "End_time",
          "Duration",
          "Amount",
          "Booking type",
          "Payment type",
          "Approval",
          "Booking ID",
        ]
      ], "BookingReport");
    } catch (error) {
      console.error(error);
    }
  };

  const checkPaymentMethod = ({
    ptx_stripe_amount,
    ptx_token_amount,
    ptx_company_amount,
    ptx_offline_amount,
  }) => {
    let returnStr = [];
    if (ptx_stripe_amount > 0) {
      returnStr.push("stripe");
    }
    if (ptx_token_amount > 0) {
      returnStr.push("token");
    }
    if (ptx_company_amount > 0) {
      returnStr.push("company");
    }
    if (ptx_offline_amount > 0) {
      returnStr.push("offline");
    }
    return returnStr;
  };

  return (
    <Form
      {...formItemLayout}
      form={form}
      name="time_related_controls"
      onFinish={onFinish}
    >
      <Form.Item
        label="Date period"
        name="date_period"
        initialValue={[moment().subtract(30, "days"), moment()]}
      >
        <DatePicker.RangePicker
          allowClear={false}
          style={{ marginLeft: 20, width: 250 }}
        />
      </Form.Item>
      <Form.Item
        {...tailLayout}
        style={{ marginTop: 20, width: "100%", textAlign: "center" }}
        wrapperCol={{ offset: 0 }}
      >
        <Button type="primary" htmlType="submit">
          Export
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AccessReportFilterModal;
