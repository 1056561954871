import React, { useEffect, useState } from "react";
import {
  Divider,
  Form,
  Input,
  InputNumber,
  notification,
  Spin,
  Button,
  Select
} from "antd";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import _ from "lodash";
import NavAndSideFrame from "../../components/NavAndSideFrame";
import TinymceWrapper from "../../components/TinymceWrapper";
import * as Service from "../../core/Service";
import MembershipDetailTable from "../../components/MembershipDetailTable";
import T9Button from "../../t9UIComponents/t9Button";
import * as Main from "../../core/Main";

const { Option } = Select;

const MembershipForm = (props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [membershipPlan, setMembershipPlan] = useState({
    description: ""
  });
  const [itemRc, setItemRc] = useState({
    bookingItemList: [],
    productItemList: [],
    keyByProductItemList: {},
    keyByBookingItemList: {},
  });
  const location = useLocation();
  const history = useHistory();

  const getPlanDetail = async (membership_plan_id) => {
    setLoading(true);
    try {
      const resp = await Service.call("get", "/api/membership/detail", {
        membership_plan_id
      });
      if (!resp || resp.status !== 1) {
        notification.error({
          message: t("error"),
          description: t(resp.errorCode),
        });
        return history.goBack();
      }
      const {
        membershipPlanRc,
        bookingItemList,
        productItemList
      } = resp.data;
      const keyByBookingItemList = _.keyBy(bookingItemList, "booking_item_id");
      const keyByProductItemList = _.keyBy(productItemList, "product_item_id");
      setMembershipPlan({
        ...membershipPlanRc,
        bookingItemList,
        productItemList,
        keyByBookingItemList,
        keyByProductItemList
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (location.state.membership_plan_id > 0) {
      getPlanDetail(location.state.membership_plan_id);
      form.setFieldsValue({ ...location.state });
    }
    setItemRc(location.state);
  }, [location]);

  const onFinish = async (formData) => {
    setLoading(true);
    try {
      let postData = {
        ...formData,
        description: membershipPlan.description,
        // apply_to_level implement later when implementing level system
        apply_to_level: false
      };
      let method = "post";
      let url = "/api/membership";

      if (formData.membership_plan_id > 0) {
        method = "patch";
      }
      console.log({ postData, method });
      const resp = await Service.call(method, url, postData);
      if (!resp || resp.status !== 1) {
        return notification.error({
          message: t("error"),
          description: t(resp.errorCode),
        });
      }
      notification.success({
        message: t("success"),
      });
      form.setFieldsValue(resp.data);
      getPlanDetail(resp.data.membership_plan_id);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <NavAndSideFrame title={t("membership_form")}>
      <T9Button
        onClick={() => history.goBack()}
      >
        {t("back")}
      </T9Button>
      <Divider />
      <Spin spinning={loading}>
        <Form
          form={form}
          name="membershipPlanForm"
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            name="membership_plan_id"
            label={t("membership_plan_name")}
            hidden
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="name"
            label={t("membership_plan_name")}
            rules={[{ required: true, message: t("name_required") }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label={t("desc")}>
            <TinymceWrapper
              initialValue={membershipPlan.description}
              onChange={(value) => {
                setMembershipPlan((prevState) => ({
                  ...prevState,
                  description: value
                }));
              }}
            />
          </Form.Item>
          <Form.Item
            name="price"
            label={t("price")}
            rules={[{ required: true, message: t("name_required") }]}
          >
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item
            name="min_order_price"
            label={t("min_order_price")}
            rules={[{ required: true, message: t("name_required") }]}
          >
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item
            name="order_discount_type"
            label={t("order_discount_type")}
            rules={[{ required: true, message: t("name_required") }]}
          >
            <Select
              style={{ width: '100%' }}
            // defaultValue={value}
            // onChange={(discount_type) => onChangeDiscountType({ discount_type, index })}
            >
              {_.map(Main.DISCOUNT_TYPE, (rcValue, rcKey) => (
                <Option key={rcKey} value={rcValue}>{t(rcKey)}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="order_discount_amount"
            label={t("order_discount_amount")}
            rules={[{ required: true, message: t("name_required") }]}
          >
            <InputNumber min={0} />
          </Form.Item>
          {/* <Form.Item
          name="level"
          label={t("level")}
          rules={[{ required: true, message: t("name_required") }]}
        >
          <InputNumber min={0} />
        </Form.Item> */}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              {t("submit")}
            </Button>
          </Form.Item>
        </Form>
        {membershipPlan.membership_plan_id > 0 && (
          <div style={{ marginBottom: 100 }}>
            <MembershipDetailTable
              key="membership_detail_table"
              membershipPlanRc={membershipPlan}
              getPlanDetail={getPlanDetail}
            />
          </div>
        )}
      </Spin>
    </NavAndSideFrame>
  );
};

export default MembershipForm;
