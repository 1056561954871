import {
  Button,
  Col,
  Form,
  InputNumber,
  message,
  Modal,
  Popconfirm,
  Row,
} from "antd";
import React, { useState } from "react";

import {
  DeleteOutlined,
} from "@ant-design/icons";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import FormUploadFile from "./FormUploadFile";
import { openNotificationWithIcon } from "./ErrorMessage";
import emptyImage from "../../assets/empty_image.png";

const FormUploadImage = (props) => {
  const config = useSelector((state) => state.app.config);

  const { t } = useTranslation();
  const {
    type,
    headers,
    title,
    imageURL,
    data,
    onChange,
    onPreview,
    onRemove,
    disabled,
    loading,
    actions = [],
    sorting,
    onDelete
  } = props;

  const uploadProps = {
    name: "file",
    // multiple: true,
    action: "/api/admin/media",
    beforeUpload: (file) => {
      if (
        file.type !== "image/png" &&
        file.type !== "image/jpeg" &&
        file.type !== "image/svg+xml"
      ) {
        message.error(t("only_jpg_or_png_can_be_uploaded"));
        return false;
      }
      message.success(t("Uploading"));
      return true;
    },
    onError(err) {
      console.error(err);
      message.error(`上載失敗，請重試`);
    },
  };

  const STATIC_SERVER_URL = config.STATIC_SERVER_URL;
  const getConfigFilePath = (filepath) => {
    if (_.isNil(filepath) || filepath === "") return null;
    let path = STATIC_SERVER_URL + "/" + filepath;
    return path;
  };

  return (
    <Row>
      <Col span={24}>
        <div className="banner-grid-container">
          {_.map(headers, (header, index) => (
            <div className="banner-table-header" key={`header_${index}`}>{header}</div>
          ))}
          {type === "company" ? (
            <CompanyLogoTable
              imageURL={imageURL}
              loading={loading}
              onChange={onChange}
              actions={actions}
              getConfigFilePath={getConfigFilePath}
              t={t}
            />
          ) : (
            <BannerTable
              imageURL={imageURL}
              loading={loading}
              onChange={onChange}
              onDelete={onDelete}
              sorting={sorting}
              getConfigFilePath={getConfigFilePath}
              t={t}
            />
          )}
        </div>
      </Col>
    </Row>
  );
};

export default FormUploadImage;

const CompanyLogoTable = ({
  imageURL,
  onChange,
  actions,
  getConfigFilePath,
  t
}) => {
  return (
    <>
      <div className="banner-table-body w-100">
        <FormUploadFile
          key="image_upload"
          type="one"
          imageURL={getConfigFilePath(imageURL) || emptyImage}
          action="/api/admin/media"
          data={{ scope: "public" }}
          onChange={(info) => onChange(info)}
          style={{ maxWidth: imageURL && "100%", maxHeight: imageURL && "200px" }}
        />
      </div>
      <div className="banner-table-body">
        <Popconfirm
          title={t("confirm_delete")}
          onConfirm={() => onChange({})}
          okText={t("yes")}
          cancelText={t("no")}
        >
          <Button shape="circle" icon={<DeleteOutlined />} />
        </Popconfirm>
      </div>
      <div className="banner-table-body">{actions}</div>
    </>
  );
};

const BannerTable = ({
  imageURL,
  onChange,
  onDelete,
  getConfigFilePath,
  onSortingChange,
  sorting,
  t,
}) => {
  const config = useSelector((state) => state.app.config);

  const [modalVisible, setModalVisible] = useState(false);
  return (
    <>
      <div className="banner-table-body">
        <img
          src={imageURL ? `${config.ADMIN_ORIGIN}/${imageURL}` : emptyImage}
          alt="banner"
          style={{ maxWidth: imageURL && "100%", maxHeight: imageURL && "100%", cursor: "pointer" }}
          onClick={() => setModalVisible(true)}
        />
      </div>
      <div className="banner-table-body">
        <Popconfirm
          title={t("confirm_delete")}
          onConfirm={() => onDelete()}
          okText={t("yes")}
          cancelText={t("no")}
        >
          <Button shape="circle" icon={<DeleteOutlined />} />
        </Popconfirm>
      </div>
      <div className="banner-table-body">
        {sorting}
      </div>
      <EditImageModal
        visible={modalVisible}
        setVisible={setModalVisible}
        imageURL={imageURL}
        onChange={onChange}
        getConfigFilePath={getConfigFilePath}
        sorting={sorting}
        onSortingChange={onSortingChange}
      />
    </>
  );
};

const EditImageModal = ({
  visible,
  setVisible,
  imageURL,
  onChange,
  getConfigFilePath,
  sorting,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [newImageURL, setNewImageURL] = useState(null);
  const [imageInfo, setImageInfo] = useState(null)

  const onFinish = (formObj) => {
    form.resetFields();
    onChange(imageInfo, formObj?.sorting);
    setNewImageURL(null);
    setImageInfo(null);
    setVisible(false)
  };

  const onImageFileChange = async (info) => {
    const { status, response } = info.file;
    if (status === "done") {
      if (response.status > 0) {
        openNotificationWithIcon("success", t("common:upload_success"), "");
        // getAllDefaultMedia();

        message.success(t("uploaded"));

        let filepath = info.file.response.filepath;
        setNewImageURL(filepath);
        setImageInfo(info)
      }
    }
  };

  return (
    <Modal
      title={t("edit_banner")}
      visible={visible}
      footer={false}
      onCancel={() => {
        form.resetFields();
        setVisible(false);
      }}
      className="edit-banner-modal"
    >
      <Form
        layout="vertical"
        form={form}
        name="form"
        onFinish={onFinish}
        initialValues={{ filepath: imageURL, sorting }}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              label={t("banner")}
              name="filepath"
              rules={[{ required: true, message: t("input_required") }]}
            >
              <FormUploadFile
                key="image_upload"
                type="one"
                imageURL={getConfigFilePath(newImageURL)}
                action="/api/admin/media"
                data={{ scope: "public" }}
                onChange={(info) => onImageFileChange(info)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t("sorting")}
              name="sorting"
              rules={[{ required: true, message: t("input_required") }]}
            >
              <InputNumber min={1} value={sorting} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item style={{ marginBottom: 0, marginTop: 16 }}>
              <Button type="primary" htmlType="submit">
                {t("submit")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
