import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Button, Form, Input, Select, Modal } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import * as Service from "../../core/Service";

const { Option, OptGroup } = Select;

const SmartAccessDoorModal = (props) => {
  const { t } = useTranslation();
  const {
    initialValues,
    selectedRecord,
    itemList,
    modalVisible,
    setModalVisible,
    handleFormSubmit,
  } = props;

  const [isNew, setIsNew] = useState(true);
  const [SADoorForm] = Form.useForm();

  const [saCompanyDoorArr, setSaCompanyDoorArr] = useState([]);
  const [itemId, setItemId] = useState(0);

  const [keyItemList, setKeyItemList] = useState([]);
  const [keyDoorArr, setKeyDoorArr] = useState([]);
  const [loading, setLoading] = useState(false);
  const [doorRef, setDoorRef] = useState(null);

  useEffect(() => {
    if (selectedRecord.sa_door_id > 0) {
      setItemId(selectedRecord.item_id);
      setDoorRef(selectedRecord.door_ref);
    } else {
      setItemId(0);
      setDoorRef(null);
      setIsNew(true);
    }
    SADoorForm.resetFields();
    setKeyItemList(_.keyBy(itemList, "booking_item_id"));
  }, [selectedRecord]);

  useEffect(() => {
    fetchData();
  }, [itemId]);

  useEffect(() => {
    SADoorForm.resetFields();
  }, [selectedRecord]);

  const fetchData = async () => {
    try {
      setLoading(true);
      let itemRc = keyItemList[itemId] || {};
      if (!_.isEmpty(itemRc)) {
        let doorArr = await Service.call(
          "get",
          `/api/sa/door/list/${itemRc.company_id}`
        );
        let saCompanyDoorResp = await Service.call(
          "get",
          `/api/sa/company/door/list/${itemRc.company_id}`
        );
        setKeyDoorArr(_.keyBy(doorArr, (saDoorRc) => {
          return `${saDoorRc.item_id}_${saDoorRc.door_ref}`;
        }));
        _.remove(saCompanyDoorResp.saCompanyDoor, {door_ref: ""});
        setSaCompanyDoorArr(saCompanyDoorResp.saCompanyDoor);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      destroyOnClose
      title={isNew ? t("add_new_record") : t("edit_record")}
      visible={modalVisible}
      footer={null}
      onCancel={() => {
        setModalVisible(false);
      }}
    >
      <Form
        form={SADoorForm}
        name="SADoorForm"
        layout="vertical"
        initialValues={selectedRecord}
        onFinish={(formData) => {
          handleFormSubmit({
            ...formData,
            door_ref: doorRef,
          });
          setItemId(null);
        }}
      >
        <Form.Item label={t("company_name")}>
          {initialValues.company_name}
        </Form.Item>

        <Form.Item
          label={t("item_name")}
          name="item_id"
          rules={[
            {
              required: true,
              message: t("select_required"),
            },
          ]}
        >
          <Select
            disabled={selectedRecord.sa_door_id !== 0 || loading}
            onChange={(value) => {
              setItemId(value);
              setDoorRef(null);
            }}
          >
            <OptGroup label="Not linked">
              {_.map(itemList, (rec) => {
                if (rec.linked === 0) {
                  return (
                    <Option value={rec.booking_item_id}>{rec.item_name}</Option>
                  );
                }
              })}
            </OptGroup>
            <OptGroup label="Linked">
              {_.map(itemList, (rec) => {
                if (rec.linked === 1) {
                  return (
                    <Option value={rec.booking_item_id}>{rec.item_name}</Option>
                  );
                }
              })}
            </OptGroup>
          </Select>
        </Form.Item>
        <Form.Item
          label={t("door_ref")}
          rules={[
            {
              required: true,
              message: t("select_required"),
            },
          ]}
        >
          <Select
            disabled={selectedRecord.sa_door_id !== 0 || loading}
            onChange={(value) => setDoorRef(value)}
            value={doorRef}
          >
            <OptGroup label="Not linked">
              {_.map(saCompanyDoorArr, (eachDoor) => {
                if (_.isEmpty(keyDoorArr[`${itemId}_${eachDoor.door_ref}`])) {
                  return (
                    <Option value={eachDoor.door_ref}>
                      {`(${eachDoor.door_ref}) ${eachDoor.door_name}`}
                    </Option>
                  );
                }
              })}
            </OptGroup>
            <OptGroup label="Linked">
              {_.map(saCompanyDoorArr, (eachDoor) => {
                if (!_.isEmpty(keyDoorArr[`${itemId}_${eachDoor.door_ref}`])) {
                  return (
                    <Option value={eachDoor.door_ref} disabled="true">
                      {`(${eachDoor.door_ref}) ${eachDoor.door_name}`}
                    </Option>
                  );
                }
              })}
            </OptGroup>
          </Select>
        </Form.Item>

        {isNew ? null : (
          <div>
            <Form.Item label={t("create_time")}>
              <p>
                {moment.unix(selectedRecord.utime).format("YYYY-MM-DD HH:mm")}
              </p>
            </Form.Item>
            <Form.Item label={t("utime")}>
              <p>
                {moment.unix(selectedRecord.ctime).format("YYYY-MM-DD HH:mm")}
              </p>
            </Form.Item>
          </div>
        )}

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t("submit")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SmartAccessDoorModal;
