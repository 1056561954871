import React, { useState, useEffect } from "react";
import _, { initial } from "lodash";
import {
  Space,
  Row,
  Col,
  Form,
  Input,
  Select,
  message,
  Tabs,
  DatePicker,
  TimePicker,
  Switch,
  notification,
  Divider,
  InputNumber,
  Radio,
  AutoComplete,
  Modal,

} from "antd";
import moment from "moment";
import * as Main from '../../../core/Main';
import * as Service from "../../../core/Service";
import { useHistory, useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import * as debugLib from "debug";
import { useTranslation } from "react-i18next";
import FormUploadFile from "../../../components/form/FormUploadFile";
import { useSelector } from "react-redux";
import Button from '../../../t9UIComponents/t9Button';

const debug = debugLib("app:pages/company/slogan/list/Modal");

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const SloganPostModal = (props) => {
  const { t } = useTranslation();
  const {
    selectedRecord,
    modalVisible,
    setModalVisible,
    handleFormSubmit,
  } = props;
  const [initialFormValues, setInitialFormValues] = useState({});
  const STATIC_SERVER_URL = useSelector(
    (state) => state.app.config.STATIC_SERVER_URL
  );

  const [SloganPostForm] = Form.useForm();

  useEffect(() => {
    SloganPostForm.resetFields();
    setInitialFormValues({});
    if (selectedRecord.slogan_id > 0) {
      setInitialFormValues(selectedRecord);
    }
  }, [selectedRecord]);

  useEffect(() => {
    SloganPostForm.resetFields();
  }, [initialFormValues])

  return (
    <Modal
      title={
        selectedRecord.slogan_id === 0 ? t("add_item") : t("edit_record")
      }
      visible={modalVisible}
      footer={null}
      width={1280}
      style={{ maxWidth: "95%" }}
      onCancel={() => {
        setModalVisible(false);
      }}
    >
      <Form
        form={SloganPostForm}
        name="SloganPostForm"
        layout="vertical"
        initialValues={initialFormValues}
        onFinish={(formData) => {
          handleFormSubmit(formData);
        }}
      >
        <Row gutter={24}>
          <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item label={t("slogan")} name="title">
              <Input placeholder="Basic usage" />
            </Form.Item>
            <Form.Item
              label={t("is_active")}
              name="is_active"
              rules={[{ required: true, message: t('select_required') }]}
            >
              <Radio.Group>
                <Radio value={1}>Active</Radio>
                <Radio value={0}>not Active </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row justify="end">
          <Col>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {t("submit")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default SloganPostModal;
