import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Card,
  InputNumber,
  notification,
  Select,
} from "antd";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import * as Service from "../../core/Service";

const { Option } = Select;

const CompanyAcInfoForm = (props) => {
  const { t } = useTranslation();
  const { initialValues, refreshCompanyInfo } = props;

  const [companyKey, setCompanyKey] = useState("");
  const history = useHistory();
  const app = useSelector((state) => state.app);
  const [companyInfoForm] = Form.useForm();

  useEffect(() => {
    refreshCompanyInfo();
  }, []);

  useEffect(() => {
    companyInfoForm.resetFields();
    if (initialValues.company_id > 0) {
      setCompanyKey(initialValues.companyKey);
    }
    companyInfoForm.setFieldsValue({
      language: "en",
      tz: 8,
      ...initialValues,
    });
  }, [initialValues]);

  const onFinish = async (formData) => {
    let postData = {
      ...initialValues,
      ...formData,
    };
    let method = "post";
    let url = "/api/company";

    if (app.isAdmin) {
      url = "/api/admin/company";
    }

    if (initialValues.company_id > 0) {
      method = "patch";
    }

    let response = await Service.call(method, url, postData);
    // console.log("response >>>", response);
    if (!response || response.status !== 1) {
      notification.error({
        message: `${t("error")} - ${t(response.errorMessage)} [${response.errorCode}]`,
      });
      return;
    }

    notification.success({
      message: t("success"),
    });
    refreshCompanyInfo();
    return history.push("/admin/company/list");
  };

  const onCompanyKeyChange = (e) => {
    const { value } = e.target;
    const reg = /^([a-zA-Z0-9_.@#*&<]*)$/;
    if ((!_.isNaN(value) && reg.test(value)) || value === "") {
      setCompanyKey(value);
      companyInfoForm.setFieldsValue({ company_key: value });
    } else {
      companyInfoForm.setFieldsValue({ company_key: companyKey });
    }
  };

  return (
    <Row gutter={[24, 24]}>
      <Col md={24} lg={16}>
        <Card>
          <Form
            form={companyInfoForm}
            name="companyInfoForm"
            layout="vertical"
            onFinish={onFinish}
          >

            <Form.Item
              label={t("company_key")}
              name="company_key"
              rules={[
                {
                  required: true,
                  message: t("input_required")
                },
              ]}
            >
              <Input
                value={companyKey}
                onChange={onCompanyKeyChange}
                maxLength={50}
              />
            </Form.Item>
            <Form.Item
              label={t("company_name")}
              name="company_name"
              rules={[{
                required: true,
                message: t("input_required")
              }]}
            >
              <Input maxLength={55} />
            </Form.Item>


            <Form.Item
              label={t("timezone")}
              name="tz"
              rules={[
                {
                  required: true,
                  message: t("input_required")
                },
              ]}
            >
              <InputNumber />
            </Form.Item>

            <Form.Item
              label={t("default_language")}
              name="language"
              rules={[
                {
                  required: true,
                  message: t("input_required")
                },
              ]}
            >
              <Select
                style={{
                  width: 120,
                }}
              >
                <Option value="en">English</Option>
              </Select>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                {t("submit")}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default CompanyAcInfoForm;
