import React, { Component, useState, useEffect } from "react";
import {
  Avatar, Button, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip
} from "antd";
import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import "antd/dist/antd.css";
import moment from "moment";
import _, { set } from "lodash";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CompanyListTable = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const tableIDName = "company_id";
  let {
    dataObj,
    loading,
    isActiveChangeCallBack
  } = props;
  const [dataList, setDataList] = useState([]);
  const [pageSize, setPageSize] = useState(100);

  useEffect(() => {
    setDataList(dataObj);
  }, [dataObj]);

  const setTableHeader = () => {
    const columns = [
      {
        title: t("operation"),
        dataIndex: tableIDName,
        render: (value, record, key) => {
          console.log('record >>>', record);
          return (
            <span>
              <Tooltip title={t("edit")}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4 }}
                  icon={<EditOutlined />}
                  // disabled={record.is_active ? true : false}
                  onClick={() => {
                    history.push(
                      "/admin/company/setting",
                      { companyInfo: record },
                    );
                  }}
                />
              </Tooltip>
              <Tooltip title={record.is_active ? t("deactivate") : t("activate")}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4 }}
                  icon={record.is_active ? <CloseCircleOutlined /> : <CheckCircleOutlined />}
                  onClick={() => {
                    let is_active = record.is_active ? 0 : 1;
                    isActiveChangeCallBack(record.company_id, is_active);
                  }}
                />
              </Tooltip>
            </span>
          );
        }
      },
      {
        title: t("company_id"),
        dataIndex: "company_id",
        sorter: (a, b) => a.company_id - b.company_id
      },
      {
        title: t("active_status"),
        dataIndex: "is_active",
        render: (value) => displayIsActive(value),
        sorter: (a, b) => a.is_active - b.is_active
      },
      {
        title: t("company_name"),
        dataIndex: "company_name",
        sorter: (a, b) => a.company_name.localeCompare(b.company_name)
      },
      {
        title: t("admin_account_max"),
        dataIndex: "admin_account_max",
        sorter: (a, b) => a.admin_account_max - b.admin_account_max
      },
      {
        title: t("utime"),
        dataIndex: "utime",
        sorter: (a, b) => a.utime - b.utime,
        render: (value) => moment.unix(value).format("YYYY-MM-DD HH:mm")
      },
      {
        title: t("create_time"),
        dataIndex: "ctime",
        sorter: (a, b) => a.ctime - b.ctime,
        render: (value) => moment.unix(value).format("YYYY-MM-DD HH:mm")
      },
    ];
    return columns;
  };

  const displayIsActive = (value) => {
    let displayStr = "";
    let tagColor = "blue";
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("active");
        tagColor = "blue";
        break;
      case 0:
        displayStr = t("inactive");
        tagColor = "red";
        break;
      default:
        displayStr = t("error");
        tagColor = "red";
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  };

  return (
    <Layout>
      <Table
        rowKey={tableIDName}
        scroll={{ x: "max-content" }}
        dataSource={dataList}
        columns={setTableHeader()}
        bordered
        size="small"
        pagination={{ pageSize }}
        loading={loading}
        onChange={(paginate) => {
          setPageSize(paginate.pageSize);
        }}
      />
    </Layout>
  );
};

export default CompanyListTable;
