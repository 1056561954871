import React, { useState, useEffect } from "react";
import {
  Button, Layout, Modal, Table, Tag, Tooltip, notification, Popconfirm
} from "antd";
import {
  EditOutlined, DollarCircleOutlined, CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined
} from "@ant-design/icons";
import "antd/dist/antd.css";
import dayjs from "dayjs";
import _ from "lodash";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import UserAddTokenForm from "../../../components/form/UserAddTokenForm";
import * as Service from "../../../core/Service";
import * as Helper from "../../../core/Helper";

const CompanyUserAcListTable = (props) => {
  const { t } = useTranslation();
  const involvedModelName = "company";
  const title = "Company User";
  const selectedKey = 3;
  const tableIDName = "company_user_id";
  const app = useSelector((state) => state.app);
  const [addTokenModalVisible, setAddTokenModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  let {
    dataList, userDetail, loading, defaultActiveKey, getAllData, userFilter, setLoading
  } = props;

  useEffect(() => { }, []);

  const handleIsActiveChange = async (company_user_id, value) => {
    setLoading(true);
    let postData = {
      company_user_id,
      is_active: value,
    };
    let submit = await Service.call(
      "patch",
      "/api/company/user/status",
      postData
    );
    if (submit.status < 1) {
      notification.error({
        message: t(`fail`),
        description: t(submit.errorCode),
      });
      setLoading(false);
    } else {
      notification.success({
        message: t("success"),
      });
      getAllData();
      setLoading(false);
    }
  };

  const handleDeleteUser = async (company_user_id) => {
    setLoading(true);
    let deleteObj = {
      company_user_id
    };
    let resp = await Service.call("delete", "/api/company/user", deleteObj);
    if (resp.status < 1) {
      notification.error({
        message: t(`fail`),
        description: t(resp.errorCode),
      });
      setLoading(false);
    } else {
      notification.success({
        message: t("success"),
      });
      getAllData();
      setLoading(false);
    }
  };

  const handleUserApproveOrReject = async (company_user_id, approve) => {
    setLoading(true);
    const submit = await Service.call("patch", "/api/company/user/registration/status", {
      company_user_id,
      status: approve ? 1 : -1,
    });
    if (submit.status < 1) {
      notification.error({
        message: t(`fail`),
        description: t(submit.errorCode),
      });
      setLoading(false);
    } else {
      notification.success({
        message: t("success"),
      });
      getAllData();
      setLoading(false);
    }
  };

  const setTableHeader = () => {
    const columns = [];
    if (userFilter !== "rejected") {
      columns.push({
        title: t("operation"),
        dataIndex: tableIDName,
        render: (value, record, key) => {
          let statusControl = [];

          if (_.includes([0, 2], _.toInteger(record.status))) {
            statusControl.push(
              <Popconfirm
                title={t("confirm_approve")}
                onConfirm={() => handleUserApproveOrReject(value, true)}
                okText={t("yes")}
                cancelText={t("no")}
              >
                <Tooltip title={t("confirm_approve")}>
                  <Button
                    shape="circle"
                    style={{ marginRight: 4 }}
                    icon={<CheckCircleOutlined />}
                  />
                </Tooltip>
              </Popconfirm>
            );
          }
          if (record.status === 0) {
            statusControl.push(
              <Popconfirm
                title={t("confirm_reject")}
                onConfirm={() => handleUserApproveOrReject(value, false)}
                okText={t("yes")}
                cancelText={t("no")}
              >
                <Tooltip title={t("confirm_reject")}>
                  <Button
                    shape="circle"
                    style={{ marginRight: 4 }}
                    icon={<CloseCircleOutlined />}
                  />
                </Tooltip>
              </Popconfirm>
            );
          }
          if (record.status === 1) {
            statusControl.push(
              <Link
                to={{
                  pathname: "/company/user/form",
                  search: `?id=${value}&key=${defaultActiveKey}`,
                  state: {
                    userInfo: record,
                    companyConfigRc: dataList.companyConfigRc,
                    userDetailMap: userDetail
                  },
                }}
              >
                <Button
                  shape="circle"
                  style={{ marginRight: 4 }}
                  icon={<EditOutlined />}
                />
              </Link>
            );
            statusControl.push(
              <Popconfirm
                title={
                  record.is_active
                    ? t("confirm_deactivate")
                    : t("confirm_activate")
                }
                onConfirm={() => {
                  let is_active = record.is_active ? 0 : 1;
                  handleIsActiveChange(value, is_active);
                }}
                okText={t("yes")}
                cancelText={t("no")}
              >
                <Tooltip
                  title={record.is_active ? t("deactivate") : t("activate")}
                >
                  <Button
                    shape="circle"
                    style={{ marginRight: 4 }}
                    icon={
                      record.is_active ? (
                        <CloseCircleOutlined />
                      ) : (
                        <CheckCircleOutlined />
                      )
                    }
                  />
                </Tooltip>
              </Popconfirm>
            );
          }

          return (
            <span>
              {statusControl}
              {/* <Tooltip title={t("token")}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4 }}
                  icon={<DollarCircleOutlined />}
                  onClick={() => {
                    setSelectedRecord(record);
                    setAddTokenModalVisible(true);
                  }}
                />
              </Tooltip> */}
            </span>
          );
        },
      });
    }
    if (userFilter === "rejected") {
      columns.push({
        title: t("operation"),
        dataIndex: tableIDName,
        render: (value, record, key) => {
          let statusControl = [];

          statusControl.push(
            <Popconfirm
              title={t("confirm_delete_user")}
              onConfirm={() => handleDeleteUser(value)}
              okText={t("yes")}
              cancelText={t("no")}
            >
              <Tooltip title={t("confirm_delete_user")}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4 }}
                  icon={<DeleteOutlined />}
                />
              </Tooltip>
            </Popconfirm>
          );

          return (
            <span>
              {statusControl}
              {/* <Tooltip title={t("token")}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4 }}
                  icon={<DollarCircleOutlined />}
                  onClick={() => {
                    setSelectedRecord(record);
                    setAddTokenModalVisible(true);
                  }}
                />
              </Tooltip> */}
            </span>
          );
        },
      });
    }
    columns.push(
      {
        title: t("company_user_id"),
        dataIndex: "company_user_id",
        sorter: (a, b) => a.company_user_id - b.company_user_id,
      },
      {
        title: t("active_status"),
        dataIndex: "is_active",
        render: (value) => displayIsActive(value),
        sorter: (a, b) => a.is_active - b.is_active,
        filters: [
          {
            text: t("active"),
            value: 1,
          },
          {
            text: t("inactive"),
            value: 0,
          },
        ],
        onFilter: (value, record) => record.is_active === value,
      },
      // {
      //   title: t("username"),
      //   dataIndex: "username",
      //   sorter: (a, b) => a.email.localeCompare(b.email),
      // },
      {
        title: t("email"),
        dataIndex: "email",
        sorter: (a, b) => a.email.localeCompare(b.email),
      },
      // {
      //   title: t("membership"),
      //   dataIndex: "membership",
      //   sorter: (a, b) => a.last_name.localeCompare(b.last_name),
      // },
      // {
      //   title: t("token_owned"),
      //   dataIndex: "token_own",
      //   sorter: (a, b) => a.token_own - b.token_own,
      // },
      {
        title: t("floor"),
        dataIndex: "user_group_key",
        render: (value) => Helper.getFloorFromAddress(value[0]),
        sorter: (a, b) => _.toInteger(Helper.getFloorFromAddress(a.user_group_key)) - _.toInteger(Helper.getFloorFromAddress(b.user_group_key))
      },
      {
        title: t("unit"),
        dataIndex: "user_group_key",
        render: (value) => Helper.getUnitFromAddress(value[0]),
        sorter: (a, b) => Helper.getUnitFromAddress(a.user_group_key).localeCompare(Helper.getUnitFromAddress(b.user_group_key))
      },
      {
        title: t("octopus_card_no"),
        dataIndex: "octopus_card_no",
        render: Helper.formatOctopusCard,
      },
    );

    if (userFilter === "approve") {
      columns.push({
        title: t("promotion_email"),
        dataIndex: "promotion_email",
        render: displayPromotionConfig,
      },
      {
        title: t("promotion_sms"),
        dataIndex: "promotion_sms",
        render: displayPromotionConfig,
      },);
    }

    columns.push({
      title: t("create_time"),
      dataIndex: "ctime",
      sorter: (a, b) => a.ctime - b.ctime,
      render: (value) => moment.unix(value).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: t("update_time"),
      dataIndex: "utime",
      sorter: (a, b) => a.utime - b.utime,
      render: (value) => moment.unix(value).format("YYYY-MM-DD HH:mm"),
    },);
    return columns;
  };

  const displayIsActive = (value) => {
    let displayStr = "";
    let tagColor = "blue";
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("active");
        tagColor = "blue";
        break;
      case 0:
        displayStr = t("inactive");
        tagColor = "red";
        break;
      default:
        displayStr = t("error");
        tagColor = "red";
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  };

  const filterUserListWithStatus = () => {
    let status;

    switch (userFilter) {
      case "pending_approve": status = 0; break;
      case "approve": status = 1; break;
      case "rejected": status = -1; break;
      default:
    }

    if (_.isUndefined(status)) return [];

    return _.filter(dataList.userList, { status });
  };


  const displayPromotionConfig = (value) => {
    let displayStr = "";
    let tagColor = "green";
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = "yes";
        tagColor = "green";
        break;
      case 0:
        displayStr = "no";
        tagColor = "#9A9A9A";
        break;
      default:
        displayStr = "error";
        tagColor = "#f50";
        break;
    }
    return <Tag color={tagColor}>{t(displayStr)}</Tag>;
  };

  return (
    <Layout>
      <Table
        rowKey={tableIDName}
        scroll={{ x: "max-content" }}
        dataSource={
          // userFilter === "active"
          //   ? _.filter(dataList.userList, { is_active: 1 })
          //   : _.filter(dataList.userList, { is_active: 0 })
          filterUserListWithStatus()
        }
        columns={setTableHeader()}
        bordered
        size="small"
        pagination={{ pageSize: 100 }}
        loading={loading}
        locale={{
          triggerDesc: t("sort_descend"),
          triggerAsc: t("sort_ascend"),
          cancelSort: t("cancel_sort"),
        }}
      />
      <Modal
        destroyOnClose
        title={t("add_token")}
        visible={addTokenModalVisible}
        footer={null}
        onCancel={() => {
          setAddTokenModalVisible(false);
        }}
      >
        <UserAddTokenForm
          dataObj={selectedRecord}
          setAddTokenModalVisible={setAddTokenModalVisible}
          getAllData={getAllData}
        />
      </Modal>
    </Layout>
  );
};

export default CompanyUserAcListTable;
