import React, { useState, useEffect } from "react";
import { Layout, Table, Tag, Button, Tooltip } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { FileTextOutlined } from "@ant-design/icons";
import BookingInfoModal from "../BookingInfo/BookingInfoModal";
import { displayOrderStatus } from "../../../core/Main";

const pageSize = 100;

const CompanyUserBookingLogListTable = (props) => {
  const formType = 1;
  const { t } = useTranslation();
  const involvedModelName = "company";
  const title = "Company User";
  const tableIDName = "booking_log_id";
  const { dataList, loading, dataSet, setLoading } = props;

  const [keyByList, setKeyByList] = useState({
    companyUserList: {},
    bookingItemList: {},
  });
  const [selectedRecord, setSelectedRecord] = useState({});
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    let { companyUserArr, bookingItemArr } = dataList;
    setKeyByList({
      companyUserList: _.keyBy(companyUserArr, "company_user_id"),
      bookingItemList: _.keyBy(bookingItemArr, "booking_item_id"),
    });
  }, [dataList]);

  const setTableHeader = () => {
    const columns = [
      {
        title: t("operation"),
        dataIndex: "booking_id",
        render: (value, record) => {
          return (
            <span>
              <Tooltip title={t("booking_info")}>
                <Button
                  shape="circle"
                  style={{ marginRight: 8, color: "#000000" }}
                  icon={<FileTextOutlined />}
                  onClick={() => {
                    setSelectedRecord(record);
                    setModalVisible(true);
                  }}
                />
              </Tooltip>
            </span>
          );
        },
      },
      {
        title: t("booking_id"),
        dataIndex: "booking_id",
        sorter: (a, b) => a.booking_id - b.booking_id,
      },
      {
        title: t("user"),
        dataIndex: "company_user_id",
        render: (value) => displayCompanyUser(value),
      },
      {
        title: t("company_user_id"),
        dataIndex: "company_user_id",
        sorter: (a, b) => a.company_user_id - b.company_user_id,
      },
      {
        title: t("booking_item"),
        dataIndex: "booking_item",
      },
      {
        title: t("start_booking_time"),
        dataIndex: "start_time",
        sorter: (a, b) => a.start_time - b.start_time,
        render: (start_time) => {
          return moment.unix(start_time).format("YYYY-MM-DD HH:mm");
        },
      },
      {
        title: t("end_booking_time"),
        dataIndex: "end_time",
        sorter: (a, b) => a.end_time - b.end_time,
        render: (end_time) => {
          return moment.unix(end_time).format("YYYY-MM-DD HH:mm");
        },
      },
      {
        title: t("section_price"),
        dataIndex: "section_price",
        sorter: (a, b) => a.section_price - b.section_price,
        render: (value) => {
          return "$" + value;
        },
      },
      {
        title: t("no_of_participant"),
        dataIndex: "quantity",
        sorter: (a, b) => a.quantity - b.quantity,
      },
      {
        title: t("guest_capacity"),
        dataIndex: "guest_capacity",
        sorter: (a, b) => a.guest_capacity - b.guest_capacity,
      },
      {
        title: t("status"),
        dataIndex: "status",
        render: (value) => displayOrderStatus(value, t),
      },
      // {
      //   title: t("booking_type"),
      //   dataIndex: "booking_type",
      //   render: (value) => displayChangeType(value),
      //   sorter: (a, b) => a.booking_type - b.booking_type,
      // },
      {
        title: t("create_time"),
        dataIndex: "ctime",
        sorter: (a, b) => a.ctime - b.ctime,
        render: (ctime) => {
          return moment.unix(ctime).format("YYYY-MM-DD HH:mm:ss");
        },
      },
    ];
    return columns;
  };

  const displayCompanyUser = (value) => {
    let companyUserRc = keyByList.companyUserList[value];
    if (!_.isEmpty(companyUserRc)) {
      // let { first_name, last_name } = companyUserRc;
      // return `${first_name} ${last_name}`;
      return companyUserRc.nickname;
    }
    return "-";
  };

  // const displayChangeType = (value) => {
  //   let displayStr = "";
  //   let tagColor = "blue";
  //   let statusValue = _.toInteger(value);
  //   switch (statusValue) {
  //     case 1:
  //       displayStr = t("system");
  //       tagColor = "blue";
  //       break;
  //     case 2:
  //       displayStr = t("admin_manual");
  //       tagColor = "red";
  //       break;
  //     default:
  //       displayStr = t("error");
  //       tagColor = "red";
  //       break;
  //   }
  //   return <Tag color={tagColor}>{displayStr}</Tag>;
  // };

  const handleFormSubmit = async (submitRecord) => {
    try {
      setLoading(true);
      // let resp = await Service.call("")
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <Table
        rowKey={tableIDName}
        scroll={{ x: "max-content" }}
        dataSource={dataList?.bookingLogArr}
        columns={setTableHeader()}
        bordered
        size="small"
        pagination={{ pageSize }}
        loading={loading}
        locale={{ triggerDesc: t('sort_descend'), triggerAsc: t('sort_ascend'), cancelSort: t('cancel_sort') }}
      />
      <BookingInfoModal
        formType={formType}
        dataObj={selectedRecord}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        handleFormSubmit={(submitRecord) => handleFormSubmit(submitRecord)}
        displayCompanyUser={displayCompanyUser}
      />
    </Layout>
  );
};

export default CompanyUserBookingLogListTable;
