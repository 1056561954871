import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Button,
  Form,
  Select,
  DatePicker,
  Collapse,
  notification,
} from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import { formItemLayout, tailLayout } from "../../../components/ModalLayout";
import * as Service from "../../../core/Service";
import * as Main from "../../../core/Main";

const { Option } = Select;
const { Panel } = Collapse;

const BookingProductReportFilterModal = (props) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const onFinish = async (postObj) => {
    let clonePostObj = _.clone(postObj);
    if (!postObj.company_door_id) {
      clonePostObj = {
        ...clonePostObj,
        company_door_id: [],
      };
    }
    if (!postObj.company_user_id_list) {
      clonePostObj = {
        ...clonePostObj,
        company_user_id_list: [],
      };
    }
    await generateBookingProductReport(clonePostObj);
  };

  const generateBookingProductReport = async (postObj) => {
    try {
      let { date_period } = postObj;
      let time_start = moment(_.head(date_period)).startOf("d").unix();
      let time_end = moment(_.last(date_period)).endOf("d").unix();
      let resp = await Service.call(
        "get",
        `/api/report/booking/product?start_time=${time_start}&end_time=${time_end}`
      );
      if (!resp || resp.status !== 1) {
        return notification.error({
          message: t("error"),
          description: t(`${resp.errorCode}`),
        });
      }
      let {
        companyUserArr,
        companyUserConfigArr,
        bookingItemArr,
        bookingProductItemArr,
        productOrderItemArr,
        bookingInfoArr,
        bookingInvoiceItemArr,
        productInvoiceItemArr,
        invoiceArr,
        paymentTransactionArr,
      } = resp.data;

      let keyById_companyUserArr = _.keyBy(companyUserArr, "company_user_id");
      let keyById_companyUserConfigArr = _.keyBy(
        companyUserConfigArr,
        "company_user_id"
      );
      let keyById_bookingInfoArr = _.keyBy(bookingInfoArr, "booking_id");
      let keyById_bookingItemArr = _.keyBy(bookingItemArr, "booking_item_id");
      let keyById_bookingProductItemArr = _.keyBy(bookingProductItemArr, "product_item_id");
      let keyById_productOrderItemArr = _.keyBy(productOrderItemArr, "order_item_id");
      let keyByOrderItemId_bookingInvoiceItemArr = _.keyBy(bookingInvoiceItemArr, "order_item_id");
      let keyById_invoiceArr = _.keyBy(invoiceArr, "invoice_id");
      let keyById_paymentTransactionArr = _.keyBy(
        paymentTransactionArr,
        "invoice_group_id"
      );

      let dataList = _.map(productInvoiceItemArr, (productInvoiceItemRc) => {
        const {
          ctime,
          invoice_id,
          item_id,
          order_item_id,
          price,
          quantity,
        } = productInvoiceItemRc;

        let productOrderItemRc = keyById_productOrderItemArr[order_item_id] || {};

        let productItemRc = keyById_bookingProductItemArr[item_id] || {};

        let { product_name } = productItemRc;

        const {
          parent_order_item_id: productParentOrderItemId
        } = productOrderItemRc;
        let bookingInvoiceItemRc = keyByOrderItemId_bookingInvoiceItemArr[productParentOrderItemId] || {};

        let bookingInfoRc = keyById_bookingInfoArr[bookingInvoiceItemRc.item_id] || {};
        const {
          company_user_id,
          booking_item_id,
          start_time,
          end_time,
          section_duration,
          booking_id,
        } = bookingInfoRc;

        let userRc = keyById_companyUserArr[company_user_id] || {};

        let userConfigRc = keyById_companyUserConfigArr[company_user_id];
        let { payment_type } = userConfigRc;

        let { first_name, last_name } = userRc;

        let bookingItemRc = keyById_bookingItemArr[booking_item_id] || {};
        let { item_name: booking_item_name } = bookingItemRc;

        let invoiceRc = keyById_invoiceArr[invoice_id] || {};

        let { company_admin_id, invoice_group_id } = invoiceRc;

        let ptxRc = keyById_paymentTransactionArr[invoice_group_id] || {};

        let booking_type = "-";
        if (company_admin_id === 0) {
          booking_type = "System";
        }
        if (!_.isUndefined(company_admin_id) && company_admin_id !== 0) {
          booking_type = "Admin";
        }

        let paymentTypeStr = "";
        let {
          ptx_complete_time,
          ptx_stripe_amount,
          ptx_token_amount,
          ptx_company_amount,
          ptx_offline_amount,
        } = ptxRc;
        if (!_.isEmpty(ptxRc)) {
          paymentTypeStr = checkPaymentMethod({
            ptx_stripe_amount,
            ptx_token_amount,
            ptx_company_amount,
            ptx_offline_amount,
          });
        }
        return {
          ctime: moment.unix(ctime).format("YYYY-MM-DD HH:mm:ss"),
          company_user_id,
          user_type: payment_type === 0 ? "Normal" : "Monthly",
          first_name,
          last_name,
          product_name,
          start_time: dayjs.unix(start_time).format("YYYY-MM-DD HH:mm:ss"),
          end_time: dayjs.unix(end_time).format("YYYY-MM-DD HH:mm:ss"),
          duration: `${section_duration / 60} mins`,
          quantity,
          amount: price,
          booking_type,
          payment_type: _.join(paymentTypeStr, ","),
          approval: ptx_complete_time ? moment.unix(ptx_complete_time).format("YYYY-MM-DD HH:mm:ss") : "-",
          booking_item_name,
          booking_id,
        };
      });

      if (_.isEmpty(dataList)) {
        return notification.info({
          message: t("info"),
          description: t("no_record"),
        });
      }
      Main.exportCsvFile(dataList, [
        [
          "Create time",
          "Company user ID",
          "User type",
          "First name",
          "Last name",
          "Product name",
          "Start_time",
          "End_time",
          "Duration",
          "Quantity",
          "Amount",
          "Booking type",
          "Payment type",
          "Approval",
          "Booking Item name",
          "Booking ID",
        ]
      ], "BookingProductReport");
    } catch (error) {
      console.error(error);
    }
  };

  const checkPaymentMethod = ({
    ptx_stripe_amount,
    ptx_token_amount,
    ptx_company_amount,
    ptx_offline_amount,
  }) => {
    let returnStr = [];
    if (ptx_stripe_amount > 0) {
      returnStr.push("stripe");
    }
    if (ptx_token_amount > 0) {
      returnStr.push("token");
    }
    if (ptx_company_amount > 0) {
      returnStr.push("company");
    }
    if (ptx_offline_amount > 0) {
      returnStr.push("offline");
    }
    return returnStr;
  };

  return (
    <Form
      {...formItemLayout}
      form={form}
      name="time_related_controls"
      onFinish={onFinish}
    >
      <Form.Item
        label="Date period"
        name="date_period"
        initialValue={[moment().subtract(30, "days"), moment()]}
      >
        <DatePicker.RangePicker
          allowClear={false}
          style={{ marginLeft: 20, width: 250 }}
        />
      </Form.Item>
      <Form.Item
        {...tailLayout}
        style={{ marginTop: 20, width: "100%", textAlign: "center" }}
        wrapperCol={{ offset: 0 }}
      >
        <Button type="primary" htmlType="submit">
          Export
        </Button>
      </Form.Item>
    </Form>
  );
};

export default BookingProductReportFilterModal;
