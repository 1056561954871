import React, {
  Component, useState, useEffect, shouldComponentUpdate, useMemo
} from "react";
import {
  Layout, Table, Tooltip, Button
} from "antd";
import {
  PlusOutlined,
} from "@ant-design/icons";
// import 'antd/dist/antd.css';
import _ from "lodash";
import { useTranslation } from "react-i18next";
import * as Service from "../../../core/Service";
import * as Main from "../../../core/Main";
// import CompanyHolidayInfoModal from './CompanyHolidayInfoModal';


const debug = require("debug")("app:admin:client:src:AdvertisementList");

const tableIDName = "booking_item_id";

const CompanyUserBookingTable = (props) => {
  const {
    roomList, loading, setModalVisible, setSelectedRecord
  } = props;
  const { t } = useTranslation();

  const setTableHeader = function() {
    return [
      {
        title: t("operation"),
        dataIndex: tableIDName,
        render: (value, record, key) => {
          return (
            <span>
              <Tooltip title={t("add")}>
                <Button
                  shape="circle"
                  style={{ marginRight: 8 }}
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setModalVisible(true);
                    setSelectedRecord(record);
                  }}
                />
              </Tooltip>
            </span>
          );
        },
      },
      {
        title: t("item_name"),
        dataIndex: `item_name`,
      },
      {
        title: t("item_desc"),
        dataIndex: `item_desc`,
      },
    ];
  };

  return (
    <Layout>
      <Table
        rowKey={tableIDName}
        scroll={{ x: "max-content" }}
        dataSource={roomList}
        columns={setTableHeader()}
        bordered
        size="small"
        pagination={{ pageSize: 100 }}
        loading={loading}
      />
    </Layout>
  );
};

export default CompanyUserBookingTable;
