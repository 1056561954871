import React, { Component, useState, useEffect } from 'react';
import {
  Button, Layout, Table, Tag, Tooltip
} from 'antd';
import {
  EditOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import 'antd/dist/antd.css';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CompanyAdminRoleTable = (props) => {
  const [dataList, setDataList] = useState([]);
  // const [selectedRecord, setSelectedRecord] = useState({});
  const tableIDName = "company_admin_role_id";
  const { t } = useTranslation();
  const { setModalVisible } = props;
  const { setSelectedRecord } = props;

  useEffect(() => {
    setDataList(props.dataObj)
  }, [props.dataObj]);

  const setTableHeader = () => {
    const columns = [
      {
        title: t('operation'),
        dataIndex: tableIDName,
        render: (value, record) => {
          return (
            <span>
              <Tooltip title={t("edit")}>
                <Button
                  shape="circle"
                  icon={<EditOutlined />}
                  disabled={record.readonly}
                  onClick={() => {
                    setModalVisible(true);
                    setSelectedRecord(record);
                  }}
                  style={{ marginRight: 8, color: '#000000' }}
                />
              </Tooltip>
            </span>
          )
        }
      },
      {
        title: t('company_admin_role_id'),
        dataIndex: `company_admin_role_id`,
      },
      // {
      //   title: t('role_key'),
      //   dataIndex: `role_key`,
      // },
      {
        title: t('role_name'),
        dataIndex: `role_name`,
      },
      {
        title: t(`permission`),
        dataIndex: `permission`,
        render: (text, record, index) => {
          let { permissionList } = record;
          return (
            <>
              {_.map(permissionList, (permissionData) => {
                const {permission} = permissionData;
                return (
                  <>
                    {permission !== "p_any" && (<Tag style={{margin: "10px 10px 10px"}} color="cyan" value={permission}>{t(`${permission}`)}</Tag>)}
                    {permission === "p_any" && (<Tag style={{margin: "10px 10px 10px"}} color="red" value={permission}>{t(`${permission}`)}</Tag>)}
                  </>
                )
              })}
            </>
          );
        },
        width: 400,
      },
    ];
    return columns;
  };

  return (
    <Layout>
      <Table
        rowKey={tableIDName}
        scroll={{ x: 'max-content' }}
        dataSource={dataList}
        columns={setTableHeader()}
      />
    </Layout>
  );
};

export default CompanyAdminRoleTable;
