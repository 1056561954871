import React from "react";
import { useSelector } from "react-redux";

const ImageComponent = (props) => {
  const config = useSelector((state) => state.app.config);
  const {
    src = "",
    useImage = true,
    width,
    height,
    className,
    loader,
    alt,
    onLoadingComplete,
  } = props;
  // Default Loader for loading the image from internal server
  const defaultLoader = ({ src, width, quality }) => {
    return `${config.STATIC_SERVER_URL}/${src}?w=${width}&q=${quality || 75}`;
  };
  return (
    <>
      {useImage ? (
        <img
          loader={loader || defaultLoader}
          className={className}
          src={src}
          layout="responsive"
          width={width}
          height={height}
          alt={alt}
          onLoadingComplete={onLoadingComplete}
        />
      ) : (
        <img src={src} className={`${className} resize`} alt={alt} />
      )}
    </>
  );
};

export default ImageComponent;
