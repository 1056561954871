import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Space,
  Table
} from "antd";
import { useTranslation } from "react-i18next";
import { SearchOutlined } from "@ant-design/icons";
import _ from "lodash";
import { useLocation, useParams } from "react-router-dom";
import SearchBar from "../SearchBar";
import dayjs from "dayjs";

const tableIDName = "event_enrollment_id";

const EventAttendanceRecord = (props) => {
  const { t } = useTranslation();
  const [dataList, setDataList] = useState([]);
  const [filterDataList, setFilterDataList] = useState([]);
  const [registerFields, setRegisterFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const {
    enrollmentList,
    eventMeta
  } = props;
  useEffect(() => {
    if (eventMeta && eventMeta.event_registration_form_fields) {
      setRegisterFields(JSON.parse(eventMeta.event_registration_form_fields));
    }
    const checkInList = _.filter(enrollmentList, (rc) => rc.check_in_status === 1);
    setDataList(checkInList);
    setFilterDataList(checkInList);
  }, [props]);

  const FILTERABLE_FIELDS = ["email", "mobile", "lname", "fname", "event_name"];

  const setTableHeader = () => {
    const columns = [
      {
        title: t("id"),
        dataIndex: "event_enrollment_id",
      },
      ..._.map(registerFields, (rc) => {
        const { field_type } = rc;
        return {
          title: t(field_type),
          dataIndex: field_type,
        };
      }),

      {
        title: t("check_in_time"),
        dataIndex: "check_in_time",
        render: (value) => dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss")
      },
    ];
    return columns;
  };

  return (
    <div className="event-tabs-page">
      <Row gutter={16} align="middle">
        <Col>{t("search_keywords")}</Col>
        <Col>
          <SearchBar
            originalDataList={dataList}
            filterDataList={dataList}
            setDataList={setFilterDataList}
            filterField={FILTERABLE_FIELDS}
            placeholder="search_keywords"
            suffix={<SearchOutlined style={{ color: "#888888" }} />}
          />
        </Col>
      </Row>
      <Table
        loading={loading}
        rowKey={tableIDName}
        scroll={{ x: "max-content" }}
        dataSource={dataList}
        columns={setTableHeader()}
        size="small"
        bordered
        style={{ marginTop: 32 }}
      />
    </div>
  );
};

export default EventAttendanceRecord;
