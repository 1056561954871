import React, { useState, useEffect } from "react";
import { Button, Divider, Table, Tooltip, Tag, notification, Popconfirm } from "antd";
import {
  EditOutlined,
  FileExcelOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import dayjs from "dayjs";
import NavAndSideFrame from "../../components/NavAndSideFrame";
import * as Service from "../../core/Service";
import T9Button from "../../t9UIComponents/t9Button";

const selectedKey = "token_package";
const openKeys = "sale_and_operations";

const TokenPackageList = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [dataList, setDataList] = useState([]);
  const history = useHistory();

  const tableIDName = "token_package_id";

  const getAllData = async () => {
    setLoading(true);
    try {
      let resp = await Service.call("get", "/api/token/package/list");
      if (resp.status === 1) {
        const { tokenPackageList } = resp.data;
        // console.log(resp.data);
        setDataList(tokenPackageList);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  const patchPackageIsActive = async (token_package_id) => {
    setButtonLoading(true);
    try {
      const method = "patch";
      const url = "/api/token/package/is_active";

      const resp = await Service.call(method, url, { token_package_id });

      if (!resp || resp.status !== 1) {
        return notification.error({
          message: t("error"),
          description: t(resp.errorCode),
        });
      }
      notification.success({
        message: t("success"),
      });
      getAllData();
    } catch (error) {
      console.error(error);
    } finally {
      setButtonLoading(false);
    }
  };

  const onDeletePackage = async (token_package_id) => {
    try {
      const method = "delete";
      const url = "/api/token/package";

      const resp = await Service.call(method, url, { token_package_id });

      if (!resp || resp.status !== 1) {
        return notification.error({
          message: t("error"),
          description: t(resp.errorCode),
        });
      }
      notification.success({
        message: t("success"),
      });
      getAllData();
    } catch (error) {
      console.error(error);
    } finally {
      setButtonLoading(false);
    }
  };

  const setTableHeader = () => {
    const columns = [
      {
        title: t("operation"),
        dataIndex: tableIDName,
        key: "token_package_id",
        render: (value, record, index) => {
          return (
            <span>
              <Tooltip title={t("edit")}>
                <Button
                  shape="circle"
                  icon={<EditOutlined />}
                  style={{ marginRight: 8 }}
                  onClick={() => {
                    history.push("/company/token/package", record);
                  }}
                />
              </Tooltip>
              <Tooltip title={record.is_active ? t("deactivate") : t("activate")}>
                <Button
                  key={record.token_package_id}
                  loading={buttonLoading}
                  shape="circle"
                  style={{ marginRight: 8 }}
                  icon={record.is_active ? <CloseCircleOutlined /> : <CheckCircleOutlined />}
                  onClick={() => patchPackageIsActive(record.token_package_id)}
                />
              </Tooltip>
              <Tooltip title={t("delete")}>
                <Popconfirm
                  title={t("confirm_delete")}
                  onConfirm={() => onDeletePackage(record.token_package_id)}
                  okText={t("yes")}
                  cancelText={t("no")}
                >
                  <Button shape="circle" icon={<DeleteOutlined />} style={{ color: "#AA0000" }} />
                </Popconfirm>
              </Tooltip>
            </span>
          );
        },
      },
      {
        title: t("Token Package ID"),
        dataIndex: "token_package_id",
        key: "token_package_id",
      },
      {
        title: t("is_active"),
        dataIndex: "is_active",
        key: "is_active",
        render: (value) => displayIsActive(value),
      },
      {
        title: t("Token Package Name"),
        dataIndex: "package_name",
        key: "package_name",
      },
      {
        title: t("token_amount"),
        dataIndex: "token_value",
        key: "token_value",
        render: (value) => {
          if (!value) {
            return "-";
          }
          return value;
        },
      },
      {
        title: t("package_price"),
        dataIndex: "package_price",
        key: "package_price",
        render: (value) => {
          if (!value) {
            return "-";
          }
          return `${value} HKD`;
        },
      },
      {
        title: t("effective_start"),
        dataIndex: "effective_start",
        key: "effective_start",
        render: (value, record) => {
          if (record.effective_all_time) return "-";
          return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss");
        },
      },
      {
        title: t("effective_end"),
        dataIndex: "effective_end",
        key: "effective_end",
        render: (value, record) => {
          if (record.effective_all_time) return "-";
          return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss");
        },
      },
      // {
      //   title: t("Limit Per User"),
      //   dataIndex: "limit_per_user",
      //   key: "limit_per_user",
      // },
      {
        title: t("create_time"),
        dataIndex: "ctime",
        key: "ctime",
        render: (value) => {
          return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss");
        },
      },
    ];
    // if (!disableEdit) {
    //   columns.unshift();
    // }
    return columns;
  };

  const displayIsActive = (value) => {
    let displayStr = "";
    let tagColor = "blue";
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("active");
        tagColor = "blue";
        break;
      case 0:
        displayStr = t("inactive");
        tagColor = "red";
        break;
      default:
        displayStr = t("error");
        tagColor = "red";
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  };

  return (
    <NavAndSideFrame title={t("token")} selectedKey={selectedKey} openKeys={openKeys}>
      <T9Button
        type="primary"
        onClick={() => history.push("/company/token/package", { membership_plan_id: 0 })}
      >
        {t("create")}
      </T9Button>
      <Divider />
      <Table
        rowKey={tableIDName}
        scroll={{ x: "max-content" }}
        loading={loading}
        dataSource={dataList}
        columns={setTableHeader()}
        defaultSelectedKeys={selectedKey}
        openKeys={openKeys}
      />
    </NavAndSideFrame>
  );
};

export default TokenPackageList;
