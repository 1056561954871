import React, { Component } from "react";
import {
  BrowserRouter, Redirect, Switch
} from "react-router-dom";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "./redux/actions";
import Login from "./pages/admin/Login";
import Home from "./pages/admin/Home";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import CompanyAdminLogin from "./pages/company/Login";
import CompanyAdminHome from "./pages/company/Home";

import ItemInfoList from "./pages/company/ItemInfo/ItemInfoList";
import CategoryInfoList from "./pages/company/ItemGroup/CategoryInfoList";
import CompanyUserBooking from "./pages/company/BookingNow/CompanyUserBooking";
import UserbookingContainer from "./pages/company/BookingInfo/UserbookingContainer";
import CompanyUserBookingLogList from "./pages/company/BookingLog/CompanyUserBookingLogList";

import CompanyHolidayList from "./pages/company/CompanyHoliday/CompanyHolidayList";

import CompanyUserAcList from "./pages/company/UserAccount/CompanyUserAcList";
import CompanyUserAcContainer from "./pages/company/UserAccount/CompanyUserAcContainer";

import CompanyAdminContainer from "./pages/company/CompanyAdmin/CompanyAdminContainer";
import CompanySettingContainer from "./components/CompanySetting/CompanySettingContainer";
import CompanyAdminList from "./pages/company/CompanyAdminList/CompanyAdminList";
import CompanyAdminRoleList from "./pages/company/CompanyAdminRoleList";

import ProductCategoryInfoList from "./pages/company/ProductCategory/ProductCategoryInfoList";
import ProductItemList from "./pages/company/ProductItem/ProductItemList";
import InvoiceGroupList from "./pages/company/Invoice/InvoiceGroupList";

import CompanyTokenLog from "./pages/company/CompanyTokenLog";

import OrderInfoList from "./pages/company/OrderInfo/OrderInfoList";

import ContactUsRecord from "./pages/company/ContactUsRecordList";

// import Setting from './pages/company/Setting';
// website
import BlogPostList from "./pages/company/BlogPost/BlogPostList";
import BlogPostCategoryList from "./pages/company/BlogPost/BlogPostCategoryList";
import NewsCategoryList from "./pages/company/BlogPost/NewsCategoryList";
import BannerPostList from "./pages/company/Banner/BannerPostList";
import SloganPostList from "./pages/company/Slogan/SloganPostList";
import CompanyButtonList from "./pages/company/CompanyButton/CompanyButtonList";
import ShopInfoList from "./pages/company/ShopInfo/ShopInfoList";
import ShippingList from "./pages/company/Shipping/ShippingList";
import ContactUsList from "./pages/company/ContactUs/ContactUsList";

import ReportList from "./pages/company/Report/ReportList";

// display
import DisplayList from "./pages/company/Display/DisplayList";
import DisplayDeviceList from "./pages/company/Display/DisplayDeviceList";

import Setting from "./pages/admin/SiteMeta";
// import SiteLogoForm from './pages/company/SiteLogoForm';

import AdminAccountList from "./pages/admin/AdminUser/AdminAccountList";
import CompanyList from "./pages/admin/Company/CompanyList";
import AllCompanyAdminList from "./pages/admin/CompanyAdmin/CompanyAdminList";
import CompanyAdminAcContainer from "./pages/admin/CompanyAdmin/CompanyAdminAcContainer";
// import CompanyDetails from './pages/admin/CompanyDetail'

import PaymentSetting from "./pages/company/PaymentSetting";
// for new version of ruleset
import BookingItemRuleSetList from "./pages/company/BookingItemRuleSet/BookingItemRuleSetList";
import ItemInfoForm from "./pages/company/ItemInfo/ItemInfoForm";
import CompanyConfig from "./pages/company/CompanyConfig";
import SiteMeta from "./pages/company/SiteMeta";
import CompanyEmailList from "./pages/company/Email/CompanyEmailList";

import EventList from "./pages/company/Event/EventList";
import EventInfo from "./pages/company/Event/EventInfo";
import EventCategory from "./pages/company/Event/EventCategory";
import EventCategoryInfo from "./pages/company/Event/EventCategoryInfo";
import EventCheckIn from "./pages/company/Event/EventCheckIn";
import MembershipList from "./pages/company/MembershipList";
import MembershipForm from "./pages/company/MembershipForm";
import MembershipUserList from "./pages/company/MembershipUserList";
import CompanyNotification from "./pages/company/CompanyNotification";
import EventFormContainer from "./pages/company/Event/EventFormContainer";
import TokenPackageForm from "./pages/company/TokenPackageForm";
import TokenPackageList from "./pages/company/TokenPackageList";
import VisitorList from "./pages/company/VisitorList";
import CompanySetting from "./pages/company/CompanySetting/CompanySetting";
import CompanyUserOrderLogList from "./pages/company/OrderLog/CompanyUserOrderLogList";
// import VisitorForm from "./pages/company/VisitorAccount/VisitorForm";

class AppRoute extends React.PureComponent {
  render() {
    // console.log('Pure component AppRoute');
    return (
      <BrowserRouter>
        <Switch>
          {/* Admin Router */}
          <PublicRoute restricted path="/admin/login" component={Login} exact />
          <PrivateRoute restricted path="/admin/home" component={Home} privilege="admin" exact />
          <PrivateRoute restricted path="/admin/list" component={AdminAccountList} privilege="admin" exact />
          <PrivateRoute restricted path="/admin/company/list" component={CompanyList} privilege="admin" exact />
          <PrivateRoute restricted path="/admin/company/setting" component={CompanySettingContainer} privilege="admin" exact />
          <PrivateRoute restricted path="/admin/company/admin/list" component={AllCompanyAdminList} privilege="admin" exact />
          <PrivateRoute restricted path="/admin/company/admin/form" component={CompanyAdminAcContainer} privilege="admin" exact />
          <PrivateRoute restricted path="/admin/site_meta" component={Setting} privilege="admin" exact />
          {/* Company Router */}

          <PublicRoute restricted path="/company/login" component={CompanyAdminLogin} exact />
          <PrivateRoute restricted path="/company/account_setting" component={CompanyAdminContainer} privilege="company" exact />
          <PrivateRoute restricted path="/company/home" component={CompanyAdminHome} privilege="company" exact />
          <PrivateRoute restricted path="/company/booking/item/list" component={ItemInfoList} privilege="company" module="booking" exact />
          <PrivateRoute restricted path="/company/booking/category/list" component={CategoryInfoList} privilege="company" module="booking" exact />
          <PrivateRoute restricted path="/company/booking/config" component={BookingItemRuleSetList} privilege="company" module="booking" exact />
          {/* <PrivateRoute restricted path="/company/booking_now" component={CompanyUserBooking} privilege="company" module="booking" exact /> */}
          <PrivateRoute restricted path="/company/booking_log" component={CompanyUserBookingLogList} privilege="company" module="booking" exact />
          <PrivateRoute restricted path="/company/holiday/list" component={CompanyHolidayList} privilege="company" exact />
          <PrivateRoute restricted path="/company/booking/item/form" component={ItemInfoForm} privilege="company" module="booking" exact />
          {/* Company User Router */}
          <PrivateRoute restricted path="/company/user/list" component={CompanyUserAcList} privilege="company" exact />
          <PrivateRoute restricted path="/company/user/form" component={CompanyUserAcContainer} privilege="company" exact />
          <PrivateRoute restricted path="/company/report" component={ReportList} privilege="company" exact />
          <PrivateRoute restricted path="/company/admin/list" component={CompanyAdminList} privilege="company" exact />
          
          <PrivateRoute restricted path="/company/visitor/list" component={VisitorList} privilege="company" exact />
          {/* <PrivateRoute restricted path="/company/visitor/form" component={VisitorForm} privilege="company" exact /> */}

          {/* <PrivateRoute restricted path="/company/company_setting" component={CompanySettingContainer} privilege="company" exact /> */}
          <PrivateRoute restricted path="/company/company_setting" component={CompanySetting} privilege="company" exact />
          <PrivateRoute restricted path="/company/company_payment_setting" component={PaymentSetting} privilege="company" exact />
          <PrivateRoute restricted path="/company/company_config" component={CompanyConfig} privilege="company" exact />
          <PrivateRoute restricted path="/company/site_meta" component={SiteMeta} privilege="company" exact />
          <PrivateRoute restricted path="/company/blog_post" component={BlogPostList} privilege="company" exact />
          <PrivateRoute restricted path="/company/blog_post/category" component={BlogPostCategoryList} privilege="company" exact />
          <PrivateRoute restricted path="/company/news/category" component={NewsCategoryList} privilege="company" exact />
          <PrivateRoute restricted path="/company/banner" component={BannerPostList} privilege="company" exact />
          <PrivateRoute restricted path="/company/token/log" component={CompanyTokenLog} privilege="company" exact />
          <PrivateRoute restricted path="/company/token/package" component={TokenPackageForm} privilege="company" module="token" exact />
          <PrivateRoute restricted path="/company/token/list" component={TokenPackageList} privilege="company" module="token" exact />
          <PrivateRoute restricted path="/company/shop_info" component={ShopInfoList} privilege="company" exact />
          <PrivateRoute restricted path="/company/button" component={CompanyButtonList} privilege="company" exact />
          <PrivateRoute restricted path="/company/shipping_method" component={ShippingList} privilege="company" exact />
          <PrivateRoute restricted path="/company/contact_us" component={ContactUsList} privilege="company" exact />

          <PrivateRoute restricted path="/company/admin_permission_setting" component={CompanyAdminRoleList} privilege="company" exact />
          <PrivateRoute restricted path="/company/product/category/list" component={ProductCategoryInfoList} module="e_shop" privilege="company" exact />
          <PrivateRoute restricted path="/company/order/list" component={OrderInfoList} privilege="company" exact />
          <PrivateRoute restricted path="/company/order_log" component={CompanyUserOrderLogList} privilege="company" exact />
          <PrivateRoute restricted path="/company/contact_us_record/list" component={ContactUsRecord} privilege="company" exact />
          <PrivateRoute restricted path="/company/email/list" component={CompanyEmailList} privilege="company" exact />
          <PrivateRoute restricted path="/company/notification" component={CompanyNotification} privilege="company" exact />
          {/* Display management Router  */}
          <PrivateRoute restricted path="/company/display/list" component={DisplayList} privilege="company" exact />
          <PrivateRoute restricted path="/company/display/device/list" component={DisplayDeviceList} privilege="company" exact />

          <PrivateRoute restricted path="/company/product/item/list" component={ProductItemList} privilege="company" module="e_shop" exact />

          {/* Event management Router */}
          {/* <PrivateRoute restricted path="/company/registration" component={Registration} privilege="company" exact /> */}
          <PrivateRoute restricted path="/company/event/category" component={EventCategory} privilege="company" module="event" exact />
          <PrivateRoute restricted path="/company/event/category/:event_category_id" component={EventCategoryInfo} privilege="company" module="event" exact />
          <PrivateRoute restricted path="/company/event" component={EventList} privilege="company" module="event" exact />
          <PrivateRoute restricted path="/company/event/check_in" component={EventCheckIn} privilege="company" module="event" exact />
          <PrivateRoute restricted path="/company/event/:event_id" component={EventInfo} privilege="company" module="event" exact />
          <PrivateRoute restricted path="/company/add_event" component={EventFormContainer} privilege="company" module="event" exact />


          {/* Membership Router */}
          <PrivateRoute restricted path="/company/membership" component={MembershipList} privilege="company" module="membership" exact />
          <PrivateRoute restricted path="/company/membership/form" component={MembershipForm} privilege="company" module="membership" exact />
          <PrivateRoute restricted path="/company/user/membership" component={MembershipUserList} privilege="company" module="membership" exact />
          <Redirect exact from="/admin/*" to="/admin/home" />
          <Redirect exact from="*" to="/company/login" />
        </Switch>
      </BrowserRouter>
    );
  }
}

function mapStateToProps(state) {
  return { ...state };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppRoute);
