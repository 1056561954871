import _ from "lodash";
import moment from "moment";

export const getFloorFromAddress = (address) => {
  if (_.isNil(address)) return "";
  if (address === "") return "";
  return _.toString(_.parseInt(address));
};

export const getUnitFromAddress = (address) => {
  return _.replace(address, getFloorFromAddress(address), "");
};

export const OCTOPUS_CARD_NO_LENGTH = 9;
export const formatOctopusCard = (_card) => {
  if (_.isNil(_card)) return null;
  if (_.includes(_card, "-deleted")) {
    return _card;
  }
  let card = _card;
  // remove brackets
  if (_.includes(card, "(") || _.includes(card, ")")) {
    card = _.replace(card, "(", "");
    card = _.replace(card, ")", "");
  }
  // get number length and split with 3/4 digits as one group
  let card_length = OCTOPUS_CARD_NO_LENGTH - 1; // default
  if (card.length > OCTOPUS_CARD_NO_LENGTH) card_length = card.length - 1;
  let card_split = card_length === 9 ? 3 : 4;
  let card_display = _.chunk(card.substring(0, card_length), card_split);
  const card_text = _.map(card_display, (arr) => _.join(arr, ""));
  // if less than octopus card number length, then return without brackets
  if (card.length < OCTOPUS_CARD_NO_LENGTH) {
    return `${_.join(card_text, " ")}`;
  }
  return `${_.join(card_text, " ")} (${card.substring(card.length - 1)})`;
};

export const datetimeFormat = "YYYY-MM-DD HH:mm";
export const displayDateTime = (unixTimestamp) => {
  return moment.unix(unixTimestamp).format(datetimeFormat);
}