const COLOR = {
  brandPrimary: "#1E73BE",
  brandSecondary: "#9E8677",
  textPrimary: "#1E73BE",
  whiteSecondary: "#FFFFFF",
  captionText: "#828282",
  whiteText: "#FFFFFF",
  disableText: "#C8C8C8",
  heading: "#000000",
  content: "#9E8677",
  line: "#C9C9C9",
  background: "#FFFFFF",
  menuText: "#000000",
  border: "#F6F6F6",
  overlay: "rgba(0, 0, 0, 0.4)",
  warningYellow: "#FFB900",
  warningRed: "#CC3C2B",
  success: "#46B450",
  disable: "#DEDEDE",
  disableBorder: "#BCBCBC",
  primary: {
    primary50: "#E1F4FF",
    primary100: "#B4D6F3",
    primary200: "#88BDEC",
    primary300: "#5CA5E5",
    primary400: "#308DDE",
    primary500: "#1E73BE",
    primary600: "#175891",
    primary700: "#103D65",
    primary800: "#092339",
    primary900: "#02080D",
  },
  success: {
    success100: "#AEDF95",
    success200: "#8FD36E",
    success300: "#71C847",
    success400: "#5AA933",
    success500: "#458227",
    success600: "#305A1B",
    success700: "#1B330F",
    success800: "#060C04",
    success900: "#000000",
  },
  warning: {
    warning100: "#FFCC85",
    warning200: "#FFB752",
    warning300: "#FFA90A",
    warning400: "#EB8900",
    warning500: "#B86B00",
    warning600: "#854D00",
    warning700: "#523000",
    warning800: "#1F1200",
    warning900: "#000000",
  },
  negative: {
    negative100: "#FEBEBE",
    negative200: "#FE8B8B",
    negative300: "#FD5959",
    negative400: "#FC2626",
    negative500: "#EC0303",
    negative600: "#BA0202",
    negative700: "#880202",
    negative800: "#560101",
    negative900: "#230000",
  },
  gray: {
    gray100: "#EEEEEE",
    gray200: "#D4D4D4",
    gray300: "#BBBBBB",
    gray400: "#A1A1A1",
    gray500: "#888888",
    gray600: "#6F6F6F",
    gray700: "#555555",
    gray800: "#3C3C3C",
    gray900: "#222222",
  },
  text: {
    primaryText: "#1E73BE",
    blackText: "#090909",
    whiteText: "#FFFFFF",
    captionText: "#828282",
  },
  function: {
    white: "#FFFFFF",
    black: "#090909",
    focus: "#B4D6F3",
    visited: "#54BED7",
    disable: "#C8C8C8",
    Placeholder: "#BBBBBB",
    divider: "#E7E8E8",
    border: "#E8E8E8",
    background: "#FBF9F9",
    overlay: "#000000",
  }
};

export default COLOR;
