import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Divider,
  Tabs,
  Spin,
  Input,
  Row,
  Col
} from "antd";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { LoadingOutlined } from "@ant-design/icons";
import queryString from "query-string";
import Button from "../../../t9UIComponents/t9Button";
import * as Service from "../../../core/Service";
import NavAndSideFrame from "../../../components/NavAndSideFrame";
import VisitorListTable from "../../../components/Visitor/VisitorListTable";
import SearchBar from "../../../components/SearchBar";
import VisitorForm from "../../../components/Visitor/VisitorForm";

const debug = require("debug")("app:admin:client:src:AdvertisementList");

const selectedKey = "visitor_list";
const openKeys = "user";
const { TabPane } = Tabs;

const FILTERABLE_FIELDS = [
  "remark"
];

const VisitorList = (props) => {
  const [loading, setLoading] = useState(true);
  const dataList = useRef([]);
  const [filterDataList, setFilterDataList] = useState([]);
  const { t } = useTranslation();
  const [formVisible, setFormVisible] = useState(false);
  const {userObj} = props;

  let location = useLocation();
  const { key } = queryString.parse(location.search);
  let defaultActiveKey = key;

  const title = t("visitor_list");

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    setLoading(true);
    try {
      let url = `/api/company/user/access/temp_code?company_user_id=${userObj.company_user_id}`;
      let resp = await Service.call("get", url);
      let { tempCodeList } = resp.data;

      dataList.current = tempCodeList;
      setFilterDataList(tempCodeList);
    } catch (error) {
      console.error("error >>> ", error);
    } finally {
      setLoading(false);
    }
  };

  const VisitorListTableWithKey = (props) => {
    return (
      <VisitorListTable
        {...props}
        dataList={filterDataList}
        loading={loading}
        getAllData={getAllData}
        setLoading={setLoading}
      />
    );
  };


  return (
    <Row>
      <Button onClick={() => setFormVisible(true)} type="primary">{t("add_visitor")}</Button>
      <Divider />
      <Row align="middle">
        <Col>
          <span>{`${t("search_keyword")}：`}</span>
        </Col>
        <Col>
          <SearchBar
            originalDataList={dataList.current}
            filterDataListKey="userList"
            filterDataList={dataList.current}
            setDataList={setFilterDataList}
            filterField={FILTERABLE_FIELDS}
          />
        </Col>
      </Row>
      <Divider />
      <VisitorListTableWithKey userObj={userObj} />
      <VisitorForm setVisible={setFormVisible} visible={formVisible} getAllData={getAllData} userId={userObj.company_user_id} />
    </Row>
  );
};

export default VisitorList;
