import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  Tooltip,
  Tag,
  notification
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  CheckOutlined
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import Modal from "antd/lib/modal/Modal";
import _ from "lodash";
import NavAndSideFrame from "../../components/NavAndSideFrame";
import PaymentMethodForm from "../../components/PaymentMethodForm";
import * as Service from "../../core/Service";

const tableIDName = "company_payment_method";
const selectedKey = "payment_management"
const openKeys = "sale_and_operations"
const PaymentSetting = () => {
  const { t } = useTranslation();
  const [companyExistPaymentMethod, setCompanyExistPaymentMethod] = useState([]);
  const [formVisible, setFormVisible] = useState(false);
  const [selectedRc, setSelectedRc] = useState({});
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const setTableHeader = () => {
    return [
      {
        key: tableIDName,
        title: t("operation"),
        dataIndex: tableIDName,
        render: (value, record) => {
          return (
            <span>
              <Tooltip title="Edit">
                <Button
                  shape="circle"
                  style={{ marginRight: 8, color: "#000000" }}
                  icon={<EditOutlined />}
                  onClick={() => {
                    setSelectedRc(record);
                    setFormVisible(true);
                  }}
                />
              </Tooltip>
              <Tooltip title={record.is_active ? "Disable Method" : "Enable Method"}>
                <Button
                  key={tableIDName}
                  shape="circle"
                  style={{ color: record.is_active ? "red" : "green" }}
                  icon={record.is_active ? (<StopOutlined />) : (<CheckOutlined />)}
                  onClick={() => patchStatus(record.company_payment_method_id)}
                />
              </Tooltip>
            </span>
          );
        }
      },
      {
        title: t("status"),
        dataIndex: "is_active",
        render: (value) => displayIsActive(value),
        sorter: (a, b) => a.is_active - b.is_active
      },
      {
        title: t("payment_name"),
        dataIndex: "payment_name",
      },
      {
        title: t("payment_type"),
        dataIndex: "payment_type",
      },
    ];
  };

  const displayIsActive = (value) => {
    let displayStr = "";
    let tagColor = "green";
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = "Enable";
        tagColor = "green";
        break;
      case 0:
        displayStr = "Disable";
        tagColor = "red";
        break;
      default:
        displayStr = "Error";
        tagColor = "#f50";
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  };

  const getAllData = async () => {
    setLoading(true);
    let resp = await Service.call("get", "/api/company/payment/method");
    if (resp && resp.status === 1) {
      const { companyPaymentMethodList } = resp.data;
      setCompanyExistPaymentMethod(companyPaymentMethodList);
    }
    setLoading(false);
  };

  const patchStatus = async (company_payment_method_id) => {
    if (buttonLoading) {
      return;
    }
    setButtonLoading(true);
    try {
      const resp = await Service.call("patch", "/api/company/payment/method/status", {
        company_payment_method_id
      });
      if (!resp || resp.status !== 1) {
        return notification.error({
          message: t("error"),
          description: t(resp.errorCode),
        });
      }
      notification.success({
        message: t("success"),
      });
      getAllData();
    } catch (error) {
      console.error(error);
    } finally {
      setButtonLoading(false);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  return (
    <NavAndSideFrame title={`${t("payment_setting")}`} selectedKey={selectedKey} openKeys={openKeys}>
      {/* <div style={{
        margin: '10px'
      }}
      >
        <Button
          type="primary"
          onClick={() => {
            setFormVisible(true)
          }}
        >
          {t('add_payment_method')}
        </Button>
      </div> */}
      <Table
        loading={loading}
        rowKey={tableIDName}
        scroll={{ x: "max-content" }}
        dataSource={companyExistPaymentMethod}
        columns={setTableHeader()}
        locale={{ triggerDesc: t('sort_descend'), triggerAsc: t('sort_ascend'), cancelSort: t('cancel_sort') }}
      />
      <Modal
        title={t("Edit")}
        visible={formVisible}
        footer={null}
        style={{ maxWidth: 800 }}
        width="90%"
        onCancel={() => {
          setFormVisible(false);
        }}
      >
        <PaymentMethodForm
          initialValue={selectedRc}
          setFormVisible={setFormVisible}
          getAllData={getAllData}
        />
      </Modal>
    </NavAndSideFrame>
  );
};

export default PaymentSetting;
