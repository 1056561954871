import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Button,
  Form,
  Input,
  Select,
  notification,
  Tooltip,
  Row,
  Col,
  Card,
  Switch,
  Collapse,
  InputNumber,
} from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { DownOutlined } from "@ant-design/icons";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import * as Service from "../core/Service";
import * as Helper from "../core/Helper";
import dayjs from "dayjs";

const { Option } = Select;
const { Panel } = Collapse;

const LOGIN_METHOD = {
  USERNAME: 1,
  EMAIL: 2,
  MOBILE: 3,
  BOTH_REQUIRE_WITH_EMAIL_LOGIN: 4,
};

const UserAcInfoForm = (props) => {
  let location = useLocation();
  const { t } = useTranslation();
  const { initialValues, setUser, companyConfigRc, isAdmin, goBack } = props;
  const id = initialValues.company_user_id || 0;
  const [loading, setLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [updatePwLoading, setUpdatePwLoading] = useState(false);
  const [initialValuesForm, setInitialValuesForm] = useState({
    mobilePrefix: "+852",
    is_admin: 0,
  });
  const [UserForm] = Form.useForm();
  const [UserStatusForm] = Form.useForm();
  const [UserResetPWForm] = Form.useForm();
  const { key } = queryString.parse(location.search);

  useEffect(() => {
    UserForm.resetFields();
    UserResetPWForm.resetFields();
    UserStatusForm.resetFields();
    if (id > 0) {
      // initialValues append to initialValuesForm
      const [mobilePrefix, mobile] = _.split(initialValues.mobile, "-");
      const newObject = {
        ...initialValuesForm,
        ...initialValues,
        mobile,
        mobilePrefix,
        floor: Helper.getFloorFromAddress(initialValues.user_group_key[0]),
        unit: Helper.getUnitFromAddress(initialValues.user_group_key[0]),
      };
      UserForm.setFieldsValue(newObject);
    }
  }, [props]);

  const onFinish = async (formData) => {
    const {
      nickname,
      email,
      mobile,
      username,
      octopus_card_no,
      password,
      floor,
      unit,
    } = formData;
    try {
      setLoading(true);
      let postData = {
        // ...formData,
        // preferred_language: "zh",
        // is_admin: formData.is_admin === true ? 1 : 0,
        // mobile: `${_.last(_.split(formData.mobile, "-"))}`,
        nickname,
        email: email || " ",
        mobile: mobile || `+0-${dayjs().valueOf()}`,
        username: username || " ",
        company_user_id: id,
        password,
        user_group_key: `${floor}${unit}`,
        octopus_card_no: _.toString(octopus_card_no),
      };
      // Check password when creating user
      if (id === 0 && !validatePassword(formData.password)) {
        return;
      }
      let method = "PUT";
      if (id > 0) {
        const oldGrpKey = initialValues.user_group_key[0];
        const newGrpKey = `${floor}${unit}`;
        method = "PATCH";
        if (oldGrpKey === newGrpKey) {
          delete postData.user_group_key;
        }
      }

      let resp = await Service.call(method, `/api/company/user`, postData);

      if (!resp || resp.status === -1) {
        setLoading(false);
        notification.error({
          message: `${t("error")}: ${resp.errorMessage}[${resp.errorCode}]`,
        });
        return;
      }
      notification.success({
        message: t("success"),
      });
      // const { data } = resp;
      // setUser(data);
      goBack();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const validatePassword = (password) => {
    if (password.length < 8) {
      notification.error({
        message: t("error"),
        description: t("password_length_too_short"),
      });
      return false;
    }
    if (password.search(/[a-z]/i) < 0) {
      notification.error({
        message: t("error"),
        description: t("password_should_include_character"),
      });
      return false;
    }
    if (password.search(/[0-9]/i) < 0) {
      notification.error({
        message: t("error"),
        description: t("password_should_include_number"),
      });
      return false;
    }
    return true;
  };

  const patchUserStatus = async (formData) => {
    try {
      setStatusLoading(true);
      formData.company_user_id = id;
      if (id > 0) {
        let response = await Service.call(
          "patch",
          `/api/company/user/status`,
          formData
        );
        if (response.status === -1) {
          notification.error({
            message: t(`error`),
            description: t(response.errorCode),
          });
          return;
        }
        notification.success({
          message: t("success"),
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setStatusLoading(false);
    }
  };

  const editPassword = async (formData) => {
    try {
      setUpdatePwLoading(true);
      let patchData = { ...formData, company_user_id: id };
      // Check password
      if (!validatePassword(formData.password)) {
        return;
      }
      if (id > 0) {
        let response = await Service.call(
          "patch",
          `/api/company/user/password`,
          patchData
        );
        if (response.status === -1) {
          notification.error({
            message: t(`error`),
            description: t(response.errorCode),
          });
          return;
        }
        UserResetPWForm.resetFields();
        notification.success({
          message: t("success"),
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setUpdatePwLoading(false);
    }
  };

  return (
    <Form form={UserForm} name="UserForm" layout="vertical" onFinish={onFinish}>
      <Row gutter={[24, 24]}>
        <Col md={24} lg={16}>
          <Card>
            <Form.Item
              label={t("username")}
              name="username"
              rules={[
                {
                  required: _.includes(
                    [LOGIN_METHOD.USERNAME],
                    companyConfigRc?.login_method
                  ),
                  message: t("please_input_username"),
                },
              ]}
            >
              {/* {...userDetailMap.username} */}
              <Input
                maxLength={40}
                disabled={
                  LOGIN_METHOD.USERNAME !== companyConfigRc?.login_method
                }
              />
            </Form.Item>
            <Form.Item
              label={t("email")}
              name="email"
              rules={[
                {
                  type: "email",
                  message: t("invalid_email"),
                },
                {
                  required: _.includes(
                    [
                      LOGIN_METHOD.EMAIL,
                      LOGIN_METHOD.BOTH_REQUIRE_WITH_EMAIL_LOGIN,
                    ],
                    companyConfigRc?.login_method
                  ),
                  message: t("please_input_email"),
                },
              ]}
            >
              <Input maxLength={128} />
            </Form.Item>

            {!id && (
              <Form.Item
                label={t("password")}
                name="password"
                rules={[
                  {
                    required: true,
                    message: t("input_required"),
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
            )}

            {!id && (
              <Form.Item
                label={t("confirm_password")}
                name="confirm_password"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: t("input_required"),
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(t("password_not_match"));
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            )}
            <Form.Item
              label={t("nickname")}
              name="nickname"
              rules={[
                {
                  required: true,
                  message: t("input_required"),
                },
              ]}
            >
              <Input maxLength={50} />
            </Form.Item>
            <Form.Item
              label={t("floor")}
              name="floor"
              rules={[
                {
                  required: true,
                  message: t("input_required"),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t("unit")}
              name="unit"
              rules={[
                {
                  required: true,
                  message: t("input_required"),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t("octopus_card_no")}
              name="octopus_card_no"
              rules={[
                {
                  required: true,
                  message: t("input_required"),
                },
              ]}
            >
              <InputNumber
                minLength={Helper.OCTOPUS_CARD_NO_LENGTH}
                formatter={Helper.formatOctopusCard}
                style={{ width: "100%" }}
              />
            </Form.Item>
            {/* <Form.Item label={t("gender")} name="gender">
              <Select>
                <Option value="M">{t("male")}</Option>
                <Option value="F">{t("female")}</Option>
              </Select>
            </Form.Item> */}
            {/* <Form.Item
              label={t('preferred_language')}
              name="preferred_language"
            >
              <Select>
                <Option value="zh">{t('zh')}</Option>
                <Option value="en">{t('en')}</Option>
              </Select>
            </Form.Item> */}
            {/* <Form.Item
              label={t("is_admin")}
              name="is_admin"
              valuePropName="checked"
            >
              <Switch checked={initialValues.is_admin === 1} />
            </Form.Item> */}
            <Form.Item>
              <div style={{ textAlign: "right" }}>
                <Button type="primary" htmlType="submit" loading={loading}>
                  {t("save_change")}
                </Button>
              </div>
            </Form.Item>
          </Card>
          {id > 0 && (
            <>
              <Collapse
                bordered={false}
                ghost
                defaultActiveKey={["1"]}
                expandIconPosition="right"
                expandIcon={({ isActive }) => (
                  <DownOutlined rotate={isActive ? 180 : 0} />
                )}
                style={{ marginTop: 24 }}
              >
                {/* <Panel header={t("user_status")} key="1">
                  <Form
                    form={UserStatusForm}
                    name="UserStatusForm"
                    layout="vertical"
                    initialValues={initialValues}
                    onFinish={patchUserStatus}
                  >
                    <Row>
                      <Col span={8}>
                        <Form.Item
                          label={t("active_status")}
                          name="is_active"
                          rules={[
                            {
                              required: true,
                              message: t("select_required"),
                            },
                          ]}
                        >
                          <Select
                            style={{ width: "100%" }}
                            placeholder={t("selectLabel")}
                          >
                            <Option value={1}>{t("active")}</Option>
                            <Option value={0}>{t("inactive")}</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item>
                      <div style={{ textAlign: "right" }}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          loading={statusLoading}
                        >
                          {t("save_change")}
                        </Button>
                      </div>
                    </Form.Item>
                  </Form>
                </Panel> */}
              </Collapse>
              <Collapse
                bordered={false}
                ghost
                defaultActiveKey={["1"]}
                expandIconPosition="right"
                expandIcon={({ isActive }) => (
                  <DownOutlined rotate={isActive ? 180 : 0} />
                )}
                style={{ marginTop: 24 }}
              >
                <Panel header={t("edit_password")} key="2">
                  <Form
                    form={UserResetPWForm}
                    name="UserResetPWForm"
                    layout="vertical"
                    onFinish={editPassword}
                  >
                    <Row>
                      <Col span={8}>
                        <Form.Item
                          label={t("edit_password")}
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: t("input_required"),
                            },
                          ]}
                        >
                          <Input.Password />
                        </Form.Item>

                        <Form.Item
                          label={t("confirm_password")}
                          name="confirm_password"
                          rules={[
                            {
                              required: true,
                              message: t("input_required"),
                            },
                            ({ getFieldValue }) => ({
                              validator(rule, value) {
                                if (
                                  !value ||
                                  getFieldValue("password") === value
                                ) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(t("password_not_match"));
                              },
                            }),
                          ]}
                        >
                          <Input.Password />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item>
                      <div style={{ textAlign: "right" }}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          loading={updatePwLoading}
                        >
                          {t("save_change")}
                        </Button>
                      </div>
                    </Form.Item>
                  </Form>
                </Panel>
              </Collapse>
            </>
          )}
        </Col>
        <Col md={24} lg={8}>
          <Card>
            <h4>{t("last_active_time")}</h4>
            <p>
              {initialValues.utime
                ? moment.unix(initialValues.utime).format("YYYY-MM-DD HH:mm")
                : "Na"}
            </p>
            <h4>{t("create_time")}</h4>
            <p>
              {initialValues.ctime
                ? moment.unix(initialValues.ctime).format("YYYY-MM-DD HH:mm")
                : "Na"}
            </p>
            {/* <Form.Item
              label={t("is_verified_email")}
              name="is_email_verified"
              valuePropName="checked"
            >
              <Switch disabled />
            </Form.Item>
            <Form.Item
              label={t("is_verified_mobile")}
              name="is_mobile_verified"
              valuePropName="checked"
            >
              <Switch disabled />
            </Form.Item> */}
          </Card>
        </Col>
      </Row>
    </Form>
  );
};

export default UserAcInfoForm;
