import React, { useState, useEffect } from "react";
import {
  Image, Layout, Modal, Table, Tag, Tooltip, notification, Popconfirm
} from "antd";
import {
  EditOutlined, DollarCircleOutlined, CheckCircleOutlined, CloseCircleOutlined
} from "@ant-design/icons";
import "antd/dist/antd.css";
import dayjs from "dayjs";
import _ from "lodash";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import * as Service from "../../core/Service";
import * as Helper from "../../core/Helper";

const VisitorListTable = (props) => {
  const { t } = useTranslation();
  const tableIDName = "visitor_id";
  const app = useSelector((state) => state.app);
  let {
    dataList, userDetail, loading, defaultActiveKey, getAllData, userFilter, setLoading, userObj
  } = props;

  useEffect(() => { }, []);

  const setTableHeader = () => {
    let columns = [
      {
        title: t("start_time"),
        dataIndex: "ctime",
        sorter: (a, b) => a.ctime - b.ctime,
        defaultSortOrder: "descend",
        render: Helper.displayDateTime,
      },
      {
        title: t("end_time"),
        dataIndex: "expire_date",
        render: Helper.displayDateTime,
      },
      {
        title: t("remarks"),
        dataIndex: "remark",
      },
    ];
    if (!userObj) {
      columns = [...columns, {
        title: t("username"),
        dataIndex: "nickname",
      }];
    }
    columns = [
      ...columns,
      {
        title: t("floor"),
        dataIndex: "address",
        render: (value) => (value ? Helper.getFloorFromAddress(value) : Helper.getFloorFromAddress(userObj?.address)),
      },
      {
        title: t("unit"),
        dataIndex: "address",
        render: (value) => (value ? Helper.getUnitFromAddress(value) : Helper.getUnitFromAddress(userObj?.address)),
      },
      {
        title: t("kiosk_scanner_image"),
        dataIndex: "filepath",
        render: (value) => (value ? <Image width={100} height={100} src={`${app.config.ADMIN_ORIGIN}/${value}`} /> : "-"),
      },
      {
        title: t("visitor_id"),
        dataIndex: "id",
      },
      {
        title: t("purpose"),
        dataIndex: "purpose",
      }
    ];
    return columns;
  };

  return (
    <Layout>
      <Table
        rowKey={tableIDName}
        scroll={{ x: "max-content" }}
        dataSource={dataList}
        columns={setTableHeader()}
        bordered
        size="small"
        pagination={{ pageSize: 100 }}
        loading={loading}
      />
    </Layout>
  );
};

export default VisitorListTable;
