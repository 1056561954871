import { Spin, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CompanyConfigForm from "../../components/CompanyConfigForm";
import { openNotificationWithIcon } from "../../components/form/ErrorMessage";
import NavAndSideFrame from "../../components/NavAndSideFrame";
import * as Service from "../../core/Service";

const { TabPane } = Tabs;
const selectedKey = "company_config"
const openKeys = "settings"
const CompanyConfig = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [companyConfig, setCompanyConfig] = useState({});
  const [tabKey, setTabKey] = useState("basic")
  useEffect(() => {
    getAllData();
  }, []);
  const getAllData = async () => {
    setLoading(true);
    try {
      let resp = await Service.call("get", "/api/company/company_config");

      if (!resp || resp.status !== 1) {
        return openNotificationWithIcon("error", t("error"), resp.errorCode);
      }
      console.log("resp >>>", resp);
      let { data } = resp;
      setCompanyConfig(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <NavAndSideFrame title={t("company_config")} selectedKey={selectedKey} openKeys={openKeys}>
      <Spin spinning={loading}>
        <Tabs
          defaultActiveKey={tabKey}
          onChange={(active_key) => {
            setTabKey(active_key);
          }}
        >
          <TabPane tab={t("basic")} key="basic" />
          <TabPane tab={t("order_setting")} key="order" />
          <TabPane tab={t("token_setting")} key="token" />
        </Tabs>
        <CompanyConfigForm
          dataObj={companyConfig}
          type={tabKey}
          getAllData={getAllData}
        />
      </Spin>
    </NavAndSideFrame>
  );
};

export default CompanyConfig;
