import React, { useState, useEffect } from 'react';
import _, { initial } from 'lodash';
import {
  Button, Divider, Form, Input, Tag, Row, Col, Card, Tooltip, Select, notification, Transfer, message
} from 'antd';
import moment from 'moment';
import * as Service from '../core/Service';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { formItemLayout, tailLayout } from "./ModalLayout";

const { Option } = Select;

const CompanyAdminRoleForm = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [permissionSet, setPermissionSet] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);

  useEffect(() => {
    form.resetFields();
    setTargetKeys([]);
    getPermissionSet();
    if (props.dataObj.company_admin_role_id !== 0) {
      let permission = _.map(props.dataObj.permissionList, 'permission');
      setTargetKeys(permission);
    }
    form.setFieldsValue(props.dataObj);
  }, [props.dataObj]);

  const getPermissionSet = async () => {
    let dataSet = [];
    try {
      let data = await Service.call('get', '/api/company/admin_role_permission');
      dataSet = data.map((item) => {
        return {key: item, title: t(item)};
      });
    } catch (error) {
      console.log(error);
    } finally {
      setPermissionSet(dataSet);
    }
  };

  const onChange = (nextTargetKeys, direction, moveKeys) => {
    console.log('targetKeys:', nextTargetKeys);
    console.log('direction:', direction);
    console.log('moveKeys:', moveKeys);
    setTargetKeys(nextTargetKeys);
  };

  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    console.log('sourceSelectedKeys:', sourceSelectedKeys);
    console.log('targetSelectedKeys:', targetSelectedKeys);
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const onFinish = async (postObj) => {
    let url = `/api/company/admin_role`;
    postObj = {
      ...postObj,
      permissionList: targetKeys
    }
    console.log('postObj', postObj)
    // Patch
    if (props.dataObj.company_admin_role_id > 0) {
      postObj.company_admin_role_id = props.dataObj.company_admin_role_id;

      let data = await Service.call('patch', url, postObj);
      if (data.errorMessage) {
        message.error(data.errorMessage);
        return props.openModal(true);
      }
      message.success('success');
      return props.openModal(false);
    }

    // PUT
    postObj.create_time = moment().unix();
    let data = await Service.call('put', url, postObj);
    if (data.errorMessage) {
      message.error(data.errorMessage);
      return props.openModal(true);
    }
    message.success('success');
    return props.openModal(false);
  };

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        name="time_related_controls"
        onFinish={onFinish}
        dataObj={props.dataObj}
      >
        <Form.Item
          label={t('role_name')}
          name="role_name"
          rules={[{ required: true, message: 'Please input role name.' }]}
        >
          <Input autoFocus />
        </Form.Item>
        {/* <Form.Item
          label={t('role_key')}
          name="role_key"
          rules={[{ required: true, message: 'Please input role key.' }]}
        >
          <Input />
        </Form.Item> */}
        <Form.Item
          label={t('permission')}
          name="permission"
        >
          <Transfer
            dataSource={permissionSet}
            titles={[t('transfer_source'), t('transfer_target')]}
            targetKeys={targetKeys}
            selectedKeys={selectedKeys}
            onChange={onChange}
            onSelectChange={onSelectChange}
            render={(item) => item.title}
            listStyle={{
              width: 300,
              height: 300,
            }}
          />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button
            type="primary"
            htmlType="submit"
          >
            {t('submit')}
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default CompanyAdminRoleForm;
