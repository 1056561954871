import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Divider, notification, Card } from "antd";
// import 'antd/dist/antd.css';
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Button from "../../t9UIComponents/t9Button";
import * as Service from "../../core/Service";
import SmartAccessDoorListTable from "./SmartAccessDoorListTable";
import SmartAccessDoorModal from "./SmartAccessDoorModal";

const debug = require("debug")("app:admin:client:src:AdvertisementList");

const involvedModelName = "admin";
const selectedKey = "sa_door";

const SmartAccessDoorList = (props) => {
  let { initialValues } = props;

  const [dataList, setDataList] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const app = useSelector((state) => state.app);
  const id = !_.isEmpty(initialValues) ? initialValues.company_id : 0;

  const title = t("sa_door_list");

  useEffect(() => {
    getAllData();
  }, [initialValues]);

  const getAllData = async () => {
    setLoading(true);
    let SADoorList = [];
    let itemArr = [];
    try {
      let resp = await Service.call(
        "get",
        `/api/sa/door/booking_item/list/${id}`
      );
      const { bookingItemArr, saDoorArr } = resp.data;
      let keyById_bookingItemArr = _.keyBy(bookingItemArr, "booking_item_id");
      let keyItem_SaDoorArr = _.keyBy(saDoorArr, "item_id");
      SADoorList = _.map(saDoorArr, (eachSaDoor) => {
        let bookingItemRc = keyById_bookingItemArr[eachSaDoor.item_id];
        return {
          ...eachSaDoor,
          item_name: bookingItemRc.item_name,
        };
      });
      itemArr = _.map(bookingItemArr, (eachBookingItem) => {
        return {
          ...eachBookingItem,
          linked: !_.isEmpty(keyItem_SaDoorArr[eachBookingItem.booking_item_id])
            ? 1
            : 0,
        };
      });
    } catch (error) {
      console.error("error >>> ", error);
    } finally {
      setDataList(SADoorList);
      setItemList(itemArr);
      setLoading(false);
    }
  };

  const handleFormSubmit = async (submitRecord) => {
    let postObj = submitRecord;
    let method = "post";
    if (selectedRecord.sa_door_id > 0) {
      method = "patch";
      postObj = {
        ...selectedRecord,
        ...submitRecord,
        sa_door_id: selectedRecord.sa_door_id,
      };
    }
    setLoading(true);
    try {
      let resp = await Service.call(method, "/api/sa/door", postObj);
      if (resp.status !== 1) {
        notification.error({
          message: t(`error`),
          description: t(resp.errorCode)
        });
      } else {
        notification.success({
          message: t("success"),
        });
        getAllData();
        setLoading(false);
        setModalVisible(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleIsActiveChange = async (sa_door_id, value) => {
    setLoading(true);
    let postData = {
      sa_door_id,
      is_active: value,
    };
    let submit = await Service.call(
      "patch",
      "/api/sa/door/is_active",
      postData
    );
    if (submit.status < 1) {
      notification.error({
        message: t(`fail`),
        description: t(submit.errorCode),
      });
      setLoading(false);
    } else {
      notification.success({
        message: t("success"),
      });
      getAllData();
      setLoading(false);
      setModalVisible(false);
    }
  };

  const handleDeleteRecord = async (sa_door_id) => {
    setLoading(true);
    try {
      let postData = {
        sa_door_id,
      };
      let resp = await Service.call(
        "patch",
        "/api/sa/door/delete",
        postData
      );
      if (resp.status !== 1) {
        return notification.error({
          message: t("fail"),
        });
      }
      notification.success({
        message: t("success"),
      });
      getAllData();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <Button
        type="primary"
        onClick={() => {
          setModalVisible(true);
          setSelectedRecord({ sa_door_id: 0 });
        }}
      >
        {t("add_sa_door")}
      </Button>
      <Divider />
      <SmartAccessDoorListTable
        dataObj={dataList}
        adminChangeData={getAllData}
        setSelectedRecord={(record) => setSelectedRecord(record)}
        setModalVisible={(value) => setModalVisible(value)}
        isActiveChangeCallBack={(sa_door_id, value) => {
          handleIsActiveChange(sa_door_id, value);
        }}
        isDeleteCallBack={handleDeleteRecord}
        loading={loading}
      />
      <SmartAccessDoorModal
        initialValues={initialValues}
        selectedRecord={selectedRecord}
        itemList={itemList}
        modalVisible={modalVisible}
        setModalVisible={(value) => {
          setModalVisible(value);
          getAllData();
        }}
        handleFormSubmit={(submitRecord) => handleFormSubmit(submitRecord)}
      />
    </Card>
  );
};

export default SmartAccessDoorList;
