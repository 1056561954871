import React, { Component, useState, useEffect } from "react";
import {
  Avatar, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Space, Table, Tag, Tooltip
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  CheckOutlined
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import "antd/dist/antd.css";
import moment from "moment";
import _ from "lodash";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Service from "../../../core/Service";
import NavAndSideFrame from "../../../components/NavAndSideFrame";
import Button from "../../../t9UIComponents/t9Button";
import EventCategoryTable from "../../../components/Event/EventCategoryTable";
import EventCategoryForm from "../../../components/Event/EventCategoryForm";

const debug = require("debug")("app:admin:client:src:AdvertisementList");

const title = "Category";
const selectedKey = "event_category";
const openKeys = "event_management";

const EventCategory = (props) => {
  const [loading, setLoading] = useState(false);
  const {t} = useTranslation();

  const [dataList, setDataList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    try {
      setLoading(true);
      let resp = await Service.call("get", "/api/event/category");
      const {data} = resp;
      setDataList(data);
    } catch (error) {
      console.error("error >>> ", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <NavAndSideFrame {...props} title={title} selectedKey={selectedKey} openKeys={openKeys}>
      <Space>
        <Button type="primary" onClick={() => setModalVisible(true)}>
          {t("add_category")}
        </Button>
      </Space>
      <Divider />
      <EventCategoryTable
        loading={loading}
        setLoading={setLoading}
        dataList={dataList}
        getAllData={getAllData}
      />

      <Modal
        width="75%"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <EventCategoryForm onSuccess={() => {setModalVisible(false); getAllData()}} />
      </Modal>
    </NavAndSideFrame>
  );
};

export default EventCategory;
