import React, { useState, useEffect } from "react";
import { Tabs, Spin } from "antd";
import "antd/dist/antd.css";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import * as Service from "../../../core/Service";

import NavAndSideFrame from "../../../components/NavAndSideFrame";
import CompanyAdminAcInfoForm from "./CompanyAdminAcInfoForm";
import CompanyAdminAcResetPasswordForm from "./CompanyAdminAcResetPasswordForm";

const { TabPane } = Tabs;

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const CompanyAdminAcContainer = (props) => {
  const { t } = useTranslation();
  let query = useQuery();
  let id = query.get("id") ? query.get("id") : 0;

  const [userObj, setUser] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (_.toInteger(id) !== 0) {
      fetchUser();
    }
  }, []);

  const fetchUser = async () => {
    setLoading(true);
    let result = [];
    try {
      result = await Service.call("get", `/api/admin/company/admin/${id}`);
      setUser(result);
    } catch (error) {
      console.error("error >>> ", error);
    }
    setLoading(false);
  };

  return (
    <NavAndSideFrame
      {...props}
      title={id > 0 ? `${t("edit")} ${t("user")}` : `${t("add")} ${t("user")}`}
      selectedKey="company_user"
    >
      <Spin size="large" spinning={loading}>
        <Tabs type="card" defaultActiveKey="user_form">
          <TabPane tab={t("user_info")} key="user_form">
            <CompanyAdminAcInfoForm initialValues={userObj} />
          </TabPane>
          {id > 0 && (
            <TabPane tab={t("edit_password")} key="userPassword">
              <CompanyAdminAcResetPasswordForm initialValues={userObj} />
            </TabPane>
          )}
        </Tabs>
      </Spin>
    </NavAndSideFrame>
  );
};

export default CompanyAdminAcContainer;
