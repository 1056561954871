import React, { useEffect, useState } from "react";
import {
  Form, Input, Button, message, Modal, Table, Tabs, Spin, Tag, Tooltip, Popconfirm, Typography
} from "antd";
import {
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import moment from "moment";

import { useSelector } from "react-redux";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import NavAndSideFrame from "../../components/NavAndSideFrame";
import * as Service from "../../core/Service";
import NotificationForm from "../../components/NotificationForm";

const debug = require("debug")("app:admin:client:src:Notification");

const selectedKey = "company_notification";
const openKeys = "notification_management";
const tableIdFieldName = "notification_queue_id";
const { TabPane } = Tabs;

const HtmlToReactParser = require("html-to-react").Parser;

const htmlToReactParser = new HtmlToReactParser();

const CompanyNotification = (props) => {
  const { t } = useTranslation();

  const [modalVisible, setModalVisible] = useState();
  const [dataList, setDataList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [displayType, setDisplayType] = useState("company");

  const [companyList, setCompanyList] = useState([]);
  const [companyUserList, setCompanyUserList] = useState([]);

  const company_admin = useSelector((state) => state.app.company_admin);

  useEffect(() => {
    getData();
    getCompanyList();
  }, []);

  useEffect(() => {
    getData();
  }, [displayType]);

  const getData = () => {
    setLoading(true);
    switch (displayType) {
      // case 'global': return getGlobalData();
      case "company": return getCompanyData();
      case "floor": return getCompanyData(); // TODO: Get Floor Notification API
      case "user": return getUserData();
      default: message.warning("Cannot found target tab data");
    }
  };

  // const getGlobalData = async () => {
  //   debug('getGlobalData');
  //   let url = `/api/notification/global`;
  //   let result = await Service.call('get', url);
  //   if (result.status !== 1) {
  //     setLoading(false);
  //     return message.error(result.errorMessage)
  //   }
  //   let { data } = result;
  //   setDataList(data);
  //   setLoading(false);
  //   // message.success('success');
  // }

  const getCompanyData = async () => {
    debug("getCompanyData");
    let url = `/api/notification/company`;
    let result = await Service.call("get", url);
    if (result.status !== 1) {
      setLoading(false);
      return message.error(result.errorMessage);
    }
    let { data } = result;
    setDataList(data);
    setLoading(false);
    // message.success('success');
  };

  const getUserData = async () => {
    debug("getUserData");
    let url = `/api/notification/user`;
    let result = await Service.call("get", url);
    if (result.status !== 1) {
      setLoading(false);
      return message.error(result.errorMessage);
    }
    let { data } = result;
    setLoading(false);
    setDataList(data);
    // message.success('success');
  };

  const getCompanyList = async () => {
    let url = `/api/company/user/by/${company_admin.company_id}`;
    let result = await Service.call("get", url);
    console.log(`/api/company/list :: ${result}`);

    setCompanyList(result.companies);
    setCompanyUserList(_.filter(result.companyUsers, { is_active: 1 }));
  };

  const deleteNotification = async (id) => {
    let url = `/api/notification`;
    let result = await Service.call("delete", url, {
      notification_queue_id: id
    });
    if (result.status !== 1) {
      setLoading(false);
      return message.error(result.errorMessage);
    }
    // let { data } = result;
    setLoading(false);
    getData();
    // setDataList(data);
  };

  const getColumns = (type = "global") => {
    const columns = [];

    columns.push({
      title: t("operation"),
      dataIndex: tableIdFieldName,
      render: (value) => {
        const operations = [];
        console.log("id ::", value);

        operations.push(
          <Popconfirm
            title={t("confirm_delete") + "?"}
            onConfirm={() => deleteNotification(value)}
            okText={t("yes")}
            cancelText={t("no")}
          >
            <Tooltip title={t("delete")}>
              <Button
                shape="circle"
                style={{ marginRight: 4 }}
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Popconfirm>
        );

        return (
          <span>{operations}</span>
        );
      }
    });

    if (type === "user") {
      columns.push({
        title: t("username"),
        dataIndex: "company_user_id",
        render: (value) => _.find(companyUserList, { company_user_id: value })?.nickname,
      });
      columns.push({
        title: t("email_address"),
        dataIndex: "company_user_id",
        render: (value) => _.find(companyUserList, { company_user_id: value })?.email,
      });
    }

    columns.push({
      title: t("create_time"),
      dataIndex: "ctime",
      sorter: (a, b) => a.ctime - b.ctime,
      render: (value) => moment.unix(value).format("YYYY-MM-DD HH:mm")
    });
    columns.push({
      title: t("status"),
      dataIndex: "status",
      render: (value) => renderStatus(value),
      sorter: (a, b) => a.status - b.status,
    });
    columns.push({
      title: t("title"),
      dataIndex: "title",
    });
    columns.push({
      title: t("static_content"),
      dataIndex: "content",
      render: (value) => htmlToReactParser.parse(value)
    });

    return columns;
  };

  const renderStatus = (value) => {
    switch (value) {
      case 1:
        return <Tag color="green">{t("success")}</Tag>;
      case 0:
        return <Tag color="blue">{t("pending")}</Tag>;
      case -1:
        return <Tag color="red">{t("fail")}</Tag>;
      case -2:
        return <Tag color="purple">{t("cancelled")}</Tag>;
      default:
        return <Tag color="red">{t("error")}</Tag>;
    }
  };

  return (
    <NavAndSideFrame {...props} title={t("notification")} selectedKey={selectedKey} openKeys={openKeys}>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => {
          setModalVisible(true);
        }}
        style={{ marginBottom: 10 }}
      >
        {t("add")}
      </Button>

      <Tabs activeKey={displayType} onChange={setDisplayType}>
        <TabPane tab={t("company")} key="company" />
        {/* <TabPane tab={t("floor")} key="floor" /> */}
        <TabPane tab={t("user")} key="user" />
      </Tabs>

      <Spin spinning={loading}>
        <Table
          rowKey={(index) => index}
          scroll={{ x: true }}
          columns={getColumns(displayType)}
          dataSource={dataList}
          bordered
          size="small"
        />
      </Spin>

      <Modal
        title={t("notification")}
        visible={modalVisible}
        footer={null}
        onCancel={() => {
          setModalVisible(false);
        }}
        width="80%"
      >
        <NotificationForm
          companyList={companyList}
          companyUserList={companyUserList}
          setModalVisible={setModalVisible}
          openModal={(value) => {
            setModalVisible(value);
            getData();
          }}
        />
      </Modal>

    </NavAndSideFrame>
  );
};

export default CompanyNotification;
