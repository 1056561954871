import React, { useEffect, useLayoutEffect } from "react";
import _ from "lodash";
import {
  MinusCircleOutlined,
  PlusOutlined,
  MinusOutlined,
} from "@ant-design/icons";

import { Rnd } from "react-rnd";

import {
  Col,
  InputNumber,
  Row,
  Slider,
  Space,
  Switch,
  Tag,
  Tooltip,
} from "antd";
import { useTranslation } from "react-i18next";

const hourPerSession = 2;
const numOfSessions = 24 / hourPerSession; // 2hrs per session

const TimeSlot = ({
  days,
  timeSlotList,
  setTimeSlotList,
  barWidth,
  barHeight,
  setBarWidth,
  setBarHeight,
  selectedRecord,
  timeBarRef,
  getBookingConfigRuleset,
  handleWindowResize,
  itemRulesetList
}) => {
  const {t} = useTranslation();

  useEffect(() => {
    if (
      selectedRecord.booking_item_timeslot_id !== 0 &&
      !_.isUndefined(selectedRecord.booking_item_timeslot_id)
    ) {
      getBookingConfigRuleset();
    }
  }, [selectedRecord]);

  useEffect(() => {
    handleWindowResize(itemRulesetList, barWidth);
  }, [barWidth]);

  useLayoutEffect(() => {
    handleBarWidthChange();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleBarWidthChange);
    handleBarWidthChange();
    return () => window.removeEventListener("resize", handleBarWidthChange);
  }, []);

  const handleBarWidthChange = () => {
    setBarWidth(timeBarRef.current?.offsetWidth);
    setBarHeight(timeBarRef.current?.offsetHeight);
  };

  const barStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const getTime = (time) => {
    let hrs = Math.floor(time);
    let mins = Math.round((time % 1) * 60);
    if (mins >= 60 || Math.ceil(mins / 10) * 10 == 60) {
      hrs = Math.ceil(time);
      mins = 0;
    }
    hrs = hrs.toString().padStart(2, "0");
    mins = mins.toString().padStart(2, "0");
    return `${hrs}:${mins}`;
  };

  const getStartEndTime = (width, x) => {
    const start = (x / barWidth) * 24;
    const end = ((x + width) / barWidth) * 24;
    const start_time = getTime(start);
    const end_time = getTime(end);
    return { start_time, end_time };
  };

  
  // if switch is on, generate a new timeslot, otherwise, remove existing timeslot
  const handleSwitchChange = (checked, row) => {
    let cloned = _.clone(timeSlotList);
    if (!checked) { 
      delete cloned[row];
    } else {
      cloned[row] = [
        {
          booking_item_timeslot_ruleset_id: 0,
          width: (barWidth / 24) * 9,
          height: 20,
          x: (barWidth / 24) * 9,
          y: 0,
          start_time: "09:00",
          end_time: "18:00",
          section_price: 0
        },
      ];
    }
    setTimeSlotList(cloned);
  };

  // handle section price change of each timeslot
  const handlePriceChange = (value, row, i) => {
    const cloned = _.clone(timeSlotList);
    cloned[row][i] = { ...cloned[row][i], section_price: value };
    setTimeSlotList(cloned);
  };

  // split one timeslot into two
  const splitTimeSlot = (day, i) => {
    let cloned = _.clone(timeSlotList);
    const slotDetail = cloned[day][i];
    const avgWidth = slotDetail.width / 2;
    cloned[day].splice(i, 1, {
      ...slotDetail,
      width: avgWidth,
      ...getStartEndTime(avgWidth, slotDetail.x),
    });
    cloned[day].splice(i + 1, 0, {
      booking_item_timeslot_ruleset_id: 0,
      width: avgWidth,
      height: barHeight,
      x: slotDetail.x + avgWidth,
      y: 0,
      ...getStartEndTime(avgWidth, slotDetail.x + avgWidth),
    });
    setTimeSlotList(cloned);
  };
 
  // add new timeslot at the front / behind
  const addTimeSlot = (day, type) => {
    let cloned = _.clone(timeSlotList);
    let width = barWidth / numOfSessions;
    let newX;
    if (type === "front") {
      newX = cloned[day][0].x - width;
      cloned[day].unshift({
        booking_item_timeslot_ruleset_id: 0,
        width: barWidth / numOfSessions,
        height: barHeight,
        x: newX,
        y: 0,
        ...getStartEndTime(width, newX),
      });
    } else if (type === "back") {
      newX =
        cloned[day][cloned[day].length - 1]?.x +
        cloned[day][cloned[day].length - 1]?.width;
      cloned[day].push({
        booking_item_timeslot_ruleset_id: 0,
        width: barWidth / numOfSessions,
        height: barHeight,
        x: newX,
        y: 0,
        ...getStartEndTime(width, newX),
      });
    }
    setTimeSlotList(cloned);
  };

  // merge two timeslot into one
  const integrateTimeSlot = (day, i) => {
    const cloned = _.clone(timeSlotList);
    const firstX = cloned[day][i - 1]?.x;
    const secondX = cloned[day][i]?.x + cloned[day][i]?.width;
    const totalWidth = secondX - firstX;
    cloned[day].splice(i - 1, 2, {
      width: totalWidth,
      height: barHeight,
      x: firstX,
      y: 0,
      section_price: 0,
      booking_item_timeslot_ruleset_id: 0,
      ...getStartEndTime(totalWidth, firstX),
    });
    setTimeSlotList(cloned);
  };

  // remove timeslot
  const removeTimeSlot = (day, i) => {
    let cloned = _.clone(timeSlotList);
    cloned[day].splice(i, 1);
    setTimeSlotList(cloned);
  };


  const renderSchedule = (rows, cols) => {
    return (
      <>
        {/* Header */}
        <div>
          <Row justify="end">
            <Col span={20} className="grid-row">
              <Slider
                className="schedule-header"
                marks={getSessionTime(cols)}
              />
            </Col>
          </Row>
        </div>
        <div className="book-schedule">
          
          {_.range(rows).map((row, i) => {
            // console.log({timeSlotList, isBool: !_.isUndefined(
            //   timeSlotList?.[row]) || !_.isEmpty(timeSlotList?.[row])})
            return (
              <Row key={`row-${i}`} align="middle">
                <Col span={4}>
                  <Space>
                    <Switch
                      checked={
                        !(_.isUndefined(
                          timeSlotList?.[row]) || _.isEmpty(timeSlotList?.[row]))
                      }
                      onChange={(checked) => handleSwitchChange(checked, row)}
                    />
                    {t(days[row])}
                  </Space>
                </Col>
                <Col span={20} className="grid-row" style={{}}>
                  <div
                    ref={timeBarRef}
                    style={{
                      background: "#eee",
                      height: 20,
                      borderRight: "1px solid #eee",
                    }}
                  >
                    {_.map(timeSlotList?.[row], (slot, i) => {
                      const cloned = _.clone(timeSlotList);
                      return (
                        <div className="resize-box" key={`${slot}-${i}`}>
                          {i !== 0 &&  <span style={{background: "#bbbbbb", height: barHeight, width: cloned[row][i]?.x -
                                        cloned[row][i - 1]?.width -
                                        cloned[row][i - 1].x , position: "absolute", left:  
                                        cloned[row][i - 1]?.width +
                                        cloned[row][i - 1].x
                                        }}/>}
                          <Tooltip
                            overlayClassName="section-price-tooltip"
                            placement="top"
                            title={
                              <Tag style={{marginRight: 0}}>
                                $
                                <InputNumber
                                  size="small"
                                  min={0}
                                  value={cloned[row][i].section_price}
                                  style={{ margin: "2px 4px" }}
                                  onChange={(value) =>
                                    handlePriceChange(value, row, i)
                                  }
                                />
                                <span style={{ color: "#1E73BE" }}>
                                  /section
                                </span>
                              </Tag>
                            }
                          >
                            <Rnd
                              maxWidth="100%"
                              bounds="parent"
                              size={{
                                width: slot?.width,
                                height: slot?.height,
                              }}
                              position={{ x: slot?.x, y: slot?.y }}
                              dragAxis="x"
                              dragGrid={[barWidth / 48, 0]}
                              resizeGrid={[barWidth / 48, 0]}
                              enableResizing={{ right: true }}
                              style={{
                                ...barStyle,
                                background:
                                  row === rows - 1 ? "#5CA5E5" : "#1E73BE",
                              }}
                              onDrag={(e, d) => {
                                e.stopImmediatePropagation();
                                if (i !== cloned[row][cloned[row].length - 1]) {
                                  if (
                                    Math.floor(d.x + slot?.width) >
                                      cloned[row][i + 1]?.x ||
                                    d.x <
                                      Math.floor(
                                        cloned[row][i - 1]?.x +
                                          cloned[row][i - 1]?.width
                                      )
                                  )
                                    return;
                                }
                                cloned[row].splice(i, 1, {
                                  ...cloned[row][i],
                                  x: d.x,
                                  ...getStartEndTime(slot?.width, d.x),
                                });
                                setTimeSlotList(cloned);
                              }}
                              onResize={(
                                e,
                                direction,
                                ref,
                                delta,
                                position
                              ) => {
                                if (i !== cloned[row][cloned[row].length - 1]) {
                                  if (
                                    position.x >
                                      cloned[row][i + 1]?.x - slot?.width ||
                                    position.x <
                                      cloned[row][i - 1]?.x +
                                        cloned[row][i - 1]?.width
                                  )
                                    return;
                                }
                                const width = _.toNumber(
                                  ref.style.width.match(/[\d\.]+/)
                                );
                                cloned[row].splice(i, 1, {
                                  ...cloned[row][i],
                                  width,
                                  height: barHeight,
                                  ...position,
                                  ...getStartEndTime(width, position.x),
                                });
                                setTimeSlotList(cloned);
                              }}
                            >
                              {i !== 0 && (
                                <MinusCircleOutlined
                                  onClick={() => integrateTimeSlot(row, i)}
                                  style={{
                                    position: "absolute",
                                    top: "150%",
                                    left: `-${
                                      (cloned[row][i]?.x -
                                        cloned[row][i - 1]?.width -
                                        cloned[row][i - 1].x +
                                        12) /
                                      2
                                    }px`,
                                    // left: 0
                                  }}
                                />
                              )}
                              {i === 0 &&
                                cloned[row][i].x >=
                                  barWidth / numOfSessions && (
                                  <div
                                    style={{
                                      fontSize: 12,
                                      position: "absolute",
                                      top: 0,
                                      left: -(barHeight * 1.5),
                                    }}
                                  >
                                    <PlusOutlined
                                      className="icon-button"
                                      onClick={() => addTimeSlot(row, "front")}
                                    />
                                  </div>
                                )}

                              <Tooltip
                                placement="top"
                                title={timeSlotList?.[row]?.[i]?.start_time}
                                color="#EEEEEE"
                                overlayClassName="book-schedule-tooltip"
                              >
                                <div
                                  style={{
                                    width: barHeight,
                                    height: barHeight,
                                    borderRadius: "50%",
                                    background: "#fff",
                                    position: "absolute",
                                    top: 0,
                                    left: -(barHeight / 2),
                                    zIndex: 1,
                                    border: "1px solid #eee",
                                  }}
                                />
                              </Tooltip>
                              <div style={{ fontSize: 12 }}>
                                <Tooltip
                                  placement="top"
                                  title="Add Breaktime"
                                  color="#EEEEEE"
                                  overlayClassName="book-schedule-tooltip"
                                >
                                  <PlusOutlined
                                    className="icon-button"
                                    onClick={() => splitTimeSlot(row, i)}
                                  />
                                </Tooltip>
                                <Tooltip
                                  placement="top"
                                  title="Remove this period"
                                  color="#EEEEEE"
                                  overlayClassName="book-schedule-tooltip"
                                >
                                  <MinusOutlined
                                    className="icon-button"
                                    style={{ marginLeft: 8 }}
                                    onClick={() => removeTimeSlot(row, i)}
                                  />
                                </Tooltip>
                              </div>
                              <Tooltip
                                placement="top"
                                title={timeSlotList?.[row]?.[i]?.end_time}
                                color="#EEEEEE"
                                overlayClassName="book-schedule-tooltip"
                              >
                                <div
                                  style={{
                                    width: barHeight,
                                    height: barHeight,
                                    borderRadius: "50%",
                                    background: "#fff",
                                    position: "absolute",
                                    top: 0,
                                    left:
                                      timeSlotList &&
                                      timeSlotList?.[row]?.[i]?.width -
                                        barHeight / 2,
                                    zIndex: 1,
                                    border: "1px solid #eee",
                                  }}
                                />
                              </Tooltip>
                              {i === cloned[row].length - 1 &&
                                barWidth -
                                  (cloned[row][cloned[row].length - 1].x +
                                    cloned[row][cloned[row].length - 1]
                                      .width) >=
                                  barWidth / numOfSessions && (
                                  <div
                                    style={{
                                      fontSize: 12,
                                      position: "absolute",
                                      top: 0,
                                      left:
                                        timeSlotList?.[row]?.[i]?.width +
                                        barHeight,
                                    }}
                                  >
                                    <PlusOutlined
                                      className="icon-button"
                                      onClick={() => addTimeSlot(row, "back")}
                                    />
                                  </div>
                                )}
                            </Rnd>
                          </Tooltip>                        
                        </div>
                      );
                    })}
                  </div>
                </Col>
              </Row>
            );
          })}
        </div>
      </>
    );
  };
  return (
    <div className="schedule-container">
      {renderSchedule(days.length, numOfSessions)}
      <div>
          <Row gutter={24} style={{ marginTop: 48 }}>
            <Col>
              <Space size={8}>
                <div style={{ width: 36, height: 16, background: "#1E73BE" }} />
                <div>{t('working_time')}</div>
              </Space>
            </Col>
            <Col>
              <Space size={8}>
                <div style={{ width: 36, height: 16, background: "#5CA5E5" }} />
                <div>{t('special_time')}</div>
              </Space>
            </Col>
            <Col>
              <Space size={8}>
                <div style={{ width: 36, height: 16, background: "#BBBBBB" }} />
                <div>{t('break_time')}</div>
              </Space>
            </Col>
          </Row>
        </div>

    </div>
  );
};

const getSessionTime = (blocks) => {
  const sessionMarks = {};
  const interval = 100 / blocks;
  _.range(blocks + 1).map(
    (block) =>
      (sessionMarks[interval * block] = (
        <div className="slot">
          {(hourPerSession * block).toString().padStart(2, "0")}:00
        </div>
      ))
  );
  return sessionMarks;
};

export default TimeSlot;
