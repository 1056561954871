import React, { Component, useState, useEffect } from "react";
import {
  Avatar,
  Divider,
  Form,
  Icon,
  Layout,
  Menu,
  Modal,
  Popconfirm,
  Table,
  Tag,
  Tooltip,
  Row,
  Col,
  notification,
} from "antd";
import {
  EditOutlined,
  FileTextOutlined,
  KeyOutlined,
  QrcodeOutlined,
  BankOutlined,
} from "@ant-design/icons";
// import 'antd/dist/antd.css';
import moment from "moment";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Button from "../../../t9UIComponents/t9Button";
import * as Service from "../../../core/Service";
import * as Main from "../../../core/Main";
import NavAndSideFrame from "../../../components/NavAndSideFrame";
import CompanyListTable from "./CompanyListTable";

const debug = require("debug")("app:admin:client:src:AdvertisementList");

const involvedModelName = "admin";

const CompanyList = (props) => {
  const history = useHistory();
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const title = t("company_list");
  const selectedKey = "commpany_admin_list";
  const openKeys = "admin_setting";
  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    setLoading(true);
    let companyList = [];
    try {
      let url = "/api/admin/company/list";
      let resp = await Service.call("get", url);
      if (resp.status && resp.status !== 1) {
        return notification.error(t(resp.errorCode));
      }
      let { companyArr, companyConfigArr } = resp;
      let keyById_ConfigArr = _.keyBy(companyConfigArr, "company_id");
      companyList = _.map(companyArr, (eachCompany) => {
        let companyConfig = keyById_ConfigArr[eachCompany.company_id] || {};
        return {
          ...eachCompany,
          admin_account_max: companyConfig.admin_account_max,
          allow_profile: companyConfig.allow_profile,
          allow_rfid: companyConfig.allow_rfid,
          allow_user_qrcode: companyConfig.allow_user_qrcode,
        };
      });
    } catch (error) {
      console.error("error >>> ", error);
    } finally {
      setDataList(companyList);
      setLoading(false);
    }
  };

  const handleIsActiveChange = async (company_id, value) => {
    setLoading(true);
    let postData = {
      company_id,
      is_active: value,
    };
    let submit = await Service.call("patch", "/api/admin/company/is_active", postData);
    if (submit.status < 1) {
      notification.error({
        message: t(`fail`),
        description: t(submit.errorCode),
      });
      setLoading(false);
    } else {
      notification.success({
        message: t("success"),
      });
      getAllData();
      setLoading(false);
    }
  };

  return (
    <NavAndSideFrame title={title} selectedKey={selectedKey} openKeys={openKeys}>
      <Button
        type="primary"
        onClick={() => {
          history.push("/admin/company/setting");
        }}
      >
        {t("add_company")}
      </Button>
      <Divider />
      <CompanyListTable
        dataObj={dataList}
        adminChangeData={getAllData}
        isActiveChangeCallBack={(company_id, value) => handleIsActiveChange(company_id, value)}
        loading={loading}
      />
    </NavAndSideFrame>
  );
};

export default CompanyList;
