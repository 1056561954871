import React, { Component, useState, useEffect } from 'react';
import {
  Avatar, Button, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip
} from 'antd';
import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const ContactUsListTable = (props) => {
  const { t } = useTranslation()
  const involvedModelName = "company";
  const title = t("contact_us_record");
  const tableIDName = "contact_us_record";
  const app = useSelector((state) => state.app);
  const { STATIC_SERVER_URL } = app.config;
  const {
    dataObj, loading,
  } = props;

  const [dataList, setDataList] = useState([]);
  const [pageSize, setPageSize] = useState(100);

  useEffect(() => {
    setDataList(dataObj);
  }, [dataObj]);

  const setTableHeader = () => {
    const columns = [
      {
        title: t('company_name'),
        dataIndex: 'company_name',
        sorter: (a, b) => a.company_name - b.company_name,
      },
      {
        title: t('name'),
        dataIndex: 'name',
        sorter: (a, b) => a.name - b.name,
      },
      {
        title: t('mobile'),
        dataIndex: 'mobile',
        sorter: (a, b) => a.mobile - b.mobile,
      },
      {
        title: t('question'),
        dataIndex: 'question',
        sorter: (a, b) => a.question - b.question,
      },
    ];
    return columns;
  }

  const displayIsActive = (value) => {
    let displayStr = '';
    let tagColor = 'blue';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("active");
        tagColor = 'blue';
        break;
      case 0:
        displayStr = t("inactive");
        tagColor = 'red';
        break;
      default:
        displayStr = t("error")
        tagColor = 'red';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  return (
    <Layout>
      <Table
        rowKey={tableIDName}
        scroll={{ x: 'max-content' }}
        dataSource={dataList}
        columns={setTableHeader()}
        bordered
        size="small"
        pagination={{ pageSize }}
        onChange={(pagination, filters, sorter, extra) => {
          if (pageSize !== pagination.pageSize) {
            setPageSize(pagination.pageSize)
          }
        }}
        loading={loading}
      />
    </Layout>

  )
}

export default ContactUsListTable;
