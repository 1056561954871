import React, { useState, useEffect } from 'react';
import {
  Tabs,
  notification
} from 'antd';
import 'antd/dist/antd.css';
import _ from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';

import { useSelector } from 'react-redux';
import * as Service from '../../core/Service';

import NavAndSideFrame from '../NavAndSideFrame';
import CompanyAcInfoForm from './CompanyAcInfoForm';
import CompanyAcImageForm from './CompanyAcImageForm';
import CompanyAdminAccountSettingForm from './CompanyAdminAccountSettingForm';
import SmartAccessDoorList from './SmartAccessDoorList';
import SmartAccessAPISettingForm from './SmartAccessAPISettingForm';
import CompanyConfigTable from './CompanyConfigTable';

const { TabPane } = Tabs;
const selectedKey = "company_setting";
const openKeys = "settings"

const CompanySettingContainer = (props) => {
  const location = useLocation();
  const companyInfo = location?.state?.companyInfo || {};
  const app = useSelector((state) => state.app);
  const { t } = useTranslation();
  const [companyObj, setCompany] = useState({});
  const [lock, setLock] = useState(_.isEmpty(companyObj));
  useEffect(() => {
    if (!_.isEmpty(companyInfo)) {
      setCompany(companyInfo);
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(companyObj)) {
      setLock(false);
    }
  }, [companyObj]);

  const refreshCompanyInfo = async () => {
    // IF company admin || clicking company
    if (!_.isEmpty(companyInfo) || app.company_admin.company_admin_id) {
      let method = 'get';
      let url = '/api/company/current';

      if (app.admin.admin_id) {
        url = `/api/admin/company/${companyInfo.company_id}`;
      };

      let resp = await Service.call(method, url)
      if (!resp || resp.status !== 1) {
        notification.error({
          message: `${t("error")} - ${resp.errorMessage} [${resp.errorCode}]`,
        });
        return;
      }
      let { data } = resp;
      let {companyRc, companyConfigRc} = data;
      setCompany({
        ...companyRc,
        ...companyConfigRc,
      });
    }
  }

  return (
    <NavAndSideFrame title={`${t('company_setting')}`} selectedKey={selectedKey} openKeys={openKeys}>
      <Tabs type="line" defaultActiveKey="info_form">
        <TabPane tab={t('company_info')} key="info_form">
          <CompanyAcInfoForm
            initialValues={companyObj}
            refreshCompanyInfo={refreshCompanyInfo}
          />
        </TabPane>
        <TabPane tab={t('image')} key="image_form" disabled={lock}>
          <CompanyAcImageForm
            initialValues={companyObj}
            refreshCompanyInfo={refreshCompanyInfo}
          />
        </TabPane>
        {app.isAdmin && (
          <TabPane
            tab={t("company_config")}
            key="admin_account_setting_form"
            disabled={lock}
          >
            <CompanyAdminAccountSettingForm
              initialValues={companyObj}
              refreshCompanyInfo={refreshCompanyInfo}
            />
          </TabPane>
        )}
        <TabPane tab={t('sa_door_setting')} key="sa_door_setting_list" disabled={lock}>
          <SmartAccessDoorList
            initialValues={companyObj}
            refreshCompanyInfo={refreshCompanyInfo}
          />
        </TabPane>
        <TabPane tab={t('sa_api_setting')} key="sa_api_setting_list" disabled={lock || !app.isAdmin}>
          <SmartAccessAPISettingForm
            initialValues={companyObj}
            refreshCompanyInfo={refreshCompanyInfo}
          />
        </TabPane>
      </Tabs>
    </NavAndSideFrame>
  )
}

export default CompanySettingContainer;
