import React, { Component, useState, useEffect } from "react";
import {
  Avatar,
  Divider,
  Form,
  Icon,
  Layout,
  Menu,
  Modal,
  Popconfirm,
  Space,
  Table,
  Tabs,
  Tag,
  Tooltip,
  notification,
  Row,
  Col,
  Select,
  Spin,
  message,
  Button,
  Empty
} from "antd";
import {
  groupBy,
  keyBy,
  map,
  includes,
  isEmpty,
  startsWith,
  endsWith,
  split,
  inRange,
} from "lodash";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import NavAndSideFrame from "../../../components/NavAndSideFrame";
import * as Service from "../../../core/Service";
// import Button from "../../../t9UIComponents/t9Button";
import QRcodeDummy from "../../../assets/QRcodeDummy.svg";
import EventQRReader from "./EventQRReader";
import EventQRScanner from "./EventQRScanner";
import { FONT, COLOR } from "../../../theme";

const selectedKey = "check_in";
const openKeys = "event_management";

const { Option } = Select;

const NON_DISPLAY_KEY = [
  "ctime",
  "utime",
  "company_user_id",
  "event_id",
  "event_user_id"
];


const EventCheckIn = (props) => {
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const [scannerType, setScannerType] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [selectedEnrollment, setSelectedEnrollment] = useState(null);
  const [showWarning, setShowWarning] = useState(false);
  // const [dataList, setDataList] = useState({
  //   eventUserInfo: {},
  //   eventEnrollmentList: []
  // })
  const [dataList, setDataList] = useState({

  });
  const [loading, setLoading] = useState(false);
  const title = t("check_in");

  const resetState = () => {
    setDataList({
      eventInfoList: [],
      keyByEventUser: {},
      keyByEventInfo: {},
      GroupByEnrollmentList: {},
      keyByEnrollmentList: {},
    });
    setSelectedEvent({});
    setSelectedEnrollment(null);
    setShowWarning(null);
    setModalVisible(false);
  };

  const onScan = async (data) => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      if (!(startsWith(data, "v") && endsWith(data, "|") && split(data, ".").length > 1)) {
        message.error("QR Code not valid");
        return;
      }

      const resp = await Service.call("post", "/api/event/verify", {
        qrCodeData: data
      });

      if (!resp || resp.status !== 1) {
        return message.error(t(resp.errorCode));
      }
      const {
        eventInfoList,
        eventUserInfo,
        eventEnrollmentList
      } = resp.data;
      setDataList({
        eventInfoList,
        keyByEventUser: keyBy(eventUserInfo, "event_id"),
        keyByEventInfo: keyBy(eventInfoList, "event_id"),
        GroupByEnrollmentList: groupBy(eventEnrollmentList, "event_id"),
        keyByEnrollmentList: keyBy(eventEnrollmentList, "event_enrollment_id"),
      });
      setModalVisible(true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const confirmCheckIn = async () => {
    setLoading(true);
    try {
      const resp = await Service.call("post", "/api/event/check_in", {
        event_id: selectedEvent.eventInfo.event_id,
        event_enrollment_id: selectedEnrollment,
      });
      if (!resp || resp.status !== 1) {
        return notification.error({
          message: t("error"),
          description: t(resp.errorCode)
        });
      }
      notification.success({
        message: t("success"),
        description: t("check_in_success")
      });
      resetState();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <NavAndSideFrame title={title} selectedKey={selectedKey} openKeys={openKeys}>
      <Row gutter={[16, 24]}>
        <div style={{}}>
          <Col span={8}>
            <Button
              type="primary"
              onClick={() => setScannerType("reader")}
            >
              {t("scan_to_check_in")}
            </Button>
          </Col>
        </div>
        <div style={{ justifyContent: "center" }}>
          <Col span={8}>
            <Button
              type="primary"
              onClick={() => {
                setScannerType("scanner");
              }}
            >
              {t("scan_to_check_in_plugin")}
            </Button>
          </Col>
        </div>
      </Row>

      <Divider />
      <Row gutter={[24, 24]}>
        <Col span={24}>
          {scannerType === "reader" && !modalVisible && (
            <EventQRReader
              onScan={onScan}
              scanDelay={2000}
              footer={null}
            />
          )}

          {scannerType === "scanner" && !modalVisible && (

            <EventQRScanner
              onScan={onScan}
              footer={null}
            />
          )}
        </Col>
      </Row>
      <Modal
        destroyOnClose
        visible={modalVisible}
        footer={null}
        style={{ maxWidth: 600 }}
        width="100%"
        onCancel={resetState}
      >
        <div style={{ ...styles.header }}>{t("user_confirmation")}</div>
        <Row gutter={[0, 30]}>
          <Col span={24}>
            <Select
              style={{
                width: "100%",
              }}
              placeholder={t("select_event")}
              onChange={(event_id) => {
                if (!event_id) return;
                setSelectedEvent({
                  eventInfo: dataList.keyByEventInfo[event_id],
                  eventUser: dataList.keyByEventUser[event_id],
                  eventEnrollmentList: dataList.GroupByEnrollmentList[event_id],
                });
                setSelectedEnrollment(null);
                setShowWarning(false);
              }}
            >
              {map(dataList.eventInfoList, (eachEvent) => {
                const {
                  event_name,
                  event_id,
                } = eachEvent;
                return (
                  <Option
                    key={event_id}
                    value={event_id}
                  >
                    {event_name}
                  </Option>
                );
              })}
            </Select>
          </Col>
          {!isEmpty(selectedEvent) && (
            <>
              <Col span={24}>
                <Select
                  style={{
                    width: "100%",
                  }}
                  placeholder={t("select_enrollment")}
                  onChange={(event_enrollment_id) => {
                    setShowWarning(false);
                    const enrollmentInfo = dataList.keyByEnrollmentList[event_enrollment_id];
                    if (enrollmentInfo
                      && !inRange(
                        dayjs().unix(),
                        enrollmentInfo.event_start_time,
                        enrollmentInfo.event_end_time
                      )) {
                      setShowWarning(true);
                    }
                    setSelectedEnrollment(event_enrollment_id);
                  }}
                  value={selectedEnrollment}
                >
                  {map(selectedEvent.eventEnrollmentList, (eachEnrollment) => {
                    const {
                      event_enrollment_id,
                      event_start_time,
                      event_end_time,
                      check_in_status
                    } = eachEnrollment;
                    return (
                      <Option
                        key={event_enrollment_id}
                        value={event_enrollment_id}
                        disabled={check_in_status}
                      >
                        {check_in_status && (`(${t("checked_in")})`)}
                        {`${dayjs.unix(event_start_time).format("YYYY-MM-DD HH:mm")}
                      - ${dayjs.unix(event_end_time).format("YYYY-MM-DD HH:mm")}`}
                      </Option>
                    );
                  })}
                </Select>
              </Col>
              {map(selectedEvent.eventUser, (value, key) => {
                if (includes(NON_DISPLAY_KEY, key) || isEmpty(value)) {
                  return;
                }
                return (
                  <React.Fragment key={key}>
                    <Col
                      span={10}
                      style={{
                        ...FONT.bodyText2Regular,
                        minWidth: "30px"
                      }}
                    >
                      {t(key)}
                    </Col>
                    <Col span={1}>:</Col>
                    <Col
                      span={10}
                      style={{
                        ...FONT.bodyText2Regular,
                        minWidth: "30px",
                        color: COLOR.primary.primary500
                      }}
                    >
                      {value}
                    </Col>
                  </React.Fragment>
                );
              })}
              {showWarning && (
                <div
                  style={{
                    color: COLOR.warning.warning300
                  }}
                >
                  {t("check_in_warning")}
                </div>
              )}
              <Col span={24}>
                <Button
                  disabled={!selectedEnrollment}
                  style={{
                    ...FONT.bodyText2Regular,
                    width: "100%",
                    height: 33,
                    color: COLOR.text.whiteText,
                    backgroundColor: !selectedEnrollment
                      ? COLOR.function.disable : COLOR.primary.primary400,
                    borderRadius: 4
                  }}
                  onClick={() => confirmCheckIn()}
                  loading={loading}
                >
                  {t("confirm")}
                </Button>
              </Col>
            </>
          )}
        </Row>
      </Modal>
    </NavAndSideFrame>
  );
};

const styles = {
  button: {
    background: "#308DDE",
    boarderRadius: "4px",
    color: "white",
    width: "77px",
    height: "33px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  header: {
    fontSize: 16,
    paddingBottom: 8,
    borderBottom: "0.5px solid #D4D4D4",
    color: "#1E73BE",
    fontWeight: 700,
    lineHeight: "24px",
    marginBottom: 20
  },
  data: {
    color: "#1E73BE",
    AlignSelf: "center"
  }
};

export default EventCheckIn;
