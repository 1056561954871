import React, { useState, useEffect } from "react";
import {
  Modal,
  Spin,
  Table,
  Tooltip,
  Button,
  Popconfirm,
  notification,
  Divider,
  Tag,
} from "antd";
import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import dayjs from "dayjs";
import * as Service from "../../core/Service";
import CompanyUserMembershipForm from "../../components/CompanyUserMembershipForm";
import T9Button from "../../t9UIComponents/t9Button";
import NavAndSideFrame from "../../components/NavAndSideFrame";

const tableIDName = "company_membership_user_id";

const selectedKey = "membership_user";
const openKeys = "membership";

const MembershipUserList = () => {
  const [loading, setLoading] = useState(false);
  const [isActiveLoading, setIsActiveLoading] = useState(false);
  const { t } = useTranslation();
  const [dataList, setDataList] = useState([]);
  const [userData, setUserData] = useState([]);
  const [availableUserList, setAvailableUserList] = useState([]);
  const [membershipData, setMembershipData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRc, setSelectedRc] = useState({});

  const getAllData = async () => {
    setLoading(true);
    try {
      let resp = await Service.call("get", "/api/company/user/membership");
      if (resp.status === 1) {
        const { companyUserMembershipList, userList, membershipPlanList } =
          resp.data;
        const keyByUserList = _.keyBy(userList, "company_user_id");
        const keyByMembershipList = _.keyBy(
          membershipPlanList,
          "membership_plan_id"
        );
        setDataList(companyUserMembershipList);
        setUserData(keyByUserList);
        setMembershipData(keyByMembershipList);
        setAvailableUserList(userList);
        return;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  const handleIsActiveChange = async (
    company_user_membership_id,
    is_active
  ) => {
    setIsActiveLoading(true);
    try {
      const resp = await Service.call(
        "patch",
        "/api/company/user/membership/status",
        {
          company_user_membership_id,
          is_active,
        }
      );
      if (!resp || resp.status !== 1) {
        return notification.error({
          message: t("error"),
          description: t(resp.errorCode),
        });
      }
      getAllData();
    } catch (error) {
      console.error(error);
    } finally {
      setIsActiveLoading(false);
    }
  };

  const columns = [
    {
      title: t("operation"),
      dataIndex: tableIDName,
      render: (value, record) => {
        return (
          <span>
            <Tooltip title={t("edit")}>
              <Button
                shape="circle"
                style={{ marginRight: 4 }}
                icon={<EditOutlined />}
                onClick={() => {
                  setModalVisible(true);
                  setSelectedRc(record);
                }}
              />
            </Tooltip>
            <Popconfirm
              title={
                record.is_active
                  ? t("confirm_deactivate")
                  : t("confirm_activate")
              }
              onConfirm={() => {
                let is_active = record.is_active ? 0 : 1;
                handleIsActiveChange(
                  record.company_user_membership_id,
                  is_active
                );
              }}
              okText={t("yes")}
              cancelText={t("no")}
            >
              <Tooltip
                title={record.is_active ? t("deactivate") : t("activate")}
              >
                <Button
                  shape="circle"
                  style={{ marginRight: 4 }}
                  loading={isActiveLoading}
                  icon={
                    record.is_active ? (
                      <CloseCircleOutlined />
                    ) : (
                      <CheckCircleOutlined />
                    )
                  }
                />
              </Tooltip>
            </Popconfirm>
          </span>
        );
      },
    },
    {
      title: t("is_active"),
      dataIndex: "is_active",
      render: (value, record, index) => displayStatus(value),
    },
    {
      title: t("company_user"),
      dataIndex: "company_user_id",
      render: (value, record, index) => {
        const userInfo = userData[value] || {};
        let name = "-";
        if (!_.isEmpty(userInfo)) {
          name = userInfo.nickname;
        }
        return name;
      },
    },
    {
      title: t("membership_plan_name"),
      dataIndex: "membership_plan_id",
      render: (value, record, index) => {
        const membershipInfo = membershipData[value] || {};
        let name = "-";
        if (!_.isEmpty(membershipInfo)) {
          name = membershipInfo.name;
        }
        return name;
      },
    },
    {
      title: t("start_time"),
      dataIndex: "start_time",
      render: (value, record, index) =>
        dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: t("end_time"),
      dataIndex: "end_time",
      render: (value, record, index) =>
        dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss"),
    },
  ];

  const displayStatus = (value) => {
    let displayStr = "";
    let tagColor = "green";
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("activate");
        tagColor = "green";
        break;
      case 0:
        displayStr = t("inactivate");
        tagColor = "red";
        break;
      default:
        displayStr = t("error");
        tagColor = "#f50";
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  };

  return (
    <NavAndSideFrame
      title={t("company_user_membership_list")}
      selectedKey={selectedKey}
      openKeys={openKeys}
    >
      <Spin spinning={loading}>
        <T9Button
          onClick={() => {
            setModalVisible(true);
            setSelectedRc({ company_user_membership_id: 0 });
          }}
        >
          {t("create")}
        </T9Button>
        <Divider />
        <Table
          rowKey={tableIDName}
          loading={loading}
          columns={columns}
          dataSource={dataList}
          pagination={false}
          scroll={{
            x: true,
          }}
        />
      </Spin>
      <Modal
        visible={modalVisible}
        footer={null}
        destroyOnClose
        onCancel={() => setModalVisible(false)}
      >
        <CompanyUserMembershipForm
          userList={availableUserList}
          membershipList={membershipData}
          setModalVisible={setModalVisible}
          selectedRc={selectedRc}
          companyUserMembershipList={dataList}
          getAllData={getAllData}
        />
      </Modal>
    </NavAndSideFrame>
  );
};

export default MembershipUserList;
