import React, { useEffect, useState } from "react";
import { Layout, Select } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";

import "react-big-calendar/lib/css/react-big-calendar.css";
import "moment/locale/zh-tw";
import { renderBackgroundColor } from "../../../core/Main";
import OrderInfoModel from "./OrderInfoModel";

const { Option } = Select;

const localizer = momentLocalizer(moment);

const CompanyUserOrderListCalender = (props) => {
  const { t, i18n } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState([]);
  const {dataList, setLoading, selectDateCallBack } = props;
  const BookingInfoList = dataList.bookingLogArr;
  const formType = 1;

  const [keyByList, setKeyByList] = useState({
    companyUserList: {},
    bookingItemList: {},
  });

  useEffect(() => {
    let { companyUserList, bookingItemArr } = dataList;
    setKeyByList({
      companyUserList: _.keyBy(companyUserList, "company_user_id"),
      bookingItemList: _.keyBy(bookingItemArr, "booking_item_id"),
    });
  }, [dataList]);

  const lookDetail = async (event) => {
    setSelectedRecord(event);
    setModalVisible(true);
  };

  const calendarStyle = (event) => {
    return {
      style: {
        backgroundColor: renderBackgroundColor(event.status),
        borderColor: "rgba(255, 255, 255, 0.3)",
      },
    };
  };

  const handleFormSubmit = async (submitRecord) => {
    try {
      setLoading(true);
      // let resp = await Service.call("")
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const displayCompanyUser = (value) => {
    let companyUserRc = keyByList.companyUserList[value];
    if (!_.isEmpty(companyUserRc)) {
      // let { first_name, last_name } = companyUserRc;
      // return `${first_name} ${last_name}`;
      return companyUserRc.nickname;
    }
    return "-";
  };

  return (
    <Layout>
      <Calendar
        // selectable
        culture={i18n.language}
        scrollToTime={new Date(new Date().setHours(8, 1))}
        localizer={localizer}
        events={BookingInfoList}
        startAccessor="start"
        endAccessor="end"
        style={{
          height: "1000px",
          background: "white",
        }}
        defaultView={Views.WEEK}
        views={["day", "week"]}
        messages={{
          day: t("day"),
          week: t("week"),
          today: t("today"),
          next: t("next"),
          previous: t("previous"),
        }}
        onNavigate={(focusWeek, flipUnit, prevOrNext) => {
          selectDateCallBack(prevOrNext, focusWeek);
        }}
        onSelectEvent={(event) => lookDetail(event)}
        eventPropGetter={(event) => calendarStyle(event)}
      />

      <OrderInfoModel
        formType={formType}
        dataObj={selectedRecord}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        handleFormSubmit={(submitRecord) => handleFormSubmit(submitRecord)}
        displayCompanyUser={displayCompanyUser}
      />
    </Layout>
  );
};

export default CompanyUserOrderListCalender;
