import Immutable from "seamless-immutable";
import * as types from "../actions/types";

const initialState = Immutable({
  auth: false,
  config: {
    STATIC_SERVER_URL: null,
  },
  loading: false,
  company: {},
  admin: {},
  company_admin: {},
  company_user: {},
  user: {},
  origin: "",
  sidebarCollapse: true,
  timeZoneOffset: 8,
  isAdmin: false,
  isCompanyUser: false,
  floorList: []
});

const appReducer = {
  app: (state = initialState, action) => {
    switch (action.type) {
      case types.SET_CONFIG: {
        return {
          ...state,
          config: action.data
        };
      }
      case types.SET_AUTH:
        return {
          ...state,
          auth: action.data
        };
      case types.SET_LOADING:
        return {
          ...state,
          loading: action.data
        };
      case types.SET_COMPANY:
        return {
          ...state,
          company: action.data
        };
      case types.SET_IS_ADMIN:
        return {
          ...state,
          isAdmin: action.data
        };
      case types.SET_IS_COMPANY_USER:
        return {
          ...state,
          isCompanyUser: action.data
        };
      case types.SET_TIME_ZONE_OFFSET:
        return {
          ...state,
          timeZoneOffset: action.data
        };
      case types.SET_SIDER:
        if (action.data !== undefined) {
          return {
            ...state,
            sidebarCollapse: action.data
          };
        }
        return {
          ...state,
          sidebarCollapse: !state.sidebarCollapse
        };
      case types.SET_ADMIN:
        if (!action.data || Object.keys(action.data).length === 0) {
          return {
            ...state,
            admin: initialState.admin,
          };
        }
        return {
          ...state, admin: action.data
        };
      case types.SET_COMPANY_ADMIN:
        if (!action.data || Object.keys(action.data).length === 0) {
          return {
            ...state,
            company_admin: initialState.company_admin,
          };
        }
        return {
          ...state, company_admin: action.data
        };
      case types.SET_COMPANY_USER:
        if (!action.data || Object.keys(action.data).length === 0) {
          return {
            ...state,
            company_user: initialState.company_user,
          };
        }
        return { ...state, company_user: action.data };
      case types.SET_USER:
        if (!action.data || Object.keys(action.data).length === 0) {
          return {
            ...state,
            user: initialState.user,
          };
        }
        return {
          ...state,
          user: action.data
        };
      case types.SET_ORIGIN:
        return {
          ...state,
          origin: action.data
        };
      case types.SET_FLOOR_LIST:
        return {
          ...state,
          floorList: action.data
        };
      default:
        return state;
    }
  }
};

export default appReducer;
