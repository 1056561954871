import {
  Button,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Form,
  InputNumber,
  message,
  notification,
  Radio,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import moment from "moment";

import {
  DownOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import dayjs from "dayjs";
import * as Service from "../../core/Service";

const { Panel } = Collapse;
const { Option } = Select;

const EventSetting = (props) => {
  const {
    dataObj,
    eventMeta,
    getEventInfo,
  } = props;
  //console.log(dataObj);
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [resetForm, setResetForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedRegType, setSelectedRegType] = useState(null);
  const [regTypeList, setRegTypeList] = useState([]);
  const [registerFieldOptions, setRegisterFieldOptions] = useState([]);

  const handleSelectedRegTypeChange = (value) => {
    form.setFieldsValue({
      event_registration_form_fields: [{
        sorting: 1,
        mandatory: true,
        field_type: _.includes(value, "email") ? "email" : "mobile"
      }]
    });
    setSelectedRegType(value);
  };

  useEffect(() => {
    getEventRegistrationTypes();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(dataObj)) setResetForm(true);
  }, [dataObj, eventMeta]);

  useEffect(() => {
    if (resetForm) {
      const { registration_type } = eventMeta;
      setSelectedRegType(registration_type || null);
      const event_period = [];
      _.map(dataObj.eventSection, (period, index) => {
        const {
          event_start_date,
          event_end_date,
          registration_start_date,
          registration_end_date,
          ...others
        } = period;
        delete others.ctime;
        delete others.utime;
        delete others.registration_count;

        event_period.push({
          ...others,
          event_date: [
            moment.unix(event_start_date),
            moment.unix(event_end_date),
          ],
          registration_date: [
            registration_start_date > 0 ? moment.unix(registration_start_date) : null,
            registration_end_date > 0 ? moment.unix(registration_end_date) : null,
          ]
        });
      });
      const metaValue = {};
      _.mapValues(eventMeta, (_meta_value, meta_key) => {
        let meta_value = _meta_value;
        switch (meta_key) {
          // case "auto_generate_password":
          //   const auto_generate_password = JSON.parse(_meta_value);
          //   const { length, ...combination } = auto_generate_password;
          //   metaValue.password_length = length;
          //   const combinationKeys = _.keys(combination);
          //   const clickedKeys = _.filter(combinationKeys, (key) => combination[key] == 1);
          //   metaValue.password_combination = clickedKeys;
          //   break;
          case "event_registration_form_fields":
            meta_value = JSON.parse(_meta_value);
            break;
          default:
            meta_value = _.toNumber(_meta_value);
        }
        metaValue[meta_key] = meta_value;
      });

      const formObj = {
        ...dataObj.eventInfo,
        ...metaValue,
        event_period,
        registration_type,
      };

      form.setFieldsValue(formObj);
      setResetForm(false);
    }
  }, [resetForm]);

  const getEventRegistrationTypes = async () => {
    const resp = await Service.call("get", `/api/event/registration/type`);

    if (!_.isNull(resp) && resp.status === 1) {
      setRegTypeList(resp.data);
      setRegisterFieldOptions(resp.registerOptions);
    }
  };

  const onFinish = async (_formObj) => {
    try {
      const formObj = _.clone(_formObj);
      // console.log("formObj >>>>>", formObj);
      formObj.event_period = [];
      // console.log("event period >>>", _formObj.event_period)
      _.map(_formObj.event_period, (eachEventPeriod) => {
        formObj.event_period.push({
          ...eachEventPeriod,
          event_date_id: eachEventPeriod.event_date_id ? eachEventPeriod.event_date_id : 0,
          event_start_date: eachEventPeriod.event_date[0].unix(),
          event_end_date: eachEventPeriod.event_date[1].unix(),
          registration_start_date: eachEventPeriod.registration_date[0].unix(),
          registration_end_date: eachEventPeriod.registration_date[1].unix(),
        });
      });
      setLoading(true);
      const url = "/api/event/setting";
      const resp = await Service.call("post", url, formObj);
      if (!resp || resp.status !== 1) {
        return notification.error({
          message: t("error"),
          description: t(resp.errorCode),
        });
      }
      notification.success({
        message: t("success")
      });
      await getEventInfo();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="event-setting event-tabs-page">
      <Form layout="vertical" form={form} name="form" onFinish={onFinish}>
        <Form.Item name="event_id" noStyle />
        <Collapse
          bordered={false}
          defaultActiveKey={["1"]}
          expandIconPosition="right"
          expandIcon={({ isActive }) => (
            <DownOutlined rotate={isActive ? 180 : 0} />
          )}
        >
          <Panel header={t("event_details")} key="1">
            <div className="event-details">
              <div style={{ marginBottom: 16 }}>{t("event_period")}</div>
              <Form.List name="event_period">
                {(fields, { add, remove }) => (
                  <>
                    {_.isEmpty(fields) && add()}
                    {fields.map(({ key, name, ...restField }, index) => (
                      <Row
                        gutter={[3, 0]}
                        key={`event_period-${key}`}
                        align="start"
                        style={fields.length > 1 && { borderBottom: "1px solid #f5f5f5", marginBottom: 5 }}
                      >
                        <Col
                          flex="none"
                          style={{
                            fontWeight: "bold",
                            color: "#1E73BE",
                          }}
                        >
                          {_.toString(index + 1).padStart(2, "0")}
                        </Col>
                        <Col xs={20} md={22} xl={23}>
                          <Row gutter={[20, 8]}>
                            <Col xs={24} xl={9}>
                              <Form.Item name="event_date_id" noStyle />
                              <Form.Item
                                label={t("event_day")}
                                name={[name, "event_date"]}
                                rules={[
                                  {
                                    required: true,
                                    message: t("missing_field_type"),
                                  },
                                ]}
                              >
                                <DatePicker.RangePicker
                                  disabledDate={(date) => date < moment().startOf("day")}
                                  showTime
                                  format="YYYY-MM-DD HH:mm"
                                  placeholder={[t("start_time"), t("end_time")]}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={8} xl={5}>
                              <NumberRadioInput
                                form={form}
                                loading={resetForm}
                                label="display_applicants"
                                name="event_period"
                                colKey={name}
                                colName="event_display_applicants"
                              />
                            </Col>

                            <Col xs={24} md={8} xl={5}>
                              <NumberRadioInput
                                form={form}
                                loading={resetForm}
                                label="applicants_count"
                                name="event_period"
                                colKey={name}
                                colName="event_applicants_count"
                              />
                            </Col>

                            <Col xs={24} md={8} xl={5}>
                              <NumberRadioInput
                                form={form}
                                loading={resetForm}
                                label="price"
                                name="event_period"
                                colKey={name}
                                colName="event_price"
                                formatter={(value) => `$ ${value}`.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                              />
                            </Col>

                            <Col xs={24} xl={9}>
                              <Form.Item
                                label={t("registration_day")}
                                name={[name, "registration_date"]}
                                rules={[
                                  {
                                    required: true,
                                    message: t("missing_field_type"),
                                  },
                                ]}
                              >
                                <DatePicker.RangePicker
                                  showTime
                                  disabledDate={(date) => date < moment().startOf("day")}
                                  format="YYYY-MM-DD HH:mm"
                                  placeholder={[t("start_time"), t("end_time")]}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={8} xl={5}>
                              <Form.Item
                                label={t("booking_capacity_per_user")}
                                name={[name, "maximum_quantity"]}
                                rules={[
                                  {
                                    required: true,
                                    message: t("missing_field_type"),
                                  },
                                ]}
                              >
                                <InputNumber
                                  min={1}
                                />
                              </Form.Item>

                            </Col>

                          </Row>
                        </Col>
                        <Col flex="none" style={{ alignSelf: "center" }}>
                          {fields.length > 1 && (
                            <MinusCircleOutlined
                              onClick={() => remove(name)}
                            />
                          )}
                        </Col>
                      </Row>
                    ))}

                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined style={{ color: "#1E73BE" }} />}
                        style={{
                          color: "#1E73BE",
                          borderColor: "#1E73BE",
                        }}
                      >
                        {t("add_event_period")}
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </div>
          </Panel>
        </Collapse>
        <Collapse
          bordered={false}
          defaultActiveKey={["2"]}
          expandIconPosition="right"
          expandIcon={({ isActive }) => (
            <DownOutlined rotate={isActive ? 180 : 0} />
          )}
          style={{ marginTop: 24 }}
        >
          <Panel header={t("registration_details")} key="2">
            <div className="registration-details">
              <Row gutter={[36, 36]}>
                <Col lg={8} xs={12}>
                  <Form.Item
                    name="registration_type"
                    label={t("event_registration_type")}
                    style={{ width: "50%" }}
                    rules={[
                      {
                        required: true,
                        message: t("missing_field_type"),
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      placeholder="Select"
                      onChange={handleSelectedRegTypeChange}
                      value={selectedRegType}
                    >
                      {_.map(regTypeList, (type) => {
                        return (
                          <Option key={`type-${type.key}`} value={type.key}>
                            {type.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col lg={8} xs={12}>
                  <Row
                    gutter={[36, 36]}
                  >

                    <Col span={_.includes(selectedRegType, "email") ? 16 : 0}>
                      <Form.Item
                        label={t("allow_duplicated_email")}
                        name="skip_email_check"
                        rules={_.includes(selectedRegType, "email") && [{
                          required: true,
                          message: t("missing_field_type"),
                        }]}
                      >
                        <Radio.Group>
                          <Radio value={0}>Yes</Radio>
                          <Radio value={1}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Col span={_.includes(selectedRegType, "mobile") ? 16 : 0}>
                      <Form.Item
                        label={t("allow_duplicated_phone")}
                        name="skip_mobile_check"
                        rules={_.includes(selectedRegType, "mobile") && [{
                          required: true,
                          message: t("missing_field_type"),
                        }]}
                      >
                        <Radio.Group>
                          <Radio value={0}>Yes</Radio>
                          <Radio value={1}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Col lg={24} xs={36}>
                <Row
                  gutter={[24, 24]}
                >
                  {!_.isNil(selectedRegType) &&
                    (
                      <>
                        <Col lg={8} xs={12}>
                          <Form.Item
                            label={t("registration_email_notify")}
                            name="registration_email_notify"
                            rules={!_.isNil(selectedRegType) && [{
                              required: true,
                              message: t("missing_field_type"),
                            }]}
                          >
                            <Radio.Group>
                              <Radio value={1}>Auto</Radio>
                              <Radio value={2}>Manual</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                        <Col lg={8} xs={12}>
                          <Form.Item
                            label={t("registration_approval")}
                            name="registration_approval"
                            rules={!_.isNil(selectedRegType) && [{
                              required: true,
                              message: t("missing_field_type"),
                            }]}
                          >
                            <Radio.Group>
                              <Radio value={1}>Auto</Radio>
                              <Radio value={2}>Manual</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </>
                    )}
                </Row>
              </Col>
              {!_.isNil(selectedRegType) && (
                <Row style={{ marginTop: 24 }}>
                  <Col lg={24} xs={24}>
                    <Form.List name="event_registration_form_fields">
                      {(fields, { add, remove }) => {
                        return (
                          <>
                            <Row
                              // style={{ margfinBottom: 8 }}
                              gutter={[24, 12]}
                            >
                              <Col lg={6} xs={10}>
                                <Typography.Text style={{ color: "rgba(0, 0, 0, 0.85)" }}>
                                  {t("registration_form_fields")}
                                </Typography.Text>
                              </Col>
                              <Col lg={4} xs={4}>
                                <Typography.Text>{t("sorting")}</Typography.Text>
                              </Col>
                              <Col lg={8} xs={8}>
                                <Typography.Text>{t("mandatory")}</Typography.Text>
                              </Col>
                            </Row>
                            {fields.map(({ key, name, ...restField }, index) => (
                              <Row
                                key={key}
                                // style={{ marginBottom: 8, width: "100%" }}
                                gutter={[24, 12]}
                              >
                                <Col lg={6} xs={10}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "field_type"]}
                                    rules={index === 0 && [
                                      {
                                        required: true,
                                        message: t("missing_field_type"),
                                      }
                                    ]}
                                  >
                                    <Select placeholder={t("select")} disabled={index === 0}>
                                      {_.map(registerFieldOptions, (opts) => {
                                        return (
                                          <Option key={opts.key} value={opts.key}>
                                            {t(opts.key)}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col lg={4} xs={4}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "sorting"]}
                                    initialValue={1}
                                  >
                                    <InputNumber min={1} style={{ width: "100%" }} />
                                  </Form.Item>
                                </Col>

                                <Col lg={8} xs={8}>
                                  <Row
                                    justify="space-between"
                                  >
                                    <Col span={4}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "mandatory"]}
                                        valuePropName="checked"
                                      >
                                        <Checkbox disabled={index === 0} />
                                      </Form.Item>
                                    </Col>
                                    {
                                      index !== 0 && (
                                        <Col xs={12} lg={20} style={{ marginTop: "5px" }}>
                                          <MinusCircleOutlined
                                            onClick={() => remove(name)}
                                          />
                                        </Col>
                                      )
                                    }
                                  </Row>
                                </Col>
                              </Row>
                            ))}
                            <Row>
                              <Col
                                span={23}
                                style={{ padding: "4px 16px 4px 0px" }}
                              >
                                <Form.Item>
                                  <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    block
                                    icon={<PlusOutlined />}
                                    style={{ color: "#A1A1A1" }}
                                  >
                                    {t("add_registration_fields")}
                                  </Button>
                                </Form.Item>
                              </Col>
                            </Row>
                          </>
                        )
                      }}
                    </Form.List>
                  </Col>
                </Row>
              )}
              {/* {_.includes(selectedRegType, "otp") && (
                <Row>
                  <Collapse
                    defaultActiveKey={["1"]}
                    ghost
                    bordered={false}
                    expandIconPosition="right"
                    expandIcon={({ isActive }) => (
                      <DownOutlined rotate={isActive ? 180 : 0} />
                    )}
                    style={{ width: "100%", marginTop: 32 }}
                  >
                    <Panel header={t("advanced_setting")} key="1">
                      <Row>
                        <Col span={8}>
                          <Form.Item
                            name="password_length"
                            label={t("password_length")}
                            rules={[{
                              required: true,
                              message: t("missing_field_type"),
                            }]}
                            extra={(
                              <div style={{ fontSize: 10 }}>
                                *This Fields can be set 4-12 unit
                              </div>
                            )}
                          >

                            <InputNumber
                              style={{width: "50%"}}
                              placeholder="6"
                              min={4}
                              max={12}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={16}>
                          <Form.Item
                            name="password_combination"
                            label={t("password_combination")}
                          >
                            <Checkbox.Group>
                              <Checkbox value="upperAlphabet">
                                Uppercase Alphabet
                              </Checkbox>
                              <Checkbox value="lowerAlphabet">
                                Lowercase Alphabet
                              </Checkbox>
                              <Checkbox value="digit">Digital Only</Checkbox>
                            </Checkbox.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Panel>
                  </Collapse>
                </Row>
              )} */}
            </div>
          </Panel>
        </Collapse>
        <Col span={24} style={{ marginTop: 40 }}>
          <Space>
            <Button
              onClick={() => setResetForm(true)}
            >
              {t("reset")}
            </Button>
            <Form.Item noStyle>
              <Button
                type="primary"
                htmlType="submit"
                disabled={loading}
              >
                {t("submit")}
              </Button>
            </Form.Item>
          </Space>
        </Col>
      </Form>
    </div>
  );
};

const NumberRadioInput = (props) => {
  const { t } = useTranslation();

  const [fixedChecked, setFixedChecked] = useState("free");
  const [inputValue, setInputValue] = useState(null);

  const {
    label, name, colKey, colName, form, formatter, parser, min = 0, loading
  } = props;

  useEffect(() => {
    if (!loading) {
      const formObj = form.getFieldsValue();
      const formItem = formObj[name][colKey][colName];
      if (formItem > 0) {
        setFixedChecked("fixed");
        setInputValue(formItem);
      }
    }
  }, [loading]);

  useEffect(() => {
    if (fixedChecked === 0) {
      const orgFormObj = form.getFieldsValue();
      const updateObj = _.clone(orgFormObj[name]);
      updateObj[colKey][colName] = 0;
      form.setFieldsValue({
        ...orgFormObj,
        [name]: updateObj
      });
    }
  }, [fixedChecked]);

  useEffect(() => {
    const orgFormObj = form.getFieldsValue();
    const updateObj = _.clone(orgFormObj[name]);
    updateObj[colKey][colName] = _.toInteger(inputValue);
    form.setFieldsValue({
      ...orgFormObj,
      [name]: updateObj
    });
  }, [inputValue]);

  const handleInputChange = (value) => {
    if (value > 0) {
      setFixedChecked("fixed");
    }
    setInputValue(value);
  };

  const handleRadioValueChange = (value) => {
    if (value === "free") {
      setInputValue(null);
    }
    setFixedChecked(value);
  };

  return (
    <>
      <Form.Item name={[name, colName]} noStyle />
      <Form.Item label={t(label)} required>
        <Radio.Group onChange={(event) => handleRadioValueChange(event.target.value)} value={fixedChecked}>
          <Radio value="fixed" checked={fixedChecked === "fixed"}>
            <InputNumber
              min={min}
              parser={parser}
              formatter={formatter}
              disabled={fixedChecked === "free"}
              onChange={handleInputChange}
              value={inputValue}
            />
          </Radio>
          <Radio
            value="free"
            style={{ fontSize: 12 }}
            checked={fixedChecked === "free"}
          >
            {t("free")}
          </Radio>
        </Radio.Group>
      </Form.Item>
    </>
  );
};


export default EventSetting;
