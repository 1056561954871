import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  Select,
  message,
  notification
} from "antd";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import FormUploadFile from "./form/FormUploadFile";
import { formItemLayout, tailLayout } from "./ModalLayout";
import MediaLibraryComponent from "./MediaLibraryComponent";
import TinymceWrapper from "./TinymceWrapper";
import * as Service from "../core/Service";


const PaymentMethodForm = (props) => {
  const [form] = Form.useForm();
  const [LogoFileInfo, setLogoFileInfo] = useState(null);
  const [activeLogoFileInfo, setActiveLogoFileInfo] = useState(null);
  const [paymentImageInfo, setPaymentImageInfo] = useState(null);
  const [activeLogoURL, setActiveLogoImageURL] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imageURL, setImageURL] = useState(null);
  const [paymentDesc, setPaymentDesc] = useState("");
  const STATIC_SERVER_URL = useSelector((state) => state.app.config.STATIC_SERVER_URL);
  const { t } = useTranslation();
  const {
    setFormVisible,
    getAllData,
    initialValue
  } = props;

  useEffect(() => {
    form.resetFields();
    if (initialValue) {
      form.setFieldsValue(initialValue);
      if (!_.isEmpty(initialValue.payment_logo)) {
        setLogoFileInfo({
          filename: initialValue.payment_logo,
          filepath: initialValue.payment_logo
        });
      }
      if (!_.isEmpty(initialValue.payment_logo_active)) {
        setActiveLogoFileInfo({
          filename: initialValue.payment_logo_active,
          filepath: initialValue.payment_logo_active
        });
      }
      if (!_.isEmpty(initialValue.payment_img)) {
        setPaymentImageInfo({
          filename: initialValue.payment_img,
          filepath: initialValue.payment_img
        });
      }
      if (!_.isEmpty(initialValue.payment_desc)) {
        setPaymentDesc(initialValue.payment_desc);
      }
    }
  }, [props]);

  const onFinish = async (formData) => {
    setLoading(true);
    let method = "PUT";
    let url = "/api/company/payment/method";

    if (initialValue.company_payment_method_id > 0) {
      method = "PATCH";
    }
    let postObj = {
      ...initialValue,
      ...formData,
      payment_desc: paymentDesc
    };
    let resp = await Service.call(method, url, postObj);

    if (!resp || resp.status !== 1) {
      setLoading(false);
      return notification.error({
        message: t("error"),
        description: t(resp.errorCode),
      });
    }
    notification.success({
      message: t("success"),
    });
    getAllData();
    setFormVisible(false);
    setLoading(false);
  };

  return (
    <Form
      {...formItemLayout}
      form={form}
      name="payment_method"
      onFinish={onFinish}
    >
      <Form.Item noStyle name="company_payment_method_id" />
      <Form.Item
        label={t("payment_name")}
        name="payment_name"
        rules={[{ required: true, message: "Please select a payment method." }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t("payment_desc")}
      >
        <TinymceWrapper
          initialValue={paymentDesc}
          onChange={(value) => {
            setPaymentDesc(value);
          }}
        />
      </Form.Item>
      <Form.Item
        label={t("payment_logo")}
        name="payment_logo"
      >
        <MediaLibraryComponent
          showImage
          selectCallback={async (mediaObj) => {
            setLogoFileInfo({
              filename: mediaObj.filename,
              filepath: mediaObj.filepath,
            });
            form.setFieldsValue({ payment_logo: mediaObj.filepath });
          }}
          mediaObj={LogoFileInfo}
        />
      </Form.Item>
      <Form.Item
        label={t("payment_logo_active")}
        name="payment_logo_active"
      >
        <MediaLibraryComponent
          showImage
          selectCallback={async (mediaObj) => {
            setActiveLogoFileInfo({
              filename: mediaObj.filename,
              filepath: mediaObj.filepath,
            });
            form.setFieldsValue({ payment_logo_active: mediaObj.filepath });
          }}
          mediaObj={activeLogoFileInfo}
        />
      </Form.Item>
      <Form.Item
        label={t("payment_img")}
        name="payment_img"
      >
        <MediaLibraryComponent
          showImage
          selectCallback={async (mediaObj) => {
            setPaymentImageInfo({
              filename: mediaObj.filename,
              filepath: mediaObj.filepath,
            });
            form.setFieldsValue({ payment_img: mediaObj.filepath });
          }}
          mediaObj={paymentImageInfo}
        />
      </Form.Item>
      {initialValue.payment_type === "stripe"
        && (
          <>
            <Form.Item
              label={t("webhook_secret")}
              name="webhook_secret"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t("gateway_url")}
              name="gateway_url"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t("api_key")}
              name="api_key"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t("api_secret")}
              name="api_secret"
            >
              <Input />
            </Form.Item>

          </>
        )}
      <Form.Item {...tailLayout}>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          {t("submit")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default PaymentMethodForm;
