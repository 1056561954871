import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Space,
  Row,
  Col,
  Form,
  Input,
  Select,
  message,
  Tabs,
  DatePicker,
  TimePicker,
  Switch,
  notification,
  Divider,
  InputNumber,
  Radio,
  AutoComplete,
  Modal,
  Button,
} from "antd";
import { useTranslation } from "react-i18next";
import { HexColorPicker, HexColorInput } from "react-colorful";
import * as Service from "../core/Service";
import { formItemLayout, tailLayout } from "./ModalLayout";
import MediaLibraryComponent from "./MediaLibraryComponent";
import TinymceWrapper from "./TinymceWrapper";

const { Option } = Select;

const CompanyConfigForm = (props) => {
  const { t } = useTranslation();
  const {
    dataObj,
    type,
    getAllData
  } = props;

  const [initialFormValues, setInitialFormValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [companyConfigForm] = Form.useForm();
  const [aboutUsObj, setAboutUsObj] = useState("");
  const [contactObj, setContactObj] = useState("");
  const [addressObj, setAddressObj] = useState("");
  const [primaryColor, setPrimaryColor] = useState("");
  const [secondaryColor, setSecondaryColor] = useState("");
  const [brandBackgroundFile, setBrandBackgroundFile] = useState({});
  useEffect(() => {
    let initObj = {
      ...dataObj
    };
    if (!_.isEmpty(dataObj)) {
      initObj.otp_expiry_interval = dataObj.otp_expiry_interval / 60;
      initObj.order_expiry_time = dataObj.order_expiry_time / 60;
      initObj.cart_item_expiry_time = dataObj.cart_item_expiry_time / 60;
      initObj.token_expiry_time = dataObj.token_expiry_time / 60 / 60 / 24;
      initObj.email_from = dataObj.email_from;
      initObj.email_to = dataObj.email_to;
      setAboutUsObj(dataObj.about_us);
      setContactObj(dataObj.contact);
      setAddressObj(dataObj.address);
      setPrimaryColor("#" + dataObj.color_primary);
      setSecondaryColor("#" + dataObj.color_secondary);
      setBrandBackgroundFile({
        filename: dataObj.brand_banner_background,
        filepath: dataObj.brand_banner_background
      });
    }
    setInitialFormValues(initObj);
  }, [dataObj]);

  useEffect(() => {
    companyConfigForm.resetFields();
  }, [initialFormValues]);

  const onFinish = async (formData) => {
    let patchObj = {
      ...formData
    };
    console.log('type >>>', type)
    if (type === "order") {
      patchObj.order_expiry_time = formData.order_expiry_time * 60;
      patchObj.cart_item_expiry_time = formData.cart_item_expiry_time * 60;
    }

    if (type === "token") {
      patchObj.token_expiry_time = formData.token_expiry_time * 60 * 60 * 24;
    }

    if (type === "basic") {
      patchObj.otp_expiry_interval = formData.otp_expiry_interval * 60;
      patchObj.color_primary = _.replace(primaryColor, "#", "");
      patchObj.color_secondary = _.replace(secondaryColor, "#", "");
      patchObj.email_from = formData.email_from;
      patchObj.email_to = formData.email_to;
    }

    if (type === "footer") {
      patchObj.about_us = aboutUsObj;
      patchObj.address = addressObj;
      patchObj.contact = contactObj;
    }
    console.log(patchObj);
    setLoading(true);
    try {
      let endpoint = "/api/company/company_config";

      let resp = await Service.call("PATCH", endpoint, patchObj);
      console.log("resp >>> ", resp);
      if (!resp || resp.status < 1) {
        message.error(resp.errorMessage);
        return;
      }
      notification.success({
        message: t("Success"),
      });
      // message.success(t('modal_success'));
      getAllData();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const renderFormItem = () => {
    if (type === "token") {
      return (
        <>
          <Form.Item
            name="token_ratio"
            label={t("token_ratio")}
          >
            <InputNumber min={1} />
          </Form.Item>
          <Form.Item
            name="token_expiry_time"
            label={t("token_expiry_time")}
          >
            <InputNumber min={1} />
          </Form.Item>
        </>
      );
    }
    if (type === "order") {
      return (
        <>
          <Form.Item
            name="order_expiry_time"
            label={t("order_expiry_time")}
          >
            <InputNumber min={1} />
          </Form.Item>
          <Form.Item
            name="cart_item_expiry_time"
            label={t("cart_item_expiry_time")}
          >
            <InputNumber min={1} />
          </Form.Item>
        </>
      );
    }

    if (type === "brand") {
      return (
        <>
          <Form.Item
            name="brand_banner_background"
            label={t("brand_banner_background")}
          >
            <MediaLibraryComponent
              showImage
              selectCallback={async (mediaObj) => {
                setBrandBackgroundFile({
                  filename: mediaObj.filename,
                  filepath: mediaObj.filepath,
                });
                companyConfigForm.setFieldsValue({ brand_banner_background: mediaObj.filepath });
              }}
              mediaObj={brandBackgroundFile}
            />
          </Form.Item>
          <Form.Item
            name="brand_banner_title"
            label={t("brand_banner_title")}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name="brand_banner_desc"
            label={t("brand_banner_desc")}
          >
            <Input.TextArea />
          </Form.Item>
        </>
      );
    }

    if (type === "footer") {
      return (
        <>
          <Form.Item
            label={t("about_us")}
          >
            <TinymceWrapper
              initialValue={aboutUsObj}
              onChange={(value) => {
                setAboutUsObj(value);
              }}
            />
          </Form.Item>
          <Form.Item
            label={t("address")}
          >
            <TinymceWrapper
              initialValue={addressObj}
              onChange={(value) => {
                setAddressObj(value);
              }}
            />
          </Form.Item>
          <Form.Item
            label={t("contact")}
          >
            <TinymceWrapper
              initialValue={contactObj}
              onChange={(value) => {
                setContactObj(value);
              }}
            />
          </Form.Item>
        </>
      );
    }
    if (type === "basic") {
      return (
        <>
          <Form.Item
            name="otp_expiry_interval"
            label={t("otp_expiry_interval")}
          >
            <InputNumber min={1} />
          </Form.Item>
          <Form.Item
            name="color_primary"
            label={t("color_primary")}
          >
            <HexColorPicker
              color={primaryColor}
              onChange={setPrimaryColor}
            />
            <div
              style={{
                borderLeft: "24px solid #000",
                paddingLeft: "10px",
                marginTop: "20px",
                borderLeftColor: primaryColor
              }}
            >
              Current color is
              {" "}
              {primaryColor}
            </div>
          </Form.Item>
          <Form.Item
            name="color_secondary"
            label={t("color_secondary")}
          >
            <HexColorPicker
              color={secondaryColor}
              onChange={setSecondaryColor}
            />
            <div
              style={{
                borderLeft: "24px solid #000",
                paddingLeft: "10px",
                marginTop: "20px",
                borderLeftColor: secondaryColor
              }}
            >
              Current color is
              {" "}
              {secondaryColor}
            </div>
          </Form.Item>
          <Form.Item
            name="email_from"
            label={t("email_from")}
            rules={[{
              type: 'email'
            }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email_to"
            label={t("email_to")}
            rules={[{
              type: 'email'
            }]}
          >
            <Input />
          </Form.Item>
        </>
      );
    }
  };

  return (
    <Form
      {...formItemLayout}
      form={companyConfigForm}
      name="time_related_controls"
      layout="vertical"
      initialValues={initialFormValues}
      onFinish={onFinish}
    >
      {renderFormItem()}
      {/* <Form.Item
        name="admin_account_max"
        label={t("admin_account_max")}
      >
        <Input disabled />
      </Form.Item> */}
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          {t("submit")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CompanyConfigForm;
