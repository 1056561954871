import {notification} from 'antd';

export const openNotificationWithIcon = (type, title, msg) => {
  notification[type]({
    message: title,
    description:
        msg,
  });
};

export const onFinishFailed = (error) => {
  for (let i = 0; i < error.errorFields.length; i++) {
    openNotificationWithIcon('error', "Failed", error.errorFields[i].errors)
  }
}
