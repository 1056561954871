import React, { useCallback, useEffect, useState } from "react";
import { Input } from "antd";
import { useTranslation } from "react-i18next";
import Fuse from "fuse.js";
import { map, isEmpty, isArray, filter } from "lodash";

const SearchBar = ({
  originalDataList,
  filterDataListKey, // Only use IF originalDataList === "object"
  filterDataList, // Same with originalDataList IF isArray(originalDataList) ELSE originalDataList[filterDataListKey]
  setDataList, // Update state
  filterField, // Filter by column names
  extraFilter = null,
  placeholder = "please_input_keyword",
  suffix
}) => {
  const { t } = useTranslation();
  const [keywordInput, setKeyword] = useState(null);
  let timeout;
  useEffect(() => {
    getDataBySearchKeyword(keywordInput);
  }, [extraFilter]);

  const filterData = (keyword) => {
    debounce(() => {
      getDataBySearchKeyword(keyword);
    }, 400);
  };
  const debounce = (func, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(func, delay);
  };
  const getDataBySearchKeyword = (keywords) => {
    if (isEmpty(keywords) && isEmpty(extraFilter)) {
      if (!isArray(originalDataList)) {
        return setDataList({
          ...originalDataList,
          [filterDataListKey]: filterDataList,
        });
      }
      return setDataList(filterDataList);
    }
    const options = {
      includeScore: true,
      findAllMatches: true,
      threshold: 0, // exact match
      keys: filterField,
      ignoreLocation: true, // also search within strings not only from start
    };
    try {
      let filteredData = filterDataList;
      if (keywords) {
        const fuseDataConfig = new Fuse(filterDataList, options);
        const filteredResult = fuseDataConfig.search(keywords);
        filteredData = map(filteredResult, "item");
      }
      if (!isEmpty(filteredData) && !isEmpty(extraFilter)) {
        filteredData = filter(filteredData, extraFilter);
      }
      if (!isArray(originalDataList)) {
        return setDataList({
          ...originalDataList,
          [filterDataListKey]: filteredData,
        });
      }
      setDataList(filteredData);
    } catch (error) {
      console.error("error >>> ", error);
    }
  };

  return (
    <Input
      placeholder={t(placeholder)}
      onChange={(values) => {
        setKeyword(values.target.value);
        filterData(values.target.value);
      }}
      value={keywordInput}
      suffix={suffix}
    />
  );
};
export default SearchBar;
