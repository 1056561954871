import React, { useState, useEffect } from "react";
import { Select, Card } from "antd";
// import 'antd/dist/antd.css';
import { useTranslation } from "react-i18next";
import * as debugLib from "debug";
import NavAndSideFrame from "../../../components/NavAndSideFrame";
import UserbookingListCalendarView from "./UserbookingListCalendarView";
import UserbookingListTableView from "./UserbookingListTableView";

// import BookingCalendar from '../../components/BookingCalendar';

const debug = debugLib("app:pages/UserbookingContainer");

const involvedModelName = "company";
const selectedKey = "booking_items_list";
const openKeys = "booking_management"
const tableIDName = "item_category_id";
const { Option } = Select;

// let sampleText = "apple";

const UserbookingContainer = (props) => {
  const { t } = useTranslation();
  const title = t("booking_list");

  const [view, setView] = useState(1);

  return (
    <NavAndSideFrame title={title} selectedKey={selectedKey} openKeys={openKeys}>
      <Select
        defaultValue={1}
        onChange={(value) => {
          setView(value);
        }}
      >
        <Option key={1} value={1}>
          {t("calendar_view")}
        </Option>
        <Option key={2} value={2}>
          {t("table_view")}
        </Option>
      </Select>
      <Card bordered={false}>
        {view === 1 ? (
          <UserbookingListCalendarView />
        ) : (
          <UserbookingListTableView />
        )}
      </Card>
    </NavAndSideFrame>
  );
};

export default UserbookingContainer;
