import React, {
  Component, useState, useEffect, useMemo
} from "react";
import {
  Card
} from "antd";
// import 'antd/dist/antd.css';
import _ from "lodash";
import { useTranslation } from "react-i18next";
import * as Service from "../../../core/Service";
import * as Main from "../../../core/Main";
import NavAndSideFrame from "../../../components/NavAndSideFrame";
import CompanyUserBookingTable from "./CompanyUserBookingTable";
import CompanyUserBookingModal from "./CompanyUserBookingModal";

const debug = require("debug")("app:admin:client:src:AdvertisementList");

const selectedKey = "booking_now";

const CompanyUserBooking = (props) => {
  const { t } = useTranslation();
  const title = t("booking_now");

  const [roomList, setRoomList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    let resp = await Service.call("get", "/api/booking_item/list");
    if (resp.status === 1) {
      setRoomList(resp.data.bookingItemList);
    }
    setLoading(false);
  };

  return (
    <NavAndSideFrame title={title} selectedKey={selectedKey}>
      <CompanyUserBookingTable
        roomList={roomList}
        loading={loading}
        setModalVisible={setModalVisible}
        setSelectedRecord={setSelectedRecord}
      />
      <CompanyUserBookingModal
        selectedRecord={selectedRecord}
        // categoryList={dataList}
        modalVisible={modalVisible}
        openModal={(value) => {
          setModalVisible(value);
        }}
        // handleFormSubmit={(submitRecord) => handleFormSubmit(submitRecord)}
      />
    </NavAndSideFrame>
  );
};

export default CompanyUserBooking;
