import React, { Component, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import {
  Avatar, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, Row, Col, Select, notification
} from 'antd';
import {
  EditOutlined,
  FileTextOutlined,
  KeyOutlined,
  QrcodeOutlined,
  BankOutlined,
} from '@ant-design/icons';
// import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import Button from '../../../t9UIComponents/t9Button';
import * as Service from '../../../core/Service';
import * as Main from '../../../core/Main';
import NavAndSideFrame from '../../../components/NavAndSideFrame';
import SloganPostListTable from './SloganPostListTable';
import SloganPostModal from './SloganPostModal';



const debug = require('debug')('app:admin:client:src:BlogPostList');

const involvedModelName = "company";
// const selectedKey = "blog_post";
const tableIDName = "slogan_id";

const SloganPostList = (props) => {
  const [loading, setLoading] = useState(true);
  const [dataList, setDataList] = useState([]);
  const { t } = useTranslation()
  const [logList, setLogList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(0);

  const title = t('slogan');

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    setLoading(true);
    let result = await Service.call('get', '/api/slogan');
    if (result.status !== 1) {
      setLoading(false);
      return notification.error({
        message: t('error'),
        description: t(`${result.errorCode}`),
      });
    }
    let { data } = result;
    setDataList(data);
    setLoading(false);
  }

  const handleFormSubmit = (submitRecord) => {

    if (selectedRecord.slogan_id === 0) {
      handlePutRecord(submitRecord);
    }
    if (selectedRecord.slogan_id > 0) {
      handlePatchRecord(submitRecord);
    }
  }

  const handlePutRecord = async (submitRecord) => {
    setLoading(true);

    let submit = await Service.call('put', '/api/slogan', submitRecord)
    if (submit.status < 1) {
      notification.error({
        message: t('fail'),
        description: t(`${submit.errorCode}`),
      });
      setLoading(false)
    } else {
      notification.success({
        message: t('success'),
      });
      getAllData();
      setLoading(false)
      setModalVisible(false)
    }
  }

  const handlePatchRecord = async (submitRecord) => {
    setLoading(true)
    let patchData = {
      slogan_id: selectedRecord.slogan_id,
      ...submitRecord
    }
    let submit = await Service.call('patch', '/api/slogan', patchData);
    if (submit.status < 1) {
      notification.error({
        message: t('fail'),
        description: t(`${submit.errorCode}`),
      });
      setLoading(false)
    } else {
      notification.success({
        message: t('success'),
      });
      getAllData();
      setLoading(false)
      setModalVisible(false)
    }
  }

  const handleDeleteRecord = async (deleteRecord) => {
    setLoading(true)
    let postData = {
      slogan_id: deleteRecord,
    };
    // console.log("deleteRecorddeleteRecord", deleteRecord)
    let submit = await Service.call('delete', `/api/slogan/${postData.slogan_id}`)
    if (submit.status < 1) {
      notification.error({
        message: t('fail'),
        description: t(`${submit.errorCode}`),
      });
      setLoading(false)
    } else {
      notification.success({
        message: t('success'),
      });
      getAllData();
      setLoading(false)
      setModalVisible(false)
    }
  }

  // const handleIsActiveChange = async (slogan_id, value) => {
  //   setLoading(true)
  //   let postData = {
  //     slogan_id,
  //     is_active: value
  //   }
  //   let submit = await Service.call('patch', '/api/blog/post/is_active', postData)
  //   if (submit.status < 1) {
  //     notification.error({
  //       message: t('fail'),
  //     });
  //     setLoading(false)
  //   } else {
  //     notification.success({
  //       message: t('success'),
  //     });
  //     getAllData();
  //     setLoading(false)
  //     setModalVisible(false)
  //   }
  // }

  return (
    <NavAndSideFrame title={title}>
      <Button
        type="primary"
        onClick={() => {
          setModalVisible(true)
          setSelectedRecord({slogan_id: 0})
        }}
      >
        {t('add_item')}
      </Button>
      <Divider />
      <Divider />
      {/* <BlogPostListTable
        logList={logList}
        loading={loading}
      /> */}
      <SloganPostListTable
        dataObj={dataList}
        adminChangeData={getAllData}
        setSelectedRecord={(record) => setSelectedRecord(record)}
        handleDeleteRecord={(record) => handleDeleteRecord(record)}
        setModalVisible={(value) => setModalVisible(value)}
        // isActiveChangeCallBack={(slogan_id, value) => handleIsActiveChange(slogan_id, value)}
        loading={loading}
      />
      <SloganPostModal
        selectedRecord={selectedRecord}
        dataObj={dataList}
        modalVisible={modalVisible}
        setModalVisible={
          (value) => {
            setModalVisible(value)
            getAllData()
          }
        }
        handleFormSubmit={(submitRecord) => handleFormSubmit(submitRecord)}
      />
    </NavAndSideFrame>
  )
}

export default SloganPostList;
