import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, message } from 'antd';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux'

export default function PublicRoute(props) {
  const {
    component: Component
  } = props;

  const app = useSelector((state) => state.app);

  if (app.auth === null) {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return (
      <div style={{position: 'absolute', top: '50%', left: '50%'}}>
        <Spin indicator={antIcon} />
      </div>
    )
  }

  return (
    <Route
      render={() => (
        <Component />
      )}
    />
  );
}
