import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Button,
  Divider,
  Form,
  Input,
  Tag,
  Row,
  Col,
  Card,
  Tooltip,
  Select,
  InputNumber,
  notification,
  Switch,
} from "antd";
import { useHistory, useLocation, useParams } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { HexColorPicker, HexColorInput } from "react-colorful";
import * as Service from "../../core/Service";

const { Option } = Select;

const loginMethodArray = [
  { key: 1, value: "Username" },
  { key: 2, value: "Mobile" },
  { key: 3, value: "Email" },
];

const TOKEN_EXPIRY_MODE = [
  { key: "centralize", value: 1 },
  { key: "individual", value: 2 },
  // { kay: "cut_off", value: 3 },
];
// const loginMethodHandleChange = (value) => {
//   console.log(value)
// }

const CompanyAdminAccountSettingForm = (props) => {
  const { t } = useTranslation();
  const { initialValues, refreshCompanyInfo } = props;
  const [colorPrimary, setColorPrimary] = useState("");
  const [colorSecondary, setColorSecondary] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const app = useSelector((state) => state.app);
  const [dataObj, setDataObj] = useState({});

  const [permission, setPermission] = useState({
    allow_profile: 0,
    allow_rfid: 0,
    allow_offline: 0,
    is_public: 0,
    allow_register: 0,
    login_otp: 0,
    login_password: 0,
    allow_booking: 0,
    allow_event: 0,
    allow_membership: 0,
    allow_token: 0,
    allow_e_shop: 0,
  });
  // console.log(initialValues)

  const [UserForm] = Form.useForm();

  useEffect(() => {
    refreshCompanyInfo();
  }, []);

  useEffect(() => {
    UserForm.resetFields();
    setColorPrimary(initialValues.color_primary);
    setColorSecondary(initialValues.color_secondary);
    setPermission({
      allow_profile: initialValues.allow_profile,
      allow_rfid: initialValues.allow_rfid,
      allow_offline: initialValues.allow_offline,
      is_public: initialValues.is_public,
      allow_register: initialValues.allow_register,
      login_otp: initialValues.login_otp,
      login_password: initialValues.login_password,
      login_method: initialValues.login_method,
      allow_booking: initialValues.allow_booking,
      allow_event: initialValues.allow_event,
      allow_membership: initialValues.allow_membership,
      allow_token: initialValues.allow_token,
      allow_e_shop: initialValues.allow_e_shop,
    });
    if (initialValues.company_id) {
      UserForm.setFieldsValue({
        ...initialValues,
        token_expiry_time: initialValues.token_expiry_time / 86400,
      });
    }
  }, [initialValues]);

  const onColorChange = (e, field) => {
    const { value } = e.target;
    const reg = /^([a-zA-Z0-9]*)$/;
    if ((!_.isNaN(value) && reg.test(value)) || value === "") {
      if (field === "primary") {
        setColorPrimary(value);
        UserForm.setFieldsValue({ color_primary: value });
      } else if (field === "secondary") {
        setColorSecondary(value);
        UserForm.setFieldsValue({ color_secondary: value });
      }
    } else {
      UserForm.setFieldsValue({ color_primary: colorPrimary });
      UserForm.setFieldsValue({ color_secondary: colorSecondary });
    }
  };

  const onFinish = async (formData) => {
    setLoading(true);
    let postData = {
      ...initialValues,
      ...formData,
      ...permission,
      color_primary: colorPrimary,
      color_secondary: colorSecondary,
      token_expiry_time: formData.token_expiry_time * 86400,
    };

    let url = "/api/admin/company/config";
    let method = "patch";

    try {
      if (dataObj.company_id > 0) {
        method = "patch";
      }
      let resp = await Service.call(method, url, postData);
      if (resp.status !== 1) {
        return notification.error({
          message: t(`error`),
          description: t(resp.errorCode),
        });
      }
      setDataObj(resp.data);
      notification.success({
        message: t("success"),
      });
      refreshCompanyInfo();
      return history.push("/admin/company/list");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const renderPermissionBtn = () => {
    return _.map(Object.keys(permission), (eachPermission) => {
      return (
        <Form.Item
          label={t(`${eachPermission}`)}
          name={`${eachPermission}`}
        >
          <Switch
            checked={permission[eachPermission]}
            onChange={(value) => {
              setPermission((prevState) => ({
                ...prevState,
                [eachPermission]: value === true ? 1 : 0,
              }));
            }}
          />
        </Form.Item>
      );
    });
  };

  return (
    <Row gutter={[24, 24]}>
      <Col md={24} lg={16}>
        <Card>
          <Form
            form={UserForm}
            name="UserForm"
            layout="vertical"
            initialValues={initialValues}
            onFinish={onFinish}
          >
            <Form.Item
              label={t("admin_account_max")}
              name="admin_account_max"
              rules={[
                {
                  required: true,
                  message: t("input_required"),
                },
              ]}
            >
              <InputNumber disabled={!app.isAdmin} maxLength={10} />
            </Form.Item>
            {renderPermissionBtn()}

            <Form.Item
              label={t("login_method")}
              name="login_method"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                style={{
                  width: 120,
                }}
              // onChange={loginMethodHandleChange}
              >
                {loginMethodArray.map((option) => {
                  return (
                    <Option value={option.key}>
                      {option.value}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label={t("token_expiry_mode")}
              name="token_expiry_mode"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                style={{
                  width: 120,
                }}
              // onChange={loginMethodHandleChange}
              >
                {_.map(TOKEN_EXPIRY_MODE, (eachMode) => {
                  return (
                    <Option key={eachMode.key} value={eachMode.value}>
                      {t(eachMode.key)}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label={t("token_ratio")}
              name="token_ratio"
              rules={[
                {
                  required: true,
                  message: t("input_required"),
                },
              ]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              label={t("token_expiry_time")}
              name="token_expiry_time"
              rules={[
                {
                  required: true,
                  message: t("input_required"),
                },
              ]}
            >
              <InputNumber />
            </Form.Item>

            <Form.Item
              label={t("google_recaptcha_key")}
              name="google_recaptcha_key"
            >
              <Input disabled={!app.isAdmin} maxLength={32} />
            </Form.Item>

            <Form.Item
              label={t("google_recaptcha_secret")}
              name="google_recaptcha_secret"
            >
              <Input disabled={!app.isAdmin} maxLength={32} />
            </Form.Item>

            <Row>
              <Col span={16}>
                <Form.Item
                  label={t("color_primary")}
                  name="color_primary"

                >
                  <HexColorPicker
                    color={colorPrimary}
                    onChange={setColorPrimary}
                  />
                  <div
                    style={{
                      borderLeft: "24px solid #000",
                      paddingLeft: "10px",
                      marginTop: "20px",
                      borderLeftColor: colorPrimary
                    }}
                  >
                    Current color is
                    {" "}
                    {colorPrimary}
                  </div>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={t("color_secondary")}
                  name="color_secondary"
                >
                  <HexColorPicker
                    color={colorSecondary}
                    onChange={setColorSecondary}
                  />
                  <div
                    style={{
                      borderLeft: "24px solid #000",
                      paddingLeft: "10px",
                      marginTop: "20px",
                      borderLeftColor: colorSecondary
                    }}
                  >
                    Current color is
                    {" "}
                    {colorSecondary}
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={!app.isAdmin}
                loading={loading}
              >
                {t("submit")}

              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default CompanyAdminAccountSettingForm;
