import React, { useEffect, useState, useMemo } from "react";
import {
  Button,
  Layout,
  Popconfirm,
  Table,
  Tag,
  Tooltip,
  Select,
  notification,
} from "antd";
import { FileTextOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import moment from "moment";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import * as Main from "../../../core/Main";
import * as Service from "../../../core/Service";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

const { Option } = Select;

const OrderInfoTable = (props) => {
  const user = useSelector((state) => state.app.company_admin);
  const { t } = useTranslation();
  const tableIDName = "order_id";
  let {
    dataObj,
    setModalVisible,
    setSelectedRecord,
    loading,
    setLoading,
    adminChangeData,
    currentPage,
    setCurrentPage,
    pagination_size,
  } = props;

  const tagOption = {
    1: {
      color: "blue",
      displayStr: t('booking')
    },
    // 2: {
    //   color: "green",
    //   displayStr: t('product')
    // },
    // 3: {
    //   color: "yellow",
    //   displayStr: t('event')
    // },
    // 4: {
    //   color: "orange",
    //   displayStr: t('membership')
    // },
    // 5: {
    //   color: "purple",
    //   displayStr: t('token')
    // }
  }

  // const keyById_userList = useMemo(() => {
  //   let { companyUserList, companyAdminList } = dataObj;
  //   return {
  //     companyUser: _.keyBy(companyUserList, "company_user_id"),
  //     companyAdmin: _.keyBy(companyAdminList, "company_admin_id"),
  //   }
  // }, [dataObj]);
  // const keyById_orderInfo = useMemo(() => {
  //   let { orderList } = dataObj;
  //   return _.keyBy(orderList, "order_id");
  // }, [dataObj]);

  const setTableHeader = () => {
    const columns = [
      {
        title: t("operation"),
        dataIndex: "order_id",
        render: (value, record) => {
          return (
            <span>
              <Tooltip title={t("order_info")}>
                <Button
                  shape="circle"
                  style={{ marginRight: 8, color: "#000000" }}
                  icon={<FileTextOutlined />}
                  onClick={() => {
                    setSelectedRecord(record);
                    setModalVisible(true);
                  }}
                />
              </Tooltip>
            </span>
          );
        },
      },
      {
        title: t("order_key"),
        dataIndex: "order_key",
        sorter: (a, b) => a.order_key.localeCompare(b.order_key),
      },
      {
        title: t("status"),
        dataIndex: "status",
        render: (value, record) => (user.is_admin === 1 ? displaySelectBar(record.order_id, value, record.order_type, record) : t(value)),
      },
      // {
      //   title: t("order_type"),
      //   dataIndex: "order_type",
      //   filterSearch: true,
      //   filters: _.map(tagOption, (value, key) => {
      //     return { value: key, text: value.displayStr }
      //   }),
      //   onFilter: (value, record) => {
      //     return record.order_type.includes(_.toInteger(value))
      //   },

      //   render: (value) => {
      //     const invalidColor = "red"
      //     const invalidWord = "invalid"
      //     if (_.isEmpty(value)) {
      //       return <Tag color={invalidColor}>{t(invalidWord)}</Tag>
      //     }
      //     return (
      //       <>
      //         {_.map(value, (rc) => {
      //           const tagRc = tagOption[rc];
      //           if (!tagRc) {
      //             return <Tag key={rc} color={invalidColor}>{t(invalidWord)}</Tag>
      //           }
      //           const { color, displayStr } = tagRc
      //           return (
      //             <Tag
      //               key={rc}
      //               color={color}
      //             >
      //               {t(displayStr)}
      //             </Tag>
      //           )
      //         })
      //         }
      //       </>
      //     );

      //   },

      // },
      {
        title: t("user"),
        dataIndex: "nickname",
        // render: (value) => {
        //   return keyById_userList.companyUser[value]
        //     // ? `${keyById_userList.companyUser[value].first_name} ${keyById_userList.companyUser[value].last_name}`
        //     ? keyById_userList.companyUser[value].nickname
        //     : "-";
        // },
        sorter: (a, b) => a.nickname.length - b.nickname.length,
      },
      {
        title: t("floor"),
        dataIndex: "floor",
        sorter: (a, b) => _.toInteger(a.floor) - _.toInteger(b.floor),
      },
      {
        title: t("unit"),
        dataIndex: "unit",
        sorter: (a, b) => a.unit - b.unit,
      },
      {
        title: t("price"),
        dataIndex: "final_price",
        sorter: (a, b) => a.final_price - b.final_price,
      },
      // {
      //   title: t("pick_up_date"),
      //   dataIndex: "pick_up_date",
      //   align: "center",
      //   render: (value) => {
      //     if (!value) {
      //       return "-"
      //     }
      //     return dayjs.unix(value).format("YYYY-MM-DD HH:mm")
      //   },
      // },
      {
        title: t("update_time"),
        dataIndex: "utime",
        sorter: (a, b) => a.utime - b.utime,
        render: (value) => moment.unix(value).format("YYYY-MM-DD HH:mm"),
      },
      {
        title: t("create_time"),
        dataIndex: "ctime",
        sorter: (a, b) => a.ctime - b.ctime,
        render: (value) => moment.unix(value).format("YYYY-MM-DD HH:mm"),
      },
    ];
    return columns;
  };

  const displayIsActive = (value) => {
    let displayStr = "";
    let tagColor = "blue";
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("active");
        tagColor = "blue";
        break;
      case 0:
        displayStr = t("inactive");
        tagColor = "red";
        break;
      default:
        displayStr = t("error");
        tagColor = "red";
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  };

  // const displayCompanyAdmin = (company_admin_id) => {
  //   let name = "-";
  //   let adminRc = keyById_userList.companyAdmin[company_admin_id];
  //   if (!_.isEmpty(adminRc)) {
  //     let { first_name, last_name } = adminRc;
  //     name = `${first_name} ${last_name}`;
  //   }
  //   return name;
  // };

  const displaySelectBar = (order_id, status, order_type, orderRc) => {
    let component;
    let single = false;
    switch (status) {
      case "placed":
        component = (
          <>
            <Option value="placed" disabled={true}>
              {t("pending")}
            </Option>
            <Option value="cancelled">{t("cancelled")}</Option>
          </>
        );
        break;
      case "pending":
        component = (
          <>
            <Option value="pending">
              {t("pending")}
            </Option>
            <Option value="payment_confirmed">{t("payment_confirmed")}</Option>
            <Option value="cancelled">{t("cancelled")}</Option>
          </>
        );
        break;
      case "pending_approve":
        component = (
          <>
            <Option value="pending_approve" disabled={true}>
              {t("pending_approve")}
            </Option>
            <Option value="payment_confirmed">{t("payment_confirmed")}</Option>
          </>
        );
        break;
      case "payment_confirmed":
        if (_.includes(order_type, Main.ITEM_TYPE.TOKEN)
          || _.includes(order_type, Main.ITEM_TYPE.MEMBERSHIP)
        ) {
          single = true
        }
        component = (
          <>
            <Option value="payment_confirmed" disabled={true}>
              {t("payment_confirmed")}
            </Option>
            <Option value="payment_refund">{t("payment_refund/void")}</Option>
          </>
        );
        break;
      case "payment_failed":
        component = (
          <>
            <Option value="payment_failed" disabled={true}>
              {t("payment_failed")}
            </Option>
            <Option value="payment_confirmed">{t("payment_confirmed")}</Option>
          </>
        );
        break;
      default:
        single = true;
        component = (
          <>
            <Option value={`${status}`} disabled={true}>
              {t(`${status}`)}
            </Option>
          </>
        );
        break;
    }
    return (
      <Select
        disabled={single}
        style={{ width: 200 }}
        value={orderRc?.status}
        onChange={async (value) => updateOrderStatus(order_id, value)}
      >
        {component}
      </Select>
    );
  };

  const updateOrderStatus = async (order_id, status) => {
    setLoading(true);
    try {
      let resp = await Service.call("patch", "/api/order/status", {
        order_id,
        status,
      });
      if (!resp || resp.status !== 1) {
        return notification.error({
          message: t("error"),
          description: resp.errorMessage,
        });
      }
      notification.success({
        message: t("success"),
      });
      return adminChangeData();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <Table
        rowKey={tableIDName}
        scroll={{ x: "max-content" }}
        dataSource={dataObj}
        columns={setTableHeader()}
        bordered
        size="small"
        pagination={{
          pageSize: pagination_size,
          current: currentPage,
          onChange: setCurrentPage,
          total: dataObj.totalLength,
        }}
        loading={loading}
        locale={{
          triggerDesc: t("sort_descend"),
          triggerAsc: t("sort_ascend"),
          cancelSort: t("cancel_sort"),
        }}
      />
    </Layout>
  );
};
export default OrderInfoTable;
