import moment from "moment";
import _ from "lodash";
import * as Service from "./Service";
import * as Main from "./Main";

const exportOrderReport = async (t) => {
  const resp = await Service.call("get", "/api/order/report/all");
  if (resp.status === 1) {
    const {data} = resp;

    const exportDatalist = _.map(data, (dataRow) => {
      const exportData = {
        [t('order_key')]: dataRow.order_key,
        [t("status")]: t(dataRow.status),
        [t("user")]: dataRow.nickname,
        [t("floor")]: dataRow.floor,
        [t("unit")]: dataRow.unit,
        [t("utime")]: moment.unix(dataRow.utime).format("YYYY-MM-DD HH:mm"),
      };
      exportData[t("payment_confirmed_date")] = "-";
      if (dataRow.ptx_complete_time > 0) {
        exportData[t("payment_confirmed_date")] = moment.unix(dataRow.ptx_complete_time).format("YYYY-MM-DD HH:mm");
      }
      return exportData;
    });

    Main.exportTableToCSV(exportDatalist, "order");
  }
};

export {exportOrderReport};
