import React, { Component, useState, useEffect } from "react";
import { Divider, PageHeader, Spin } from "antd";
import "antd/dist/antd.css";
import _ from "lodash";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Service from "../../../core/Service";
import NavAndSideFrame from "../../../components/NavAndSideFrame";
import EventCategoryForm from "../../../components/Event/EventCategoryForm";

const debug = require("debug")("app:admin:client:src:AdvertisementList");

const title = "Category";
const selectedKey = "event_list";
const openKeys = "event_management";

const EventCategoryInfo = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const event_category_id = _.last(location.pathname.split("/")) || params.event_id;

  const [dataObj, setDataObj] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getInfo();
  }, []);

  const getInfo = async () => {
    try {
      setLoading(true);
      let resp = await Service.call("get", `/api/event/category/${event_category_id}`);
      const { data } = resp;
      setDataObj(data);
    } catch (error) {
      console.error("error >>> ", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <NavAndSideFrame {...props} title="" selectedKey={selectedKey} openKeys={openKeys}>
      <PageHeader
        onBack={() => history.push("/company/event/category")}
        title={`${title} - ${event_category_id}`}
      />
      <Divider />
      <Spin spinning={loading}>
        <EventCategoryForm dataObj={dataObj} onSuccess={() => history.push("/company/event/category")} />
      </Spin>
    </NavAndSideFrame>
  );
};

export default EventCategoryInfo;
