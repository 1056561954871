import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import {
  Divider,
  Row,
  Col
} from "antd";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import * as Service from "../../core/Service";
import VisitorListTable from "../../components/Visitor/VisitorListTable";
import SearchBar from "../../components/SearchBar";
import NavAndSideFrame from "../../components/NavAndSideFrame";

const selectedKey = "visitor_list";
const openKeys = "user_list";
const FILTERABLE_FIELDS = [
  "remark",
];

const VisitorList = () => {
  const [loading, setLoading] = useState(true);
  const dataList = useRef([]);
  const [filterDataList, setFilterDataList] = useState([]);
  const { t } = useTranslation();

  let location = useLocation();
  const { key } = queryString.parse(location.search);
  let defaultActiveKey = key;

  const title = t("visitor_list");

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    setLoading(true);
    try {
      let url = `/api/company/temp_code`;
      let resp = await Service.call("get", url);

      const {tempCodeList} = resp.data;

      url = "/api/company/user/with/token/by/company";
      resp = await Service.call("get", url);

      let { companyUserList } = resp.data;
      let keyById_userList = _.keyBy(companyUserList, "company_user_id");

      const list = _.map(tempCodeList, (tempCode) => {
        return {
          ...keyById_userList[tempCode.uid],
          ...tempCode,
        };
      });

      dataList.current = list;
      setFilterDataList(list);
    } catch (error) {
      console.error("error >>> ", error);
    } finally {
      setLoading(false);
    }
  };

  const VisitorListTableWithKey = (props) => {
    return (
      <VisitorListTable
        {...props}
        dataList={filterDataList}
        loading={loading}
        getAllData={getAllData}
        setLoading={setLoading}
      />
    );
  };


  return (
    <NavAndSideFrame
      title={title}
      selectedKey={selectedKey}
      openKeys={openKeys}
    >
      <Row align="middle">
        <Col>
          <span>{`${t("search_keyword")}：`}</span>
        </Col>
        <Col>
          <SearchBar
            originalDataList={dataList.current}
            filterDataListKey="userList"
            filterDataList={dataList.current}
            setDataList={setFilterDataList}
            filterField={FILTERABLE_FIELDS}
          />
        </Col>
      </Row>
      <Divider />
      <VisitorListTableWithKey />
    </NavAndSideFrame>
  );
};

export default VisitorList;
