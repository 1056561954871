import React, { useState, useEffect } from "react";
import { Button, Layout, Popconfirm, Table, Tag, Tooltip } from "antd";
import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import "antd/dist/antd.css";
import moment from "moment";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const SmartAccessDoorListTable = (props) => {
  const { t } = useTranslation();
  const tableIDName = "sa_door_id";
  let {
    dataObj,
    setModalVisible,
    setSelectedRecord,
    loading,
    isActiveChangeCallBack,
    isDeleteCallBack,
  } = props;
  const [pageSize, setPageSize] = useState(100);
  const app = useSelector((state) => state.app);

  useEffect(() => {}, [dataObj]);

  const setTableHeader = () => {
    const columns = [
      {
        title: t("operation"),
        dataIndex: tableIDName,
        render: (value, record, key) => {
          return (
            <span>
              <Tooltip title={t("edit")}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4 }}
                  icon={<EditOutlined />}
                  onClick={() => {
                    setModalVisible(true);
                    setSelectedRecord(record);
                  }}
                />
              </Tooltip>
              <Popconfirm
                title={
                  record.is_active
                    ? t("confirm_deactivate")
                    : t("confirm_activate")
                }
                onConfirm={() => {
                  let is_active = record.is_active ? 0 : 1;
                  isActiveChangeCallBack(record.sa_door_id, is_active);
                }}
                okText={t("yes")}
                cancelText={t("no")}
              >
                <Tooltip
                  title={record.is_active ? t("deactivate") : t("activate")}
                >
                  <Button
                    shape="circle"
                    style={{ marginRight: 4 }}
                    icon={
                      record.is_active ? (
                        <CloseCircleOutlined />
                      ) : (
                        <CheckCircleOutlined />
                      )
                    }
                  />
                </Tooltip>
              </Popconfirm>
              <Popconfirm
                title={t("confirm_delete") + "?"}
                onConfirm={() => isDeleteCallBack(record.sa_door_id)}
                okText={t("yes")}
                cancelText={t("no")}
              >
                <Tooltip title={t("delete")}>
                  <Button
                    shape="circle"
                    style={{ marginRight: 4 }}
                    icon={<DeleteOutlined />}
                  />
                </Tooltip>
              </Popconfirm>
            </span>
          );
        },
      },
      {
        title: t("sa_door_id"),
        dataIndex: "sa_door_id",
        sorter: (a, b) => a.sa_door_id - b.sa_door_id,
      },
      {
        title: t("active_status"),
        dataIndex: "is_active",
        render: (value) => displayIsActive(value),
        sorter: (a, b) => a.is_active - b.is_active,
      },
      {
        title: t("item_name"),
        dataIndex: "item_name",
        sorter: (a, b) => a.item_name.localeCompare(b.item_name),
      },
      {
        title: t("door_ref"),
        dataIndex: "door_ref",
        sorter: (a, b) => a.door_ref.localeCompare(b.door_ref),
      },
      {
        title: t("utime"),
        dataIndex: "utime",
        sorter: (a, b) => a.utime - b.utime,
        render: (value) => moment.unix(value).format("YYYY-MM-DD HH:mm"),
      },
      {
        title: t("create_time"),
        dataIndex: "ctime",
        sorter: (a, b) => a.ctime - b.ctime,
        render: (value) => moment.unix(value).format("YYYY-MM-DD HH:mm"),
      },
    ];
    return columns;
  };

  const displayIsActive = (value) => {
    let displayStr = "";
    let tagColor = "blue";
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("active");
        tagColor = "blue";
        break;
      case 0:
        displayStr = t("inactive");
        tagColor = "red";
        break;
      default:
        displayStr = t("error");
        tagColor = "red";
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  };

  return (
    <Layout>
      <Table
        rowKey={tableIDName}
        scroll={{ x: "max-content" }}
        dataSource={dataObj}
        columns={setTableHeader()}
        bordered
        size="small"
        pagination={{ pageSize }}
        loading={loading}
        onChange={(paginate) => {
          setPageSize(paginate.pageSize);
        }}
        locale={{ triggerDesc: t('sort_descend'), triggerAsc: t('sort_ascend'), cancelSort: t('cancel_sort') }}
      />
    </Layout>
  );
};

export default SmartAccessDoorListTable;
