import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Divider, notification } from "antd";
import moment from "moment";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import Button from "../../../t9UIComponents/t9Button";
import * as Service from "../../../core/Service";
import * as Main from "../../../core/Main";
import NavAndSideFrame from "../../../components/NavAndSideFrame";
import CompanyAdminListTable from "./CompanyAdminListTable";

const debug = require("debug")("app:admin:client:src:AdvertisementList");

const involvedModelName = "admin";
const CompanyAdminList = (props) => {
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const selectedKey = "admin_company_admin_list"
  const openKeys = "admin_setting"
  const title = t("company_user_list");

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    setLoading(true);
    let companyUserList = [];
    try {
      let data = await Service.call("get", "/api/admin/company/admin/list");
      let resp = await Service.call("get", "/api/admin/company/list");
      let { companyArr } = resp;
      let companyArrMap = _.keyBy(companyArr, "company_id");

      _.each(data, (rec, i) => {
        data[i].company_name = !_.isUndefined(companyArrMap[rec.company_id])
          ? companyArrMap[rec.company_id].company_name
          : "";
      });
      companyUserList = data;
    } catch (error) {
      console.error("error >>> ", error);
    } finally {
      setDataList(companyUserList);
      setLoading(false);
    }
  };

  const handleDeleteRecord = async (deleteRecord) => {
    setLoading(true);
    let postData = {
      company_user_id: deleteRecord,
    };
    let submit = await Service.call(
      "post",
      "/api/admin/company/user/delete",
      postData
    );
    if (submit.status < 1) {
      notification.error({
        message: t(`fail`),
        description: t(submit.errorCode),
      });
      setLoading(false);
    } else {
      notification.success({
        message: t("success"),
      });
      getAllData();
      setLoading(false);
    }
  };

  const handleIsActiveChange = async (company_admin_id, value) => {
    setLoading(true);
    let postData = {
      company_admin_id,
      is_active: value,
    };
    let submit = await Service.call(
      "patch",
      "/api/admin/company/admin/is_active",
      postData
    );
    if (submit.status < 1) {
      notification.error({
        message: t(`fail`),
        description: t(submit.errorCode),
      });
      setLoading(false);
    } else {
      notification.success({
        message: t("success"),
      });
      getAllData();
      setLoading(false);
    }
  };

  return (
    <NavAndSideFrame title={title} selectedKey={selectedKey} openKeys={openKeys}>
      <Link
        to={{
          pathname: "/admin/company/admin/form",
          search: "?id=0",
        }}
      >
        <Button type="primary">{t("add_company_user")}</Button>
      </Link>
      <Divider />
      <CompanyAdminListTable
        dataObj={dataList}
        openKeys
        adminChangeData={getAllData}
        deleteRecord={(record) => handleDeleteRecord(record)}
        isActiveChangeCallBack={(company_admin_id, value) => {
          handleIsActiveChange(company_admin_id, value);
        }}
        loading={loading}
      />
    </NavAndSideFrame>
  );
};

export default CompanyAdminList;
