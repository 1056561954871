import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Button, Form, Input, message } from "antd";
import { useTranslation } from "react-i18next";
import * as Service from "../../../core/Service";
import TinymceWrapper from "../../../components/TinymceWrapper";

const CompanyEmailForm = (props) => {
  const { dataObj } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [bodyContent, setBodyContent] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    form.resetFields();
    setBodyContent(dataObj.body_content);
  }, [dataObj]);

  const getDefaultTemplate = async () => {
    const resp = await Service.call(
      "get",
      `/api/email/default_template/${dataObj.email_type}`
    );
    setBodyContent(resp.data.templateRc);
  };

  const onFinish = async (postObj) => {
    try {
      setLoading(true);
      // Patch
      let url = `/api/email`;
      let resp = await Service.call("patch", url, {
        ...postObj,
        body_content: bodyContent,
      });
      if (resp.errorMessage) {
        message.error(resp.errorMessage);
        return props.openModal(true);
      }
      message.success("success");
      props.openModal(false);
      props.adminChangeData();
      return;
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      // {...formItemLayout}
      form={form}
      name="time_related_controls"
      layout="vertical"
      onFinish={onFinish}
      initialValues={dataObj}
    >
      <Form.Item name="company_email_id" hidden />
      <Form.Item name="email_type" hidden />
      <Form.Item
        label="Subject"
        name="subject"
        rules={[{ required: false, message: "Please input name." }]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Button
          onClick={() => {
            getDefaultTemplate();
          }}
          type="primary"
        >
          {t("apply_default_template")}
        </Button>
      </Form.Item>
      <Form.Item label="Body Content">
        <TinymceWrapper
          style={{ height: 400 }}
          initialValue={bodyContent}
          onChange={(value) => {
            setBodyContent(value);
          }}
        />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" disabled={loading}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CompanyEmailForm;
