import React, { useState, useEffect, useRef } from "react";
import {
  Divider,
  notification,
  Modal,
  Row,
  Col,
  DatePicker,
  Select,
  Input
} from "antd";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import moment from "moment";
import NavAndSideFrame from "../../../components/NavAndSideFrame";
import OrderInfoTable from "./OrderInfoTable";
import useFetch from "../../../hooks/useFetch";
import OrderApprovementDocument from "./OrderApprovementDocument";
import useQuery from "../../../hooks/useQuery";

const debug = require("debug")("app:admin:client:src:AdvertisementList");

const selectedKey = "order_list";
const openKeys = "sale_and_operations";

const { RangePicker } = DatePicker;
const { Option } = Select;

const PAGINATION_SIZE = 10;

const OrderInfoList = (props) => {
  const query = useQuery();
  const queryOrderId = +query.get("order_id");
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [selectedField, setSelectedField] = useState({
    user: null,
    date: [
      moment().subtract(1, "M").startOf("d"),
      moment(),
    ],
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [dataList, setDataList, refresh, setRefresh, rawDataList] = useFetch({
    route: "/api/order/list",
    whereClause: {
      start_time: _.head(selectedField.date).unix(),
      end_time: _.last(selectedField.date).unix(),
      offset: (currentPage - 1) * PAGINATION_SIZE,
      company_user_id: selectedField.user,
    }
  });

  const { t } = useTranslation();

  const title = t("order_list");

  useEffect(() => {
    if (queryOrderId) {
      setSelectedRecord({ order_id: queryOrderId });
      setModalVisible(true);
    }
  }, [queryOrderId]);

  const onChange = (val) => {
    const orderList = _.filter(rawDataList, (order) => {
      _.includes(val, [order.orderKey, order.address, order.nickname])
      if (_.includes(order.orderKey, val) || _.includes(order.address, val) || _.includes(order.nickname, val)) return true;
      return false;
    });
    setDataList(orderList);
  };

  return (
    <NavAndSideFrame title={title} selectedKey={selectedKey} openKeys={openKeys}>
      <Row gutter={[50, 20]}>
        <Col>
          <span>{`${t("date")}：`}</span>
          <RangePicker
            allowClear={false}
            allowEmpty={false}
            placeholder={[t("start"), t("end")]}
            value={selectedField.date}
            onChange={(dates) => {
              setSelectedField({
                ...selectedField,
                date: dates,
              });
              setCurrentPage(1);
              setRefresh(true);
            }}
          />
        </Col>
        <Col style={{ display: "flex", alignItems: "center", marginLeft: 10 }}>
          <div>{`${t("search_keywords")}:`}</div>
          <div style={{ marginLeft: 20 }}>
            <Input
              placeholder={t("search_keywords")}
              style={{ minWidth: 270, width: "50%" }}
              onChange={(values) => {
                onChange(values.target.value);
              }}
            />
          </div>
        </Col>
      </Row>
      <Divider />
      <OrderInfoTable
        dataObj={dataList}
        adminChangeData={() => {
          setRefresh(true);
        }}
        setLoading={setLoading}
        setSelectedRecord={(record) => setSelectedRecord(record)}
        setModalVisible={(value) => setModalVisible(value)}
        loading={loading || refresh}
        currentPage={currentPage}
        setCurrentPage={(page) => {
          setCurrentPage(page);
          setRefresh(true);
        }}
        pagination_size={PAGINATION_SIZE}
      />
      <Modal
        title={<div style={{ margin: "0 0 0 40px", fontWeight: "bolder" }}>{t("order_info")}</div>}
        footer={null}
        visible={modalVisible}
        bodyStyle={{ margin: "0 30px" }}
        width={1200}
        onCancel={() => {
          setModalVisible(false);
          setSelectedRecord({});
        }}
        destroyOnClose
      >
        <OrderApprovementDocument
          selectedRecord={selectedRecord}
          openModal={(value) => {
            setModalVisible(value);
          }}
          modalVisible={modalVisible}
        />
      </Modal>
    </NavAndSideFrame>
  );
};

export default OrderInfoList;
