import React, { useState, useEffect } from "react";
import { Divider, notification } from "antd";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import Button from "../../../t9UIComponents/t9Button";
import * as Service from "../../../core/Service";
import NavAndSideFrame from "../../../components/NavAndSideFrame";
import ProductCategoryInfoListTable from "./ProductCategoryInfoListTable";
import ProductCategoryInfoModal from "./ProductCategoryInfoModal";

const involvedModelName = "company";
const selectedKey = "product_category_management";
const openKeys = "product"
const tableIDName = "product_category_id";

const ProductCategoryInfoList = (props) => {
  const [dataList, setDataList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const title = t("product_category_management");
  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    setLoading(true);
    try {
      let url = "/api/product_category/list";
      let resp = await Service.call("get", url);
      if (!resp || resp.status !== 1) {
        return notification.error({
          message: t("error"),
          description: t(`${resp.errorCode}`),
        });
      }
      setDataList(resp.data);
    } catch (error) {
      console.error("error >>> ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFormSubmit = async (submitRecord) => {
    try {
      let method = submitRecord.product_category_id === 0 ? "put" : "patch";
      let resp = await Service.call(method, "/api/product_category", submitRecord);
      if (resp.status < 1) {
        return notification.error({
          message: t("fail"),
          description: t(`${resp.errorCode}`),
        });
      }
      notification.success({
        message: t("success"),
      });
      getAllData();
      setModalVisible(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteRecord = async (deleteRecord) => {
    setLoading(true);
    let postData = {
      product_category_id: deleteRecord,
    };
    let submit = await Service.call(
      "post",
      "/api/product_category/delete",
      postData
    );
    if (submit.status < 1) {
      notification.error({
        message: t("fail"),
        description: t(`${submit.errorCode}`),
      });
      setLoading(false);
    } else {
      notification.success({
        message: t("success"),
      });
      getAllData();
      setLoading(false);
      setModalVisible(false);
    }
  };

  const handleIsActiveChange = async (product_category_id, value) => {
    setLoading(true);
    let postData = {
      product_category_id,
      is_active: value,
    };
    let submit = await Service.call(
      "patch",
      "/api/product_category/is_active",
      postData
    );
    if (submit.status < 1) {
      notification.error({
        message: t("fail"),
        description: t(`${submit.errorCode}`),
      });
      setLoading(false);
    } else {
      notification.success({
        message: t("success"),
      });
      getAllData();
      setLoading(false);
      setModalVisible(false);
    }
  };

  return (
    <NavAndSideFrame title={title} selectedKey={selectedKey} openKeys={openKeys}>
      <Button
        type="primary"
        onClick={() => {
          setModalVisible(true);
          setSelectedRecord({ product_category_id: 0 });
        }}
      >
        {t("add_category")}
      </Button>
      <Divider />
      <ProductCategoryInfoListTable
        dataObj={dataList}
        adminChangeData={getAllData}
        setSelectedRecord={(record) => setSelectedRecord(record)}
        deleteRecord={(product_category_id) => handleDeleteRecord(product_category_id)}
        setModalVisible={(value) => setModalVisible(value)}
        isActiveChangeCallBack={(product_category_id, value) => {
          handleIsActiveChange(product_category_id, value);
        }}
        loading={loading}
      />
      <ProductCategoryInfoModal
        selectedRecord={selectedRecord}
        categoryList={dataList}
        modalVisible={modalVisible}
        setModalVisible={(value) => {
          setModalVisible(value);
        }}
        handleFormSubmit={(submitRecord) => handleFormSubmit(submitRecord)}
      />
    </NavAndSideFrame>
  );
};

export default ProductCategoryInfoList;
