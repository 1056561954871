import React, { Component, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import {
  Avatar, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, Row, Col, Select, notification
} from 'antd';
import {
  EditOutlined,
  FileTextOutlined,
  KeyOutlined,
  QrcodeOutlined,
  BankOutlined,
} from '@ant-design/icons';
// import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import Button from '../../../t9UIComponents/t9Button';
import * as Service from '../../../core/Service';
import * as Main from '../../../core/Main';
import NavAndSideFrame from '../../../components/NavAndSideFrame';
import ContactUsListTable from './ContactUsListTable';



const debug = require('debug')('app:admin:client:src:ContactUsList');

const involvedModelName = "company";

const tableIDName = "contact_us_record_id";

const ContactUsList = (props) => {
  const [loading, setLoading] = useState(true);
  const [dataList, setDataList] = useState([]);
  const { t } = useTranslation()
  const [selectedRecord, setSelectedRecord] = useState(0);

  const title = t('contact_us');

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    setLoading(true);
    let result = await Service.call('get', '/api/contact_us/list');
    if (result.status !== 1) {
      setLoading(false);
      return notification.error({
        message: t('error'),
        description: t(`${result.errorCode}`),
      });
    }
    let { data } = result;
    setDataList(data);
    setLoading(false);
  }

  return (
    <NavAndSideFrame title={title}>
      <ContactUsListTable
        dataObj={dataList}
        adminChangeData={getAllData}
        setSelectedRecord={(record) => setSelectedRecord(record)}
        loading={loading}
      />
    </NavAndSideFrame>
  )
}

export default ContactUsList;
