import React, { useState, useEffect } from "react";
import { Divider, notification, Modal } from "antd";
import { useTranslation } from "react-i18next";
import Button from "../../../t9UIComponents/t9Button";
import * as Service from "../../../core/Service";
import NavAndSideFrame from "../../../components/NavAndSideFrame";
import CompanyAdminTable from "./CompanyAdminTable";
import CompanyAdminModal from "./CompanyAdminModal";

const CompanyAdminList = (props) => {
  const [dataList, setDataList] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const selectedKey = "company_admin_list";
  const openKeys = "admin_setting_company";
  const title = t("company_admin_list");

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    try {
      setLoading(true);
      let url = "/api/company/admin/list";
      let resp = await Service.call("get", url);

      if (resp.status !== 1 || !resp) {
        return notification.error({
          message: t("error"),
          description: t(resp.errorCode),
        });
      }
      setDataList(resp.data);
    } catch (error) {
      console.error("error >>> ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFormSubmit = async (submitRecord) => {
    try {
      setLoading(true);
      let formData = {
        nickname: submitRecord.nickname,
        first_name: submitRecord.first_name,
        last_name: submitRecord.last_name,
        password: submitRecord.password,
        email: submitRecord.email,
        mobile: submitRecord.mobile,
        username: submitRecord.username,
        is_verified_email: submitRecord.is_verified_email,
        is_verified_mobile: submitRecord.is_verified_mobile,
        gender: submitRecord.gender ? submitRecord.gender : "M",
        company_admin_role_id: submitRecord.company_admin_role_id,
      };
      let method = "put";

      if (submitRecord.company_admin_id > 0) {
        method = "patch";
        formData = {
          nickname: submitRecord.nickname,
          first_name: submitRecord.first_name,
          last_name: submitRecord.last_name,
          email: submitRecord.email,
          mobile: submitRecord.mobile,
          is_verified_email: submitRecord.is_verified_email,
          is_verified_mobile: submitRecord.is_verified_mobile,
          gender: submitRecord.gender,
          company_admin_role_id: submitRecord.company_admin_role_id,
          company_admin_id: submitRecord.company_admin_id
        };
      }
      const resp = await Service.call(
        method,
        "/api/company/admin",
        formData
      );
      if (resp.status < 1) {
        return notification.error({
          message: t("fail"),
          description: t(resp.errorCode),
        });
      }
      notification.success({
        message: t("success"),
      });
      getAllData();
      setModalVisible(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleIsActiveChange = async (company_admin_id, value) => {
    setLoading(true);
    let postData = {
      company_admin_id,
      is_active: value,
    };
    let submit = await Service.call(
      "patch",
      "/api/company/admin/active",
      postData
    );
    if (submit.status < 1) {
      notification.error({
        message: t("fail"),
      });
      setLoading(false);
    } else {
      notification.success({
        message: t("success"),
      });
      getAllData();
      setLoading(false);
      setModalVisible(false);
    }
  };

  const handleIsDeleteChange = async (company_admin_id) => {
    setLoading(true);
    let postData = {
      company_admin_id,
      is_active: 0
    };
    let submit = await Service.call(
      "delete",
      "/api/company/admin",
      postData
    );
    if (submit.status < 1) {
      notification.error({
        message: t("fail"),
      });
      setLoading(false);
    } else {
      notification.success({
        message: t("success"),
      });
      getAllData();
      setLoading(false);
      setModalVisible(false);
    }
  };

  return (
    <NavAndSideFrame
      title={title}
      selectedKey={selectedKey}
      openKeys={openKeys}
    >
      <Button
        type="primary"
        onClick={() => {
          setSelectedRecord({ company_admin_id: 0 });
          setModalVisible(true);
        }}
      >
        {t("add_admin")}
      </Button>
      <Divider />
      <CompanyAdminTable
        dataObj={dataList}
        adminChangeData={getAllData}
        setSelectedRecord={(record) => setSelectedRecord(record)}
        setModalVisible={(value) => setModalVisible(value)}
        isActiveChangeCallBack={handleIsActiveChange}
        isDeleteChangeCallBack={handleIsDeleteChange}
        loading={loading}
      />
      <Modal
        title={t("company_admin")}
        footer={null}
        visible={modalVisible}
        width={1200}
        onCancel={() => setModalVisible(false)}
      >
        <CompanyAdminModal
          dataObj={dataList}
          selectedRecord={selectedRecord}
          setModalVisible={setModalVisible}
          handleFormSubmit={handleFormSubmit}
        />
      </Modal>
    </NavAndSideFrame>
  );
};

export default CompanyAdminList;
