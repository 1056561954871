import React, { useState, useEffect } from "react";
import {
  Button, Layout, Popconfirm, Table, Tag, Tooltip
} from "antd";
import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import "antd/dist/antd.css";
import moment from "moment";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ItemInfoListTable = (props) => {
  const { t } = useTranslation();
  const tableIDName = "item_id";
  let {
    dataObj,
    loading,
    deleteRecord,
    isActiveChangeCallBack,
    bookingItemCategoryRefArr,
    bookingItemCategoryArr,
    itemRulesetRefArr,
    bookingItemTimeSlotMap,
  } = props;


  const setTableHeader = () => {
    const columns = [
      {
        title: t("operation"),
        dataIndex: tableIDName,
        render: (value, record, key) => {
          return (
            <span>
              <Tooltip title={t("edit")}>
                <Link to={{
                  pathname: "/company/booking/item/form",
                  state: {
                    selectedRecord: record
                  }
                }}
                >
                  <Button
                    shape="circle"
                    style={{ marginRight: 4 }}
                    icon={<EditOutlined />}
                  />
                </Link>
              </Tooltip>
              <Popconfirm
                title={
                  record.is_active
                    ? t("confirm_deactivate")
                    : t("confirm_activate")
                }
                onConfirm={() => {
                  let is_active = record.is_active ? 0 : 1;
                  isActiveChangeCallBack(record.booking_item_id, is_active);
                }}
                okText={t("yes")}
                cancelText={t("no")}
              >
                <Tooltip
                  title={record.is_active ? t("deactivate") : t("activate")}
                >
                  <Button
                    shape="circle"
                    style={{ marginRight: 4 }}
                    icon={
                      record.is_active ? (
                        <CloseCircleOutlined />
                      ) : (
                        <CheckCircleOutlined />
                      )
                    }
                  />
                </Tooltip>
              </Popconfirm>
              <Popconfirm
                title={t("confirm_delete") + "?"}
                onConfirm={() => deleteRecord(record.booking_item_id)}
                okText={t("yes")}
                cancelText={t("no")}
              >
                <Tooltip title={t("delete")}>
                  <Button
                    shape="circle"
                    style={{ marginRight: 4 }}
                    icon={<DeleteOutlined />}
                  />
                </Tooltip>
              </Popconfirm>
            </span>
          );
        },
      },
      {
        title: t("id"),
        dataIndex: "booking_item_id",
        sorter: (a, b) => a.booking_item_id.localeCompare(b.booking_item_id),
      },
      {
        title: t("name"),
        dataIndex: "item_name",
      },
      // {
      //   title: t("category_name"),
      //   dataIndex: "booking_item_id",
      //   render: (value, record) => {
      //     const bookingCategoryRef = bookingItemCategoryRefArr[value];
      //     if (!bookingCategoryRef) {
      //       return "-";
      //     }
      //     return _.map(bookingCategoryRef, (eachRef) => {
      //       const { booking_category_id } = eachRef;
      //       const categoryDetail = bookingItemCategoryArr[booking_category_id];
      //       if (categoryDetail) {
      //         // TODO: on click tag will go to edit category page
      //         return <Tag color="blue">{categoryDetail.category_name}</Tag>;
      //       }
      //     });
      //   }
      // },
      {
        title: t("ruleset"),
        dataIndex: "booking_item_id",
        render: (value) => {
          const bookingItemTimeslotRef = itemRulesetRefArr[value];
          if (!bookingItemTimeslotRef) {
            return "-";
          }

          return _.map(bookingItemTimeslotRef, (eachRef) => {
            const { booking_item_timeslot_id } = eachRef;
            const timeslotDetail = bookingItemTimeSlotMap[booking_item_timeslot_id];
            if (timeslotDetail) {
              // TODO: on click tag will go to edit timeslot page
              return <Tag color="blue">{timeslotDetail.name}</Tag>;
            }
          });
        }
      },
      {
        title: t("active_status"),
        dataIndex: "is_active",
        render: (value) => displayIsActive(value),
        sorter: (a, b) => a.is_active - b.is_active,
      },
      {
        title: t("update_time"),
        dataIndex: "utime",
        sorter: (a, b) => a.ctime - b.ctime,
        render: (value) => moment.unix(value).format("YYYY-MM-DD HH:mm"),
      },
      {
        title: t("create_time"),
        dataIndex: "ctime",
        sorter: (a, b) => a.ctime - b.ctime,
        render: (value) => moment.unix(value).format("YYYY-MM-DD HH:mm"),
      },
    ];
    return columns;
  };

  const displayIsActive = (value) => {
    let displayStr = "";
    let tagColor = "blue";
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("active");
        tagColor = "blue";
        break;
      case 0:
        displayStr = t("inactive");
        tagColor = "red";
        break;
      default:
        displayStr = t("error");
        tagColor = "red";
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  };
  const displayCategoryName = (value) => {
    let displayStr = "";
    let tagColor = "green";
    let statusValue = value;
    switch (statusValue) {
      case "Multi-Function Rooms":
        displayStr = t("multi_function_rooms");
        tagColor = "blue";
        break;
      case "Co-working Space":
        displayStr = t("co_working_space");
        tagColor = "blue";
        break;
      case "Meeting Rooms":
        displayStr = t("meeting_rooms");
        tagColor = "blue";
        break;
      case "Private Office":
        displayStr = t("private_office");
        tagColor = "blue";
        break;
      default:
        displayStr = t("error");
        tagColor = "#f50";
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  };

  return (
    <Layout>
      <Table
        rowKey={tableIDName}
        scroll={{ x: "max-content" }}
        dataSource={dataObj.bookingItemArr}
        columns={setTableHeader()}
        bordered
        size="small"
        pagination={{ pageSize: 100 }}
        loading={loading}
        locale={{
          triggerDesc: t("sort_descend"),
          triggerAsc: t("sort_ascend"),
          cancelSort: t("cancel_sort"),
        }}
      />
    </Layout>
  );
};
export default ItemInfoListTable;
