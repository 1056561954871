import React, { Component, useState, useEffect } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin, message } from "antd";
import debugLib from "debug";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {
  setConfig, setAdmin, setAuth, setCompanyAdmin, setCompanyUser, setUser, setCompany, setIsAdmin, setIsCompanyUser, setFloorList
} from "./redux/actions/common";
import * as Service from "./core/Service";
import AppRoute from "./AppRoute";
// import * as Service from './core/Service';

const debug = debugLib("app:AppContainer");

debugLib.enable("app:*");

let configOrigin = "";

message.config({
  top: "75%",
  maxCount: 1,
});

const AppContainer = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    // setLoading(true);

    let allPermission = [];
    let resp = await Service.call("get", "/api/config");
    if (resp && resp.status > 0) {
      dispatch(setConfig(resp));
      allPermission = resp.allPermission;
    } else {
      throw new Error("failed to get app config");
    }

    // resp = await Service.call('get', `/api/admin`);
    // if (resp && resp.status > 0) {
    //   resp.userData[0].isAdmin = true
    //   // debug('resp.userData>>>', resp.userData)
    //   dispatch(setAdmin(resp.userData[0]));
    //   dispatch(setIsAdmin(true))
    //   dispatch(setAuth(true));
    //   setLoading(false);
    //   return;
    // }

    // resp = await Service.call('get', `/api/login/get_company_admin`);
    // if (resp && resp.status > 0) {
    //   dispatch(setCompanyAdmin(resp.userData));
    //   dispatch(setAuth(true));
    //   setLoading(false);
    //   return;
    // }

    resp = await Service.call("get", `/api/login/get_company_admin`);
    if (resp && resp.status > 0) {
      // resp.userData[0].isCompanyUser = true;
      dispatch(setCompanyAdmin(resp.userData));
      dispatch(setAuth(true));

      // Set Permission By CSS
      let myPermission = resp.permissionList;
      if (!_.includes(myPermission, "p_any")) {
        const missingPermission = _.difference(allPermission, myPermission);
        console.log(missingPermission);
        const el = document.getElementsByTagName("body")[0];
        _.each(missingPermission, (perm) => {
          el.classList.add(`${perm}_none`);
        });
      }

      let companyInfo = await Service.call("get", "/api/company/current");
      // console.log('companyInfo>>', companyInfo)
      dispatch(setFloorList(companyInfo?.data?.floorList));
      dispatch(setCompany(companyInfo.data));

      setLoading(false);
      return;
    }

    // resp = await Service.call('get', `/api/login/get_user`);
    // if (resp && resp.status > 0) {
    //   dispatch(setUser(resp.userData));
    //   dispatch(setAuth(true));
    //   setLoading(false);
    //   return;
    // }
    dispatch(setIsAdmin(false));
    dispatch(setAuth(false));
    setLoading(false);
  };

  if (loading) {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return (
      <div style={{position: "absolute", top: "50%", left: "50%"}}>
        <Spin indicator={antIcon} />
      </div>
    );
  }

  return (
    <div>
      <AppRoute />
    </div>
  );
};

export default AppContainer;
