import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import "antd/dist/antd.css";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import * as Service from "../../../core/Service";

import NavAndSideFrame from "../../../components/NavAndSideFrame";
import UserAccountSettingResetPasswordForm from "../../../components/UserAccountSettingResetPasswordForm";
import CompanyAdminForm from "./CompanyAdminForm";

const { TabPane } = Tabs;
const selectedKey = "company_account_setting"
const openKeys = "admin_setting_company"
const CompanyAdminContainer = (props) => {
  const { t } = useTranslation();

  const [userObj, setUser] = useState({});

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = async () => {
    let result = [];
    try {
      result = await Service.call("get", `/api/company/admin/current`);
      setUser(result);
    } catch (error) {
      console.error("error >>> ", error);
    }
  };

  return (
    <NavAndSideFrame
      {...props}
      title={`${t("account_setting")}`}
      selectedKey={selectedKey}
      openKeys={openKeys}
    >
      <Tabs type="line" defaultActiveKey="user_form" onChange={(key) => null}>
        <TabPane tab={t("user_info")} key="user_form">
          <CompanyAdminForm initialValues={userObj} />
        </TabPane>
        <TabPane tab={t("edit_password")} key="userPassword">
          <UserAccountSettingResetPasswordForm initialValues={userObj} />
        </TabPane>
      </Tabs>
    </NavAndSideFrame>
  );
};

export default CompanyAdminContainer;
