import React, { useState, useEffect } from "react";
import { Button, Divider, Table, Tooltip, Tag } from "antd";
import { EditOutlined, FileExcelOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import NavAndSideFrame from "../../components/NavAndSideFrame";
import * as Service from "../../core/Service";
import T9Button from "../../t9UIComponents/t9Button";

const selectedKey = "membership_plan";
const openKeys = "membership";

const MembershipList = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [dataList, setDataList] = useState([]);
  const history = useHistory();

  const tableIDName = "membership_plan_id";

  const getAllData = async () => {
    setLoading(true);
    try {
      let resp = await Service.call("get", "/api/membership/list");
      if (resp.status === 1) {
        const { membershipPlanList } = resp.data;
        console.log(membershipPlanList);
        setDataList(membershipPlanList);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  const setTableHeader = () => {
    const columns = [
      {
        title: t("operation"),
        dataIndex: tableIDName,
        key: "membership_plan_id",
        render: (value, record, index) => {
          return (
            <span>
              <Tooltip title={t("edit")}>
                <Button
                  shape="circle"
                  icon={<EditOutlined />}
                  style={{ marginRight: 8 }}
                  onClick={() => {
                    history.push("/company/membership/form", record);
                    // setSelectedRecord(record);
                    // setModalVisible(true)
                  }}
                />
              </Tooltip>
            </span>
          );
        },
      },
      {
        title: t("is_active"),
        dataIndex: "is_active",
        key: "is_active",
        render: (value) => displayIsActive(value),
      },
      {
        title: t("name"),
        dataIndex: "name",
        key: "name",
      },
      {
        title: t("description"),
        dataIndex: "description",
        key: "description",
      },
      {
        title: t("price"),
        dataIndex: "price",
        key: "price",
      },
      {
        title: t("level"),
        dataIndex: "level",
        key: "level",
        render: (value) => {
          if (!value) {
            return "-";
          }
          return value;
        },
      },
    ];
    // if (!disableEdit) {
    //   columns.unshift();
    // }
    return columns;
  };

  const displayIsActive = (value) => {
    let displayStr = "";
    let tagColor = "blue";
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("active");
        tagColor = "blue";
        break;
      case 0:
        displayStr = t("inactive");
        tagColor = "red";
        break;
      default:
        displayStr = t("error");
        tagColor = "red";
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  };

  return (
    <NavAndSideFrame title={t("membership")} selectedKey={selectedKey} openKeys={openKeys}>
      <T9Button
        type="primary"
        onClick={() => history.push("/company/membership/form", { membership_plan_id: 0 })}
      >
        {t("create")}
      </T9Button>
      <Divider />
      <Table
        rowKey={tableIDName}
        scroll={{ x: "max-content" }}
        loading={loading}
        dataSource={dataList}
        columns={setTableHeader()}
        defaultSelectedKeys={selectedKey}
        openKeys={openKeys}
      />
    </NavAndSideFrame>
  );
};

export default MembershipList;
