import { Button, Checkbox, Col, Modal, Row, Space } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { MailOutlined } from "@ant-design/icons";
import { useState } from "react";

const SendEmailPopup = ({ modalVisible, setModalVisible, selectedUsers }) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);

  const handleSendEmail = () => {
    // fetch api
    console.log({ options, selectedUsers});
  };

  const selectOptions = [
    {
      label: (
        <Space>
          <MailOutlined />
          {t("invitation_mail_to_selected_user")}
        </Space>
      ),
      value: "invitation",
    },
    {
      label: (
        <Space>
          <MailOutlined />
          {t("reminder_mail_to_selected_user")}
        </Space>
      ),
      value: "reminder",
    },
  ];
  return (
    <Modal
      title={t("send_mail")}
      centered
      visible={modalVisible}
      footer={false}
      onOk={handleSendEmail}
      onCancel={() => setModalVisible(false)}
      className="send-email-modal"
      style={{maxWidth: 352}}
    >
      <Checkbox.Group options={selectOptions} onChange={setOptions} />
      <Row gutter={16} justify="end" style={{marginTop: 32}}>
        <Col>
          <Button type="primary" ghost className="action-btn" onCancel={() => setModalVisible(false)}>
            {t("cancel")}
          </Button>
        </Col>
        <Col>
          <Button type="primary" className="action-btn">{t("confirm")}</Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default SendEmailPopup;
