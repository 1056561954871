import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Card,
  Tooltip,
  Select,
  Switch,
  notification,
} from "antd";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Service from "../../../core/Service";

const { Option } = Select;

const CompanyAdminIcInfoForm = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { initialValues } = props;
  const [UserForm] = Form.useForm();
  const [number, setNumber] = useState("");
  const [companyOptions, setCompanyOptions] = useState([]);
  const [companyAdminRoleOptions, setCompanyAdminRoleOptions] = useState([]);

  useEffect(() => {
    getCompanyList();
    UserForm.resetFields();
    let [mobile_prefix, mobile] = _.split(initialValues.mobile, "-");
    setNumber(mobile);
    initialValues.mobile_prefix = mobile_prefix;
    initialValues.mobile = mobile;
    getCompanyAdminRoleList(initialValues.company_id);
    UserForm.setFieldsValue(initialValues);
  }, [initialValues]);

  const getCompanyList = async () => {
    let companyList = [];
    try {
      let resp = await Service.call("get", "/api/admin/company/list");
      let { companyArr } = resp;

      _.each(companyArr, (rec) => {
        companyList.push(
          <Option value={rec.company_id}>{rec.company_name}</Option>
        );
      });
    } catch (error) {
      console.log(error);
    } finally {
      setCompanyOptions(companyList);
    }
  };

  const getCompanyAdminRoleList = async (id) => {
    console.log("getCompanyAdminRoleList id", id);
    let companyAdminRoleList = [];
    try {
      let resp = await Service.call(
        "get",
        `/api/admin/company/${id}/admin_role`
      );
      _.each(resp, (rec) => {
        companyAdminRoleList.push(
          <Option value={rec.company_admin_role_id}>{rec.role_name}</Option>
        );
      });
    } catch (error) {
      console.log(error);
    } finally {
      setCompanyAdminRoleOptions(companyAdminRoleList);
    }
  };

  const onNumberChange = (e) => {
    const { value } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if ((!_.isNaN(value) && reg.test(value)) || value === "" || value === "-") {
      setNumber(value);
      UserForm.setFieldsValue({ mobile: value });
    }
  };

  const onFinish = async (formData) => {
    formData.mobile = `${formData.mobile_prefix}-${formData.mobile}`;
    if (!_.isUndefined(initialValues.company_admin_id)) {
      let patchData = { ...initialValues, ...formData };
      let response = await Service.call(
        "patch",
        `/api/admin/company/admin`,
        patchData
      );
      // console.log("response>>", response)
      if (response.status === -1) {
        notification.error({
          message: t(`error`),
          description: t(response.errorCode),
        });
        return;
      }
      notification.success({
        message: t("success"),
      });
    } else {
      let response = await Service.call(
        "post",
        `/api/admin/company/admin`,
        formData
      );
      // console.log("response>>", response)
      if (response.status === -1) {
        notification.error({
          message: t(`error`),
          description: t(response.errorCode),
        });
        return;
      }
      notification.success({
        message: t("success"),
      });
    }
    history.push("/admin/company/admin/list");
  };

  return (
    <Form
      form={UserForm}
      name="UserForm"
      layout="vertical"
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <Row gutter={[24, 24]}>
        <Col md={24} lg={16}>
          <Card>
            <Form.Item
              label={t("company_name")}
              name="company_id"
              rules={[
                {
                  required: true,
                  message: t("select_required"),
                },
              ]}
            >
              <Select
                disabled={!_.isUndefined(initialValues.company_id)}
                onChange={getCompanyAdminRoleList}
              >
                {companyOptions}
              </Select>
            </Form.Item>

            <Form.Item
              label={t("username")}
              name="username"
              rules={[
                {
                  required: true,
                  message: t("input_required"),
                },
              ]}
            >
              <Input maxLength={40} disabled={initialValues.company_admin_id} />
            </Form.Item>

            <Form.Item label={t("first_name")} name="first_name">
              <Input maxLength={64} />
            </Form.Item>

            <Form.Item label={t("last_name")} name="last_name">
              <Input maxLength={64} />
            </Form.Item>

            <Form.Item label={t("nickname")} name="nickname">
              <Input maxLength={50} />
            </Form.Item>

            <Form.Item
              label={t("email")}
              name="email"
              rules={[
                {
                  type: "email",
                  message: t("invalid_email"),
                },
                {
                  required: true,
                  message: t("input_required"),
                },
              ]}
            >
              <Input maxLength={128} />
            </Form.Item>

            <Form.Item
              label={t("moblie")}
              name="mobile"
              rules={[
                {
                  required: true,
                  message: "input_required",
                },
              ]}
            >
              <Tooltip>
                <Input
                  value={number}
                  onChange={onNumberChange}
                  addonBefore={
                    <Form.Item
                      name="mobile_prefix"
                      noStyle
                      rules={[
                        {
                          reqruied: true,
                          message: t("select_required"),
                        },
                      ]}
                    >
                      <Select style={{ width: 80 }}>
                        <Option value="+852">+852</Option>
                      </Select>
                    </Form.Item>
                  }
                  maxLength={15}
                  style={{ width: "100%" }}
                />
              </Tooltip>
            </Form.Item>

            <Form.Item
              label={t("admin_permission")}
              name="is_admin"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>

            <Form.Item
              label={t("company_admin_role")}
              name="company_admin_role_id"
              rules={[
                {
                  required: true,
                  message: t("select_required"),
                },
              ]}
            >
              <Select>{companyAdminRoleOptions}</Select>
            </Form.Item>

            {_.isUndefined(initialValues.company_admin_id) && (
              <Form.Item
                label={t("password")}
                name="password"
                rules={[
                  {
                    required: true,
                    message: t("input_required"),
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
            )}

            {_.isUndefined(initialValues.company_admin_id) && (
              <Form.Item
                label={t("confirm_password")}
                name="confirm_password"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: t("input_required"),
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(t("password_not_match"));
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            )}

            <Form.Item>
              <Button type="primary" htmlType="submit">
                {t("submit")}
              </Button>
            </Form.Item>
          </Card>
        </Col>
        <Col md={24} lg={8}>
          <Card>
            <h4>{t("last_active_time")}</h4>
            <p>
              {initialValues.utime
                ? moment.unix(initialValues.utime).format("YYYY-MM-DD HH:mm")
                : "Na"}
            </p>
            <h4>{t("create_time")}</h4>
            <p>
              {initialValues.ctime
                ? moment.unix(initialValues.ctime).format("YYYY-MM-DD HH:mm")
                : "Na"}
            </p>
          </Card>
        </Col>
      </Row>
    </Form>
  );
};

export default CompanyAdminIcInfoForm;
