import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Row, Col, Form, Select, Divider, Modal } from "antd";
import { useTranslation } from "react-i18next";
import * as debugLib from "debug";
import Button from "../../../t9UIComponents/t9Button";

const debug = debugLib("app:pages/company/slogan/list/Modal");

const { Option } = Select;

const InvoiceGroupModal = (props) => {
  const { t } = useTranslation();
  const {
    dataList,
    selectedRecord,
    modalVisible,
    setModalVisible,
    handleFormSubmit,
  } = props;
  const [invoiceGroupForm] = Form.useForm();
  const [invoiceArr, setInvoiceArr] = useState([]);
  const [companyUserId, setCompanyUserId] = useState(null);
  const [keyByDataList, setKeyByDataList] = useState({
    companyUserArr: {},
  });

  useEffect(() => {
    invoiceGroupForm.resetFields();
    setCompanyUserId(null);
    setInvoiceArr([]);
    if (selectedRecord.invoice_group_id !== 0) {
      setCompanyUserId(selectedRecord.company_user_id);
      setInvoiceArr(_.map(selectedRecord.invoiceArr, "invoice_id"));
    }
  }, [selectedRecord]);

  useEffect(() => {
    setKeyByDataList({
      companyUserArr: _.keyBy(dataList.companyUserArr, "company_user_id"),
    });
  }, [dataList]);

  const displayValidInvoice = () => {
    let filteredList = dataList.invoiceArr;
    if (selectedRecord.invoice_group_id === 0) {
      let { company_user_group_id } =
        keyByDataList.companyUserArr[companyUserId] || {};
      let filterClause = {};
      if (selectedRecord.invoice_group_id === 0) {
        if (
          !_.isUndefined(company_user_group_id) &&
          company_user_group_id !== 0
        ) {
          filterClause.company_user_id = _.map(
            _.filter(dataList.companyUserArr, { company_user_group_id }),
            "company_user_id"
          );
        } else {
          filterClause.company_user_id = [companyUserId];
        }
        filterClause = {
          ...filterClause,
          status: "pending",
        };
      }
      filteredList = _.filter(dataList.invoiceArr, (rc) => {
        return (
          _.includes(filterClause.company_user_id, rc.company_user_id) &&
          rc.status === "pending" &&
          rc.invoice_group_id === 0
        );
      });
    }
    return _.map(filteredList, (invoiceRc) => {
      let { first_name, last_name, company_user_id } =
        keyByDataList.companyUserArr[invoiceRc.company_user_id] || {};
      let { invoice_id, final_price } = invoiceRc;
      return (
        <Option value={invoice_id}>
          {`(${company_user_id}) ${first_name} ${last_name} $${final_price}`}
        </Option>
      );
    });
  };

  return (
    <Modal
      title={
        selectedRecord.invoice_group_id === 0 ? t("add_item") : t("edit_record")
      }
      visible={modalVisible}
      footer={null}
      width={1280}
      style={{ maxWidth: "95%" }}
      onCancel={() => {
        setModalVisible(false);
      }}
    >
      <Form
        form={invoiceGroupForm}
        name="invoiceGroupForm"
        layout="vertical"
        onFinish={(formData) => {
          handleFormSubmit({
            ...formData,
            invoice_id_list: invoiceArr,
            invoice_group_id: selectedRecord.invoice_group_id,
            company_user_id: companyUserId,
          });
        }}
      >
        <Row gutter={24}>
          <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item label={t("company_user")}>
              <Select
                disabled={selectedRecord.invoice_group_id !== 0}
                showSearch
                optionFilterProp="children"
                // onChange={onChange}
                // onSearch={onSearch}
                style={{ width: 300 }}
                placeholder={t("search_item")}
                value={companyUserId}
                onChange={(value) => {
                  setCompanyUserId(value);
                  setInvoiceArr([]);
                }}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {_.map(dataList.companyUserArr, (eachUser) => {
                  let { first_name, last_name, company_user_id } = eachUser;
                  return (
                    <Option value={company_user_id}>
                      {`(${company_user_id}) ${first_name} ${last_name}`}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item label={t("invoice")}>
              <Select
                disabled={selectedRecord.invoice_group_id !== 0}
                allowClear
                mode="multiple"
                style={{ width: 300 }}
                placeholder={t("search_item")}
                value={invoiceArr}
                onChange={(value) => setInvoiceArr(value)}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {displayValidInvoice()}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row justify="end">
          <Col>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={selectedRecord.invoice_group_id !== 0}
              >
                {t("submit")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default InvoiceGroupModal;
