import React, { useState, useEffect } from "react";
import { Avatar, Divider, notification, Tabs } from "antd";
// import 'antd/dist/antd.css';
import { useTranslation } from "react-i18next";
import Button from "../../../t9UIComponents/t9Button";
import * as Service from "../../../core/Service";
import NavAndSideFrame from "../../../components/NavAndSideFrame";
import BlogPostCategoryTable from "./BlogPostCategoryTable";
import BlogPostCategoryModal from "./BlogPostCategoryModal";
import NewsCategoryTable from "./NewsCategoryTable";

const debug = require("debug")("app:admin:client:src:BlogPostList");

const involvedModelName = "company";
const selectedKey = "blog_post_category";
const openKeys = "blog";
const tableIDName = "blog_post_id";

const { TabPane } = Tabs;

const BlogPostCategoryList = (props) => {
  const [loading, setLoading] = useState(true);
  const [selectedType, setSelectedType] = useState("blog");
  const [blogPostCategoryList, setBlogPostCategoryList] = useState([]);
  const [newsCategoryList, setNewsCategoryList] = useState([]);
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(0);

  const title = t("blog_post_category");

  useEffect(() => {
    getDataByType(selectedType);
  }, [selectedType]);

  const getBlogPostCategoryList = async () => {
    setLoading(true);
    let result = await Service.call("get", "/api/blog/post/category");
    if (result.status !== 1) {
      setLoading(false);
      return notification.error({
        message: t("error"),
        description: t(result.errorCode),
      });
    }
    let { blogPostCategoryArr } = result.data;
    // console.log("blogPostCategoryArr", blogPostCategoryArr);
    setBlogPostCategoryList(blogPostCategoryArr);
    setLoading(false);
  };

  const getNewsCategoryList = async () => {
    setLoading(true);
    let result = await Service.call("get", "/api/news/category");
    if (result.status !== 1) {
      setLoading(false);
      return notification.error({
        message: t("error"),
        description: t(result.errorCode),
      });
    }
    let { newsCategoryArr } = result.data;
    // console.log("newsCategoryArr", newsCategoryArr);
    setNewsCategoryList(newsCategoryArr);
    setLoading(false);
  };

  const handleFormSubmit = async (submitRecord) => {
    try {
      setLoading(true);
      let submit;
      let method;

      switch (submitRecord.type_name) {
        case "blog":
          method = submitRecord.blog_post_category_id === 0 ? "put" : "patch";
          submit = await Service.call(
            method,
            "/api/blog/post/category",
            submitRecord
          );
          break;
        case "news":
          method = submitRecord.news_category_id === 0 ? "put" : "patch";
          submit = await Service.call(
            method,
            "/api/news/category",
            submitRecord
          );
          break;
        default:
          break;
      }

      if (submit.status < 1) {
        notification.error({
          message: t("fail"),
          description: t(submit.errorCode),
        });
        return setLoading(false);
      }
      notification.success({
        message: t("success"),
      });
      setModalVisible(false);
      getDataByType(selectedType);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteRecord = async (deleteRecord) => {
    setLoading(true);
    let postData;
    let submit;

    switch (selectedType) {
      case "blog":
        postData = {
          blog_post_category_id: deleteRecord,
        };
        submit = await Service.call(
          "patch",
          `/api/blog/post/category/delete`,
          postData
        );
        break;

      case "news":
        postData = {
          news_category_id: deleteRecord,
        };
        submit = await Service.call(
          "patch",
          `/api/news/category/delete`,
          postData
        );
        break;

      default:
        break;
    }

    if (submit?.status < 1) {
      notification.error({
        message: t("fail"),
        description: t(submit?.errorCode),
      });
      setLoading(false);
    } else {
      notification.success({
        message: t("success"),
      });
      getDataByType(selectedType);
      setLoading(false);
      setModalVisible(false);
    }
  };

  const handleIsActiveChange = async (category_id, value) => {
    setLoading(true);
    let postData;
    let resp;

    switch (selectedType) {
      case "blog":
        postData = {
          blog_post_category_id: category_id,
          is_active: value,
        };
        resp = await Service.call(
          "patch",
          "/api/blog/post/category/active",
          postData
        );
        break;
      case "news":
        postData = {
          news_category_id: category_id,
          is_active: value,
        };
        resp = await Service.call(
          "patch",
          "/api/news/category/active",
          postData
        );
        break;
      default:
        break;
    }

    if (resp?.status < 1) {
      notification.error({
        message: t("fail"),
        description: t(resp?.errorCode),
      });
      setLoading(false);
    } else {
      notification.success({
        message: t("success"),
      });
      getDataByType(selectedType)
      setLoading(false);
      setModalVisible(false);
    }
  };

  const getDataByType = (type) => {
    switch (type) {
      case "blog":
        getBlogPostCategoryList();
        break;
      case "news":
        getNewsCategoryList();
        break;
      default:
        break;
    }
  }

  return (
    <NavAndSideFrame
      title={title}
      selectedKey={selectedKey}
      openKeys={openKeys}
    >
      <Button
        type="primary"
        onClick={() => {
          setModalVisible(true);
          setSelectedRecord({ blog_post_category_id: 0, news_category_id: 0 });
        }}
      >
        {t("add_item")}
      </Button>
      <Divider />

      <Tabs
        defaultActiveKey="blog"
        onChange={(key) => setSelectedType(key)}
      >
        <TabPane tab={t("blog")} key="blog">
          <BlogPostCategoryTable
            dataObj={blogPostCategoryList}
            adminChangeData={getBlogPostCategoryList}
            setSelectedRecord={(record) => setSelectedRecord(record)}
            handleDeleteRecord={(record) => handleDeleteRecord(record)}
            setModalVisible={(value) => setModalVisible(value)}
            isActiveChangeCallBack={(blog_post_category_id, value) => {
              handleIsActiveChange(blog_post_category_id, value);
            }}
            loading={loading}
          />
        </TabPane>
        <TabPane tab={t("news")} key="news">
          <NewsCategoryTable
            dataObj={newsCategoryList}
            adminChangeData={getNewsCategoryList}
            setSelectedRecord={(record) => setSelectedRecord(record)}
            handleDeleteRecord={(record) => handleDeleteRecord(record)}
            setModalVisible={(value) => setModalVisible(value)}
            isActiveChangeCallBack={(news_category_id, value) => {
              handleIsActiveChange(news_category_id, value);
            }}
            loading={loading}
          />
        </TabPane>
      </Tabs>

      <BlogPostCategoryModal
        selectedRecord={selectedRecord}
        dataObj={blogPostCategoryList}
        modalVisible={modalVisible}
        setModalVisible={(value) => {
          setModalVisible(value);
          getDataByType(selectedType);
        }}
        handleFormSubmit={(submitRecord) => handleFormSubmit(submitRecord)}
        loading={loading}
      />
    </NavAndSideFrame>
  );
};

export default BlogPostCategoryList;
