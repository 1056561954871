import React, { Component, useState, useEffect } from 'react';
import { Button } from 'antd';

const T9Button = (props) => {
  const [bgColour, setBgColour] = useState("#F4D35E");
  const [transformScale, setTransformScale] = useState(1);
  // const styles = {
  //   backgroundColor: `${bgColour}`,
  // };

  return (
    <Button
      {...props}
      style={{
        background: bgColour,
        color: 'black',
        border: 'none',
        textShadow: 'none',
        boxShadow: 'none',
        transform: `scale(${transformScale})`
        // borderColor: 'linear-gradient(to right, #000046, #1cb5e0)',
      }}
      onMouseEnter={() => {
        setBgColour("#FFD952")
        setTransformScale(1.1)
      }}
      onMouseLeave={() => {
        setBgColour("#F4D35E")
        setTransformScale(1)
      }}
    />
  )
}

export default T9Button
