import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { notification, Table, Layout } from "antd";
import * as Service from "../../core/Service";
import NavAndSideFrame from "../../components/NavAndSideFrame";


const ContactUsList = (props) => {
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const title = t("contact_us_record");
  const tableIDName = "contact_us_record_id";

  useEffect(() => {
    getAllContactUsLog();
  }, []);

  const getAllContactUsLog = async () => {
    try {
      setLoading(true);
      const response = await Service.call("get", "/api/contact_us/list");
      let {status, data, errorMessage} = response;

      if (status !== 1 || !response) {
        return notification.error({
          message: t("error"),
          description: errorMessage
        });
      }

      let contactUsRecordList = data;
      setDataList(
        contactUsRecordList,
      );
    } catch (error) {
      console.error("error >>> ", error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: t("name"),
      dataIndex: "name",
    },
    {
      title: t("email"),
      dataIndex: "email",
    },
    {
      title: t("mobile"),
      dataIndex: "mobile",
    },
    {
      title: t("question"),
      dataIndex: "question",
    }
  ];

  return (
    <NavAndSideFrame title={title}>
      <Layout>
        <Table
          rowKey={tableIDName}
          scroll={{ x: "max-content" }}
          dataSource={dataList}
          columns={columns}
          bordered
          size="small"
          pagination={{ pageSize: 50 }}
          loading={loading}
        />
      </Layout>
    </NavAndSideFrame>
  );
};

export default ContactUsList;
