import React, { useState, useEffect } from "react";
import { Divider, notification } from "antd";
// import 'antd/dist/antd.css';
import _ from "lodash";
import { useTranslation } from "react-i18next";
import Button from "../../../t9UIComponents/t9Button";
import * as Service from "../../../core/Service";
import * as Main from "../../../core/Main";
import NavAndSideFrame from "../../../components/NavAndSideFrame";
import InvoiceGroupListTable from "./InvoiceGroupListTable";
import InvoiceGroupModal from "./InvoiceGroupModal";

const debug = require("debug")("app:admin:client:src:BlogPostList");

const involvedModelName = "invoice_group";
const tableIDName = "invoice_group_id";

const InvoiceGroupList = (props) => {
  const [loading, setLoading] = useState(true);
  const [dataList, setDataList] = useState({});
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [invoicePDF, setInvoicePDF] = useState("");
  const title = t("invoice");

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    try {
      setLoading(true);
      let resp = await Service.call("get", "/api/invoice/list");
      if (resp.status !== 1) {
        setLoading(false);
        return notification.error({
          message: t("error"),
          description: t(resp.errorCode) 
        });
      }
      let { invoiceGroupArr, invoiceArr, companyUserArr, companyUserGroupArr } =
        resp.data;
      let keyBy_companyUserArr = _.keyBy(companyUserArr, "company_user_id");
      let keyBy_companyUserGroupArr = _.keyBy(
        companyUserGroupArr,
        "company_user_group_id"
      );
      let groupBy_invoiceArr = _.groupBy(invoiceArr, "invoice_group_id");
      invoiceGroupArr = _.map(invoiceGroupArr, (eachInvoiceGroup) => {
        let { company_user_id, company_user_group_id, invoice_group_id } =
          eachInvoiceGroup;
        let companyUserRc = keyBy_companyUserArr[company_user_id];
        let { first_name, last_name } = companyUserRc || {};
        let companyUserGroupRc =
          keyBy_companyUserGroupArr[company_user_group_id] || {};
        let selectedInvoiceArr = groupBy_invoiceArr[invoice_group_id] || [];
        let status = displayInvoiceGroupStatus(selectedInvoiceArr);
        return {
          ...eachInvoiceGroup,
          company_user_name: `${first_name} ${last_name}`,
          company_user_group_name: !_.isEmpty(companyUserGroupRc)
            ? companyUserGroupRc.name
            : "-",
          invoiceArr: selectedInvoiceArr,
          status,
        };
      });
      setDataList({
        invoiceGroupArr: _.orderBy(invoiceGroupArr, ["ctime"], ["desc"]),
        invoiceArr,
        companyUserArr,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const displayInvoiceGroupStatus = (invoiceArr) => {
    let str = "payment_confirmed";
    _.each(invoiceArr, (eachInvoice) => {
      let { status } = eachInvoice;
      if (status === "voided" && invoiceArr.length === 1) {
        str = "voided";
        return false;
      }
      if (status === "pending") {
        str = "pending";
        return false;
      }
    });
    return str;
  };

  const handleFormSubmit = async (submitRecord) => {
    try {
      setLoading(true);
      let { invoice_id_list } = submitRecord;
      if (_.isEmpty(invoice_id_list)) {
        return notification.error({
          message: t("error"),
          description: t("invoice_list_should_not_be_empty"),
        });
      }
      let submit = await Service.call(
        "put",
        "/api/invoice/group",
        submitRecord
      );
      if (submit.status < 1) {
        notification.error({
          message: t("fail"),
          description: t(submit.errorCode) 
        });
        setLoading(false);
      } else {
        notification.success({
          message: t("success"),
        });
        getAllData();
        setModalVisible(false);
        setInvoicePDF(submit.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const handleGenerate = async (records) => {
    let { company_user_id, invoice_group_id, company_user_group_id, status } = records;
    try {
      setLoading(true);
      let submit = await Service.call(
        "post",
        "/api/invoice/generateInvoicePDF",
        {invoice_group_id, company_user_group_id, company_user_id, status }
      );
      if (submit.status < 1) {
        notification.error({
          message: t("fail"),
          description: t(submit.errorCode) 
        });
        setLoading(false);
      } else {
        notification.success({
          message: t("success"),
        });
        getAllData();
        setModalVisible(false);
        setInvoicePDF(submit.data); // To find out which invoice / receipt
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const handlePaided = async (invoice_group_id) => {
    try {
      setLoading(true);
      let submit = await Service.call(
        "patch",
        "/api/invoice/group/status",
        {invoice_group_id}
      );
      if (submit.status < 1) {
        notification.error({
          message: t("fail"),
          description: t(submit.errorCode) 
        });
        setLoading(false);
      } else {
        notification.success({
          message: t("success"),
        });
        getAllData();
        setModalVisible(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <NavAndSideFrame title={title}>
      <Button
        type="primary"
        onClick={() => {
          setModalVisible(true);
          setSelectedRecord({ invoice_group_id: 0 });
        }}
      >
        {t("add_item")}
      </Button>
      <Divider />
      <InvoiceGroupListTable
        dataList={dataList}
        adminChangeData={getAllData}
        setSelectedRecord={(record) => setSelectedRecord(record)}
        setModalVisible={(value) => setModalVisible(value)}
        handlePaided={(value) => handlePaided(value)}
        handleGenerate={(value) => handleGenerate(value)}
        invoicePDF={invoicePDF}
        loading={loading}
      />
      <InvoiceGroupModal
        selectedRecord={selectedRecord}
        dataList={dataList}
        modalVisible={modalVisible}
        setModalVisible={(value) => {
          setModalVisible(value);
          getAllData();
        }}
        handleFormSubmit={(submitRecord) => handleFormSubmit(submitRecord)}
      />
    </NavAndSideFrame>
  );
};

export default InvoiceGroupList;
