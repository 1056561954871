import React, { useState, useEffect } from 'react';
import {
  Tabs,
  notification
} from 'antd';
import 'antd/dist/antd.css';
import _ from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';

import { useSelector } from 'react-redux';
import * as Service from '../../../core/Service';

import NavAndSideFrame from '../../../components/NavAndSideFrame';
import CompanyNameForm from '../../../components/CompanySetting/CompanyNameForm';

const { TabPane } = Tabs;
const selectedKey = "company_setting";
const openKeys = "settings"

const CompanySetting = (props) => {
  const location = useLocation();
  const companyInfo = location?.state?.companyInfo || {};
  const app = useSelector((state) => state.app);
  const { t } = useTranslation();
  const [companyObj, setCompany] = useState({});
  const [lock, setLock] = useState(_.isEmpty(companyObj));
  useEffect(() => {
    if (!_.isEmpty(companyInfo)) {
      setCompany(companyInfo);
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(companyObj)) {
      setLock(false);
    }
  }, [companyObj]);

  useEffect(() => {
    refreshCompanyInfo();
  }, [])

  const refreshCompanyInfo = async () => {
    // IF company admin || clicking company
    if (!_.isEmpty(companyInfo) || app.company_admin.company_admin_id) {
      let method = "get";
      let url = "/api/company/name";

      let resp = await Service.call(method, url)
      if (!resp || resp.status !== 1) {
        notification.error({
          message: `${t("error")} - ${resp.errorMessage} [${resp.errorCode}]`,
        });
        return;
      }
      let { data } = resp;
      setCompany(data);
    }
  }

  return (
    <NavAndSideFrame title={`${t("company_setting")}`} selectedKey={selectedKey} openKeys={openKeys}>
      <Tabs type="line" defaultActiveKey="info_form">
        <TabPane tab={t('company_info')} key="info_form">
          <CompanyNameForm
            initialValues={companyObj}
            refreshCompanyInfo={refreshCompanyInfo}
          />
        </TabPane>
      </Tabs>
    </NavAndSideFrame>
  )
}

export default CompanySetting;
