import React, { Component } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin, message } from "antd";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as _ from "lodash";
import { ActionCreators } from "./redux/actions";

export default function PrivateRoute(props) {
  const {
    component: AppComponent,
    privilege,
    module,
  } = props;

  const app = useSelector((state) => state.app);
  const { companyConfigRc } = app.company;

  if (app.auth === null) {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return (
      <div style={{ position: "absolute", top: "50%", left: "50%" }}>
        <Spin indicator={antIcon} />
      </div>
    );
  }

  if (module && privilege === "company" && !companyConfigRc[`allow_${module}`]) {
    return <Redirect to="/company/home" />;
  }
  return (
    <Route
      render={() => {
        if (app.auth) {
          if (privilege === "admin" && app.admin.admin_id) {
            return <AppComponent />;
          }
          if (privilege === "company" && (app.company_admin.company_admin_id || app.company_user.company_user_id)) {
            return <AppComponent />;
          }
          if (privilege === "user" && app.user.user_id) {
            return <AppComponent />;
          }
          if (privilege === "admin" && !app.admin.admin_id) {
            return <Redirect to="/company/home" />;
          }
          if (privilege === "company" && !app.company_admin.company_admin_id) {
            return <Redirect to="/admin/home" />;
          }
        }

        return <Redirect to="/company/login" />;
      }}
    />
  );
}
